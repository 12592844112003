<app-header></app-header>
<div class="inner-background-two">
    <img src="assets/images/inner_banner.jpg">
    <div class="latest-overlay-two">
    <div class="container"><h2>Audit</h2></div>
  </div>
  </div>
  <section class="inner-post">
    <div class="container">
      <div class="row">
         <div class="col-lg-9 col-sm-12 col-12">
        
          
           </div>
         <div class="col-lg-3 col-sm-12 col-12">
         <div class="management-section-two">
        <h2>COVID management &amp; dead body disposal team</h2>
        <ul>
          <li><a routerLink="/notice">Notice</a></li>
          <li><a href="">Lockdown Order</a></li>
          <li><a href="">Covid Control Room</a></li>
          <li><a href="">Prachesta scheme - to outbreak of COVID-19.</a></li>
        </ul>
      </div>
      <div class="notice-yu">
        <h2>Notice</h2>
        <ul>
          <li>Renovation of Community Toilet & Public Toilet at different locations under Municipality. NIT No : 15 of 2021-22 under memo no. 2570/RM dated 24.01.22</li>
        </ul>
      </div>
         </div>
      </div>
    </div>
  </section>
 