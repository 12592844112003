import { Injectable } from '@angular/core';
import { ActivatedRouteSnapshot, CanActivate, Router, RouterStateSnapshot } from '@angular/router';
import { Observable } from 'rxjs';
//import { Observable } from 'rxjs';
import { AuthService } from './services/auth.service';
import { map, take } from 'rxjs/operators';


@Injectable({
  providedIn: 'root'
})
export class AuthGuard implements CanActivate {

  constructor(private authService: AuthService,private router: Router){}

  // canActivate():boolean{
  //   if(this.authService.loggedIn()){
  //     return true
  //   }else{
  //     this.router.navigate(['adminLogin'])
  //     return false
  //   }
  // }


  canActivate(next: ActivatedRouteSnapshot, state: RouterStateSnapshot): Observable<boolean> {
    return this.authService.isLoggedIn.pipe(take(100), map((isLoggedIn: boolean) => {
      if (!isLoggedIn) {
        this.router.navigate(['/adminLogin']);
        return false;
      }
      return true;
    })
    );
  }
  
}


