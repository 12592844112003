import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-departmentalwise-contacts',
  templateUrl: './departmentalwise-contacts.component.html',
  styleUrls: ['./departmentalwise-contacts.component.css']
})
export class DepartmentalwiseContactsComponent implements OnInit {

  constructor() { }

  ngOnInit(): void {
  }

}
