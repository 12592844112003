<app-header></app-header>
  <div class="inner-background-two">
    <img src="assets/images/inner_banner.jpg">
    <div class="latest-overlay-two">
    <div class="container"><h2>Contact Us</h2></div>
  </div>
  </div>
  <section class="inner-post">
    <div class="container">
      <div class="row">
         <div class="col-lg-9 col-sm-12 col-12">
					
         <div>
           <h3 style="font-size: larger; font-weight: 700;"> <u>Our Address</u></h3>
      <br>
      <p>Ranaghat Municipality, Subhas Avenue, PO+PS: Ranaghat, Dist: Nadia, 
        State: West Bengal, India</p>
      <p> Pin-741201</p>

        <p>T: (03473) 210 -030</p>

        <p>Fax: (03473) 210-029</p>

        <p>Whatsapp: 9434382222 </p>
         </div>
				
			
          <p>
            Web: <a href="http://www.ranaghatmunicipality.org">www.ranaghatmunicipality.org</a>
          <br />
            E-mail: <a href="mailto:ranaghatmunicipality@gmail.com">ranaghatmunicipality@gmail.com</a>
          </p>
          <div style="margin-top:30px">
            <iframe width="100%" height="450" frameborder="0" scrolling="no" marginheight="0" marginwidth="0" 
            src="https://maps.google.co.in/maps?f=q&amp;source=s_q&amp;hl=en&amp;geocode=&amp;q=ranaghat+municipality&amp;sll=20.98352,82.752628&amp;sspn=46.222799,86.572266&amp;t=h&amp;ie=UTF8&amp;hq=municipality&amp;hnear=Ranaghat,+Nadia,+West+Bengal&amp;fll=23.175706,88.567561&amp;fspn=0.001423,0.002642&amp;st=109146043351405611748&amp;rq=1&amp;ev=zi&amp;split=1&amp;ll=23.175779,88.566988&amp;spn=0.001423,0.002642&amp;z=14&amp;iwloc=A&amp;cid=9103250351606983274&amp;output=embed&amp;amp;wmode=transparent&amp;amp;wmode=transparent&amp;amp;wmode=transparent&amp;amp;wmode=transparent&amp;amp;wmode=transparent&amp;amp;wmode=transparent&amp;amp;wmode=transparent&amp;amp;wmode=transparent&amp;amp;wmode=transparent&amp;amp;wmode=transparent">
            </iframe></div>
           </div>
      </div>
    </div>
  </section>


