import { Component, OnInit } from '@angular/core';
import { NgxSpinnerService } from "ngx-spinner";
import { ApiService } from 'src/app/services/api.service';

@Component({
  selector: 'app-municipality-operational-structure',
  templateUrl: './municipality-operational-structure.component.html',
  styleUrls: ['./municipality-operational-structure.component.css']
})
export class MunicipalityOperationalStructureComponent implements OnInit {
 getAllStructure:any
  constructor(private apiService: ApiService,
    private SpinnerService: NgxSpinnerService,) { }

  ngOnInit(): void {
    this.apiService.municipalityOrg().subscribe((res:any )=> {
      this.SpinnerService.show();
      this.getAllStructure = res.data;
      this.SpinnerService.hide();
  },
  (error:any )=> {

  });
  }

}
