<app-header></app-header>
<div class="inner-background-two">
    <img src="assets/images/inner_banner.jpg">
    <div class="latest-overlay-two">
    <div class="container"><h2>Contact Us</h2></div>
  </div>
  </div>
  <section class="inner-post">
    <div class="container">
      <div class="row">
         <div class="col-lg-9 col-sm-12 col-12">
            <h4 style="font-weight: 700;">1. <u>Community Toilet
            </u>			
            </h4>
            <br>
            <div class="table-responsive">
                <table  class="table table-bordered table-striped" >
                    <thead>
                        <tr>
                            <td style="font-size:large; font-weight:bolder; color: black;text-align: left;">S.No</td>
                            <td style="font-size:large; font-weight:bolder; color: black;width:304px;text-align: left;">List of Community Toilet
                            </td>
                            <td style="font-size:large; font-weight:bolder; color: black;width:304px;text-align: left;">Landmark
                            </td>
                            <td style="font-size:large; font-weight:bolder; color: black;width:550px;text-align: left;">Type of Area(Slum,Residential,Commercial,Lake/Pond/River, Any other)
                            </td>
                            <td style="font-size:large; font-weight:bolder; color: black;width:550px;text-align: left;">Ward No
                            </td>
                        </tr>
                    </thead>
                    <tbody>
                        <tr>
                           <td style=" font-size:medium; font-weight: 500;text-align:left">1</td>
                           <td style=" font-size:medium; font-weight: 500;text-align:left">Talpukurpara Sweepers Qtr

                        </td>
                           <td style=" font-size:medium; font-weight: 500;text-align:left">Talpukurpara
                        </td>
                           <td style=" font-size:medium; font-weight: 500;text-align:left">Residential 
                        </td>
                           <td style=" font-size:medium; font-weight: 500;text-align:left">19</td>
                        </tr>
                        <tr>
                            <td style=" font-size:medium; font-weight: 500;text-align:left">2</td>
                            <td style=" font-size:medium; font-weight: 500;text-align:left">Gandhipark Sweepers  Qtr
                            </td>
                            <td style=" font-size:medium; font-weight: 500;text-align:left">Madanmohan Colony
                            </td>
                            <td style=" font-size:medium; font-weight: 500;text-align:left">Residential 
                            </td>
                            <td style=" font-size:medium; font-weight: 500;text-align:left">12</td>
                         </tr>
                         <tr>
                            <td style=" font-size:medium; font-weight: 500;text-align:left">3</td>
                            <td style=" font-size:medium; font-weight: 500;text-align:left">Mohaprovupara

                            </td>
                            <td style=" font-size:medium; font-weight: 500;text-align:left">In front of Mohaprovu Tank
                            </td>
                            <td style=" font-size:medium; font-weight: 500;text-align:left">Slum (Near Kapuriapara)
                            </td>
                            <td style=" font-size:medium; font-weight: 500;text-align:left">16</td>
                         </tr>
                    </tbody>
                </table>
            </div>	

            <br>
            <br>
            <h4 style="font-weight: 700;">2. <u>Public Toilet

            </u>			
            </h4>
            <br>
            <div class="table-responsive">
                <table  class="table table-bordered table-striped" >
                    <thead>
                        <tr>
                            <td style="font-size:large; font-weight:bolder; color: black;text-align: left;">S.No</td>
                            <td style="font-size:large; font-weight:bolder; color: black;width:304px;text-align: left;">List of Community Toilet
                            </td>
                            <td style="font-size:large; font-weight:bolder; color: black;width:304px;text-align: left;">Landmark
                            </td>
                            <td style="font-size:large; font-weight:bolder; color: black;width:550px;text-align: left;">Type of Area(Slum,Residential,Commercial,Lake/Pond/River, Any other)
                            </td>
                            <td style="font-size:large; font-weight:bolder; color: black;width:550px;text-align: left;">Ward No
                            </td>
                        </tr>
                    </thead>
                    <tbody>
                        <tr>
                           <td style=" font-size:medium; font-weight: 500;text-align:left">1</td>
                           <td style=" font-size:medium; font-weight: 500;text-align:left">Netaji Bazar
                        </td>
                           <td style=" font-size:medium; font-weight: 500;text-align:left"> Beside Municipality
                        </td>
                           <td style=" font-size:medium; font-weight: 500;text-align:left">Commercial
 
                        </td>
                           <td style=" font-size:medium; font-weight: 500;text-align:left">4</td>
                        </tr>
                        <tr>
                            <td style=" font-size:medium; font-weight: 500;text-align:left">2</td>
                            <td style=" font-size:medium; font-weight: 500;text-align:left">Informal Market
                            </td>
                            <td style=" font-size:medium; font-weight: 500;text-align:left">Near Municipality
                            </td>
                            <td style=" font-size:medium; font-weight: 500;text-align:left">Commercial
 
                            </td>
                            <td style=" font-size:medium; font-weight: 500;text-align:left">13</td>
                         </tr>
                         <tr>
                            <td style=" font-size:medium; font-weight: 500;text-align:left">3</td>
                            <td style=" font-size:medium; font-weight: 500;text-align:left">Madanmohan Bus Stand
                            </td>
                            <td style=" font-size:medium; font-weight: 500;text-align:left">Monoroma Hospital
                            </td>
                            <td style=" font-size:medium; font-weight: 500;text-align:left">Commercial
                            </td>
                            <td style=" font-size:medium; font-weight: 500;text-align:left">11</td>
                         </tr>
                         <tr>
                            <td style=" font-size:medium; font-weight: 500;text-align:left">4</td>
                            <td style=" font-size:medium; font-weight: 500;text-align:left">Rathtola Bus Stand
                            </td>
                            <td style=" font-size:medium; font-weight: 500;text-align:left">Rathtala
                            </td>
                            <td style=" font-size:medium; font-weight: 500;text-align:left">Commercial
                            </td>
                            <td style=" font-size:medium; font-weight: 500;text-align:left">19</td>
                         </tr>
                         <tr>
                            <td style=" font-size:medium; font-weight: 500;text-align:left">5</td>
                            <td style=" font-size:medium; font-weight: 500;text-align:left">Binodini Park
                            </td>
                            <td style=" font-size:medium; font-weight: 500;text-align:left">Happy Club
                            </td>
                            <td style=" font-size:medium; font-weight: 500;text-align:left">River

                            </td>
                            <td style=" font-size:medium; font-weight: 500;text-align:left">2</td>
                         </tr>
                         <tr>
                            <td style=" font-size:medium; font-weight: 500;text-align:left">6</td>
                            <td style=" font-size:medium; font-weight: 500;text-align:left">Swargadawar road

                            </td>
                            <td style=" font-size:medium; font-weight: 500;text-align:left">tahna para
                            </td>
                            <td style=" font-size:medium; font-weight: 500;text-align:left">Residential
                            </td>
                            <td style=" font-size:medium; font-weight: 500;text-align:left">10</td>
                         </tr>
                         <tr>
                            <td style=" font-size:medium; font-weight: 500;text-align:left">7</td>
                            <td style=" font-size:medium; font-weight: 500;text-align:left">Rathranga road
                            </td>
                            <td style=" font-size:medium; font-weight: 500;text-align:left">Rathdanga Road
                            </td>
                            <td style=" font-size:medium; font-weight: 500;text-align:left">Residential
                            </td>
                            <td style=" font-size:medium; font-weight: 500;text-align:left">16</td>
                         </tr>
                         <tr>
                            <td style=" font-size:medium; font-weight: 500;text-align:left">8</td>
                            <td style=" font-size:medium; font-weight: 500;text-align:left">School Lane 
                            </td>
                            <td style=" font-size:medium; font-weight: 500;text-align:left">Near Chairman House

                            </td>
                            <td style=" font-size:medium; font-weight: 500;text-align:left">River

                            </td>
                            <td style=" font-size:medium; font-weight: 500;text-align:left">7</td>
                         </tr>
                         <tr>
                            <td style=" font-size:medium; font-weight: 500;text-align:left">9</td>
                            <td style=" font-size:medium; font-weight: 500;text-align:left">Abakash Park
 
                            </td>
                            <td style=" font-size:medium; font-weight: 500;text-align:left">Thana para
                            </td>
                            <td style=" font-size:medium; font-weight: 500;text-align:left">River

                            </td>
                            <td style=" font-size:medium; font-weight: 500;text-align:left">10</td>
                         </tr>
                    </tbody>
                </table>
            </div>
        
           </div>
      </div>
    </div>
  </section>



