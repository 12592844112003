import { Injectable } from '@angular/core';
import { UserModel } from './user-model';
import { ApiService } from './../services/api.service';
import { Router } from '@angular/router';
import { BehaviorSubject } from 'rxjs';
import { ToastrService } from 'ngx-toastr';
import { NotificationService } from './../services/notification.service'


const STORAGE_KEY = "appStorage";
const TOKEN_KEY = "accessToken";
@Injectable({
  providedIn: 'root'
})
export class AuthService {
  menuList: any;
  dataMenuList: any = [];

  private loggedIn: BehaviorSubject<boolean> = new BehaviorSubject<boolean>(false);
  private navItems:BehaviorSubject<[]> = new BehaviorSubject<[]>([]);
  userModel: UserModel = new UserModel();

  get isLoggedIn() {
    return this.loggedIn.asObservable();
  }

  get roleMenus(){
    return this.navItems.asObservable();
  }
  constructor(private apiService: ApiService, private router: Router,
    private toastrService: ToastrService,
    private notifyService: NotificationService) { }

  // loggedIn(){
  //   return !!localStorage.getItem('token')
  // }

  private _user: UserModel | undefined;
  private _accessToken: String = '';

  get user(): UserModel {
    return this._user || this.dataFromStore;
  }
  set user(userModel: UserModel) {
    this._user = userModel;
  }

  get accessToken(): String {
    return this._accessToken || this.tokenFromStore;
  }
  set accessToken(accessToken: String) {
    this._accessToken = accessToken;
  }

  login(param: any) {
    this.apiService.adminLogin(param).subscribe((res: any) => {
      if (res.result == "Success") {
        this.resetcredentials();
        this.loggedIn.next(true);      

        this.userModel.userId = res.data.id;
        this.userModel.firstName = res.data.firstName;
        this.userModel.lastName = res.data.lastName;
        this.userModel.roleId = res.data.adminRoleId;
        sessionStorage.setItem('LoginName', res.data.loginName);
        sessionStorage.setItem('RoleID', res.data.adminRoleId);
        //to save in locaStorage 
        localStorage.setItem('userrole',JSON.stringify(res.data.adminRoleId)); 
        console.log('role: ', sessionStorage.getItem('RoleID'));
        sessionStorage.setItem('showMenu', 'admin');
        sessionStorage.setItem('showLogin', 'login');        
        this.storeToken = res.data.id + res.data.adminRoleId;
        this.getMenus(res.data.adminRoleId);        
      }
      else{
        //alert('Incorrect User Name and Password');
        this.toastrService.warning('Incorrect User Name and Password');
        //this.notifyService.showInfo("Login info", "Incorrect User Name and Password")
      }
    });
  }


 getMenus(adminId: any) {
    this.apiService.adminMenu(adminId).subscribe((res: any) => {
      if (res.result == "Success") {
        this.userModel.sidemenu = res.data;
        this.menuList = res.data;
        sessionStorage.setItem('MENUITEMLIST', JSON.stringify(this.menuList));
        this.storeData = this.userModel;
        this.dataMenuList = this.userModel;
        this.navItems.next(res.data);
        this.router.navigate(['adminDashboard']).then(() => {
          window.location.reload();
        });
      }
    });
  } 

  getToken() {
    return localStorage.getItem('token')
  }

  set storeData(userModel: UserModel) {
    localStorage.setItem(STORAGE_KEY, JSON.stringify(userModel));
  }
  get dataFromStore(): UserModel {
    let data: any = localStorage.getItem(STORAGE_KEY) || {};
    let model: any = new UserModel();
    for (let prop in JSON.parse(data)) {
      model[prop] = data[prop];
    }
    return model;
  }


  set storeToken(token: string) {
    localStorage.setItem(TOKEN_KEY, token);
  }
  get tokenFromStore(): string {
    let data: any = localStorage.getItem(TOKEN_KEY) || null;
    return data;
  }


  public loadJsFile(url: any) {
    let node = document.createElement('script');
    node.src = url;
    node.type = 'text/javascript';
    document.getElementsByTagName('head')[0].appendChild(node);
  }


  resetcredentials() {
    this.loggedIn.next(false);
    localStorage.clear();
    sessionStorage.clear();
  }

  logout() {
    this.router.navigate(['/adminLogin']);
    this.resetcredentials();
  }

  accessMenuFromStorage(){
    let accessObj:any=this.user.sidemenu;
    console.log('accesobj: ',accessObj);
    this.navItems.next(accessObj)
    this.loggedIn.next(true);
  }

  reloadLoginPage(){
    this.router.navigate(['/adminLogin']);
    localStorage.setItem('loginpage', 'loginpage')
    //location.reload();
  }
 
}