<app-header></app-header>
<div class="main-wapper">
  <div class="wapper-body">
    <div class="row">
      <div class="col-lg-12 col-md-12 col-sm-12 col-xs-12 col-mob-12">
        <div class="top-panel">
          <form>
            <div class="form-group">
              <input type="text" name="text" (keyup)="searchTable($event)" placeholder="Search files">
              <a href="#" class="src-icon"><img src="assets/assetsAdmin/img/loupe.png"></a>
            </div>
          </form>
          <a href="javascript:void(0);" class="add-btn"><i class="fa fa-plus" (click)="openPopupAdd()"
              aria-hidden="true" title="Add Page"></i></a>
        </div>
        <div class="bottom-panel">
          <div class="table-responsive">
            <table class="table table-striped jambo_table">
              <thead>
                <tr style="color: black;">
                  <th>Sl No</th>
                  <th>Title</th>                  
                  <th>Publishing Date</th>
                  <th>Action</th>
                </tr>
              </thead>
              <tbody *ngFor="let item of tableData;let i=index">
                <tr>
                  <td>{{(pager.startIndex -1) + i + 2}}</td>
                  <td>{{item.title}}</td>                  
                  <td>{{item.publishingDate}}</td>
                  <td class="last">
                    <div class="action-button">
                      <!-- <a href="javascript:void(0);" class="plus-btn"><i class="fa fa-plus" aria-hidden="true"></i></a> -->
                      <a href="javascript:void(0);" class="edit-btn"><i class="fa fa-edit"
                          (click)="openPopupEdit(item.id)" aria-hidden="true" title="Edit"></i></a>
                      <!-- <a href="javascript:void(0);" class="delete-btn"><i class="fa fa-times" aria-hidden="true"></i></a> -->
                    </div>
                  </td>
                </tr>

              </tbody>
            </table>
          </div>

    <!-- #region  Pagination-->
      <div class="row">
        <div class="col-md-5">
          <div class="dataTables_info" *ngIf="totalCount>0">Showing {{pager.startIndex +1}} to {{pager.endIndex+1}}
            of {{totalCount}} entries</div>
        </div>

        <div class="col-md-7">
          <div class="dataTables_paginate paging_simple_numbers">
            <ul *ngIf="pager.pages && pager.pages.length" class="pagination">
              <li class="paginate_button previous disabled" ng-disabled="true"
                [ngClass]="{'disabled':pager.currentPage === 1}">
                <a aria-controls="datatable-fixed-header" (click)="getallNewsEvent(1)">First</a>
              </li>
              <li class="paginate_button previous disabled" [ngClass]="{disabled:pager.currentPage === 1}">
                <a aria-controls="datatable-fixed-header"
                  (click)="getallNewsEvent(pager.currentPage - 1)">Previous</a>
              </li>
              <li class="paginate_button previous disabled" *ngFor="let page of pager.pages"
                [ngClass]="{active:pager.currentPage === page}">
                <a aria-controls="datatable-fixed-header" (click)="getallNewsEvent(page)">{{page}}</a>
              </li>
              <li class="paginate_button previous disabled"
                [ngClass]="{disabled:pager.currentPage === pager.totalPages}">
                <a aria-controls="datatable-fixed-header"
                  (click)="getallNewsEvent(pager.currentPage + 1)">Next</a>
              </li>
              <li class="paginate_button previous disabled"
                [ngClass]="{disabled:pager.currentPage === pager.totalPages}">
                <a aria-controls="datatable-fixed-header" (click)="getallNewsEvent(pager.totalPages)">Last</a>
              </li>
            </ul>
          </div>
        </div>
      </div>
    <!-- #endregion -->
        </div>
      </div>
    </div>
  </div>
</div>

<ngx-spinner bdColor="rgba(51, 51, 51, 0.8)" size="default" type="ball-spin-clockwise">
  <p style="color: white">Please Wait... </p>
</ngx-spinner>

<!---pop-new-poroject-->
<div class="new-pop" class="modal" [ngStyle]="{'display':displayStyleAdd}">
  <div class="new-pop-body"><a href="javascript:void(0);" (click)="closePopupAdd()" class="cls-new-pop"><img
        src="assets/assetsAdmin/img/close.png" /></a>
    <h4>Add News & Events</h4>

    <form id="contact-form" role="form" [formGroup]="adminNewseventForm">
      <div class="controls">
        <div class="row">
          <div class="col-md-4">
            <div class="form-group">
              <label for="form_name">Title *</label>
              <input id="form_name" type="text" name="subject" formControlName="title" class="form-control"
                required="required" data-error="Name is required.">
              <div *ngIf="f1.title.invalid && (f1.title.dirty || f1.title.touched)" class="alert">
                <div *ngIf="f1.title.errors.required" style="color: red;">
                  Please enter title
                </div>
              </div>
            </div>
          </div>
          <div class="col-md-4">
            <div class="form-group">
              <label for="form_route">Body *</label>
              <input id="form_route" type="text" name="financial" formControlName="body" class="form-control"
                required="required" data-error="Route is required.">
              <div *ngIf="f1.body.invalid && (f1.body.dirty || f1.body.touched)" class="alert">
                <div *ngIf="f1.body.errors.required" style="color: red;">
                  Please enter body
                </div>
              </div>
            </div>
          </div>
          <div class="col-md-4">
            <!-- <div class="form-group">
              <label for="form_route">Blurb</label>
              <input id="form_route" type="text" name="memo" formControlName="blurb" class="form-control"
                required="required" data-error="Route is required.">
            </div> -->
          </div>
        </div>
        <div class="row">
          <div class="col-md-6">
            <div class="form-group">
              <label for="form_cption">Date of Publishing</label>
              <input id="form_cption" type="date" name="publishing" formControlName="publishingdate"
                class="form-control" (change)="SelectPublishingDate($event)" [(ngModel)]="publishingdate">
              <div *ngIf="f1.publishingdate.invalid && (f1.publishingdate.dirty || f1.publishingdate.touched)"
                class="alert">
                <div *ngIf="f1.publishingdate.errors.required" style="color: red;">
                  Please enter publishingdate
                </div>
              </div>
            </div>
          </div>
          <div class="col-md-6">
            <div class="form-group">
              <label for="form_email">Date of Event</label>
              <input id="form_email" type="date" name="closing" formControlName="eventdate" class="form-control"
              (change)="SelectEventDate($event)" [(ngModel)]="eventdate">
              <div *ngIf="f1.eventdate.invalid && (f1.eventdate.dirty || f1.eventdate.touched)" class="alert">
                <div *ngIf="f1.eventdate.errors.required" style="color: red;">
                  Please enter eventdate
                </div>
              </div>
            </div>
          </div>
        </div>
        <div class="row">
          <!-- <div class="col-md-12">
              <div class="form-group file-area">
                <label for="images">File should be less than 4mb</label>
                <input type="file" name="images" id="images" required="required" multiple="multiple"/>
                <div class="file-dummy">
                  <div class="success">Great, your files are selected. Keep on.</div>
                  <div class="default">Please select some files  Or Drop File Here</div>
                </div>
              </div>
            </div> -->
          <!-- <div class="col-md-4">
                <label>Select File</label>
                <input type="file" (change)="onSelectFile($event)" class="file">
                <font color="sky blue">(Maximum 3 MB)</font>
            </div> -->
          <div class="col-md-4">
            <label>Type</label>&nbsp;
            <select id="type" name="type" formControlName="type" class="form-control">
              <option value="E">Event</option>
              <option value="N">News</option>
            </select>
          </div>
          <div *ngIf="f1.type.invalid && (f1.type.dirty || f1.type.touched)" class="alert">
            <div *ngIf="f1.type.errors.required" style="color: red;">
              Please enter type
            </div>
          </div>
          <div class="col-md-4">
            <label>Is Published</label>&nbsp;
            <input type="checkbox" id="published" name="published" formControlName="ispublished"
              style="width:20px;height:20px;" class="form-control">
          </div>
        </div>
        <div class="row">
          <div class="col-md-12">
            <div class="btn-grp">
              <button type="button" class="done-btn" (click)="Submit()">Submit</button>
              <button type="button" class="cancl-btn" (click)="closePopupAdd()">Cancel</button>

            </div>
          </div>
        </div>
      </div>
    </form>
  </div>
</div>
<!---pop-plus Pge-->
<!-- <div class="plus-pop">
  <div class="new-pop-body"><a href="javascript:void(0);" class="cls-plus-pop"><img
        src="assets/assetsAdmin/img/close.png" /></a>
    <h4>Upload Files</h4>
    <form id="contact-form" role="form">
      <div class="controls">
        <div class="row">
          <div class="col-md-4">
            <div class="form-group">
              <label for="form_name">Subject *</label>
              <input id="form_name" type="text" name="name" class="form-control" placeholder="Please enter Subject *"
                required="required" data-error="Subject is required.">
              <small class="required">Subject Is Required</small>
            </div>
          </div>
        </div>

        <div class="upload-area-main">
          <a href="javascript:void(0);" class="cls-btn"><img src="assets/assetsAdmin/img/close.png" /></a>
          <div class="upload-area">
            <div class="single-upload-area">
              <a href="javascript:void(0);" class="cls-btn"><img src="assets/assetsAdmin/img/close.png" /></a>
              <div class="single-upload-container">
                <div class="img-area"><img src="assets/assetsAdmin/img/ftg1.jpg" /></div>
                <span>Subject Is Required</span>
              </div>
            </div>
            <div class="single-upload-area">
              <a href="javascript:void(0);" class="cls-btn"><img src="assets/assetsAdmin/img/close.png" /></a>
              <div class="single-upload-container">
                <div class="img-area"><img src="assets/assetsAdmin/img/ftg2.jpg" /></div>
                <span>Subject Is Required</span>
              </div>
            </div>
            <div class="single-upload-area">
              <a href="javascript:void(0);" class="cls-btn"><img src="assets/assetsAdmin/img/close.png" /></a>
              <div class="single-upload-container">
                <div class="img-area"><img src="assets/assetsAdmin/img/ftg2.jpg" /></div>
                <span>Subject Is RequiredSubject Is RequiredSubject Is Required</span>
              </div>
            </div>
            <div class="single-upload-area">
              <a href="javascript:void(0);" class="cls-btn"><img src="assets/assetsAdmin/img/close.png" /></a>
              <div class="single-upload-container">
                <div class="img-area"><img src="assets/assetsAdmin/img/ftg2.jpg" /></div>
                <span>Subject Is Required</span>
              </div>
            </div>
            <div class="single-upload-area">
              <a href="javascript:void(0);" class="cls-btn"><img src="assets/assetsAdmin/img/close.png" /></a>
              <div class="single-upload-container">
                <div class="img-area"><img src="assets/assetsAdmin/img/ftg2.jpg" /></div>
                <span>Subject Is Required</span>
              </div>
            </div>
          </div>

          <div class="upaload-btn-area">
            <span><i class="fa fa-file" aria-hidden="true"></i> 2 Files Selected</span>
            <div class="btn-grp">
              <button type="button" class="cancl-btn"><i class="fa fa-trash" aria-hidden="true"></i> Remove</button>
              <button type="button" class="done-btn "><i class="fa fa-folder" aria-hidden="true"></i> Browse</button>
            </div>
          </div>
        </div>

        <div class="row">
          <div class="col-md-12">
            <div class="btn-grp">
              <button type="button" class="done-btn ">Upload</button>
              <button type="button" class="cancl-btn">Cancel</button>
            </div>
          </div>
        </div>
      </div>
    </form>
  </div>
</div> -->
<!---pop-Edit Pge-->
<div class="edit-pop" class="modal" [ngStyle]="{'display':displayStyleEdit}">
  <div class="new-pop-body"><a href="javascript:void(0);" (click)="closePopupEdit()" class="cls-edit-pop"><img
        src="assets/assetsAdmin/img/close.png" /></a>
    <h4>Edit News & Events</h4>
    <!-- <p>Set frame rate, size resolution</p> -->
    <form id="contact-form" role="form" [formGroup]="adminNewseventForm">
      <div class="controls">
        <div class="row">
          <div class="col-md-4">
            <div class="form-group">
              <label for="form_name">Title</label>
              <input id="form_name" type="text" name="subject" formControlName="title" class="form-control"
                required="required" data-error="Name is required.">
              <div *ngIf="f1.title.invalid && (f1.title.dirty || f1.title.touched)" class="alert">
                <div *ngIf="f1.title.errors.required" style="color: red;">
                  Please enter title
                </div>
              </div>
            </div>
          </div>
          <div class="col-md-4">
            <div class="form-group">
              <label for="form_route">Body</label>
              <input id="form_route" type="text" name="financial" formControlName="body" class="form-control"
                required="required" data-error="Route is required.">
              <div *ngIf="f1.body.invalid && (f1.body.dirty || f1.body.touched)" class="alert">
                <div *ngIf="f1.body.errors.required" style="color: red;">
                  Please enter title
                </div>
              </div>
            </div>
          </div>
          <!-- <div class="col-md-4">
            <div class="form-group">
              <label for="form_route">Blurb</label>
              <input id="form_route" type="text" name="memo" formControlName="blurb" class="form-control"
                required="required" data-error="Route is required.">
            </div>
          </div> -->
        </div>
        <div class="row">
          <div class="col-md-6">
            <div class="form-group">
              <label for="form_cption">Date of Publishing</label>
              <input id="form_cption" type="date" name="publishing" formControlName="publishingdate"
                class="form-control" (change)="SelectPublishingDate($event)" [(ngModel)]="publishingdate">
              <div *ngIf="f1.publishingdate.invalid && (f1.publishingdate.dirty || f1.publishingdate.touched)"
                class="alert">
                <div *ngIf="f1.publishingdate.errors.required" style="color: red;">
                  Please enter publishing date
                </div>
              </div>
            </div>
          </div>
          <div class="col-md-6">
            <div class="form-group">
              <label for="form_email">Date of Event</label>
              <input id="form_email" type="date" name="closing" formControlName="eventdate" class="form-control"
              (change)="SelectEventDate($event)" [(ngModel)]="eventdate">
              <div *ngIf="f1.eventdate.invalid && (f1.eventdate.dirty || f1.eventdate.touched)" class="alert">
                <div *ngIf="f1.eventdate.errors.required" style="color: red;">
                  Please enter event date
                </div>
              </div>
            </div>
          </div>
        </div>
        <div class="row">
          <div class="col-md-4">
            <label>Type</label>&nbsp;
            <select id="type" name="type" formControlName="type" class="form-control">
              <option value="E">Event</option>
              <option value="N">News</option>
            </select>
            <div *ngIf="f1.type.invalid && (f1.type.dirty || f1.type.touched)" class="alert">
              <div *ngIf="f1.type.errors.required" style="color: red;">
                Please enter type
              </div>
            </div>
          </div>
          <div class="col-md-4">
            <label>Is Published</label>&nbsp;
            <input type="checkbox" id="published" name="published" formControlName="ispublished"
              style="width:20px;height:20px;" class="form-control">
          </div>
        </div>

        <div class="col-md-12">
          <div class="btn-grp">
            <button type="button" class="done-btn" (click)="Update()">Update</button>
            <button type="button" class="cancl-btn" (click)="closePopupEdit()">Cancel</button>
          </div>
        </div>
      </div>
    </form>
  </div>
</div>