import { Component, OnInit } from '@angular/core';
import { ApiService } from 'src/app/services/api.service';
import { NgxSpinnerService } from "ngx-spinner";
@Component({
  selector: 'app-wbrtps',
  templateUrl: './wbrtps.component.html',
  styleUrls: ['./wbrtps.component.css']
})
export class WbrtpsComponent implements OnInit {
  getwbrps:any;

  constructor(private apiService: ApiService,
    private SpinnerService: NgxSpinnerService,
 ) { }

  ngOnInit(): void {
    this.apiService.wbrps().subscribe((res:any )=> {
      this.SpinnerService.show(); 
      this.getwbrps = res.data;
      this.SpinnerService.hide(); 
  },
  (error:any )=> {

  });
 }
  }


