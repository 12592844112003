<app-header></app-header>
<div class="inner-background-two">
    <img src="assets/images/inner_banner.jpg">
    <div class="latest-overlay-two">
    <div class="container"><h2>Download</h2></div>
  </div>
  </div>
  <section class="inner-post">
    <div class="container">
      <div class="row">
         <div class="col-lg-9 col-sm-12 col-12">
        
            <div class="table-responsive">
                <table class="table">
                    <thead>
                    <tr>
                        <th style="width:20px; font-size:medium; font-weight: 700;">Sr No.</th>
                        <th style="width:20px; font-size:medium; font-weight: 700;">Subject</th>
                        <th style="width:20px; font-size:medium; font-weight: 700;">Title</th>
                        <th style="width:20px; font-size:medium; font-weight: 700;">Uploaded Date</th>
                        <th style="width:20px; font-size:medium; font-weight: 700;">Download Type</th>
                        <th style="width:20px; font-size:medium; font-weight: 700;">Action</th>                       
                    </tr>
                    </thead>
                    <tbody *ngFor="let item of downloadList ; let i = index">
                    <tr>
                        <td style="width:20px; font-size:medium; font-weight: 500;">{{i+1}}</td>
                        <td style="width:20px; font-size:medium; font-weight: 500;">{{item.subject}}</td>
                         <td style="width:20px; font-size:medium; font-weight: 500;">{{item.title}}</td>
                        <td style="width:20px; font-size:medium; font-weight: 500;">{{item.uploadDate}}</td>
                        <td style="width:20px; font-size:medium; font-weight: 500;">{{item.downloadType}}</td>                    
                        <td><a href="javascript:void(0);"><img src="../../../assets/images/pdf_icon.png"  (click)="downloadFile(item.id)"></a> </td>
                    </tr>							 
                    </tbody>
                  </table>
              </div>
           </div>
         
      </div>
    </div>
  </section>
