<app-header></app-header>
<div class="main-wapper">
    <div class="wapper-body">
        <div class="row">
            <div class="col-lg-12 col-md-12 col-sm-12 col-xs-12 col-mob-12">
                <div class="top-panel">
                    <form>
                        <div class="form-group">
                            <input type="text" name="text" (keyup)="searchTable($event)" placeholder="Search files">
                            <a href="#" class="src-icon"><img src="assets/assetsAdmin/img/loupe.png"></a>
                        </div>
                    </form>
                    <a href="javascript:void(0);" class="add-btn"><i class="fa fa-plus" (click)="openPopupAdd()"
                            aria-hidden="true" title="Add Page"></i></a>
                </div>
                <div class="bottom-panel">
                    <div class="table-responsive">
                        <table class="table table-striped jambo_table">
                            <thead>
                                <tr style="color: black;">
                                    <th class="column-title">Sl No</th>
                                    <th class="column-title">First Name</th>
                                    <th class="column-title">Last Name</th>
                                    <th class="column-title">Login Name</th>
                                    <th class="column-title">Password</th>
                                    <th class="column-title">Action</th>

                                </tr>
                            </thead>
                            <tbody *ngFor="let item of profileList; let i = index">
                                <td>{{(pager.startIndex -1) + i + 2}}</td>
                                <td>{{item.firstName}}</td>
                                <td>{{item.lastName}}</td>
                                <td>{{item.loginName}}</td>
                                <td>{{item.password}}</td>

                                <!-- <td><a href="javascript:void(0);"><i class="fa fa-file-download"></i></a></td> -->

                                <td class="last">
                                    <div class="action-button">
                                        <!-- <a class="plus-btn"><i class="fa fa-plus" aria-hidden="true" (click)="openPopupPlus()"></i></a> -->
                                        <a href="javascript:void(0);" class="edit-btn"
                                            (click)="openPopupEdit(item.id)"><i class="fa fa-edit" aria-hidden="true"
                                                title="Edit"></i></a>
                                        <!-- <a class="delete-btn"><i class="fa fa-times" aria-hidden="true"></i></a> -->
                                    </div>
                                </td>
                            </tbody>
                        </table>

                    </div>
                    <!-- #region  Pagination-->
                    <div class="row">
                        <div class="col-md-5">
                            <div class="dataTables_info" *ngIf="totalCount>0">Showing {{pager.startIndex +1}} to
                                {{pager.endIndex+1}}
                                of {{totalCount}} entries</div>
                        </div>

                        <div class="col-md-7">
                            <div class="dataTables_paginate paging_simple_numbers">
                                <ul *ngIf="pager.pages && pager.pages.length" class="pagination">
                                    <li class="paginate_button previous disabled" ng-disabled="true"
                                        [ngClass]="{'disabled':pager.currentPage === 1}">
                                        <a aria-controls="datatable-fixed-header"
                                            (click)="GetProfile(1)">First</a>
                                    </li>
                                    <li class="paginate_button previous disabled"
                                        [ngClass]="{disabled:pager.currentPage === 1}">
                                        <a aria-controls="datatable-fixed-header"
                                            (click)="GetProfile(pager.currentPage - 1)">Previous</a>
                                    </li>
                                    <li class="paginate_button previous disabled" *ngFor="let page of pager.pages"
                                        [ngClass]="{active:pager.currentPage === page}">
                                        <a aria-controls="datatable-fixed-header"
                                            (click)="GetProfile(page)">{{page}}</a>
                                    </li>
                                    <li class="paginate_button previous disabled"
                                        [ngClass]="{disabled:pager.currentPage === pager.totalPages}">
                                        <a aria-controls="datatable-fixed-header"
                                            (click)="GetProfile(pager.currentPage + 1)">Next</a>
                                    </li>
                                    <li class="paginate_button previous disabled"
                                        [ngClass]="{disabled:pager.currentPage === pager.totalPages}">
                                        <a aria-controls="datatable-fixed-header"
                                            (click)="GetProfile(pager.totalPages)">Last</a>
                                    </li>
                                </ul>
                            </div>
                        </div>
                    </div>
                    <!-- #endregion -->
                </div>
            </div>
        </div>
    </div>
</div>
<ngx-spinner bdColor="rgba(51, 51, 51, 0.8)" size="default" type="ball-spin-clockwise">
    <p style="color: white">Please Wait... </p>
</ngx-spinner>

<!---pop-new-poroject-->
<div class="new-pop" class="modal" [ngStyle]="{'display':displayStyleAdd}">
    <div class="new-pop-body"><a href="javascript:void(0);" (click)="closePopupAdd()" class="cls-new-pop"><img
                src="assets/assetsAdmin/img/close.png" /></a>
        <h4>Add Profile</h4>
        <form id="profile-form" [formGroup]="adminProfileForm">
            <div class="controls">
                <div class="row">
                    <div class="col-md-4">
                        <div class="form-group">
                            <label for="form_name">First Name *</label>
                            <input id="form_name" type="text" class="form-control" formControlName="firstname">
                            <div *ngIf="f1.firstname.invalid && (f1.firstname.dirty || f1.firstname.touched)">
                                <div *ngIf="f1.firstname.errors.required" style="color: red;">
                                    Please enter first name
                                </div>
                            </div>
                        </div>
                    </div>
                    <div class="col-md-4">
                        <div class="form-group">
                            <label for="form_name">Last Name *</label>
                            <input id="form_name" type="text" class="form-control" formControlName="lastname">
                            <div *ngIf="f1.lastname.invalid && (f1.lastname.dirty || f1.lastname.touched)">
                                <div *ngIf="f1.lastname.errors.required" style="color: red;">
                                    Please enter last name
                                </div>
                            </div>
                        </div>
                    </div>                    
                </div>
                <div class="row">
                    <div class="col-md-4">
                        <div class="form-group">
                            <label for="form_name">Login Name *</label>
                            <input id="form_name" type="text" class="form-control" formControlName="loginname">
                            <div *ngIf="f1.loginname.invalid && (f1.loginname.dirty || f1.loginname.touched)">
                                <div *ngIf="f1.lastname.errors.required" style="color: red;">
                                    Please enter last name
                                </div>
                            </div>
                        </div>
                    </div>
                    <div class="col-md-4">
                        <div class="form-group">
                            <label for="form_name">Password *</label>
                            <input id="form_name" type="password" class="form-control" formControlName="password">
                            <div *ngIf="f1.password.invalid && (f1.password.dirty || f1.password.touched)">
                                <div *ngIf="f1.password.errors.required" style="color: red;">
                                    Please enter password
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                <div class="row">
                    <div class="col-md-4">
                        <div class="form-group">
                            <label>Is System Admin</label>&nbsp;
                            <input type="checkbox" id="published" name="published" formControlName="issystemadmin"
                                style="width:20px;height:20px;">
                            <div *ngIf="f1.issystemadmin.invalid && (f1.issystemadmin.dirty || f1.issystemadmin.touched)"
                                class="alert">
                                <div *ngIf="f1.issystemadmin.errors.required" style="color: red;">
                                    Please check systemadmin
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                <div class="row">
                    <div class="col-md-12">
                        <div class="btn-grp">
                            <button type="button" class="done-btn ">Submit</button>
                            <button type="button" class="cancl-btn" (click)="closePopupAdd()">Cancel</button>

                        </div>
                    </div>
                </div>
            </div>
        </form>
    </div>
</div>
<!---pop-Edit Pge-->
<div class="edit-pop" class="modal" [ngStyle]="{'display':displayStyleEdit}">
    <div class="new-pop-body"><a href="javascript:void(0);" (click)="closePopupEdit()" class="cls-edit-pop"><img
                src="./assets/img/close.png" /></a>
        <h4>Edit Profile</h4>
        <form id="profile-form" role="form" [formGroup]="adminProfileForm">
            <div class="controls">
                <div class="row">
                    <div class="col-md-4">
                        <div class="form-group">
                            <label for="form_name">First Name *</label>
                            <input id="form_name" type="text" class="form-control" formControlName="firstname">
                            <div *ngIf="f1.firstname.invalid && (f1.firstname.dirty || f1.firstname.touched)">
                                <div *ngIf="f1.firstname.errors.required" style="color: red;">
                                    Please enter first name
                                </div>
                            </div>
                        </div>
                    </div>
                    <div class="col-md-4">
                        <div class="form-group">
                            <label for="form_name">Last Name *</label>
                            <input id="form_name" type="text" class="form-control" formControlName="lastname">
                            <div *ngIf="f1.lastname.invalid && (f1.lastname.dirty || f1.lastname.touched)">
                                <div *ngIf="f1.lastname.errors.required" style="color: red;">
                                    Please enter last name
                                </div>
                            </div>
                        </div>
                    </div>                    
                </div>
                <div class="row">
                    <div class="col-md-4">
                        <div class="form-group">
                            <label for="form_name">Login Name *</label>
                            <input id="form_name" type="text" class="form-control" formControlName="loginname">
                            <div *ngIf="f1.loginname.invalid && (f1.loginname.dirty || f1.loginname.touched)">
                                <div *ngIf="f1.lastname.errors.required" style="color: red;">
                                    Please enter last name
                                </div>
                            </div>
                        </div>
                    </div>
                    <div class="col-md-4">
                        <div class="form-group">
                            <label for="form_name">Password *</label>
                            <input id="form_name" type="password" class="form-control" formControlName="password">
                            <div *ngIf="f1.password.invalid && (f1.password.dirty || f1.password.touched)">
                                <div *ngIf="f1.password.errors.required" style="color: red;">
                                    Please enter password
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                <div class="row">
                    <div class="col-md-4">
                        <div class="form-group">
                            <label>Is System Admin</label>&nbsp;
                            <input type="checkbox" id="published" name="issystemadmin" formControlName="issystemadmin"
                                style="width:20px;height:20px;">
                            <div *ngIf="f1.issystemadmin.invalid && (f1.issystemadmin.dirty || f1.issystemadmin.touched)"
                                class="alert">
                                <div *ngIf="f1.issystemadmin.errors.required" style="color: red;">
                                    Please check systemadmin
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                <div class="col-md-12">
                    <div class="btn-grp">
                        <button type="button" class="done-btn" (click)="Update()">Update</button>
                        <button type="button" class="cancl-btn" (click)="closePopupEdit()">Cancel</button>
                    </div>
                </div>
            </div>
        </form>
    </div>
</div>