import { Component, OnInit } from '@angular/core';
import { NgxSpinnerService } from "ngx-spinner";
import { ApiService } from 'src/app/services/api.service';

@Component({
  selector: 'app-otherinfo',
  templateUrl: './otherinfo.component.html',
  styleUrls: ['./otherinfo.component.css']
})
export class OtherinfoComponent implements OnInit {
  otherInfo:any;
  constructor(private apiService: ApiService,
    private SpinnerService: NgxSpinnerService,) { }

  ngOnInit(): void {
    this.apiService.otherInfo().subscribe((res:any )=> {
      this.SpinnerService.show();
      this.otherInfo = res.data;
      this.SpinnerService.hide();
      
   },
   (error:any )=> {
    
   });

  }

}
