import { Component, OnInit } from '@angular/core';
import { NgxSpinnerService } from "ngx-spinner";
import { ApiService } from 'src/app/services/api.service';

@Component({
  selector: 'app-download',
  templateUrl: './download.component.html',
  styleUrls: ['./download.component.css']
})
export class DownloadComponent implements OnInit {

  downloadList: any;

  constructor(private apiService: ApiService,
    private SpinnerService: NgxSpinnerService,) { }

  ngOnInit(): void {
    this.apiService.DownloadGetAll().subscribe((res:any )=> {
      this.SpinnerService.show();
      this.downloadList= res.data;
      console.log('DownloadList: ',res.data)
      this.SpinnerService.hide();
  },
  (error:any )=> {
   
  });
  }

  downloadFile(downloadId:any){
    this.apiService.viewDownload(downloadId).subscribe(res => {
      const fileURL = URL.createObjectURL(res);
      window.open(fileURL, '_blank');
    });
}
}
