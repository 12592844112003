import { Component, OnInit } from '@angular/core';
import { NgxSpinnerService } from "ngx-spinner";
import { ApiService } from 'src/app/services/api.service';
// import { ToastrManager } from 'ng6-toastr-notifications';
//import { NgxSpinnerService } from "ngx-spinner";

@Component({
  selector: 'app-mission-vission',
  templateUrl: './mission-vission.component.html',
  styleUrls: ['./mission-vission.component.css']
})
export class MissionVissionComponent implements OnInit {
vission:any;
  constructor(private apiService: ApiService,
    private SpinnerService: NgxSpinnerService,) { }

  ngOnInit(): void {

    this.apiService.Vission().subscribe((res:any )=> {
      this.SpinnerService.show();
      this.vission = res.data;
      this.SpinnerService.hide();
   },
   (error:any )=> {

   });
  }

}
