<app-header></app-header>
<div class="inner-background-two">
  <img src="assets/images/inner_banner.jpg">
  <div class="latest-overlay-two">
    <div class="container">
      <h2>Current-Tender</h2>
    </div>
  </div>
</div>

<div class="container">
  <div class="row">
    <div class="table-responsive">
      <table class="table table-striped table-bordered jambo_table">
        <thead style="position: sticky;top: 0">
          <tr>
            <th style="width: 5%;">SLNO.</th>
            <th style="width: 39%;">Subject</th>
            <th style="width: 15%;">Memo No.</th>
            <th style="width: 10%;">Memo Date.</th>
            <th style="width: 11%;">Financial Year</th>
            <th style="width: 10%;">Closing Date</th>
            <th style="width: 10%;">Action</th>
          </tr>
        </thead>
        <tbody *ngFor="let item of tenderC; let i = index">
          <tr>
            <td style="width:20px; font-size:medium; font-weight: 500;">{{i+1}}</td>
            <td style="width:20px; font-size:medium; font-weight: 500;">{{item.subject}}</td>
            <td style="width:20px; font-size:medium; font-weight: 500;">{{item.memoNo}}</td>
            <td style="width:20px; font-size:medium; font-weight: 500;">{{item.memoDate}}</td>
            <td style="width:20px; font-size:medium; font-weight: 500;">{{item.financialYear}}</td>
            <td style="width:20px; font-size:medium; font-weight: 500;">{{item.closingDate}}</td>
            <td style="width:20px; font-size:medium; font-weight: 500;">
              <a href="javascript:void(0);" (click)="tenderCurrent(item.id)">
                <img src="../../../assets/images/pdf_icon.png">Download</a>
            </td>
          </tr>
        </tbody>
      </table>
    </div>
  </div>
</div>