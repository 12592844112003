<app-header></app-header>
<div class="inner-background-two">
    <img src="assets/images/inner_banner.jpg">
    <div class="latest-overlay-two">
    <div class="container"><h2>Slum Population</h2></div>
  </div>
  </div>
  <section class="inner-post">
    <div class="container">
      <div class="row">
         <div class="col-lg-9 col-sm-12 col-12">
        <!-- <div *ngIf="slumPopulation">
          <span [innerHtml]="slumPopulation.mainContent"></span>
        </div> -->
        <table border="1" cellpadding="0" cellspacing="0" >
       
          <tbody>
            <tr >
              <td style="text-align: center;font-weight: 700;" colspan="11">Details of Slum Population in the ULB  </td>
              <td ></td>
            </tr>
            <tr class="row1">
              <td style="text-align: center;font-weight: 700;" colspan="11">Name of the ULB: RANAGHAT</td>
            
            </tr>
            <tr >
              <td style="font-weight: 700;width: 20%;">Sl. No.</td>
              <td style="font-weight: 700;width: 20%;">Ward No. </td>
              <td style="font-weight: 700;width: 20%;">Name of the Slum</td>
              <td style="font-weight: 700;width: 20%;">Identity of Slum( Notified / Non Notified)</td>
              <td style="font-weight: 700;width: 20%;">Total No.of Households</td>
              <td style="font-weight: 700;width: 20%;">Total population of slum</td>
              <td style="font-weight: 700;width: 20%;">Total no. of Male </td>
              <td style="font-weight: 700;width: 1221px;">Total no. of Female</td>
              <td style="font-weight: 700;width: 1221px;">Total no. of Children &gt;12 age</td>
              <td style="font-weight: 700;width: 2000%;" colspan="2">Total no. of Children less than 12 years age</td>
            </tr>
            <tr class="row3">
              <td class="column0 style10 n">1</td>
              <td class="column1 style11 n">1</td>
              <td class="column2 style12 s">Kheyaghat Colony</td>
              <td class="column3 style13 s">NOTIFIED</td>
              <td class="column4 style14 n">74</td>
              <td class="column5 style15 n">162</td>
              <td class="column6 style16 f">83</td>
              <td class="column7 style16 f">79</td>
              <td class="column8 style17 f">141</td>
              <td class="column9 style17 f">21</td>
            </tr>
            <tr class="row4">
              <td class="column0 style18 n">2</td>
              <td class="column1 style19 n">1</td>
              <td class="column2 style20 s">Snahalata Pally (Near Basko Khal)</td>
              <td class="column3 style21 s">NOTIFIED</td>
              <td class="column4 style22 n">102</td>
              <td class="column5 style23 n">225</td>
              <td class="column6 style16 f">115</td>
              <td class="column7 style16 f">110</td>
              <td class="column8 style17 f">196</td>
              <td class="column9 style17 f">29</td>
            </tr>
            <tr class="row5">
              <td class="column0 style18 n">3</td>
              <td class="column1 style19 n">2</td>
              <td class="column2 style20 s">Sastitola (side of river Churni)</td>
              <td class="column3 style21 s">NOTIFIED</td>
              <td class="column4 style22 n">52</td>
              <td class="column5 style23 n">115</td>
              <td class="column6 style16 f">59</td>
              <td class="column7 style16 f">56</td>
              <td class="column8 style17 f">100</td>
              <td class="column9 style17 f">15</td>
            </tr>
            <tr class="row6">
              <td class="column0 style18 n">4</td>
              <td class="column1 style19 n">3</td>
              <td class="column2 style20 s">Dhobapara</td>
              <td class="column3 style21 s">NOTIFIED</td>
              <td class="column4 style22 n">35</td>
              <td class="column5 style23 n">124</td>
              <td class="column6 style16 f">63</td>
              <td class="column7 style16 f">61</td>
              <td class="column8 style17 f">108</td>
              <td class="column9 style17 f">16</td>
            </tr>
            <tr class="row7">
              <td class="column0 style18 n">5</td>
              <td class="column1 style19 n">4</td>
              <td class="column2 style20 s">Nadia Samaj Pally</td>
              <td class="column3 style21 s">NOTIFIED</td>
              <td class="column4 style22 n">30</td>
              <td class="column5 style23 n">156</td>
              <td class="column6 style16 f">80</td>
              <td class="column7 style16 f">76</td>
              <td class="column8 style17 f">136</td>
              <td class="column9 style17 f">20</td>
            </tr>
            <tr class="row8">
              <td class="column0 style18 n">6</td>
              <td class="column1 style19 n">5</td>
              <td class="column2 style20 s">Ghoshpara</td>
              <td class="column3 style21 s">NOTIFIED</td>
              <td class="column4 style22 n">52</td>
              <td class="column5 style23 n">102</td>
              <td class="column6 style16 f">52</td>
              <td class="column7 style16 f">50</td>
              <td class="column8 style17 f">89</td>
              <td class="column9 style17 f">13</td>
            </tr>
            <tr class="row9">
              <td class="column0 style18 n">7</td>
              <td class="column1 style19 n">5</td>
              <td class="column2 style20 s">Chunuripara</td>
              <td class="column3 style21 s">NOTIFIED</td>
              <td class="column4 style22 n">38</td>
              <td class="column5 style23 n">116</td>
              <td class="column6 style16 f">59</td>
              <td class="column7 style16 f">57</td>
              <td class="column8 style17 f">101</td>
              <td class="column9 style17 f">15</td>
            </tr>
            <tr class="row10">
              <td class="column0 style18 n">8</td>
              <td class="column1 style19 n">7</td>
              <td class="column2 style20 s">Churipara</td>
              <td class="column3 style21 s">NOTIFIED</td>
              <td class="column4 style22 n">34</td>
              <td class="column5 style23 n">99</td>
              <td class="column6 style16 f">50</td>
              <td class="column7 style16 f">49</td>
              <td class="column8 style17 f">86</td>
              <td class="column9 style17 f">13</td>
            </tr>
            <tr class="row11">
              <td class="column0 style18 n">9</td>
              <td class="column1 style19 n">10</td>
              <td class="column2 style20 s">Swargadwar Colony (West side of NH-34)</td>
              <td class="column3 style21 s">NOTIFIED</td>
              <td class="column4 style22 n">33</td>
              <td class="column5 style23 n">99</td>
              <td class="column6 style16 f">50</td>
              <td class="column7 style16 f">49</td>
              <td class="column8 style17 f">86</td>
              <td class="column9 style17 f">13</td>
            </tr>
            <tr class="row12">
              <td class="column0 style18 n">10</td>
              <td class="column1 style19 n">10</td>
              <td class="column2 style20 s">Srinathpur</td>
              <td class="column3 style21 s">NOTIFIED</td>
              <td class="column4 style22 n">83</td>
              <td class="column5 style23 n">325</td>
              <td class="column6 style16 f">166</td>
              <td class="column7 style16 f">159</td>
              <td class="column8 style17 f">283</td>
              <td class="column9 style17 f">42</td>
            </tr>
            <tr class="row13">
              <td class="column0 style18 n">11</td>
              <td class="column1 style19 n">11</td>
              <td class="column2 style20 s">Nityanandapally (Near Hangar Khal)</td>
              <td class="column3 style21 s">NOTIFIED</td>
              <td class="column4 style22 n">46</td>
              <td class="column5 style23 n">96</td>
              <td class="column6 style16 f">49</td>
              <td class="column7 style16 f">47</td>
              <td class="column8 style17 f">84</td>
              <td class="column9 style17 f">12</td>
            </tr>
            <tr class="row14">
              <td class="column0 style18 n">12</td>
              <td class="column1 style19 n">12</td>
              <td class="column2 style20 s">Daspara</td>
              <td class="column3 style21 s">NOTIFIED</td>
              <td class="column4 style22 n">37</td>
              <td class="column5 style23 n">435</td>
              <td class="column6 style16 f">222</td>
              <td class="column7 style16 f">213</td>
              <td class="column8 style17 f">378</td>
              <td class="column9 style17 f">57</td>
            </tr>
            <tr class="row15">
              <td class="column0 style24 n">13</td>
              <td class="column1 style19 n">12</td>
              <td class="column2 style20 s">Madanmohanpally</td>
              <td class="column3 style21 s">NOTIFIED</td>
              <td class="column4 style22 n">35</td>
              <td class="column5 style23 n">487</td>
              <td class="column6 style16 f">248</td>
              <td class="column7 style16 f">239</td>
              <td class="column8 style17 f">424</td>
              <td class="column9 style17 f">63</td>
            </tr>
            <tr class="row16">
              <td class="column0 style18 n">14</td>
              <td class="column1 style19 n">13</td>
              <td class="column2 style20 s">Sarajit Pally</td>
              <td class="column3 style21 s">NOTIFIED</td>
              <td class="column4 style22 n">34</td>
              <td class="column5 style23 n">250</td>
              <td class="column6 style16 f">128</td>
              <td class="column7 style16 f">123</td>
              <td class="column8 style17 f">218</td>
              <td class="column9 style17 f">32</td>
            </tr>
            <tr class="row17">
              <td class="column0 style18 n">15</td>
              <td class="column1 style19 n">13</td>
              <td class="column2 style20 s">Bani Pally</td>
              <td class="column3 style21 s">NOTIFIED</td>
              <td class="column4 style22 n">47</td>
              <td class="column5 style23 n">256</td>
              <td class="column6 style16 f">131</td>
              <td class="column7 style16 f">125</td>
              <td class="column8 style17 f">223</td>
              <td class="column9 style17 f">33</td>
            </tr>
            <tr class="row18">
              <td class="column0 style18 n">16</td>
              <td class="column1 style19 n">13</td>
              <td class="column2 style20 s">Nasra North Colony (Near Kadapukur)</td>
              <td class="column3 style21 s">NOTIFIED</td>
              <td class="column4 style22 n">49</td>
              <td class="column5 style23 n">264</td>
              <td class="column6 style16 f">135</td>
              <td class="column7 style16 f">129</td>
              <td class="column8 style17 f">230</td>
              <td class="column9 style17 f">34</td>
            </tr>
            <tr class="row19">
              <td class="column0 style18 n">17</td>
              <td class="column1 style19 n">14</td>
              <td class="column2 style20 s">Dhakapara &amp; Amtala</td>
              <td class="column3 style21 s">NOTIFIED</td>
              <td class="column4 style22 n">56</td>
              <td class="column5 style23 n">194</td>
              <td class="column6 style16 f">99</td>
              <td class="column7 style16 f">95</td>
              <td class="column8 style17 f">169</td>
              <td class="column9 style17 f">25</td>
            </tr>
            <tr class="row20">
              <td class="column0 style18 n">18</td>
              <td class="column1 style19 n">15</td>
              <td class="column2 style20 s">Muktinagar</td>
              <td class="column3 style21 s">NOTIFIED</td>
              <td class="column4 style22 n">55</td>
              <td class="column5 style23 n">250</td>
              <td class="column6 style16 f">128</td>
              <td class="column7 style16 f">123</td>
              <td class="column8 style17 f">218</td>
              <td class="column9 style17 f">32</td>
            </tr>
            <tr class="row21">
              <td class="column0 style18 n">19</td>
              <td class="column1 style19 n">15</td>
              <td class="column2 style20 s">Suryanagar</td>
              <td class="column3 style21 s">NOTIFIED</td>
              <td class="column4 style22 n">62</td>
              <td class="column5 style23 n">294</td>
              <td class="column6 style16 f">150</td>
              <td class="column7 style16 f">144</td>
              <td class="column8 style17 f">256</td>
              <td class="column9 style17 f">38</td>
            </tr>
            <tr class="row22">
              <td class="column0 style18 n">20</td>
              <td class="column1 style19 n">16</td>
              <td class="column2 style20 s">Kapuriapara</td>
              <td class="column3 style21 s">NOTIFIED</td>
              <td class="column4 style22 n">63</td>
              <td class="column5 style23 n">250</td>
              <td class="column6 style16 f">128</td>
              <td class="column7 style16 f">123</td>
              <td class="column8 style17 f">218</td>
              <td class="column9 style17 f">32</td>
            </tr>
            <tr class="row23">
              <td class="column0 style18 n">21</td>
              <td class="column1 style19 n">17</td>
              <td class="column2 style20 s">Dargatala (Near Jagadish Pathsala)</td>
              <td class="column3 style21 s">NOTIFIED</td>
              <td class="column4 style22 n">25</td>
              <td class="column5 style23 n">126</td>
              <td class="column6 style16 f">64</td>
              <td class="column7 style16 f">62</td>
              <td class="column8 style17 f">110</td>
              <td class="column9 style17 f">16</td>
            </tr>
            <tr class="row24">
              <td class="column0 style18 n">22</td>
              <td class="column1 style19 n">18</td>
              <td class="column2 style20 s">Nasra South Coloni-I(Near Milanmandir Club)</td>
              <td class="column3 style21 s">NOTIFIED</td>
              <td class="column4 style22 n">73</td>
              <td class="column5 style25 n">280</td>
              <td class="column6 style16 f">143</td>
              <td class="column7 style16 f">137</td>
              <td class="column8 style17 f">244</td>
              <td class="column9 style17 f">36</td>
            </tr>
            <tr class="row25">
              <td class="column0 style18 n">23</td>
              <td class="column1 style19 n">18</td>
              <td class="column2 style20 s">Nasra South Coloni-II(Near Pakar More)</td>
              <td class="column3 style21 s">NOTIFIED</td>
              <td class="column4 style22 n">36</td>
              <td class="column5 style25 n">150</td>
              <td class="column6 style16 f">77</td>
              <td class="column7 style16 f">74</td>
              <td class="column8 style17 f">131</td>
              <td class="column9 style17 f">19</td>
            </tr>
            <tr class="row26">
              <td class="column0 style18 n">24</td>
              <td class="column1 style19 n">18</td>
              <td class="column2 style20 s">Aurobindapally (In front of Aurobindo Primary School)</td>
              <td class="column3 style21 s">NOTIFIED</td>
              <td class="column4 style22 n">33</td>
              <td class="column5 style25 n">106</td>
              <td class="column6 style16 f">54</td>
              <td class="column7 style16 f">52</td>
              <td class="column8 style17 f">92</td>
              <td class="column9 style17 f">14</td>
            </tr>
            <tr class="row27">
              <td class="column0 style18 n">25</td>
              <td class="column1 style19 n">19</td>
              <td class="column2 style20 s">Daspara</td>
              <td class="column3 style21 s">NOTIFIED</td>
              <td class="column4 style22 n">63</td>
              <td class="column5 style25 n">225</td>
              <td class="column6 style16 f">115</td>
              <td class="column7 style16 f">110</td>
              <td class="column8 style17 f">196</td>
              <td class="column9 style17 f">29</td>
            </tr>
            <tr class="row28">
              <td class="column0 style18 n">26</td>
              <td class="column1 style19 n">19</td>
              <td class="column2 style20 s">Talpukurpara &amp; Daspara</td>
              <td class="column3 style21 s">NOTIFIED</td>
              <td class="column4 style22 n">47</td>
              <td class="column5 style25 n">157</td>
              <td class="column6 style16 f">80</td>
              <td class="column7 style16 f">77</td>
              <td class="column8 style17 f">137</td>
              <td class="column9 style17 f">20</td>
            </tr>
            <tr class="row29">
              <td class="column0 style18 n">27</td>
              <td class="column1 style19 n">19</td>
              <td class="column2 style20 s">Millpara &amp; Khalpar Colony</td>
              <td class="column3 style21 s">NOTIFIED</td>
              <td class="column4 style22 n">74</td>
              <td class="column5 style25 n">212</td>
              <td class="column6 style16 f">108</td>
              <td class="column7 style16 f">104</td>
              <td class="column8 style17 f">184</td>
              <td class="column9 style17 f">28</td>
            </tr>
            <tr class="row30">
              <td class="column0 style18 n">28</td>
              <td class="column1 style26 n">20</td>
              <td class="column2 style27 s">Malir Bagan (Side of Basko Khal &amp; Railway Track)</td>
              <td class="column3 style28 s">NOTIFIED</td>
              <td class="column4 style29 n">57</td>
              <td class="column5 style30 n">102</td>
              <td class="column6 style16 f">52</td>
              <td class="column7 style16 f">50</td>
              <td class="column8 style17 f">89</td>
              <td class="column9 style17 f">13</td>
            </tr>
            <tr class="row31">
              <td class="column0 style31 null"></td>
              <td class="column1 style31 null"></td>
              <td class="column2 style31 null"></td>
              <td class="column3 style31 null"></td>
              <td class="column4 style31 f">1425</td>
              <td class="column5 style31 f">5657</td>
              <td class="column6 style32 f">2888</td>
              <td class="column7 style32 f">2773</td>
              <td class="column8 style32 f">4927</td>
              <td class="column9 style32 f">730</td>
            </tr>
          </tbody>
      </table>
         
           </div>
      </div>
    </div>
  </section>
