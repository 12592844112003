<app-header></app-header>
<div class="inner-background-two">
    <img src="assets/images/inner_banner.jpg">
    <div class="latest-overlay-two">
    <div class="container"><h2 style="margin: 10px 10px 10px -104px">Departmental-wise Contacts</h2></div>
  </div>
  </div>
  <section class="inner-post">
    <div class="container">
      <div class="row">
         <div class="col-lg-9 col-sm-12 col-12">
				<div>
					<table border="1" cellpadding="0" cellspacing="0" >
					
						<tbody>
						  <tr >
							<td  style="font-weight: 700;text-align: center; font-size: large;width: 1221px;">First Name</td>
							<td  style="font-weight: 700;text-align: center; font-size: large;width: 1221px;">Last Name</td>
							<td  style="font-weight: 700;text-align: center; font-size: large;width: 1221px;">Designation</td>
							<td  style="font-weight: 700;text-align: center; font-size: large;width: 1221px;">Ministry / Department</td>
							<td  style="font-weight: 700;text-align: center; font-size: large;width: 1221px;">Mobile no.(10 digit Numerals)</td>
							<td  style="font-weight: 700;text-align: center; font-size: large;width: 2000px;">Complete Email address</td>
						  </tr>
						  <tr class="row1">
							<td class="column0 style3 s">KOSHALDEB </td>
							<td class="column1 style3 s">BANDYOPADHYAY</td>
							<td class="column2 style4 s">CHAIRMAN</td>
							<td class="column3 style4 s">URBAN DEVELOPMENT AND MUNICIPAL AFFAIRS</td>
							<td class="column4 style3 n">9434139444</td>
							<td class="column5 style5 s"><a href="mailto:chm.rngh-wb@wburbanservices.gov.in" title="">chm.rngh-wb@wburbanservices.gov.in</a></td>
						  </tr>
						  <tr class="row2">
							<td class="column0 style3 s">ANANDA </td>
							<td class="column1 style3 s">DEY</td>
							<td class="column2 style4 s">VICE CHAIRMAN</td>
							<td class="column3 style3 s">DO</td>
							<td class="column4 style3 n">9434321956</td>
							<td class="column5 style5 s"><a href="mailto:vcm.rngh-wb@wburbanservices.gov.in" title="">vcm.rngh-wb@wburbanservices.gov.in</a></td>
						  </tr>
						  <tr class="row3">
							<td class="column0 style6 s">BIPUL</td>
							<td class="column1 style6 s">CHAKRABORTI</td>
							<td class="column2 style6 s">EO</td>
							<td class="column3 style3 s">DO</td>
							<td class="column4 style3 n">9433312780</td>
							<td class="column5 style7 s"><a href="mailto:eo.rngh-wb@bangla.gov.in" title="">eo.rngh-wb@bangla.gov.in</a></td>
						  </tr>
						  <tr class="row4">
							<td class="column0 style3 s">NITISH </td>
							<td class="column1 style3 s">CHNADRA  BAIN</td>
							<td class="column2 style4 s">FINANCE OFFICER</td>
							<td class="column3 style3 s">DO</td>
							<td class="column4 style3 n">9474929510</td>
							<td class="column5 style5 s"><a href="mailto:fo.rngh-wb@wburbanservices.gov.in" title="">fo.rngh-wb@wburbanservices.gov.in</a></td>
						  </tr>
						  <tr class="row5">
							<td class="column0 style3 s">Dr Saikat </td>
							<td class="column1 style3 s">Chatterjee</td>
							<td class="column2 style4 s">HEALTH OFFICER (NUHM)</td>
							<td class="column3 style3 s">DO</td>
							<td class="column4 style3 n">7439261808</td>
							<td class="column5 style5 s"><a href="mailto:ho.rngh-wb@wburbanservices.gov.in" title="">ho.rngh-wb@wburbanservices.gov.in</a></td>
						  </tr>
						  <tr class="row6">
							<td class="column0 style3 s">SHAMIK </td>
							<td class="column1 style3 s">MAJUMDER</td>
							<td class="column2 style3 s">HEAD CLERK</td>
							<td class="column3 style3 s">DO</td>
							<td class="column4 style3 n">9333515145</td>
							<td class="column5 style5 s"><a href="mailto:hc.rngh-wb@wburbanservices.gov.in" title="">hc.rngh-wb@wburbanservices.gov.in</a></td>
						  </tr>
						  <tr class="row7">
							<td class="column0 style4 s">MRINAL </td>
							<td class="column1 style4 s">KANTI BASAK</td>
							<td class="column2 style4 s">ASSISTANT ENGINEER</td>
							<td class="column3 style3 s">DO</td>
							<td class="column4 style8 n">7908386458</td>
							<td class="column5 style9 s"><a href="mailto:ae.rngh-wb@wburbanservices.gov.in" title="">ae.rngh-wb@wburbanservices.gov.in</a></td>
						  </tr>
						  <tr class="row8">
							<td class="column0 style3 s">SUDIPTA </td>
							<td class="column1 style3 s">MUKHERJEE</td>
							<td class="column2 style4 s">SUB ASSISTANT ENGINEER</td>
							<td class="column3 style3 s">DO</td>
							<td class="column4 style3 n">7001323900</td>
							<td class="column5 style5 s"><a href="mailto:sae1.rngh-wb@wburbanservices.gov.in" title="">sae1.rngh-wb@wburbanservices.gov.in</a></td>
						  </tr>
						  <tr class="row9">
							<td class="column0 style3 s">SUBRATA </td>
							<td class="column1 style3 s">SARKAR</td>
							<td class="column2 style4 s">SUB ASSISTANT ENGINEER</td>
							<td class="column3 style3 s">DO</td>
							<td class="column4 style3 n">8509677756</td>
							<td class="column5 style5 s"><a href="mailto:sae2.rngh-wb@wburbanservices.gov.in" title="">sae2.rngh-wb@wburbanservices.gov.in</a></td>
						  </tr>
						  <tr class="row10">
							<td class="column0 style3 s">ASHOKE </td>
							<td class="column1 style3 s">KUMAR BOSE</td>
							<td class="column2 style4 s">SUB ASSISTANT ENGINEER</td>
							<td class="column3 style3 s">DO</td>
							<td class="column4 style3 n">9434129443</td>
							<td class="column5 style5 s"><a href="mailto:sae3.rngh-wb@wburbanservices.gov.in" title="">sae3.rngh-wb@wburbanservices.gov.in</a></td>
						  </tr>
						  <tr class="row11">
							<td class="column0 style3 s">KINJAL</td>
							<td class="column1 style3 s">BOSE</td>
							<td class="column2 style4 s">SUB ASSISTANT ENGINEER (ELECTRICAL)</td>
							<td class="column3 style3 s">DO</td>
							<td class="column4 style3 n">8637833214</td>
							<td class="column5 style5 s"><a href="mailto:sae4.rngh-wb@wburbanservices.gov.in" title="">sae4.rngh-wb@wburbanservices.gov.in</a></td>
						  </tr>
						  <tr class="row12">
							<td class="column0 style3 s">SHUBHANKAR</td>
							<td class="column1 style3 s">CHAKRABORTY</td>
							<td class="column2 style4 s">SUB ASSISTANT ENGINEER</td>
							<td class="column3 style3 s">DO</td>
							<td class="column4 style3 n">7908432122</td>
							<td class="column5 style5 s"><a href="mailto:sae5.rngh-wb@wburbanservices.gov.in" title="">sae5.rngh-wb@wburbanservices.gov.in</a></td>
						  </tr>
						  <tr class="row13">
							<td class="column0 style6 s">RAJESH</td>
							<td class="column1 style6 s">KARMAKAR</td>
							<td class="column2 style6 s">SUB ASSISTANT ENGINEER  ( CONTRACTUAL)</td>
							<td class="column3 style3 s">DO</td>
							<td class="column4 style3 n">8337896867</td>
							<td class="column5 style5 s"><a href="mailto:sae6.rngh-wb@wburbanservices.gov.in" title="">sae6.rngh-wb@wburbanservices.gov.in</a></td>
						  </tr>
						  <tr class="row14">
							<td class="column0 style3 s">SUROJIT</td>
							<td class="column1 style3 s">DAS</td>
							<td class="column2 style4 s">CLTC , SUDA  SUB ASSISTANT ENGINEER</td>
							<td class="column3 style3 s">DO</td>
							<td class="column4 style3 null"></td>
							<td class="column5 style5 s"><a href="mailto:sae7.rngh-wb@wburbanservices.gov.in" title="">sae7.rngh-wb@wburbanservices.gov.in</a></td>
						  </tr>
						  <tr class="row15">
							<td class="column0 style3 s">PRIYANKA</td>
							<td class="column1 style3 s">BARIK</td>
							<td class="column2 style3 s">IT</td>
							<td class="column3 style3 s">DO</td>
							<td class="column4 style3 n">9903811787</td>
							<td class="column5 style5 s"><a href="mailto:it.rngh-wb@wburbanservices.gov.in" title="">it.rngh-wb@wburbanservices.gov.in</a></td>
						  </tr>
						  <tr class="row16">
							<td class="column0 style3 s">RAJA </td>
							<td class="column1 style3 s">GHOSH</td>
							<td class="column2 style3 s">SANITARY INSPECTOR</td>
							<td class="column3 style3 s">DO</td>
							<td class="column4 style3 n">9474134608</td>
							<td class="column5 style5 s"><a href="mailto:si.rngh-wb@wburbanservices.gov.in" title="">si.rngh-wb@wburbanservices.gov.in</a></td>
						  </tr>
						  <tr class="row17">
							<td class="column0 style3 s">BAPAN </td>
							<td class="column1 style3 s">CHAKRABORTY</td>
							<td class="column2 style3 s">CMM MANAGER</td>
							<td class="column3 style3 s">DO</td>
							<td class="column4 style3 n">8946069220</td>
							<td class="column5 style5 s"><a href="mailto:cmm.rngh-wb@wburbanservices.gov.in" title="">cmm.rngh-wb@wburbanservices.gov.in</a></td>
						  </tr>
						  <tr class="row18">
							<td class="column0 style3 s">BIJAY </td>
							<td class="column1 style3 s">DALAI</td>
							<td class="column2 style3 s">ASSISTANT CASHIER</td>
							<td class="column3 style3 s">DO</td>
							<td class="column4 style3 n">9593043304</td>
							<td class="column5 style5 s"><a href="mailto:atcash.rngh-wb@wburbanservices.gov.in" title="">atcash.rngh-wb@wburbanservices.gov.in</a></td>
						  </tr>
						  <tr class="row19">
							<td class="column0 style6 s">AMTI </td>
							<td class="column1 style6 s">SAU</td>
							<td class="column2 style6 s">DEALING ASSISTANT STORE</td>
							<td class="column3 style3 s">DO</td>
							<td class="column4 style3 n">9883468202</td>
							<td class="column5 style5 s"><a href="mailto:store.rngh-wb@wburbanservices.gov.in" title="">store.rngh-wb@wburbanservices.gov.in</a></td>
						  </tr>
						  <tr class="row20">
							<td class="column0 style6 s">DEBADITYA </td>
							<td class="column1 style6 s">BHATTACHARJEE</td>
							<td class="column2 style6 s">DEALING ASSISTANT PFMS for NSAP &amp; Housing</td>
							<td class="column3 style3 s">DO</td>
							<td class="column4 style3 n">9735390815</td>
							<td class="column5 style5 s"><a href="mailto:dahfa.rngh-wb@wburbanservices.gov.in" title="">dahfa.rngh-wb@wburbanservices.gov.in</a></td>
						  </tr>
						  <tr class="row21">
							<td class="column0 style6 s">RAJU</td>
							<td class="column1 style6 s">ROY</td>
							<td class="column2 style6 s">ELECTRRIC INCHARGE (CLERK)</td>
							<td class="column3 style3 s">DO</td>
							<td class="column4 style3 n">9775688564</td>
							<td class="column5 style5 s"><a href="mailto:elec.rngh-wb@wburbanservices.gov.in" title="">elec.rngh-wb@wburbanservices.gov.in</a></td>
						  </tr>
						  <tr class="row22">
							<td class="column0 style6 s">DIPANJAN </td>
							<td class="column1 style3 s">BISWAS</td>
							<td class="column2 style6 s">DELAING ASSISTATN HEALTH</td>
							<td class="column3 style3 s">DO</td>
							<td class="column4 style3 n">9064089202</td>
							<td class="column5 style5 s"><a href="mailto:dahth.rngh-wb@wburbanservices.gov.in" title="">dahth.rngh-wb@wburbanservices.gov.in</a></td>
						  </tr>
						  <tr class="row23">
							<td class="column0 style6 s">SUPARNA</td>
							<td class="column1 style6 s">BRAHMA</td>
							<td class="column2 style6 s">BSK OPERATOR</td>
							<td class="column3 style3 s">DO</td>
							<td class="column4 style3 n">7908417727</td>
							<td class="column5 style5 s"><a href="mailto:bsk1.rngh-wb@wburbanservices.gov.in" title="">bsk1.rngh-wb@wburbanservices.gov.in</a></td>
						  </tr>
						  <tr class="row24">
							<td class="column0 style6 s">SUBHADIP</td>
							<td class="column1 style6 s">GHOSH</td>
							<td class="column2 style6 s">BSK OPERATOR</td>
							<td class="column3 style3 s">DO</td>
							<td class="column4 style3 n">9547633423</td>
							<td class="column5 style5 s"><a href="mailto:bsk2.rngh-wb@wburbanservices.gov.in" title="">bsk2.rngh-wb@wburbanservices.gov.in</a></td>
						  </tr>
						  <tr class="row25">
							<td class="column0 style6 s">PROSENJIT </td>
							<td class="column1 style6 s">DEY</td>
							<td class="column2 style6 s">PWD2 INCHARGE</td>
							<td class="column3 style3 s">DO</td>
							<td class="column4 style3 n">9735267708</td>
							<td class="column5 style5 s"><a href="mailto:pwd2.rngh-wb@wburbanservices.gov.in" title="">pwd2.rngh-wb@wburbanservices.gov.in</a></td>
						  </tr>
						  <tr class="row26">
							<td class="column0 style6 s">TOUSIF</td>
							<td class="column1 style6 s">HOSSEN</td>
							<td class="column2 style6 s">DEALING WITH LB </td>
							<td class="column3 style3 s">DO</td>
							<td class="column4 style3 n">9333515108</td>
							<td class="column5 style5 s"><a href="mailto:deo.rngh-wb@wburbanservices.gov.in" title="">deo.rngh-wb@wburbanservices.gov.in</a></td>
						  </tr>
						</tbody>
					</table>
				</div>	
					
				
					
				
		
       
           </div>
      </div>
    </div>
  </section>



