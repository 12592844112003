import { Component, OnInit } from '@angular/core';
import { NgxSpinnerService } from "ngx-spinner";
import { ToastrService } from 'ngx-toastr';
import { Router, ActivatedRoute } from '@angular/router';
import { ApiService } from 'src/app/services/api.service';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { formatDate } from '@angular/common'
import { DatePipe, Location } from '@angular/common';
import { PagerService } from '../../services/pager.service';

@Component({
  selector: 'app-admin-internalaudit',
  templateUrl: './admin-internalaudit.component.html',
  styleUrls: ['./admin-internalaudit.component.css']
})
export class AdminInternalauditComponent implements OnInit {

  adminRoleId: any;
  getallInternalAuditList: any = [];
  fileInternalAudit: any;
  adminInternalAuditForm: any;
  userid: any;  
  preview: boolean = false;
  displayStylePlus = "none";
  displayStyleEdit = "none";
  displayStyleAdd = "none";
  disabledTender: boolean = false;
  updateTender: boolean = false;

  currentPage: number = 1;
  pageSize: number = 10;
  totalCount: number = 0;
  pager: any = {};
  pagedItems: any = [];
  pageNo: number = 1;
  searchItem: string = '';


  id: any;
  dateRangeValidator: any;
  invalid: boolean = false;
  error: any = { isError: false, errorMessage: '' };

  constructor(private apiService: ApiService,
    private router: Router, private formBuilder: FormBuilder,
    private toastrService: ToastrService,
    private SpinnerService: NgxSpinnerService,
    private datePipe: DatePipe,
    private pagerService: PagerService) {
    this.initForm();
    this.adminRoleId = localStorage.getItem('adminRoleId');
  }

  ngOnInit(): void {
    this.getallInternalAudit(this.currentPage);
    //this.authService.loadJsFile("assets/assetsAdmin/js/main.js");
  }

  getallInternalAudit(currentPage: number) {
    let param = {      
      "filter": this.searchItem,
      "order": "title",
      "limit": this.pageSize,
      "page": currentPage
      
    }
    //this.SpinnerService.show(); 
    this.apiService.adminInternalAuditAll(param).subscribe((res:any )=> {
      if(res.result == "Success"){
        this.getallInternalAuditList = res.data.audits;
        console.log('internal-audit', this.getallInternalAuditList)
        this.totalCount = res.data.count;
        this.setPage(currentPage);        
      }
      else{
        this.getallInternalAuditList = [];
        this.totalCount = res.data.count;
      }
   },
   (error:any )=> {
    
   });
  }

  setPage(page: number) {
    this.pager = this.pagerService.getPager(this.totalCount, page, this.pageSize);
    this.pagedItems = this.totalCount > 0 ? this.getallInternalAuditList.slice(this.pager.startIndex, this.pager.endIndex + 1) : [];
  }
  onSelectFile(event:any){
		if (event.target.files.length > 0) {
			if (event.target.files[0].size > 3*1024*1024) {
        this.toastrService.error('File size must not exceed 1 MB', 'Error!');
			  event.target.value = null;
			}
			else { 
				this.fileInternalAudit = <File>event.target.files[0]; 
        this.f1.fileInternalAudit.setValue(this.fileInternalAudit);
			}
		}
	}
  get f1() { return this.adminInternalAuditForm.controls; }
  initForm() {
    this.adminInternalAuditForm = this.formBuilder.group({
      title: ['', Validators.required],
      financialyear: [''],
      uploadingdate: ['',Validators.required],
      isactive: [''],
      fileInternalAudit:['',Validators.required],
    });
    
  }
  searchTable(event: any) {
    this.searchItem = event.target.value;
    this.getallInternalAudit(this.currentPage);
  }

  openPopupEdit(id:any) {
    this.id = id;
    this.apiService.adminInternalAuditId(id).subscribe((res:any )=> {
      if(res.result == "Success"){
        console.log('data :', res.data)
        if(res.data.fileName){
          this.preview = true;
        }
        this.f1.title.setValue(res.data.title);
        this.f1.financialyear.setValue(res.data.financialYear);
        this.f1.isactive.setValue(res.data.isActive);
        //this.f1.uploadingdate.setValue(this.datePipe.transform(res.data.uploadDate, 'dd-MM-yyyy'))
        this.f1.uploadingdate.setValue(this.datePipe.transform(res.data.uploadDate, 'yyyy-MM-dd', 'en'));
        //this.f1.uploadingdate.setValue(res.data.uploadDate);
      }
   },
   (error:any )=> {
    
   });
   this.displayStyleEdit = "block";
 
  }
  Submit(){    
    let profileFD = new FormData();
    this.userid = localStorage.getItem('userid');
    if(this.fileInternalAudit){profileFD.append('file', this.f1.fileInternalAudit.value)}
    profileFD.append("Title", this.f1.title.value)
    profileFD.append("financialYear", this.f1.financialyear.value)
    profileFD.append("UploadDate", this.f1.uploadingdate.value)
    profileFD.append("IsActive", this.f1.isactive.value)
    profileFD.append("createdBy", this.userid)
    this.apiService.adminInternalAuditAdd(profileFD).subscribe((res:any )=> {
      if(res.result == "Success"){
        this.toastrService.success('Internal Audit added successfully');
        window.location.reload();
      }
   },
   (error:any )=> {
    this.toastrService.error('Internal Audit not added successfully');
   });
  }

  Update() {
    let profileFD = new FormData();
    if (this.fileInternalAudit) 
    { 
      profileFD.append('file', this.fileInternalAudit, this.fileInternalAudit.name); 
    }
    profileFD.append("Title", this.f1.title.value)
    profileFD.append("financialYear", this.f1.financialyear.value)
    profileFD.append("UploadDate", this.f1.uploadingdate.value)
    profileFD.append("IsActive", this.f1.isactive.value)
    profileFD.append("createdBy", this.userid)
    profileFD.append("id", this.id)

    this.apiService.adminInternalAuditUpdate(profileFD).subscribe((res: any) => {
      if (res.result == "Success") {
        this.toastrService.success('Internal Audit updated successfully');
        window.location.reload();
      }
    },
      (error: any) => {
        this.toastrService.error("Internal Audit not updated successfully");
      });
  }
  closePopupEdit() {
    this.displayStyleEdit = "none";
  }
  
  openPopupAdd(){
    this.displayStyleAdd = "block";
    this.f1.title.setValue(null);
    this.f1.financialyear.setValue(null);
    this.f1.uploadingdate.setValue(null);
    this.f1.isactive.setValue(null);
  }
  closePopupAdd() {
    this.displayStyleAdd = "none";
  }

  }