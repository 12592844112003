import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-census',
  templateUrl: './census.component.html',
  styleUrls: ['./census.component.css']
})
export class CensusComponent implements OnInit {
  isShow = false;
  citydata = false;
  worker = false;
  literacy = false;
  sRatio = false;
  religion = false;
  constructor() { }

  ngOnInit(): void {
  }
  townCensus(){
    this.isShow =true;
    this.citydata =false;
    this.literacy = false;
    this.worker = false;
    this.sRatio = false;
    this.religion = false;
  }
  cityortownData(){
    this.citydata =true;
    this.isShow =false;
    this.literacy = false;
    this.worker = false;
    this.sRatio = false;
    this.religion = false;
  }
  workerProfile(){
   this.worker = true;
   this.citydata =false;
    this.isShow =false;
    this.literacy = false;
    this.sRatio = false;
    this.religion = false;
  }
  literacyRate(){
    this.literacy = true;
    this.worker = false;
   this.citydata =false;
    this.isShow =false;
    this.sRatio = false;
    this.religion = false;
  }
  sexRatio(){
    this.sRatio = true;
    this.literacy = false;
    this.worker = false;
   this.citydata =false;
    this.isShow =false;
    this.religion = false;
  }
  ReligionPopulation(){
    this.religion = true;
    this.sRatio = false;
    this.literacy = false;
    this.worker = false;
   this.citydata =false;
    this.isShow =false;
  }
}
