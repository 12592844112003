import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { UntypedFormBuilder, FormGroup, Validators } from '@angular/forms';
import { AuthService } from './../../services/auth.service';
import { UserModel } from 'src/app/services/user-model';
import { ToastrService } from 'ngx-toastr';
import { NgxSpinnerService } from "ngx-spinner";
import { ApiService } from 'src/app/services/api.service';

@Component({
  selector: 'app-change-password',
  templateUrl: './change-password.component.html',
  styleUrls: ['./change-password.component.css']
})
export class ChangePasswordComponent implements OnInit {
  changePasswordParameter!: FormGroup;
  pwd: any;
  newPwd: any;
  confPwd: any; 
  submitted: boolean = false;
  Password:any;
  NewPassword:any;
  ConfirmPassword:any;
  constructor( private authService: AuthService,private router:Router,
    private formBuilder: UntypedFormBuilder, private toastrService: ToastrService,
    private spinner: NgxSpinnerService,private apiService: ApiService,
    private SpinnerService: NgxSpinnerService) { }

  ngOnInit(): void {
    this.initForm();
    
  }
  get f1() { return this.changePasswordParameter.controls; }
  initForm() {
    this.changePasswordParameter = this.formBuilder.group({
      password: ['', Validators.required],
      newpassword: ['', Validators.required],
      confpassword: ['', Validators.required]
    });
  }
  onLogin(){
    if (this.changePasswordParameter.invalid) {
      this.toastrService.error('Please enter valid password & confirm password');
      return;
    }else{
      //this.spinner.show();
    let param = {
      UserId: "18AB4267-90C6-4297-89B9-484A662EA03F",
      password: this.f1.password.value,
      newPassword: this.f1.newpassword.value,
      confirmpassword: this.f1.confpassword.value,
    }
    //this.SpinnerService.show(); 
    this.apiService.changePassword(param).subscribe((res:any )=>{
      if (res.result == "Success") {
        this.authService.logout();
        this.toastrService.success('Password has been changed successfully');
      }
    })
  }
}
}
