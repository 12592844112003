<app-header></app-header>
<div class="inner-background-two">
    <img src="assets/images/inner_banner.jpg">
    <div class="latest-overlay-two">
    <div class="container"><h2>Municipal-Basic-Info</h2></div>
  </div>
  </div>
  <section class="inner-post">
    <div class="container">
      <div class="row">
         <div class="col-lg-9 col-sm-12 col-12">
            <div>
            <div class="table-responsive">
                <table border="1" >
                    <tbody>
                        <tr>
                            <td style="text-align: center;width: 1000px;font-weight: 700;">No. of Major Commercial areas(Count)			
                            </td>
                            <td style="width: 304px;text-align: right;font-weight: 700;">9			
                            </td>
                        </tr>
                    </tbody>
                </table>
            </div>
                <br>
                <br>
            <h4 style="font-weight: 700;"><u>1. List Of Major Commercial Areas</u>			
            </h4>
            <br>
            <div class="table-responsive">
                <table  class="table ">
                    <thead>
                        <tr>
                            <td style="font-size:large; font-weight:bolder; color: black;text-align: left;">S.No</td>
                            <td style="font-size:large; font-weight:bolder; color: black;width:304px;text-align: left;">Name of Notified Commercial Areas</td>
                            <td style="font-size:large; font-weight:bolder; color: black;width:304px;text-align: left;">Ward No</td>
                            <td style="font-size:large; font-weight:bolder; color: black;width:550px;text-align: left;">No. of Constructed Public Toilet in the Area</td>
                            <td style="font-size:large; font-weight:bolder; color: black;width:550px;text-align: left;">No. of Public Toilets under Construction in the Area</td>
                        </tr>
                    </thead>
                    <tbody>
                        <tr>
                           <td style=" font-size:medium; font-weight: 500;text-align:left">1</td>
                           <td style=" font-size:medium; font-weight: 500;text-align:left">Municipality  Super Market
                        </td>
                           <td style=" font-size:medium; font-weight: 500;text-align:left">4
                        </td>
                           <td style=" font-size:medium; font-weight: 500;text-align:left">1</td>
                           <td style=" font-size:medium; font-weight: 500;text-align:left">0</td>
                        </tr>
                        <tr>
                            <td style=" font-size:medium; font-weight: 500;text-align:left">2</td>
                            <td style=" font-size:medium; font-weight: 500;text-align:left">Subhas Avenue
                            </td>
                            <td style=" font-size:medium; font-weight: 500;text-align:left">4</td>
                            <td style=" font-size:medium; font-weight: 500;text-align:left">0</td>
                            <td style=" font-size:medium; font-weight: 500;text-align:left">0</td>
                         </tr>
                         <tr>
                            <td style=" font-size:medium; font-weight: 500;text-align:left">3</td>
                            <td style=" font-size:medium; font-weight: 500;text-align:left">Court More
                            </td>
                            <td style=" font-size:medium; font-weight: 500;text-align:left">10</td>
                            <td style=" font-size:medium; font-weight: 500;text-align:left">1</td>
                            <td style=" font-size:medium; font-weight: 500;text-align:left">0</td>
                         </tr>
                         <tr>
                            <td style=" font-size:medium; font-weight: 500;text-align:left">4</td>
                            <td style=" font-size:medium; font-weight: 500;text-align:left">Mahabir Market
                            </td>
                            <td style=" font-size:medium; font-weight: 500;text-align:left">9</td>
                            <td style=" font-size:medium; font-weight: 500;text-align:left">0</td>
                            <td style=" font-size:medium; font-weight: 500;text-align:left">0</td>
                         </tr>
                         <tr>
                            <td style=" font-size:medium; font-weight: 500;text-align:left">5</td>
                            <td style=" font-size:medium; font-weight: 500;text-align:left">Chabi gate Near Shopping mall
                            </td>
                            <td style=" font-size:medium; font-weight: 500;text-align:left">17</td>
                            <td style=" font-size:medium; font-weight: 500;text-align:left">0</td>
                            <td style=" font-size:medium; font-weight: 500;text-align:left">0</td>
                         </tr>
                         <tr>
                            <td style=" font-size:medium; font-weight: 500;text-align:left">6</td>
                            <td style=" font-size:medium; font-weight: 500;text-align:left">Ranaghat College more
                            </td>
                            <td style=" font-size:medium; font-weight: 500;text-align:left">11</td>
                            <td style=" font-size:medium; font-weight: 500;text-align:left">0</td>
                            <td style=" font-size:medium; font-weight: 500;text-align:left">0</td>
                         </tr>
                         <tr>
                            <td style=" font-size:medium; font-weight: 500;text-align:left">7</td>
                            <td style=" font-size:medium; font-weight: 500;text-align:left">Rathtala More
                            </td>
                            <td style=" font-size:medium; font-weight: 500;text-align:left">19</td>
                            <td  style=" font-size:medium; font-weight: 500;text-align:left">1</td>
                            <td  style=" font-size:medium; font-weight: 500;text-align:left">0</td>
                         </tr>
                         <tr>
                            <td  style=" font-size:medium; font-weight: 500;text-align:left">8</td>
                            <td  style=" font-size:medium; font-weight: 500;text-align:left">Nasra Bazar
                            </td>
                            <td  style=" font-size:medium; font-weight: 500;text-align:left">18</td>
                            <td  style=" font-size:medium; font-weight: 500;text-align:left">0</td>
                            <td  style=" font-size:medium; font-weight: 500;text-align:left">0</td>
                         </tr>
                         <tr>
                            <td  style=" font-size:medium; font-weight: 500;text-align:left">9</td>
                            <td  style=" font-size:medium; font-weight: 500;text-align:left">Sarak para
                            </td>
                            <td  style=" font-size:medium; font-weight: 500;text-align:left">1</td>
                            <td  style=" font-size:medium; font-weight: 500;text-align:left">0</td>
                            <td  style=" font-size:medium; font-weight: 500;text-align:left">0</td>
                         </tr>

                    </tbody>
                </table>
            </div>
            <br>
            <br>
            <div class="table-responsive">
                <table border="1" >
                    <tbody>
                        <tr>
                            <td style="text-align: center;width: 1000px;font-weight: 700;">No. of Vegetable Markets(Count)			
			
                            </td>
                            <td style="width: 304px;text-align: right;font-weight: 700;">7			
                            </td>
                        </tr>
                    </tbody>
                </table>
            </div>
                <br>
                <br>
            <h4 style="font-weight: 700;"><u>2. List of Vegetable Markets</u>			
            </h4>
            <br>
            <div class="table-responsive">
                <table  class="table ">
                    <thead>
                        <tr>
                            <td style="font-size:large; font-weight:bolder; color: black;text-align: left;">S.No</td>
                            <td style="font-size:large; font-weight:bolder; color: black;width:304px;text-align: left;">Name of </td>
                            <td style="font-size:large; font-weight:bolder; color: black;width:304px;text-align: left;">Ward No</td>
                            <td style="font-size:large; font-weight:bolder; color: black;width:550px;text-align: left;">No. of Constructed Public Toilet in the Area</td>
                            <td style="font-size:large; font-weight:bolder; color: black;width:550px;text-align: left;">No. of Public Toilets under Construction in the Area</td>
                        </tr>
                    </thead>
                    <tbody>
                        <tr>
                           <td style=" font-size:medium; font-weight: 500;text-align:left">1</td>
                           <td style=" font-size:medium; font-weight: 500;text-align:left">Netaji Bazar
                        </td>
                           <td style=" font-size:medium; font-weight: 500;text-align:left">4
                        </td>
                           <td style=" font-size:medium; font-weight: 500;text-align:left">1</td>
                           <td style=" font-size:medium; font-weight: 500;text-align:left">0</td>
                        </tr>
                        <tr>
                            <td style=" font-size:medium; font-weight: 500;text-align:left">2</td>
                            <td style=" font-size:medium; font-weight: 500;text-align:left">Rail Bazar
                            </td>
                            <td style=" font-size:medium; font-weight: 500;text-align:left">4</td>
                            <td style=" font-size:medium; font-weight: 500;text-align:left">0</td>
                            <td style=" font-size:medium; font-weight: 500;text-align:left">0</td>
                         </tr>
                         <tr>
                            <td style=" font-size:medium; font-weight: 500;text-align:left">3</td>
                            <td style=" font-size:medium; font-weight: 500;text-align:left">Ghatak Bazar
                            </td>
                            <td style=" font-size:medium; font-weight: 500;text-align:left">4</td>
                            <td style=" font-size:medium; font-weight: 500;text-align:left">1</td>
                            <td style=" font-size:medium; font-weight: 500;text-align:left">0</td>
                         </tr>
                         <tr>
                            <td style=" font-size:medium; font-weight: 500;text-align:left">4</td>
                            <td style=" font-size:medium; font-weight: 500;text-align:left">Dutta Bazar
                            </td>
                            <td style=" font-size:medium; font-weight: 500;text-align:left">11</td>
                            <td style=" font-size:medium; font-weight: 500;text-align:left">0</td>
                            <td style=" font-size:medium; font-weight: 500;text-align:left">0</td>
                         </tr>
                         <tr>
                            <td style=" font-size:medium; font-weight: 500;text-align:left">5</td>
                            <td style=" font-size:medium; font-weight: 500;text-align:left">Informal Market
                            </td>
                            <td style=" font-size:medium; font-weight: 500;text-align:left">13</td>
                            <td style=" font-size:medium; font-weight: 500;text-align:left">1</td>
                            <td style=" font-size:medium; font-weight: 500;text-align:left">0</td>
                         </tr>
                         <tr>
                            <td style=" font-size:medium; font-weight: 500;text-align:left">6</td>
                            <td style=" font-size:medium; font-weight: 500;text-align:left">Beltola Bazar
                            </td>
                            <td style=" font-size:medium; font-weight: 500;text-align:left">14</td>
                            <td style=" font-size:medium; font-weight: 500;text-align:left">0</td>
                            <td style=" font-size:medium; font-weight: 500;text-align:left">0</td>
                         </tr>
                         <tr>
                            <td style=" font-size:medium; font-weight: 500;text-align:left">7</td>
                            <td style=" font-size:medium; font-weight: 500;text-align:left">Rathtola Fish & Sabji Market
                            </td>
                            <td style=" font-size:medium; font-weight: 500;text-align:left">19</td>
                            <td style=" font-size:medium; font-weight: 500;text-align:left">0</td>
                            <td style=" font-size:medium; font-weight: 500;text-align:left">0</td>
                         </tr>
                    </tbody>
                </table>
            </div>

            <br>
            <br>
        <h4 style="font-weight: 700;"><u>3. List of Bus stands, Bus Station</u>			
		
        </h4>
        <br>
        <div class="table-responsive">
            <table  class="table ">
                <thead>
                    <tr>
                        <td style="font-size:large; font-weight:bolder; color: black;text-align: left;">S.No</td>
                        <td style="font-size:large; font-weight:bolder; color: black;width:304px;text-align: left;">Name of </td>
                        <td style="font-size:large; font-weight:bolder; color: black;width:304px;text-align: left;">Ward No</td>
                        <td style="font-size:large; font-weight:bolder; color: black;width:550px;text-align: left;">No. of Constructed Public Toilet in the Area</td>
                        <td style="font-size:large; font-weight:bolder; color: black;width:550px;text-align: left;">No. of Public Toilets under Construction in the Area</td>
                    </tr>
                </thead>
                <tbody>
                    <tr>
                       <td style=" font-size:medium; font-weight: 500;text-align:left">1</td>
                       <td style=" font-size:medium; font-weight: 500;text-align:left">RATHTALA BUS STAND
                    </td>
                       <td style=" font-size:medium; font-weight: 500;text-align:left">19
                    </td>
                       <td style=" font-size:medium; font-weight: 500;text-align:left">1</td>
                       <td style=" font-size:medium; font-weight: 500;text-align:left">N.A</td>
                    </tr>
                    <tr>
                        <td style=" font-size:medium; font-weight: 500;text-align:left">2</td>
                        <td style=" font-size:medium; font-weight: 500;text-align:left">ModanMohan/NBSTC BUS STAND

                        </td>
                        <td style=" font-size:medium; font-weight: 500;text-align:left">11</td>
                        <td style=" font-size:medium; font-weight: 500;text-align:left">1</td>
                        <td style=" font-size:medium; font-weight: 500;text-align:left">N.A</td>
                     </tr>
                     <tr>
                        <td style=" font-size:medium; font-weight: 500;text-align:left">3</td>
                        <td style=" font-size:medium; font-weight: 500;text-align:left">Railway Station
                        </td>
                        <td style=" font-size:medium; font-weight: 500;text-align:left">4</td>
                        <td style=" font-size:medium; font-weight: 500;text-align:left">1</td>
                        <td style=" font-size:medium; font-weight: 500;text-align:left">N.A</td>
                     </tr>
                </tbody>
            </table>
        </div>

        <br>
        <br>
    <h4 style="font-weight: 700;"><u>4. List of Religious Importance Place/Recreational Parks/Playgrounds</u>				
		
    </h4>
    <br>
    <div class="table-responsive">
        <table  class="table ">
            <thead>
                <tr>
                    <td style="font-size:large; font-weight:bolder; color: black;text-align: left;">S.No</td>
                    <td style="font-size:large; font-weight:bolder; color: black;width:304px;text-align: left;">Name of </td>
                    <td style="font-size:large; font-weight:bolder; color: black;width:304px;text-align: left;">Ward No</td>
                    <td style="font-size:large; font-weight:bolder; color: black;width:550px;text-align: left;">No. of Constructed Public Toilet in the Area</td>
                </tr>
            </thead>
            <tbody>
                <tr>
                   <td style=" font-size:medium; font-weight: 500;text-align:left">1</td>
                   <td style=" font-size:medium; font-weight: 500;text-align:left">Binidini Park
                </td>
                   <td style=" font-size:medium; font-weight: 500;text-align:left">2
                </td>
                   <td style=" font-size:medium; font-weight: 500;text-align:left">1(Biotoilet)</td>
                </tr>
                <tr>
                    <td style=" font-size:medium; font-weight: 500;text-align:left">2</td>
                    <td style=" font-size:medium; font-weight: 500;text-align:left">Abakash Park
                    </td>
                    <td style=" font-size:medium; font-weight: 500;text-align:left">10</td>
                    <td style=" font-size:medium; font-weight: 500;text-align:left">1</td>
                   
                 </tr>
                 <tr>
                    <td style=" font-size:medium; font-weight: 500;text-align:left">3</td>
                    <td style=" font-size:medium; font-weight: 500;text-align:left">Gopinath Mondir
                    </td>
                    <td style=" font-size:medium; font-weight: 500;text-align:left">1</td>
                    <td style=" font-size:medium; font-weight: 500;text-align:left">0</td>
                    
                 </tr>
                 <tr>
                    <td style=" font-size:medium; font-weight: 500;text-align:left">4</td>
                    <td style=" font-size:medium; font-weight: 500;text-align:left">Brajaballav Mandir

                    </td>
                    <td style=" font-size:medium; font-weight: 500;text-align:left">2</td>
                    <td style=" font-size:medium; font-weight: 500;text-align:left">0</td>
                    
                 </tr>
                 <tr>
                    <td style=" font-size:medium; font-weight: 500;text-align:left">5</td>
                    <td style=" font-size:medium; font-weight: 500;text-align:left">Siddeswari Mandir
                    </td>
                    <td style=" font-size:medium; font-weight: 500;text-align:left">6</td>
                    <td style=" font-size:medium; font-weight: 500;text-align:left">0</td>
                   
                 </tr>
                 <tr>
                    <td style=" font-size:medium; font-weight: 500;text-align:left">6</td>
                    <td style=" font-size:medium; font-weight: 500;text-align:left">Shitalatal Mandir
                    </td>
                    <td style=" font-size:medium; font-weight: 500;text-align:left">12</td>
                    <td style=" font-size:medium; font-weight: 500;text-align:left">0</td>
                   
                 </tr>
                 <tr>
                    <td style=" font-size:medium; font-weight: 500;text-align:left">7</td>
                    <td style=" font-size:medium; font-weight: 500;text-align:left">Daspara Masjid
                    </td>
                    <td style=" font-size:medium; font-weight: 500;text-align:left">12</td>
                    <td style=" font-size:medium; font-weight: 500;text-align:left">0</td>
                    
                 </tr>
                 <tr>
                    <td style=" font-size:medium; font-weight: 500;text-align:left">8</td>
                    <td style=" font-size:medium; font-weight: 500;text-align:left">Mahaprabhu para Mandir</td>
                    <td style=" font-size:medium; font-weight: 500;text-align:left">16</td>
                    <td style=" font-size:medium; font-weight: 500;text-align:left">0</td>
                 </tr>
            </tbody>
        </table>
    </div>

    <br>
    <br>
<h4 style="font-weight: 700;"><u>5. List of Water bodies including river, Lakes, ponds	</u>		
</h4>
<br>
<div class="table-responsive">
    <table  class="table ">
        <thead>
            <tr>
                <td style="font-size:large; font-weight:bolder; color: black;text-align: left;">S.No</td>
                <td style="font-size:large; font-weight:bolder; color: black;width:304px;text-align: left;">Name of Location
                </td>
                <td style="font-size:large; font-weight:bolder; color: black;width:304px;text-align: left;">Location
                </td>
                <td style="font-size:large; font-weight:bolder; color: black;width:550px;text-align: left;">Ward No
                </td>
            </tr>
        </thead>
        <tbody>
            <tr>
               <td style=" font-size:medium; font-weight: 500;text-align:left">1</td>
               <td style=" font-size:medium; font-weight: 500;text-align:left">River Churni
            </td>
               <td style=" font-size:medium; font-weight: 500;text-align:left">Thanapara,Strand Road
            </td>
               <td style=" font-size:medium; font-weight: 500;text-align:left">1, 2, 4, 7 & 10
            </td>
            </tr>
            <tr>
                <td style=" font-size:medium; font-weight: 500;text-align:left">2</td>
                <td style=" font-size:medium; font-weight: 500;text-align:left">Basko Khal
                </td>
                <td style=" font-size:medium; font-weight: 500;text-align:left">SarakPara
                </td>
                <td style=" font-size:medium; font-weight: 500;text-align:left">1, 19 & 20
                </td>
               
             </tr>
             <tr>
                <td style=" font-size:medium; font-weight: 500;text-align:left">3</td>
                <td style=" font-size:medium; font-weight: 500;text-align:left">Dey Chowdhury Pond
                </td>
                <td style=" font-size:medium; font-weight: 500;text-align:left">Siddeswesital
                </td>
                <td style=" font-size:medium; font-weight: 500;text-align:left">6</td>
                
             </tr>
             <tr>
                <td style=" font-size:medium; font-weight: 500;text-align:left">4</td>
                <td style=" font-size:medium; font-weight: 500;text-align:left">Sreenathpur Pond
                </td>
                <td style=" font-size:medium; font-weight: 500;text-align:left">Near Monorama
                </td>
                <td style=" font-size:medium; font-weight: 500;text-align:left">10</td>
                
             </tr>
             <tr>
                <td style=" font-size:medium; font-weight: 500;text-align:left">5</td>
                <td style=" font-size:medium; font-weight: 500;text-align:left">Nasra Pond
                </td>
                <td style=" font-size:medium; font-weight: 500;text-align:left">Near Sangha Mitra Club
                </td>
                <td style=" font-size:medium; font-weight: 500;text-align:left">17</td>
               
             </tr>
             <tr>
                <td style=" font-size:medium; font-weight: 500;text-align:left">6</td>
                <td style=" font-size:medium; font-weight: 500;text-align:left">Talpukur
                </td>
                <td style=" font-size:medium; font-weight: 500;text-align:left">Talpukurpara
                </td>
                <td style=" font-size:medium; font-weight: 500;text-align:left">19</td>
               
             </tr>
             <tr>
                <td style=" font-size:medium; font-weight: 500;text-align:left">7</td>
                <td style=" font-size:medium; font-weight: 500;text-align:left">Kamarpukur
                </td>
                <td style=" font-size:medium; font-weight: 500;text-align:left">Kamarpara
                </td>
                <td style=" font-size:medium; font-weight: 500;text-align:left">16</td>
                
             </tr>
             <tr>
                <td style=" font-size:medium; font-weight: 500;text-align:left">8</td>
                <td style=" font-size:medium; font-weight: 500;text-align:left">Mohaprovu Tank
                </td>
                <td style=" font-size:medium; font-weight: 500;text-align:left">Mahaprabhupara
                </td>
                <td style=" font-size:medium; font-weight: 500;text-align:left">16</td>
             </tr>
             <tr>
                <td style=" font-size:medium; font-weight: 500;text-align:left">9</td>
                <td style=" font-size:medium; font-weight: 500;text-align:left">Yusuf Pond
                </td>
                <td style=" font-size:medium; font-weight: 500;text-align:left">Yusuf School
                </td>
                <td style=" font-size:medium; font-weight: 500;text-align:left">16</td>
             </tr>
             <tr>
                <td style=" font-size:medium; font-weight: 500;text-align:left">10</td>
                <td style=" font-size:medium; font-weight: 500;text-align:left">Balir Pukur
                </td>
                <td style=" font-size:medium; font-weight: 500;text-align:left">Uttar Nasra
                </td>
                <td style=" font-size:medium; font-weight: 500;text-align:left">13</td>
             </tr>
             <tr>
                <td style=" font-size:medium; font-weight: 500;text-align:left">11</td>
                <td style=" font-size:medium; font-weight: 500;text-align:left">Sitala Pukur
                </td>
                <td style=" font-size:medium; font-weight: 500;text-align:left">Nasra

                </td>
                <td style=" font-size:medium; font-weight: 500;text-align:left">14</td>
             </tr>
        </tbody>
    </table>
</div>
          </div>
           </div>
     
      </div>
    </div>
  </section>

