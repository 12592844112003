import { Component, OnInit } from '@angular/core';
import { Router,ActivatedRoute } from '@angular/router';
import { ApiService } from 'src/app/services/api.service';

import { FormBuilder, FormGroup, Validators,ValidatorFn } from '@angular/forms';
import { ToastrService } from 'ngx-toastr';
import { NgxSpinnerService } from "ngx-spinner";
import { DomSanitizer } from '@angular/platform-browser';

@Component({
  selector: 'app-wordwiseinfo',
  templateUrl: './wordwiseinfo.component.html',
  styleUrls: ['./wordwiseinfo.component.css']
})
export class WordwiseinfoComponent implements OnInit {
  singleInfoDiv:boolean=true;
  wardHouseholdInfo:boolean=true;
  wardWiseinformation:any;
 wardWiseinfo:any;
 imageCouncillor: any;
 isShow = false;
 ward1= false;
 ward2= false;
 ward3= false;
 ward4= false;
 ward5= false;
 ward6= false;
 ward7= false;
 ward8= false;
 ward9= false;
 ward10= false;
 ward11= false;
 ward12= false;
 ward13= false;
 ward14= false;
 ward15= false;
 ward16= false;
 ward17= false;
 ward18= false;
 ward19= false;
 ward20= false;
 wardWiseData:any=[];
 wardNo:any;
 wardCouncillorName:any;
 councillorImage:any;
 councillorMobileNo: any;
 emailId: any;
 wardAddress: any;
 wardArea: any;
 landUse:any;
 houseHoldNo:any;
 bplhouseHoldNo:any;
 totalPopulation:any;
 totalMale:any;
 totalFemale:any;
 totalLengthRoad:any;
 population1981:any;
 population1991:any;
 population2001:any;
 population2011:any;
 population2022:any;
 floatingPopulation:any;
 noSchool:any;
 noCollege:any;
 wardMap:any;
 detailShow:boolean = false;
 wardSchoolInfoResponces:any=[];
 imageObjofCouncillor: Array<object>=[];
 imageObjofWardMap: Array<object>=[];
 bytedataimageforcouncillor: any;
 bytedataimageforwardmap: any;

  constructor(private apiService: ApiService,private router: Router,private route:ActivatedRoute,private formBuilder: FormBuilder,
    private toastrService: ToastrService,
    private SpinnerService: NgxSpinnerService, private domSanitizer: DomSanitizer)
    { }
 
  
  ngOnInit() {
    this.apiService.wardWiseinfo().subscribe((res:any )=> {
      
      this.wardWiseinformation = res.data;
      //  console.log(this.wardWiseinformation);

     
  },
  (error:any )=> {
  
  });
    this.apiService.getAllWard().subscribe((res:any )=> {
    this.wardWiseinfo =res.data;
  
   
},
(error:any )=> {

});


  }  
  getwardInfoById(id:any){
    this.apiService.getWardInfoById(id).subscribe((res:any )=> {
    // console.log("wardwise info",res);
    this.detailShow = true;
      this.wardWiseData =res.data;
      this.wardNo = this.wardWiseData.wardNo;
      this.wardCouncillorName = this.wardWiseData.wardCouncillorName;
      this.councillorImage = this.wardWiseData.councillorImage;
      this.councillorMobileNo = this.wardWiseData.councillorMobileNo;
      this.emailId = this.wardWiseData.emailId;
      this.wardAddress = this.wardWiseData.wardAddress;
      this.wardArea = this.wardWiseData.wardArea;
      this.landUse = this.wardWiseData.landUse;
      this.houseHoldNo = this.wardWiseData.houseHoldNo;
      this.bplhouseHoldNo = this.wardWiseData.bplhouseHoldNo;
      this.totalPopulation = this.wardWiseData.totalPopulation;
      this.totalMale = this.wardWiseData.totalMale;
      this.totalFemale =this.wardWiseData.totalFemale;
      this.totalLengthRoad = this.wardWiseData.roadContent;
      this.population1981 = this.wardWiseData.population1981;
      this.population1991 = this.wardWiseData.population1991;
      this.population2001 = this.wardWiseData.population2001;
      this.population2011 = this.wardWiseData.population2011;
      this.population2022 = this.wardWiseData.population2022;
      this.floatingPopulation = this.wardWiseData.floatingPopulation;
      this.noSchool = this.wardWiseData.noSchool;
      this.noCollege = this.wardWiseData.noCollege;
      this.wardMap = this.wardWiseData.wardMap;
      this.wardSchoolInfoResponces = this.wardWiseData.wardSchoolInfoResponces;
      this.bytedataimageforcouncillor = this.wardWiseData.byteDataforCouncillor;
      this.bytedataimageforwardmap = this.wardWiseData.byteDataforWardMap;
      // this.imageCouncillor = this.domSanitizer.bypassSecurityTrustUrl(this.wardWiseData.byteDataforCouncillor);
      //let imageCouncillor = 'data:image/jpg;base64,${res.data.byteDataforCouncillor}';
        // let param1 = [
        //   {
        //     image: imageCouncillor,
        //     thumbImage: imageCouncillor,
        //     title: 'Councillor Image',
        //     alt: 'Councillor Image'
        //   }
        // ];  
       // this.imageObjofCouncillor.push(param1);
        // let imageWardMap = `data:image/png;base64,${this.wardWiseData.byteDataforWardMap}`;
        // let param = [
        //   {
        //     image: imageWardMap,
        //     thumbImage: imageWardMap,
        //     title: 'WardMap Image',
        //     alt: 'WardMap Image'
        //   }
        // ];  
        // this.imageObjofWardMap.push(param);
    
     
  },
  (error:any )=> {
  
  });
  }
  ward1info(){
    this.isShow = true;
    this.ward1 = true;
    this.ward2 = false;
    this.ward3 = false;
    this.ward4 = false;
    this.ward5 = false;
    this.ward6 = false;
    this.ward7 = false;
    this.ward8 = false;
    this.ward9 = false;
    this.ward10 = false;
    this.ward11 = false;
    this.ward12 = false;
    this.ward13 = false;
    this.ward14 = false;
    this.ward15 = false;
    this.ward16 = false;
    this.ward17 = false;
    this.ward18 = false;
    this.ward19 = false;
    this.ward20 = false;
  }
 
  ward2info(){
    this.isShow = false;
    this.ward1 = false;
    this.ward2 = true;
    this.ward3 = false;
    this.ward4 = false;
    this.ward5 = false;
    this.ward6 = false;
    this.ward7 = false;
    this.ward8 = false;
    this.ward9 = false;
    this.ward10 = false;
    this.ward11 = false;
    this.ward12 = false;
    this.ward13 = false;
    this.ward14 = false;
    this.ward15 = false;
    this.ward16 = false;
    this.ward17 = false;
    this.ward18 = false;
    this.ward19 = false;
    this.ward20 = false;
  }
  ward3info(){
    this.isShow = false;
    this.ward1 = false;
    this.ward2 = false;
    this.ward3 = true;
    this.ward4 = false;
    this.ward5 = false;
    this.ward6 = false;
    this.ward7 = false;
    this.ward8 = false;
    this.ward9 = false;
    this.ward10 = false;
    this.ward11 = false;
    this.ward12 = false;
    this.ward13 = false;
    this.ward14 = false;
    this.ward15 = false;
    this.ward16 = false;
    this.ward17 = false;
    this.ward18 = false;
    this.ward19 = false;
    this.ward20 = false;
  }
  ward4info(){
    this.isShow = false;
    this.ward1 = false;
    this.ward2 = false;
    this.ward3 = false;
    this.ward4 = true;
    this.ward5 = false;
    this.ward6 = false;
    this.ward7 = false;
    this.ward8 = false;
    this.ward9 = false;
    this.ward10 = false;
    this.ward11 = false;
    this.ward12 = false;
    this.ward13 = false;
    this.ward14 = false;
    this.ward15 = false;
    this.ward16 = false;
    this.ward17 = false;
    this.ward18 = false;
    this.ward19 = false;
    this.ward20 = false;
  }
  ward5info(){
    this.isShow = false;
    this.ward1 = false;
    this.ward2 = false;
    this.ward3 = false;
    this.ward4 = false;
    this.ward5 = true;
    this.ward6 = false;
    this.ward7 = false;
    this.ward8 = false;
    this.ward9 = false;
    this.ward10 = false;
    this.ward11 = false;
    this.ward12 = false;
    this.ward13 = false;
    this.ward14 = false;
    this.ward15 = false;
    this.ward16 = false;
    this.ward17 = false;
    this.ward18 = false;
    this.ward19 = false;
    this.ward20 = false;
  }
  ward6info(){
    this.isShow = false;
    this.ward1 = false;
    this.ward2 = false;
    this.ward3 = false;
    this.ward4 = false;
    this.ward5 = false;
    this.ward6 = true;
    this.ward7 = false;
    this.ward8 = false;
    this.ward9 = false;
    this.ward10 = false;
    this.ward11 = false;
    this.ward12 = false;
    this.ward13 = false;
    this.ward14 = false;
    this.ward15 = false;
    this.ward16 = false;
    this.ward17 = false;
    this.ward18 = false;
    this.ward19 = false;
    this.ward20 = false;
  }
  ward7info(){
    this.isShow = false;
    this.ward1 = false;
    this.ward2 = false;
    this.ward3 = false;
    this.ward4 = false;
    this.ward5 = false;
    this.ward6 = false;
    this.ward7 = true;
    this.ward8 = false;
    this.ward9 = false;
    this.ward10 = false;
    this.ward11 = false;
    this.ward12 = false;
    this.ward13 = false;
    this.ward14 = false;
    this.ward15 = false;
    this.ward16 = false;
    this.ward17 = false;
    this.ward18 = false;
    this.ward19 = false;
    this.ward20 = false;
  }
  ward8info(){
    this.isShow = false;
    this.ward1 = false;
    this.ward2 = false;
    this.ward3 = false;
    this.ward4 = false;
    this.ward5 = false;
    this.ward6 = false;
    this.ward7 = false;
    this.ward8 = true;
    this.ward9 = false;
    this.ward10 = false;
    this.ward11 = false;
    this.ward12 = false;
    this.ward13 = false;
    this.ward14 = false;
    this.ward15 = false;
    this.ward16 = false;
    this.ward17 = false;
    this.ward18 = false;
    this.ward19 = false;
    this.ward20 = false;
  }
  ward9info(){
    this.isShow = false;
    this.ward1 = false;
    this.ward2 = false;
    this.ward3 = false;
    this.ward4 = false;
    this.ward5 = false;
    this.ward6 = false;
    this.ward7 = false;
    this.ward8 = false;
    this.ward9 = true;
    this.ward10 = false;
    this.ward11 = false;
    this.ward12 = false;
    this.ward13 = false;
    this.ward14 = false;
    this.ward15 = false;
    this.ward16 = false;
    this.ward17 = false;
    this.ward18 = false;
    this.ward19 = false;
    this.ward20 = false;
  }
  ward10info(){
    this.isShow = false;
    this.ward1 = false;
    this.ward2 = false;
    this.ward3 = false;
    this.ward4 = false;
    this.ward5 = false;
    this.ward6 = false;
    this.ward7 = false;
    this.ward8 = false;
    this.ward9 = false;
    this.ward10 = true;
    this.ward11 = false;
    this.ward12 = false;
    this.ward13 = false;
    this.ward14 = false;
    this.ward15 = false;
    this.ward16 = false;
    this.ward17 = false;
    this.ward18 = false;
    this.ward19 = false;
    this.ward20 = false;
  }
  ward11info(){
    this.isShow = false;
    this.ward1 = false;
    this.ward2 = false;
    this.ward3 = false;
    this.ward4 = false;
    this.ward5 = false;
    this.ward6 = false;
    this.ward7 = false;
    this.ward8 = false;
    this.ward9 = false;
    this.ward10 = false;
    this.ward11 = true;
    this.ward12 = false;
    this.ward13 = false;
    this.ward14 = false;
    this.ward15 = false;
    this.ward16 = false;
    this.ward17 = false;
    this.ward18 = false;
    this.ward19 = false;
    this.ward20 = false;
  }
  ward12info(){
    this.isShow = false;
    this.ward1 = false;
    this.ward2 = false;
    this.ward3 = false;
    this.ward4 = false;
    this.ward5 = false;
    this.ward6 = false;
    this.ward7 = false;
    this.ward8 = false;
    this.ward9 = false;
    this.ward10 = false;
    this.ward11 = false;
    this.ward12 = true;
    this.ward13 = false;
    this.ward14 = false;
    this.ward15 = false;
    this.ward16 = false;
    this.ward17 = false;
    this.ward18 = false;
    this.ward19 = false;
    this.ward20 = false;
  }
  ward13info(){
    this.isShow = false;
    this.ward1 = false;
    this.ward2 = false;
    this.ward3 = false;
    this.ward4 = false;
    this.ward5 = false;
    this.ward6 = false;
    this.ward7 = false;
    this.ward8 = false;
    this.ward9 = false;
    this.ward10 = false;
    this.ward11 = false;
    this.ward12 = false;
    this.ward13 = true;
    this.ward14 = false;
    this.ward15 = false;
    this.ward16 = false;
    this.ward17 = false;
    this.ward18 = false;
    this.ward19 = false;
    this.ward20 = false;
  }
  ward14info(){
    this.isShow = false;
    this.ward1 = false;
    this.ward2 = false;
    this.ward3 = false;
    this.ward4 = false;
    this.ward5 = false;
    this.ward6 = false;
    this.ward7 = false;
    this.ward8 = false;
    this.ward9 = false;
    this.ward10 = false;
    this.ward11 = false;
    this.ward12 = false;
    this.ward13 = false;
    this.ward14 = true;
    this.ward15 = false;
    this.ward16 = false;
    this.ward17 = false;
    this.ward18 = false;
    this.ward19 = false;
    this.ward20 = false;
  }
  ward15info(){
    this.isShow = false;
    this.ward1 = false;
    this.ward2 = false;
    this.ward3 = false;
    this.ward4 = false;
    this.ward5 = false;
    this.ward6 = false;
    this.ward7 = false;
    this.ward8 = false;
    this.ward9 = false;
    this.ward10 = false;
    this.ward11 = false;
    this.ward12 = false;
    this.ward13 = false;
    this.ward14 = false;
    this.ward15 = true;
    this.ward16 = false;
    this.ward17 = false;
    this.ward18 = false;
    this.ward19 = false;
    this.ward20 = false;
  }
  ward16info(){
    this.isShow = false;
    this.ward1 = false;
    this.ward2 = false;
    this.ward3 = false;
    this.ward4 = false;
    this.ward5 = false;
    this.ward6 = false;
    this.ward7 = false;
    this.ward8 = false;
    this.ward9 = false;
    this.ward10 = false;
    this.ward11 = false;
    this.ward12 = false;
    this.ward13 = false;
    this.ward14 = false;
    this.ward15 = false;
    this.ward16 = true;
    this.ward17 = false;
    this.ward18 = false;
    this.ward19 = false;
    this.ward20 = false;
  }
  ward17info(){
    this.isShow = false;
    this.ward1 = false;
    this.ward2 = false;
    this.ward3 = false;
    this.ward4 = false;
    this.ward5 = false;
    this.ward6 = false;
    this.ward7 = false;
    this.ward8 = false;
    this.ward9 = false;
    this.ward10 = false;
    this.ward11 = false;
    this.ward12 = false;
    this.ward13 = false;
    this.ward14 = false;
    this.ward15 = false;
    this.ward16 = false;
    this.ward17 = true;
    this.ward18 = false;
    this.ward19 = false;
    this.ward20 = false;
  }
  ward18info(){
    this.isShow = false;
    this.ward1 = false;
    this.ward2 = false;
    this.ward3 = false;
    this.ward4 = false;
    this.ward5 = false;
    this.ward6 = false;
    this.ward7 = false;
    this.ward8 = false;
    this.ward9 = false;
    this.ward10 = false;
    this.ward11 = false;
    this.ward12 = false;
    this.ward13 = false;
    this.ward14 = false;
    this.ward15 = false;
    this.ward16 = false;
    this.ward17 = false;
    this.ward18 = true;
    this.ward19 = false;
    this.ward20 = false;
  }
  ward19info(){
    this.isShow = false;
    this.ward1 = false;
    this.ward2 = false;
    this.ward3 = false;
    this.ward4 = false;
    this.ward5 = false;
    this.ward6 = false;
    this.ward7 = false;
    this.ward8 = false;
    this.ward9 = false;
    this.ward10 = false;
    this.ward11 = false;
    this.ward12 = false;
    this.ward13 = false;
    this.ward14 = false;
    this.ward15 = false;
    this.ward16 = false;
    this.ward17 = false;
    this.ward18 = false;
    this.ward19 = true;
    this.ward20 = false;
  }
  ward20info(){
    this.isShow = false;
    this.ward1 = false;
    this.ward2 = false;
    this.ward3 = false;
    this.ward4 = false;
    this.ward5 = false;
    this.ward6 = false;
    this.ward7 = false;
    this.ward8 = false;
    this.ward9 = false;
    this.ward10 = false;
    this.ward11 = false;
    this.ward12 = false;
    this.ward13 = false;
    this.ward14 = false;
    this.ward15 = false;
    this.ward16 = false;
    this.ward17 = false;
    this.ward18 = false;
    this.ward19 = false;
    this.ward20 = true;
  }

  hideShow(){
    this.singleInfoDiv= true;
    this.wardHouseholdInfo = false;
  }
  // singleInfoDiv:boolean=true;
  // wardHouseholdInfo:boolean=true;
}
