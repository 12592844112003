<app-header></app-header>
<div class="main-wapper">
  <div class="wapper-body">    
    <!-- <div class="row">
      <div class="col-lg-12 col-md-12 col-sm-12 col-xs-12 col-mob-12">
        <div class="top-panel">
          <form>
            <div class="form-group">
              <input type="text" name="text" placeholder="Search files">
              <a href="#" class="src-icon"><img src="assets/assetsAdmin/img/loupe.png"></a>
            </div>
          </form>
          <a href="javascript:void(0);" class="add-btn"><i class="fa fa-plus" aria-hidden="true"></i></a>
        </div>
        <div class="bottom-panel">
          <div class="table-responsive">
            <table class="table table-striped jambo_table">
              <thead>
                <tr class="headings">
                  <th class="column-title">Sl No</th>
                  <th class="column-title">Name</th>
                  <th class="column-title">Desination</th>
                  <th class="column-title">PF Number</th>
                  <th class="column-title">Salary A/c No</th>
                  <th class="column-title">Brunch</th>
                  <th class="column-title">Action</th>
                </tr>
              </thead>
              <tbody>
                <tr>
                  <td>1</td>
                  <td>Archya Dutta</td>
                  <td>Brunch Manager-in-charge</td>
                  <td>00024758</td>
                  <td>000457986397</td>
                  <td>Durgapur Brucnh</td>
                  <td class="last">
                    <div class="action-button">
                      <a href="javascript:void(0);" class="edit-btn"><i class="fa fa-edit" aria-hidden="true"></i></a>
                      <a href="javascript:void(0);" class="delete-btn"><i class="fa fa-times"
                      aria-hidden="true"></i></a>
                    </div>
                  </td>
                </tr>
              </tbody>
            </table>
          </div>

        </div>
      </div>
    </div> -->
  </div>
</div>


<!---pop-new-poroject-->
<!-- <div class="new-pop">
  <div class="new-pop-body"><a href="javascript:void(0);" class="cls-new-pop"><img
        src="assets/assetsAdmin/img/close.png" /></a>
    <h4>Add Page</h4>
    <p>Set frame rate, size resolution</p>
    <form id="contact-form" role="form">
      <div class="controls">
        <div class="row">
          <div class="col-md-6">
            <div class="form-group">
              <label for="form_name">Name *</label>
              <input id="form_name" type="text" name="name" class="form-control" placeholder="Please enter your name *"
                required="required" data-error="Name is required.">
            </div>
          </div>
          <div class="col-md-6">
            <div class="form-group">
              <label for="form_route">Route *</label>
              <input id="form_route" type="text" name="surname" class="form-control" placeholder="Please enter route *"
                required="required" data-error="Route is required.">
            </div>
          </div>
        </div>
        <div class="row">
          <div class="col-md-6">
            <div class="form-group">
              <label for="form_cption">Caption 01</label>
              <input id="form_cption" type="text" name="caption" class="form-control"
                placeholder="Please enter Caption">
              <small class="required">First Caption Is Required</small>
            </div>
          </div>
          <div class="col-md-6">
            <div class="form-group">
              <label for="form_email">Caption 02</label>
              <input id="form_email" type="text" name="caption" class="form-control" placeholder="Please enter Caption">
            </div>
          </div>
        </div>
        <div class="row">
          <div class="col-md-12">
            <div class="form-group file-area">
              <label for="images">File should be less than 4mb</label>
              <input type="file" name="images" id="images" required="required" multiple="multiple" />
              <div class="file-dummy">
                <div class="success">Great, your files are selected. Keep on.</div>
                <div class="default">Please select some files Or Drop File Here</div>
              </div>
            </div>
          </div>
        </div>
        <div class="row editor">
          <div class="col-md-12">
            <textarea name="editor1">Type here and show console to see editor's height</textarea>
          </div>
        </div>
        <div class="row">
          <div class="col-md-12">
            <div class="btn-grp">
              <button type="button" class="done-btn ">Submit</button>
              <button type="button" class="cancl-btn">Cancel</button>
            </div>
          </div>
        </div>
      </div>
    </form>
  </div>
</div> -->

<!---pop-plus Pge-->
<!-- <div class="plus-pop">
  <div class="new-pop-body"><a href="javascript:void(0);" class="cls-plus-pop"><img
        src="assets/assetsAdmin/img/close.png" /></a>
    <h4>Upload Files</h4>
    <p>Set frame rate, size resolution</p>
    <form id="contact-form" role="form">
      <div class="controls">
        <div class="row">
          <div class="col-md-4">
            <div class="form-group">
              <label for="form_name">Subject *</label>
              <input id="form_name" type="text" name="name" class="form-control" placeholder="Please enter Subject *"
                required="required" data-error="Subject is required.">
              <small class="required">Subject Is Required</small>
            </div>
          </div>
        </div>

        <div class="upload-area-main">
          <a href="javascript:void(0);" class="cls-btn"><img src="assets/assetsAdmin/img/close.png" /></a>
          <div class="upload-area">
            <div class="single-upload-area">
              <a href="javascript:void(0);" class="cls-btn"><img src="assets/assetsAdmin/img/close.png" /></a>
              <div class="single-upload-container">
                <div class="img-area"><img src="assets/assetsAdmin/img/ftg1.jpg" /></div>
                <span>Subject Is Required</span>
              </div>
            </div>
            <div class="single-upload-area">
              <a href="javascript:void(0);" class="cls-btn"><img src="assets/assetsAdmin/img/close.png" /></a>
              <div class="single-upload-container">
                <div class="img-area"><img src="assets/assetsAdmin/img/ftg2.jpg" /></div>
                <span>Subject Is Required</span>
              </div>
            </div>
            <div class="single-upload-area">
              <a href="javascript:void(0);" class="cls-btn"><img src="assets/assetsAdmin/img/close.png" /></a>
              <div class="single-upload-container">
                <div class="img-area"><img src="assets/assetsAdmin/img/ftg2.jpg" /></div>
                <span>Subject Is RequiredSubject Is RequiredSubject Is Required</span>
              </div>
            </div>
            <div class="single-upload-area">
              <a href="javascript:void(0);" class="cls-btn"><img src="assets/assetsAdmin/img/close.png" /></a>
              <div class="single-upload-container">
                <div class="img-area"><img src="assets/assetsAdmin/img/ftg2.jpg" /></div>
                <span>Subject Is Required</span>
              </div>
            </div>
            <div class="single-upload-area">
              <a href="javascript:void(0);" class="cls-btn"><img src="assets/assetsAdmin/img/close.png" /></a>
              <div class="single-upload-container">
                <div class="img-area"><img src="assets/assetsAdmin/img/ftg2.jpg" /></div>
                <span>Subject Is Required</span>
              </div>
            </div>
          </div>

          <div class="upaload-btn-area">
            <span><i class="fa fa-file" aria-hidden="true"></i> 2 Files Selected</span>
            <div class="btn-grp">
              <button type="button" class="cancl-btn"><i class="fa fa-trash" aria-hidden="true"></i> Remove</button>
              <button type="button" class="done-btn "><i class="fa fa-folder" aria-hidden="true"></i> Browse</button>
            </div>
          </div>
        </div>

        <div class="row">
          <div class="col-md-12">
            <div class="btn-grp">
              <button type="button" class="done-btn ">Upload</button>
              <button type="button" class="cancl-btn">Cancel</button>
            </div>
          </div>
        </div>
      </div>
    </form>
  </div>
</div> -->

<!---pop-Edit Pge-->
<!-- <div class="edit-pop">
  <div class="new-pop-body"><a href="javascript:void(0);" class="cls-edit-pop"><img
        src="assets/assetsAdmin/img/close.png" /></a>
    <h4>Edit Page</h4>
    <p>Set frame rate, size resolution</p>
    <form id="contact-form" role="form">
      <div class="controls">
        <div class="row">
          <div class="col-md-4">
            <div class="form-group">
              <label for="form_email">Email *</label>
              <input id="form_email" type="text" name="name" class="form-control"
                placeholder="Please enter your Email *" required="required" data-error="Email is required.">
            </div>
          </div>
          <div class="col-md-4">
            <div class="form-group">
              <label for="form_code">Code *</label>
              <input id="form_code" type="text" name="surname" class="form-control"
                placeholder="Please enter your Code *" required="required" data-error="Code is required.">
            </div>
          </div>
          <div class="col-md-4">
            <div class="form-group">
              <label for="form_password">Password *</label>
              <input id="form_password" type="password" name="surname" class="form-control"
                placeholder="Please enter your Password *" required="required" data-error="Password is required.">
            </div>
          </div>
        </div>
        <div class="row">
          <div class="col-md-3">
            <div class="form-group">
              <label for="form_need">Security questions *</label>
              <select id="form_need" name="need" class="form-control" required="required" data-error="Please Answer.">
                <option value="" selected disabled>--Select Your questions--</option>
                <option>Request Invoice for order</option>
                <option>Request order status</option>
                <option>Haven't received cashback yet</option>
                <option>Other</option>
              </select>
            </div>
          </div>
          <div class="col-md-3">
            <div class="form-group">
              <label for="form_answer">Security Answer *</label>
              <input id="form_answer" type="text" name="name" class="form-control"
                placeholder="Please enter your Answer *" required="required" data-error="Answer is required.">
            </div>
          </div>
          <div class="col-md-3">
            <div class="form-group">
              <label for="form_brunch">Brunch</label>
              <select id="form_brunch" name="need" class="form-control" required="required" data-error="Please Answer.">
                <option value="" selected disabled>--Select Brunch--</option>
                <option>Request Invoice for order</option>
                <option>Request order status</option>
                <option>Haven't received cashback yet</option>
                <option>Other</option>
              </select>
            </div>
          </div>
          <div class="col-md-3">
            <div class="form-group">
              <label for="form_lastname">Place Of Posting *</label>
              <input id="form_lastname" type="text" name="surname" class="form-control"
                placeholder="Please enter your Password *" required="required" data-error="Password is required.">
            </div>
          </div>
        </div>
        <div class="row">
          <div class="col-md-12">
            <div class="form-group">
              <label for="form_head">Section Head</label>
              <input id="form_head" type="text" name="surname" class="form-control"
                placeholder="Please enter Section Head *" required="required" data-error="Section Head is required.">
            </div>
          </div>
        </div>
        <div class="row">
          <div class="col-md-6">
            <div class="form-group">
              <div class="check-group">
                <input type="checkbox" id="active">
                <label for="active">Is Active?</label>
              </div>
            </div>
          </div>
        </div>
        <div class="row">
          <div class="col-md-12">
            <p><strong>Edit Profile Photo and Signature Editing</strong></p>
          </div>
        </div>

        <div class="row">
          <div class="col-md-6">
            <div class="form-group">
              <div class="check-group">
                <input type="checkbox" id="photo">
                <label for="photo">Edit Photo</label>
              </div>
            </div>
          </div>
          <div class="col-md-6">
            <div class="form-group">
              <div class="check-group">
                <input type="checkbox" id="signature">
                <label for="signature">Edit Signature</label>
              </div>
            </div>
          </div>
        </div>
        <div class="col-md-12">
          <div class="btn-grp">
            <button type="button" class="done-btn ">Update</button>
            <button type="button" class="cancl-btn">Cancel</button>
          </div>
        </div>
      </div>
    </form>
  </div>
</div> -->