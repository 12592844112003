import { Component, OnInit } from '@angular/core';
import { NgxSpinnerService } from "ngx-spinner";
import { ApiService } from 'src/app/services/api.service'

@Component({
  selector: 'app-tender-archive',
  templateUrl: './tender-archive.component.html',
  styleUrls: ['./tender-archive.component.css']
})
export class TenderArchiveComponent implements OnInit {

  tenderA:any;

  constructor(private apiService: ApiService,
    private SpinnerService: NgxSpinnerService,) { }

  ngOnInit(): void {
    this.apiService.tenderArchive().subscribe((res:any )=> {
      this.SpinnerService.show();
        this.tenderA = res.data;
        this.SpinnerService.hide();
    },
    (error:any )=> {

    });
  }
  tenderArchive(tenderId:any){
    this.apiService.viewtenderAFile(tenderId).subscribe(res => {
      const fileURL = URL.createObjectURL(res);
      window.open(fileURL, '_blank');
    });

 }

}
