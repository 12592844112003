<!-- #region Main Refresh Landing-->
<header class="header-section" *ngIf="showLogin == 'login'"> 
</header>
<!-- #endregion -->
<!-- #region Main Refresh Landing-->
<header class="header-section" *ngIf="showMenu == 'noAdmin' && !(isLoggedIn$ | async)">
  <div class="top-section">
    <div class="container">
      <div class="row">
        <div class="col-sm-12 col-md-12">
          <p class="t-top"><i class="fa fa-phone" style="color:rgb(16, 4, 189)"></i> &nbsp; (03473) 210 -030 | <i
              class="fa fa-envelope" style="color:red"></i> &nbsp; ranaghatmunicipality@gmail.com |
            <i class="fab fa-whatsapp" style="color:lime"></i> &nbsp;9434382222 | &nbsp;

            <span>
              <a class="midicon" href="javascript:void(0);" onclick="changefontsize('minus');"
                title="Font Decrease">A-</a>
              <a class="midicon" href="javascript:void(0);" onclick="changefontsize('0');" title="Normal Font">A</a>
              <a class="midicon" href="javascript:void(0);" onclick="changefontsize('plus');"
                title="Font Increase">A+</a>
            </span>
          </p>
        </div>
      </div>
    </div>
  </div>
  <div class="container">
    <div class="row">
      <div class="col-lg-10 col-sm-8 col-8"> <a class="logo"><img src="./../../../assets/images/logo.png"
            alt="Logo" /></a></div>
      <div class="col-lg-2 col-sm-4 col-4 dt-none">
        <div class="tredt">
          <ul>
          </ul>
        </div>
      </div>
    </div>
  </div>

  <div class="nav-section">
    <div class="container">
      <div class="row">
        <nav id="mainNav" class="navbar mainNav navbar-expand-lg navbar-light">
          <div class="container">
            <button class="navbar-toggler order-3" type="button" data-toggle="collapse"
              data-target="#navbarSupportedContent" aria-controls="navbarSupportedContent" aria-expanded="false"
              aria-label="Toggle navigation">
              <span class="navbar-toggler-icon"></span>
            </button>

            <div class="collapse navbar-collapse order-4 order-lg-2" id="navbarSupportedContent">

              <ul class="navbar-nav ml-auto">
                <li class="home-icon">
                  <a class="nav-link active" href="javascript:void(0);" routerLink="/home"><img
                      src="./../../../assets/images/home-icon.png"> </a>
                </li>
                <li class="nav-item dropdown">
                  <a class="nav-link dropdown-toggle" href="javascript:void(0);" id="navbarDropdown2" role="button"
                    data-toggle="dropdown" aria-haspopup="true" aria-expanded="false" style="color: white;">About Us</a>
                  <div class="dropdown-menu" aria-labelledby="navbarDropdown2">
                    <a class="dropdown-item" routerLink="/speech">Chairman's Speech</a>
                    <a class="dropdown-item" routerLink="/vice-chairman">Vice-Chairman's Speech</a>
                    <a class="dropdown-item" routerLink="/town-profile">Town Profile</a>
                    <a class="dropdown-item" routerLink="/mission-vission">Mission & Vision</a>
                    <a class="dropdown-item" routerLink="/headofdept">Head-of-Dept</a>
                    <a class="dropdown-item" routerLink="/at-a-glance">At-a-glance</a>
                    <!-- <a class="dropdown-item" routerLink="/census">Census-2011</a> -->

                  </div>
                </li>
                <li class="nav-item dropdown">
                  <a class="nav-link dropdown-toggle" href="javascript:void(0);" id="navbarDropdown2" role="button"
                    data-toggle="dropdown" aria-haspopup="true" aria-expanded="false" routerLink="/otherinfo"
                    style="color: white;">Other
                    Information</a>
                  <div class="dropdown-menu" aria-labelledby="navbarDropdown2">
                    <a class="dropdown-item" routerLink="/operational-structure">Municipality Operational Structure</a>
                  </div>
                </li>
                <li class="nav-item dropdown">
                  <a class="nav-link dropdown-toggle" href="javascript:void(0);" id="navbarDropdown2" role="button"
                    data-toggle="dropdown" aria-haspopup="true" aria-expanded="false" routerLink="/wordwiseinfo"
                    style="color: white;">Wardwise Info</a>
                  <div class="dropdown-menu" aria-labelledby="navbarDropdown2">

                    <a class="dropdown-item" routerLink="/ward-councillor">Ward Councillor</a>
                    <a class="dropdown-item" routerLink="/Ward-Committee">Ward Committee</a>
                    <a class="dropdown-item" routerLink="/slum-population">Slum Population</a>
                    <a class="dropdown-item" routerLink="/self-help-grp">Self-Help-Group</a>
                    <a class="dropdown-item" routerLink="/Municipal-Basic-Info">Municipal-Basic-Info</a>
                    <a class="dropdown-item" routerLink="/ct-pt">CT-PT</a>

                  </div>
                </li>

                <li class="nav-item dropdown">
                  <a class="nav-link dropdown-toggle" href="javascript:void(0);" id="navbarDropdown2" role="button"
                    data-toggle="dropdown" aria-haspopup="true" aria-expanded="false" style="color: white;">Tender</a>
                  <div class="dropdown-menu" aria-labelledby="navbarDropdown">
                    <a class="dropdown-item" routerLink="/tender-current">Current Tender</a>
                    <a class="dropdown-item" routerLink="/tender-archive">Archive Tender</a>
                  </div>
                </li>
                <!-- <li class="nav-item">
                  <a class="nav-link" href="javascript:void(0);" routerLink="/ward-councillor"
                    style="color: white;">Ward Councillor</a>
                </li> -->
                <li class="nav-item dropdown">
                  <a class="nav-link" routerLink="/PhotoGalary" id="navbarDropdown" role="button" aria-haspopup="true"
                    aria-expanded="false" style="color: white;">Photo Gallery</a>

                </li>
                <!-- <li class="nav-item">
                  <a class="nav-link" href="javascript:void(0);" routerLink="/contacts"
                    style="color: white;">Contacts</a>
                </li> -->
                <li class="nav-item dropdown">
                  <a class="nav-link dropdown-toggle" href="javascript:void(0);" routerLink="/contacts"
                    id="navbarDropdown2" role="button" data-toggle="dropdown" aria-haspopup="true" aria-expanded="false"
                    style="color: white;">Contacts</a>
                  <div class="dropdown-menu" aria-labelledby="navbarDropdown">
                    <a class="dropdown-item" routerLink="/deptwish-contact">Departmental-wish Contacts Details</a>
                    <a class="dropdown-item" routerLink="/recruitment">Recruitment</a>

                  </div>
                </li>
                <!-- <li class="nav-item">
                  <a class="nav-link" href="javascript:void(0);" routerLink="/RTI"
                    style="color: white;">zaheer</a>
                </li> -->

                <li class="nav-item">
                  <a class="nav-link" href="javascript:void(0);" routerLink="/RTI" style="color: white;">Rti</a>
                </li>
                <li class="nav-item">
                  <a class="nav-link" href="javascript:void(0);" routerLink="/wbrtps" style="color: white;">WBRTPS</a>
                </li>
                <li class="nav-item dropdown">
                  <a class="nav-link dropdown-toggle" href="javascript:void(0);" id="navbarDropdown2" role="button"
                    data-toggle="dropdown" aria-haspopup="true" aria-expanded="false" style="color: white;">citizen
                    Service</a>
                  <div class="dropdown-menu" aria-labelledby="navbarDropdown2">
                    <a class="dropdown-item" href="https://wburbanservices.org/" target="_blank">e-Mutation</a>
                    <a class="dropdown-item" href="http://holdingtax.co.in/" target="_blank">Online Property Tax</a>
                    <a class="dropdown-item" href="https://edistrict.wb.gov.in/PACE/login.do" target="_blank">Online
                      trade License</a>
                    <a class="dropdown-item" href="../../../assets/images/LC-Certificate.pdf" target="_blank">Life
                      Certificate</a>
                    <a class="dropdown-item" href=" https://pis.ranaghatmunicipality.in/collection/login.aspx"
                      target="_blank">Solid Waste Management</a>
                    <a class="dropdown-item" href="javascript:void(0);" id="edit-popup" title="Booking"
                      data-toggle="modal" data-target="#bookingModal1">All Types Municipality Booking</a>

                    <a class="dropdown-item">Certificate Issue</a>
                    <a class="dropdown-item" routerLink="/download">Download</a>

                  </div>
                </li>
              
                <div class="modal fade bs-example-modal-new" id="bookingModal1" role="dialog"
                  aria-labelledby="myLargeModalLabel" aria-hidden="true">
                  <div class="modal-dialog modal-dialog-centered" role="document">
                    <div class="modal-content" style="width: 838px; ">
                      <div class="modal-body">

                        <button type="button" #closebutton class="close" data-dismiss="modal">&times;</button>
                        <div class="body-message">
                          <div class="head-panel">
                            <h5>Ranaghat Municipality - Online Booking</h5>
                          </div>
                          <table class="table table-striped" style="margin-top: 20px;">
                            <thead>
                              <tr>
                                <th style="width:304px; font-size:medium; font-weight:bolder; color: black;">Name</th>
                                <th style="width:304px; font-size:medium; font-weight:bolder; color: black;">URL</th>
                              </tr>
                            </thead>
                            <tbody>
                              <tr>
                                <td style=" font-size:medium; font-weight: 500;text-align:left">RANAGHAT MUNICIPALITY
                                  ADVERTISEMENT AND HOSPITAL</td>
                                <td style=" font-size:medium; font-weight: 500;text-align:left;color: blue;">
                                  <a href="https://advertise.ranaghatmunicipality.in/"
                                    target="_blank">https://advertise.ranaghatmunicipality.in/</a>
                                </td>
                              </tr>
                              <tr>
                                <td style=" font-size:medium; font-weight: 500;text-align:left">RANAGHAT MUNICIPALITY
                                  SOLID WASTE </td>
                                <td style=" font-size:medium; font-weight: 500;text-align:left;color: blue;">
                                  <a href="https://pis.ranaghatmunicipality.in/collection/login.aspx"
                                    target="_blank">https://pis.ranaghatmunicipality.in/collection/login.aspx</a>
                                </td>
                              </tr>
                              <tr>
                                <td style=" font-size:medium; font-weight: 500;text-align:left">RANAGHAT MUNICIPALITY
                                  A/C COMMUNITY HALL</td>
                                <td style=" font-size:medium; font-weight: 500;text-align:left;color: blue;">
                                  <a href="booking.ranaghatmunicipality.in"
                                    target="_blank">booking.ranaghatmunicipality.in</a>
                                </td>
                              </tr>
                              <tr>
                                <td style=" font-size:medium; font-weight: 500;text-align:left">পৌর জনসংযোগ</td>
                                <td style=" font-size:medium; font-weight: 500;text-align:left;color: blue;">
                                  <a href="https://play.google.com/store/apps/details?id=pj.info.rana"
                                    target="_blank">https://play.google.com/store/apps/details?id=pj.info.rana</a>
                                </td>
                              </tr>
                            </tbody>
                          </table>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
                <li class="nav-item dropdown">
                  <a class="nav-link dropdown-toggle" href="javascript:void(0);" id="navbarDropdown2" role="button"
                    data-toggle="dropdown" aria-haspopup="true" aria-expanded="false" style="color: white;">Accounts</a>
                  <div class="dropdown-menu" aria-labelledby="navbarDropdown2">
                    <a class="dropdown-item" routerLink="/balance-sheet">Balance Sheet</a>
                    <a class="dropdown-item" routerLink="/acc-balance">Internal Audit</a>
                    <a class="dropdown-item" routerLink="/annual-report">Annual Report</a>
                  </div>
                </li>
                <li class="nav-item">
                  <a class="nav-link" href="https://rmemployee.com/" target="_blank"  style="color: white;">Employee Login</a>
                </li>
              </ul>
            </div>
          </div>
        </nav>
      </div>
    </div>
  </div>
</header>
<!-- #endregion -->

<!-- #region Admin-->
<header class="cmn-header" *ngIf="showMenu == 'admin' && (isLoggedIn$ | async)">
  <div class="logo"> <a href="#"><img src="assets/assetsAdmin/img/logo1.png" /></a> </div>
  <div class="ryt-option">
    <ul>
      <li> <a href="javascript:void(0);" class="tgl-side-menu">
          <div class="nav-icon"> <span></span> <span></span> <span></span> </div>
        </a> </li>
    </ul>
  </div>
</header>

<menu class="wapper-top-text" *ngIf="showMenu == 'admin' && (isLoggedIn$ | async)">
  <div class="wapper-lft">
    <h4>{{pageHeader}}</h4>
  </div>
  <div class="wapper-ryt">
    <h6><span><a routerLink="/adminDashboard"><i class="fa fa-home" aria-hidden="true"></i></a> / </span> <span><a
          routerLink="/adminDashboard">Admin</a> /
      </span> <span class="active">{{pageHeader}}</span></h6>
  </div>
</menu>

<!-- <div class="left-menu" *ngIf="showMenu == 'admin' && (isLoggedIn$ | async)">
  <nav>
    <ul>

      <div *ngIf="pageHeader == 'Dashboard'">
      <li *ngFor="let item of roleMenus$ | async" class="sub-menu">
        <a href="javascript:void(0);" (click)="goto(item.menu.name)">{{item.menu.name}} <span  [ngClass]="{'right rotate': item.menus.length > 0 }"><i class="fa fa-{{item.menu.icon}}" aria-hidden="true"></i></span></a>
        <ul *ngIf="item.menus.length > 0">
          <li *ngFor="let childRoleMenu of item.menus" class="sub-menu">
            <a href="javascript:void(0);" (click)="goto(childRoleMenu.menu.name)">{{childRoleMenu.menu.name}}<span [ngClass]="{'right rotate': childRoleMenu.menus.length > 0 }"><i [ngClass]="{'fa fa-angle-right': childRoleMenu.menus.length > 0}" aria-hidden="true"></i></span> </a>
          </li>
        </ul>
      </li>
    </div>
      <li *ngFor="let item of menuList" class="sub-menu">
        <a href="javascript:void(0);" (click)="goto(item.menu.name)">{{item.menu.name}} <span
            [ngClass]="{'right rotate': item.menus.length > 0 }"><i class="fa fa-{{item.menu.icon}}"
              aria-hidden="true"></i></span></a>
        <ul *ngIf="item.menus.length > 0">
          <li *ngFor="let childRoleMenu of item.menus" class="sub-menu">
            <a href="javascript:void(0);" (click)="goto(childRoleMenu.menu.name)">{{childRoleMenu.menu.name}}<span
                [ngClass]="{'right rotate': childRoleMenu.menus.length > 0 }"><i
                  [ngClass]="{'fa fa-angle-right': childRoleMenu.menus.length > 0}" aria-hidden="true"></i></span> </a>

          </li>
        </ul>
      </li>


      <li class="sub-menu" aria-expanded="true"> <a href="javascript:void(0);"><i class="fa fa-user-o"
            aria-hidden="true"></i> My Account
          <span class="right rotate"><i class="fa fa-angle-right" aria-hidden="true"></i></span></a>
        <ul>
          <li><a href="#type">Profile (Sujoy Ranjan Dasg...)</a></li>
          <li><a href="#type" routerLink="/changepassword">Change Password</a></li>
          <li><a href="javascript:void(0);" (click)="logOut()">Logout</a></li>
        </ul>
      </li>
    </ul>
  </nav>
</div> -->



<div class="left-menu" *ngIf="showMenu == 'admin' && pageHeader == 'Dashboard'">
  <nav>
    <ul>
      <li *ngFor="let item of menuList" class="sub-menu">
        <a href="javascript:void(0);" (click)="goto(item.menu.name)">{{item.menu.name}} <span
            [ngClass]="{'right rotate': item.menus.length > 0 }"><i class="fa fa-{{item.menu.icon}}"
              aria-hidden="true"></i></span></a>
        <ul *ngIf="item.menus.length > 0">
          <li *ngFor="let childRoleMenu of item.menus" class="sub-menu">
            <a href="javascript:void(0);" (click)="goto(childRoleMenu.menu.name)">{{childRoleMenu.menu.name}}<span
                [ngClass]="{'right rotate': childRoleMenu.menus.length > 0 }"><i
                  [ngClass]="{'fa fa-angle-right': childRoleMenu.menus.length > 0}" aria-hidden="true"></i></span> </a>
          </li>
        </ul>
      </li>
      <li class="sub-menu" aria-expanded="true"> <a href="javascript:void(0);"><i class="fa fa-user-o"
            aria-hidden="true"></i> My Account
          <span class="right rotate"><i class="fa fa-angle-right" aria-hidden="true"></i></span></a>
        <ul>
          <li><a href="javascript:void(0);" (click)="goto('change-profile')">Profile ({{loginname}})</a></li>
          <li><a href="javascript:void(0);" routerLink="/changepassword">Change Password</a></li>
          <li><a href="javascript:void(0);" (click)="logOut()">Logout</a></li>
        </ul>
      </li>
    </ul>
  </nav>
</div>

<div class="left-menu" *ngIf="showMenu == 'admin' && (isLoggedIn$ | async)">
  <nav>
    <ul>
      <li *ngFor="let item of menuList" class="sub-menu">
        <a href="javascript:void(0);" (click)="goto(item.menu.name)">{{item.menu.name}} <span
            [ngClass]="{'right rotate': item.menus.length > 0 }"><i class="fa fa-{{item.menu.icon}}"
              aria-hidden="true"></i></span></a>
        <ul *ngIf="item.menus.length > 0">
          <li *ngFor="let childRoleMenu of item.menus" class="sub-menu">
            <a href="javascript:void(0);" (click)="goto(childRoleMenu.menu.name)">{{childRoleMenu.menu.name}}<span
                [ngClass]="{'right rotate': childRoleMenu.menus.length > 0 }"><i
                  [ngClass]="{'fa fa-angle-right': childRoleMenu.menus.length > 0}" aria-hidden="true"></i></span> </a>

          </li>
        </ul>
      </li>
      <li class="sub-menu" aria-expanded="true"> <a href="javascript:void(0);"><i class="fa fa-user-o"
            aria-hidden="true"></i> My Account
          <span class="right rotate"><i class="fa fa-angle-right" aria-hidden="true"></i></span></a>
        <ul>
          <li><a href="javascript:void(0);" (click)="goto('change-profile')">Profile ({{loginname}})</a></li>
          <li><a href="javascript:void(0);" routerLink="/changepassword">Change Password</a></li>
          <li><a href="javascript:void(0);" (click)="logOut()">Logout</a></li>
        </ul>
      </li>
    </ul>
  </nav>
</div>

<!-- #endregion -->