import { HttpClient, HttpResponse, HttpHeaders } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { AppSettings } from '../settings/app-settings';


@Injectable({
  providedIn: 'root'
})
export class ApiService {

  constructor(public httpClient: HttpClient) { }

  tenderArchive() {
    return this.httpClient.get(`${AppSettings.RANAGHAT_API}${AppSettings.TENDER_ARCHIVE}`);
  }

  tenderCurrent() {
    return this.httpClient.get(`${AppSettings.RANAGHAT_API}${AppSettings.TENDER_CURRENT}`);
  }
  contactUs() {
    return this.httpClient.get(`${AppSettings.RANAGHAT_API}${AppSettings.CONTACTUS}`);
  }
  aboutUs(){
    return this.httpClient.get(`${AppSettings.RANAGHAT_API}${AppSettings.ABOUTUS}`);
  }
  Vission(){
    return this.httpClient.get(`${AppSettings.RANAGHAT_API}${AppSettings.VISSION}`)
  }
  chairman(){
    return this.httpClient.get(`${AppSettings.RANAGHAT_API}${AppSettings.CHAIRMAN}`)
  }
  NoticeList(){
    return this.httpClient.get(`${AppSettings.RANAGHAT_API}${AppSettings.NOTICELIST}`)
  }
  Rti(){
    return this.httpClient.get(`${AppSettings.RANAGHAT_API}${AppSettings.RTI}`)
  }
  wardCouncilor(){
    return this.httpClient.get(`${AppSettings.RANAGHAT_API}${AppSettings.WORDCOUNCILOR}`)
  }
  // viewNoticefile(noticeId:any){
  //   return this.httpClient.get(`${AppSettings.RANAGHAT_API}${AppSettings.VIEWFILE}`)

  // }
  viewNoticeFile(noticeId: string) {
    let headers = new HttpHeaders({ "Content-Type": "application/pdf" });
    let options = { headers: headers };
    return this.httpClient.get(AppSettings.RANAGHAT_API + '/notice/view/' + noticeId, { headers: new HttpHeaders({ 'Content-Type': 'application/pdf' }), responseType: 'blob' });
  }
  viewtenderAFile(tenderId:string){
    let headers = new HttpHeaders({ "Content-Type": "application/pdf" });
    let options = { headers: headers };
    return this.httpClient.get(AppSettings.RANAGHAT_API + '/Tender/View/' + tenderId, { headers: new HttpHeaders({ 'Content-Type': 'application/pdf' }), responseType: 'blob' });
    

  }
  viewtenderCFile(tenderId:string){
    let headers = new HttpHeaders({ "Content-Type": "application/pdf" });
    let options = { headers: headers };
    return this.httpClient.get(AppSettings.RANAGHAT_API + '/Tender/View/' + tenderId, { headers: new HttpHeaders({ 'Content-Type': 'application/pdf' }), responseType: 'blob' });
  }
  
  photoGallery(){
    return this.httpClient.get(`${AppSettings.RANAGHAT_API}${AppSettings.PHOTOGALLERY}`)
  }
  enterGallery(galleryid:any){
    return this.httpClient.get(`${AppSettings.RANAGHAT_API}${AppSettings.PHOTOGALLERY}/${galleryid}`);
  }

  otherInfo(){
    return this.httpClient.get(`${AppSettings.RANAGHAT_API}${AppSettings.OTHERINFO}`) 
  }

  wardWiseinfo(){
    return this.httpClient.get(`${AppSettings.RANAGHAT_API}${AppSettings.WORDWISEINFO}`) 
  }

 ViceChairmanSpeech(){
    return this.httpClient.get(`${AppSettings.RANAGHAT_API}${AppSettings.VICEMANCHAIRMAN}`)
  }

  adminLogin(param: any) {
    //let theaders = new HttpHeaders({ "Content-Type": "application/x-www-form-urlencoded" });
    let theaders = new HttpHeaders({ "Content-Type": "application/json" });
    return this.httpClient.post(`${AppSettings.RANAGHAT_API}${AppSettings.ADMINLOGIN}`, param,{ headers: theaders});
  };

  adminMenu(roleid:any){
    return this.httpClient.get(`${AppSettings.RANAGHAT_API}${AppSettings.ADMINMENU}/${roleid}`);
  }

  adminMenuList(roleid:any){
    return this.httpClient.get(`${AppSettings.RANAGHAT_API}${AppSettings.ADMINMENU}/${roleid}`);
  }

  adminTenderAll(param: any){
    let theaders = new HttpHeaders({ "Content-Type": "application/json" });
    return this.httpClient.post(`${AppSettings.RANAGHAT_API}${AppSettings.TENDERALL}`,param);
  }
  adminWardAll(param: any){
    let theaders = new HttpHeaders({ "Content-Type": "application/json" });
    return this.httpClient.post(`${AppSettings.RANAGHAT_API}${AppSettings.WARDALL}`,param);
  }
  adminWardAdd(param: any) {
    let theaders = new HttpHeaders({ "Content-Type": "application/json" });
    return this.httpClient.post(`${AppSettings.RANAGHAT_API}${AppSettings.WARDADD}`, param);
  };
  adminWardUpdate(param: any) {
    let theaders = new HttpHeaders({ "Content-Type": "application/json" });
    return this.httpClient.post(`${AppSettings.RANAGHAT_API}${AppSettings.WARDUPDATE}`, param);
  };
  adminWardId(id:any){
    return this.httpClient.get(`${AppSettings.RANAGHAT_API}${AppSettings.ADMINWARDID}/${id}`);
  }

  adminTenderId(id:any){
    return this.httpClient.get(`${AppSettings.RANAGHAT_API}${AppSettings.TENDERID}/${id}`);
  }

  adminTenderAdd(param: any) {
    let theaders = new HttpHeaders({ "Content-Type": "application/json" });
    return this.httpClient.post(`${AppSettings.RANAGHAT_API}${AppSettings.TENDERADD}`, param);
  }; 

  adminTenderUpdate(param: any) {
    let theaders = new HttpHeaders({ "Content-Type": "application/json" });
    return this.httpClient.post(`${AppSettings.RANAGHAT_API}${AppSettings.TENDERUPDATE}`, param);
  }
  adminNoticegetall (param: any){
    let theaders = new HttpHeaders({ "Content-Type": "application/json" });
    return this.httpClient.post(`${AppSettings.RANAGHAT_API}${AppSettings.ADNOTICEGETALL}`,param);
  }
  adminNoticeAdd(param: any){
    let theaders = new HttpHeaders({ "Content-Type": "application/json" });
    return this.httpClient.post(`${AppSettings.RANAGHAT_API}${AppSettings.ADNOTICE}`, param);
  };
  adminNoticeId(id:any){
    return this.httpClient.get(`${AppSettings.RANAGHAT_API}${AppSettings.ADMINNOTICEID}/${id}`);
  }
  adminNoticeUpdate(param: any) {
    let theaders = new HttpHeaders({ "Content-Type": "application/json" });
    return this.httpClient.post(`${AppSettings.RANAGHAT_API}${AppSettings.NOTICEUPDATE}`, param);
  };
  adminNoticeDelete(param: any){
    let theaders = new HttpHeaders({ "Content-Type": "application/json" });
    return this.httpClient.post(`${AppSettings.RANAGHAT_API}${AppSettings.NOTICEDELETE}`, param);
  }

  adminEventnewsAdd(param: any) {
    let theaders = new HttpHeaders({ "Content-Type": "application/json" });
    return this.httpClient.post(`${AppSettings.RANAGHAT_API}${AppSettings.ADMINEVENTNEWSADD}`, param);
  };
  adminEventnewsUpdate(param: any) {
    let theaders = new HttpHeaders({ "Content-Type": "application/json" });
    return this.httpClient.post(`${AppSettings.RANAGHAT_API}${AppSettings.ADMINEVENTNEWSUPDATE}`, param);
  };

  adminEventnewsGetall(param: any) {
    let theaders = new HttpHeaders({ "Content-Type": "application/json" });
    return this.httpClient.post(`${AppSettings.RANAGHAT_API}${AppSettings.ADMINEVENTNEWSGETALL}`, param);
  };
  adminEventnewsId(id:any){
    return this.httpClient.get(`${AppSettings.RANAGHAT_API}${AppSettings.ADMINEVENTNEWSID}/${id}`);
  }

  adminPageGetall(param: any){
    let theaders = new HttpHeaders({ "Content-Type": "application/json" });
    return this.httpClient.post(`${AppSettings.RANAGHAT_API}${AppSettings.ADPAGEGETALL}`, param);
  }
  adminPageAdd(param: any){
    let theaders = new HttpHeaders({ "Content-Type": "application/json" });
    return this.httpClient.post(`${AppSettings.RANAGHAT_API}${AppSettings.PAGEADD}`, param);
  }
  adminPageId(id:any){
    return this.httpClient.get(`${AppSettings.RANAGHAT_API}${AppSettings.ADMINPAGEID}/${id}`);
  }
  adminPageUpdate(param: any) {
    let theaders = new HttpHeaders({ "Content-Type": "application/json" });
    return this.httpClient.post(`${AppSettings.RANAGHAT_API}${AppSettings.PAGEUPDATE}`, param);
  };
  adminPageDelete(param: any){
    let theaders = new HttpHeaders({ "Content-Type": "application/json" });
    return this.httpClient.post(`${AppSettings.RANAGHAT_API}${AppSettings.PAGEDELETE}`, param);
  }

  adminRecruitmentgetall(param: any){
    let theaders = new HttpHeaders({ "Content-Type": "application/json" });
    return this.httpClient.post(`${AppSettings.RANAGHAT_API}${AppSettings.ADRECRUITMENTGETALL}`, param);
  }
  adminRecruitmentadd(param: any){
    let theaders = new HttpHeaders({ "Content-Type": "application/json" });
    return this.httpClient.post(`${AppSettings.RANAGHAT_API}${AppSettings.RECRUITMENTADD}`, param);
  }

  adminRecruitmentId(id:any){
    return this.httpClient.get(`${AppSettings.RANAGHAT_API}${AppSettings.ADMINRECRUITMENTID}/${id}`);
  }
  adminRecruitmentUpdate(param: any){
    let theaders = new HttpHeaders({ "Content-Type": "application/json" });
    return this.httpClient.post(`${AppSettings.RANAGHAT_API}${AppSettings.RECRUITMENTUPDATE}`, param);
  }
  recruitmentGetAll(){
    return this.httpClient.get(`${AppSettings.RANAGHAT_API}${AppSettings.RECRUITMENTALL}`) 
  }

  adminGalleryGetall(param: any){
    let theaders = new HttpHeaders({ "Content-Type": "application/json" });
    return this.httpClient.post(`${AppSettings.RANAGHAT_API}${AppSettings.ADMINGALLERYGETALL}`, param);
  }
  adminGalleryId(id:any){
    return this.httpClient.get(`${AppSettings.RANAGHAT_API}${AppSettings.ADMINGALLERYID}/${id}`);
  }
  adminGalleryadd(param: any){
    let theaders = new HttpHeaders({ "Content-Type": "application/json" });
    return this.httpClient.post(`${AppSettings.RANAGHAT_API}${AppSettings.GALLERYADD}`, param);
  }
  adminGalleryUpdate(param: any){
    let theaders = new HttpHeaders({ "Content-Type": "application/json" });
    return this.httpClient.post(`${AppSettings.RANAGHAT_API}${AppSettings.GALLERYUPDATE}`, param);
  }
  adminGalleryDelete(Id: any){
    let theaders = new HttpHeaders({ "Content-Type": "application/json" });
    return this.httpClient.delete(`${AppSettings.RANAGHAT_API}${AppSettings.GALLERYDELETE}/${Id}`);
  }

  adminRemoveGallery(Id: any){
    let theaders = new HttpHeaders({ "Content-Type": "application/json" });
    return this.httpClient.delete(`${AppSettings.RANAGHAT_API}${AppSettings.GALLERYREMOVE}/${Id}`);
  }

  viewRecruitmentFile(recruitmentId:string){
    let headers = new HttpHeaders({ "Content-Type": "application/pdf" });
    let options = { headers: headers };
    return this.httpClient.get(AppSettings.RANAGHAT_API + '/recruitment/view/' + recruitmentId, { headers: new HttpHeaders({ 'Content-Type': 'application/pdf' }), responseType: 'blob' });
  }

  adminDownloadGetAll(param: any) {
    let theaders = new HttpHeaders({ "Content-Type": "application/json" });
    return this.httpClient.post(`${AppSettings.RANAGHAT_API}${AppSettings.DOWNLOADALL}`, param);
  };
  
  adminDownloadadd(param: any){
    let theaders = new HttpHeaders({ "Content-Type": "application/json" });
    return this.httpClient.post(`${AppSettings.RANAGHAT_API}${AppSettings.DOWNLOADADD}`, param);
  }
  adminDownloadUpdate(param: any){
    let theaders = new HttpHeaders({ "Content-Type": "application/json" });
    return this.httpClient.post(`${AppSettings.RANAGHAT_API}${AppSettings.DOWNLOADUPDATE}`, param);
  }
  adminDownloadId(id:any){
    return this.httpClient.get(`${AppSettings.RANAGHAT_API}${AppSettings.ADMINDOWNLOADID}/${id}`);
  }
  adminDownloadDelete(param: any){
    let theaders = new HttpHeaders({ "Content-Type": "application/json" });
    return this.httpClient.post(`${AppSettings.RANAGHAT_API}${AppSettings.DOWNLOADDELETE}`, param);
  }

  viewDownloadFile(downloadId:string){
    let headers = new HttpHeaders({ "Content-Type": "application/pdf" });
    let options = { headers: headers };
    return this.httpClient.get(AppSettings.RANAGHAT_API + '/admindownload/view/' + downloadId, { headers: new HttpHeaders({ 'Content-Type': 'application/pdf' }), responseType: 'blob' });
  }

  changePassword(param: any){
    let theaders = new HttpHeaders({ "Content-Type": "application/json" });
    return this.httpClient.post(`${AppSettings.RANAGHAT_API}${AppSettings.CNGPASSWORD}`, param);
  }
  getAllWard(){
    return this.httpClient.get(`${AppSettings.RANAGHAT_API}${AppSettings.GETALLWARD}`) 
  }
  getWardInfoById(id:any){
    return this.httpClient.get(`${AppSettings.RANAGHAT_API}${AppSettings.GETWARDINFOBYID}/${id}`) 
  }
  cityProfile(){
    return this.httpClient.get(`${AppSettings.RANAGHAT_API}${AppSettings.CITYPROFILE}`);
  }
  wbrps(){
    return this.httpClient.get(`${AppSettings.RANAGHAT_API}${AppSettings.WBRPS}`);
  }
  municipalityOrg(){
    return this.httpClient.get(`${AppSettings.RANAGHAT_API}${AppSettings.MUNICIPALITYORG}`);
  }
  wardDetails(){
    return this.httpClient.get(`${AppSettings.RANAGHAT_API}${AppSettings.WARDDETAILS}`)
  }
  wardwiseSchoolDetails(){
    return this.httpClient.get(`${AppSettings.RANAGHAT_API}${AppSettings.WARDWISESCHOOLDETAILS}`) 
  }
  slumPopulationData(){
    return this.httpClient.get(`${AppSettings.RANAGHAT_API}${AppSettings.SLUMPOPULATION}`) 
  }
  DownloadGetAll(){
    return this.httpClient.get(`${AppSettings.RANAGHAT_API}${AppSettings.DOWNLOADFILEALL}`) 
  }
  viewDownload(downloadId:string){
    let headers = new HttpHeaders({ "Content-Type": "application/pdf" });
    let options = { headers: headers };
    return this.httpClient.get(AppSettings.RANAGHAT_API + '/download/viewfile/' + downloadId, { headers: new HttpHeaders({ 'Content-Type': 'application/pdf' }), responseType: 'blob' });
  }
  adminInternalAuditAll(param: any){
    let theaders = new HttpHeaders({ "Content-Type": "application/json" });
    return this.httpClient.post(`${AppSettings.RANAGHAT_API}${AppSettings.INTERNALAUDITALL}`,param);
  }
  adminInternalAuditId(id:any){
    return this.httpClient.get(`${AppSettings.RANAGHAT_API}${AppSettings.INTERNALAUDITID}/${id}`);
  }
  adminInternalAuditAdd(param: any){
    let theaders = new HttpHeaders({ "Content-Type": "application/json" });
    return this.httpClient.post(`${AppSettings.RANAGHAT_API}${AppSettings.INTERNALAUDITADD}`, param);
  }
  adminInternalAuditUpdate(param: any){
    let theaders = new HttpHeaders({ "Content-Type": "application/json" });
    return this.httpClient.post(`${AppSettings.RANAGHAT_API}${AppSettings.INTERNALAUDITUPDATE}`, param);
  }
  InternalAuditFileGet(){
    return this.httpClient.get(`${AppSettings.RANAGHAT_API}${AppSettings.INTERNALAUDITFILE}`) 
  }
  viewInternalAudit(internalauditId:string){
    let headers = new HttpHeaders({ "Content-Type": "application/pdf" });
    let options = { headers: headers };
    return this.httpClient.get(AppSettings.RANAGHAT_API + '/admininternalaudit/viewfile/' + internalauditId, { headers: new HttpHeaders({ 'Content-Type': 'application/pdf' }), responseType: 'blob' });
  }
  adminProfile(param: any) {
    //let theaders = new HttpHeaders({ "Content-Type": "application/x-www-form-urlencoded" });
    let theaders = new HttpHeaders({ "Content-Type": "application/json" });
    return this.httpClient.post(`${AppSettings.RANAGHAT_API}${AppSettings.ADMINPROFILE}`, param,{ headers: theaders});
  };
  adminProfileadd(param: any){
    let theaders = new HttpHeaders({ "Content-Type": "application/json" });
    return this.httpClient.post(`${AppSettings.RANAGHAT_API}${AppSettings.ADMINPROFILEADD}`, param);
  }
  adminProfileId(id:any){
    return this.httpClient.get(`${AppSettings.RANAGHAT_API}${AppSettings.ADMINPROFILEID}/${id}`);
  }
  adminProfileUpdate(param: any){
    let theaders = new HttpHeaders({ "Content-Type": "application/json" });
    return this.httpClient.post(`${AppSettings.RANAGHAT_API}${AppSettings.ADMINPROFILEUPDATE}`, param);
  }
}
