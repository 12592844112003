import { Component, OnInit } from '@angular/core';
import { NgxSpinnerService } from "ngx-spinner";
import { ApiService } from 'src/app/services/api.service';

@Component({
  selector: 'app-municipality-at-a-glance',
  templateUrl: './municipality-at-a-glance.component.html',
  styleUrls: ['./municipality-at-a-glance.component.css']
})
export class MunicipalityAtAGlanceComponent implements OnInit {
  cityProfiledata:any;
 
  constructor(private apiService: ApiService,
    private SpinnerService: NgxSpinnerService,) { }

  ngOnInit(): void {
//     this.apiService.cityProfile().subscribe((res:any )=> {
//       this.SpinnerService.show();
//     this.cityProfiledata =res.data;
//     this.SpinnerService.hide();
  
    
//  },
//  (error:any )=> {
 
//  });
  }

}
