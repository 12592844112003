import { Component, OnInit } from '@angular/core';
import { Router,ActivatedRoute } from '@angular/router';
import { ApiService } from 'src/app/services/api.service';
import { NgxSpinnerService } from "ngx-spinner";
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { ToastrService } from 'ngx-toastr';
import { formatDate } from '@angular/common'
import { DatePipe, Location } from '@angular/common';
import { PagerService } from '../../services/pager.service';

@Component({
  selector: 'app-change-profile',
  templateUrl: './change-profile.component.html',
  styleUrls: ['./change-profile.component.css']
})
export class ChangeProfileComponent implements OnInit {

  adminProfileForm: any;
  profileList:any;
  preview: boolean = false;
  msgShow: any;
  displayStyleAdd = "none";
  displayStyleEdit = "none";
  displayStylePlus = "none";
  userid: any;
  
  currentPage: number = 1;
  pageSize: number = 10;
  totalCount: number = 0;
  pager: any = {};
  pagedItems: any = [];
  pageNo: number = 1;
  searchItem: string = '';

  id:any;
  invalid:boolean=false;

  constructor(private apiService:ApiService,
    private router:Router,
    private formBuilder: FormBuilder,
    private SpinnerService: NgxSpinnerService,
    private toastrService: ToastrService,
    private datePipe: DatePipe,
    private pagerService: PagerService) 
    { 
      this.initForm();
    }

  ngOnInit(): void {
    this.GetProfile(this.currentPage);
  }

  searchTable(event: any) {
    this.searchItem = event.target.value;
    this.GetProfile(this.currentPage);
  }
  initForm() {
    this.adminProfileForm = this.formBuilder.group({
      firstname: ['', Validators.required],
      lastname: ['', Validators.required],
      loginname: ['', Validators.required],
      password: ['', Validators.required],
      issystemadmin: ['']
    });
  }

  get f1() { return this.adminProfileForm.controls; }

  GetProfile(currentPage: number){
    let param = {
      // "filter": this.searchItem,
      // "order": "subject",
      // "limit": 10,
      // "page": this.pageNo

      "filter": this.searchItem,
      "order": "firstName",
      "limit": this.pageSize,
      "page": currentPage
    }
    //this.SpinnerService.show(); 
    this.apiService.adminProfile(param).subscribe((res:any )=> {
      if (res.result == "Success") {
        this.profileList = res.data.adminusers;
        console.log('profile :', this.profileList)
        //this.SpinnerService.hide(); 
        this.totalCount = res.data.count;
        console.log('totalcount', this.totalCount)
        this.setPage(currentPage);
        
      }
      else{
        this.totalCount = res.data.count;
        console.log('totalcount', this.totalCount)
        this.setPage(currentPage);

      }
   },
   (error:any )=> {
   });
  }

  setPage(page: number) {
    this.pager = this.pagerService.getPager(this.totalCount, page, this.pageSize);
    this.pagedItems = this.totalCount > 0 ? this.profileList.slice(this.pager.startIndex, this.pager.endIndex + 1) : [];
  }

  Submit() {
    if (this.adminProfileForm.invalid) {
      this.invalid=true;
      return;
    }
  else{
    let profileFD = new FormData();
    this.userid = localStorage.getItem('userid');    
    profileFD.append("firstname", this.f1.firstname.value)
    profileFD.append("lastname", this.f1.lastname.value)
    profileFD.append("loginname", this.f1.loginname.value)
    profileFD.append("password", this.f1.password.value)
    profileFD.append("issystemadmin", this.f1.issystemadmin.value)
    profileFD.append("createdBy", this.userid)
    this.apiService.adminProfileadd(profileFD).subscribe((res: any) => {
      if (res.result == "Success") {
        this.toastrService.success('Profile added successfully');
        window.location.reload();
      }
    },
      (error: any) => {
        this.toastrService.error('Profile not added successfully');
      });
    }
    this.displayStyleAdd = "none";
  }
  openPopupEdit(id:any) {
    this.id = id;
    this.apiService.adminProfileId(id).subscribe((res:any )=> {
      if(res.result == "Success"){
        console.log('data :', res.data)
        // if(res.data.fileName){
        //   this.preview = true;
        // }
        this.f1.firstname.setValue(res.data.firstName);
        this.f1.lastname.setValue(res.data.lastName);
        this.f1.loginname.setValue(res.data.loginName);
        this.f1.password.setValue(res.data.password);
        //this.f1.publishingdate.setValue(this.datePipe.transform(new Date(res.data.publishingDate), 'yyyy-MM-dd'))        
        this.f1.issystemadmin.setValue(res.data.isSystemAdmin);
      }
   },
   (error:any )=> {
    
   });
   this.displayStyleEdit = "block";
 
  }

  Update(){
    let profileFD = new FormData();    
    profileFD.append("firstname", this.f1.firstname.value)
    profileFD.append("lastname", this.f1.lastname.value)
    profileFD.append("loginname", this.f1.loginname.value)
    profileFD.append("password", this.f1.password.value)
    profileFD.append("issystemadmin", this.f1.issystemadmin.value)
    profileFD.append("id",this.id)
  
    this.apiService. adminProfileUpdate(profileFD).subscribe((res: any) => {
      if (res.result == "Success") {
        this.toastrService.success('Admin Profile updated successfully');
        window.location.reload();
      }
    },
      (error: any) => {
        this.toastrService.error("Admin Profile not updated successfully");
      });
  }

  closePopupAdd() {
    this.displayStyleAdd = "none";
  }
  openPopupAdd() {
    this.displayStyleAdd = "block";
    this.f1.firstname.setValue(null);
    this.f1.lastname.setValue(null);
    this.f1.loginname.setValue(null);
    this.f1.password.setValue(null);
    this.f1.ispublished.setValue(null);
  }
  closePopupEdit() {
    this.displayStyleEdit = "none";
  }

}
