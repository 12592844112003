import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { ApiService } from 'src/app/services/api.service';
import { AuthService } from './../../services/auth.service';


@Component({
  selector: 'app-admin-dashboard',
  templateUrl: './admin-dashboard.component.html',
  styleUrls: ['./admin-dashboard.component.css']
})
export class AdminDashboardComponent implements OnInit {

  adminRoleId: any;
  adminMenu: any;
  home: any;
  masters: any;
  pages: any;
  menuList: any;
  roleid: any;
  newmenuList: any=[];

  constructor(private authService: AuthService,
    private router: Router,
    private apiService: ApiService) {
    
    this.adminMenu = sessionStorage.getItem('showMenu');
    this.roleid = sessionStorage.getItem('RoleID');    
  }

  ngOnInit(): void {
    
  }
  
}
