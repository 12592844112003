import { Component, ElementRef, OnInit, ViewChild } from '@angular/core';
import { NgxSpinnerService } from "ngx-spinner";
import { ApiService } from 'src/app/services/api.service';
import { Router,ActivatedRoute } from '@angular/router';
import { DomSanitizer, SafeResourceUrl, SafeUrl} from '@angular/platform-browser';

@Component({
  selector: 'app-photo-galary',
  templateUrl: './photo-galary.component.html',
  styleUrls: ['./photo-galary.component.css']
})
export class PhotoGalaryComponent implements OnInit {

  gallery:any;
  imagePath:any;
//@ViewChild('itemPreview') itemPreview!: ElementRef;
  insideGallery:any;

  constructor(private apiService: ApiService,
    private SpinnerService: NgxSpinnerService,
    private router: Router,private route:ActivatedRoute) { }

  ngOnInit(): void {
    this.apiService.photoGallery().subscribe((res:any )=> {
      this.SpinnerService.show();
      this.gallery = res.data;
      this.SpinnerService.hide();
   },
   (error:any )=> {
    this.SpinnerService.hide();
   });
  }


  enterGallery(galleryid:any){
    this.router.navigate(['galleryInside',{galleryid:galleryid}])
  }
}
