import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-outbreak-of-covid',
  templateUrl: './outbreak-of-covid.component.html',
  styleUrls: ['./outbreak-of-covid.component.css']
})
export class OutbreakOfCovidComponent implements OnInit {

  constructor() { }

  ngOnInit(): void {
  }

}
