<app-header></app-header>
<div class="inner-background-two">
  <img src="assets/images/inner_banner.jpg">
  <div class="latest-overlay-two">
    <div class="container">
      <h2>Wardwise Information</h2>
    </div>
  </div>
</div>
<section class="inner-post">
  <div class="container" *ngIf="singleInfoDiv" (click)="hideShow()">
    <div class="row" style="padding: 10px;">
      <div class="col-md-12">
        <div class="btn-grp" style="margin: 32px -47px 31px -20px;">
          <button *ngFor="let item of wardWiseinfo" type="button" class="done-btn" style="margin: 10px 20px 10px 10px;
          background-color: #227ba7;
          border: none;
          width: 110px;
          color: white;" (click)="getwardInfoById(item.id)" title="Click to View">Ward-{{item.wardNo}}</button>
        </div>
      </div>
    </div>
    <!-- ward-1 Model -->
        <div class="table-responsive table-striped table-bordered table-sm" [hidden]="!detailShow" style="padding: 5px;">
          <h4 style="background-color: #d5effc; padding: 15px;" class="card-title">Ward-{{wardNo}} Information</h4>
          <div class="card mb-4" style="max-width: 1000px; border: none; padding: 5px;">
            <div class="row">              
              <div class="col-md-3">
                <!-- <img
                  src="../../../assets/images/{{councillorImage}}"
                  alt="Image of ward person"
                  class="img-fluid rounded-start ward-img"
                /> -->
                <img src="data:image/jpg;base64,{{bytedataimageforcouncillor}}"  class="img-fluid rounded-start ward-img">
                
              </div>
              <div class="col-md-5" >
                <div class="card-body" style="margin: 0px; padding: 5px;">
                  <!-- <h4 class="card-title">Ward-1 Information</h4> -->
                  <p class="card-text">
                    Ward Councellor Name : <span><b>{{wardCouncillorName}}</b></span>
                  </p>
                  <p class="card-text">
                    Contact Number : <span><b>{{councillorMobileNo}}</b></span>
                  </p>
                  <p class="card-text">
                    Email ID : <span><b>{{emailId}}</b></span>
                  </p>
                  <p class="card-text">
                    Ward Official Address : 
                    <span><b>{{wardAddress}}</b></span>
                  </p>
                  <!-- <p class="card-text">
                    <small class="text-muted">Last updated 3 mins ago</small>
                  </p> -->
                </div>
              </div>
              <div class="col-md-4">
                <!-- <img src="../../../assets/images/{{wardMap}}" width="390" height="390"/> -->
                <img src="data:image/jpg;base64,{{bytedataimageforwardmap}}" width="390" height="390"/>
              </div>
            </div>
          </div> 
          <br>         
          <b>Ward Area with landmarks :</b><br>
          <table class="table" border="1">
            <thead>
              <tr>
                <th scope="col">Ward No.</th>
                <th scope="col">Area</th>
                <th scope="col">Land use (residential, commercial, mixed, institutional etc.)</th>
                <th scope="col">Number of house-holds (as per 2011)</th>
                <th scope="col">Number BPL house-holds (as per SECC)</th>
                <th scope="col">Total Population 2011</th>
                <th scope="col">Total Male</th>
                <th scope="col">Total Female</th>                
              </tr>
            </thead>
            <tbody>
              <tr>
                <td>{{wardNo}}</td>
                <td>{{wardArea}}</td>
                <td>{{landUse}}</td>
                <td>{{houseHoldNo}}</td>
                <td>{{bplhouseHoldNo}}</td>
                <td>{{totalPopulation}}</td>
                <td>{{totalMale}}</td>
                <td>{{totalFemale}}</td>
              </tr>              
            </tbody>
          </table>
          <br>
          <br>
          <b><u>Total Length of Roads :  </u>{{wardWiseData.totalLengthRoad}}</b><br>
          <span [innerHtml]="wardWiseData.roadContent"></span>
          <br>
          <b>Ward wise Population Data :</b><br>
          <table class="table" border="1">
            <thead>
              <tr>
                <th scope="row">Population</th>
                <th scope="col">1981</th>
                <th scope="col">1991</th>
                <th scope="col">2001</th>
                <th scope="col">2011</th>
                <th scope="col">2022 (Projected)</th>
                <th scope="col">Floating Population (current estimated)</th>
              </tr>
            </thead>
            <tbody>
              <tr>
                <td></td>
                <td>{{population1981}}</td>
                <td>{{population1991}}</td>
                <td>{{population2001}}</td>
                <td>{{population2011}}</td>
                <td>{{population2022}}</td>
                <td>{{floatingPopulation}}</td>
              </tr>              
            </tbody>            
          </table>
          <br>      
      <b>Number of Schools : </b><span><b>{{noSchool}}</b></span>
      <br>
      <!-- <b>Number of Colleges : </b><span><b>{{noCollege}}</b></span>
      <br>     -->
      <br>      
      <b>Ward Wise School Information :</b><br>
      <table class="table" border="1">
        <thead>
          <tr>
            <th scope="col">WARD NO</th>
            <th scope="col">SCHOOL STATUS</th>
            <th scope="col">SCHOOL NAME</th>
            <th scope="col">DISE CODE</th>
            <th scope="col">ENROLLMENT</th>
          </tr>
        </thead>
        <tbody>
          <tr *ngFor="let item of wardSchoolInfoResponces">
            <td>{{wardNo}}</td>
            <td>{{item.schoolStatus}}</td>
            <td >{{item.schoolName}}</td>
            <td>{{item.discCode}}</td>
            <td>{{item.enrollment}}</td>
          </tr>
        </tbody>            
      </table>
      
     
    </div>
    
    <!--End ward-1 Model -->

  
<!--End ward-20 Model -->
<br>
<br>
  <div >
    <img src="../../../assets/images/Municipal Ward Map_page-0001.jpg" width="1000" height="900">
    <!-- <img src="data:image/jpg;base64,{{bytedataimageforwardmap}}" width="1000" height="900"> -->
  </div>

  </div>
  <div class="row" style="margin: 10px -126px 45px 96px;" >

    <div class="col-lg-9 col-sm-12 col-12" >
      <div *ngIf="wardHouseholdInfo">
        <span [innerHtml]='wardWiseinformation.mainContent'></span>
        <br>
      <br>
      <table cellspacing="0" border="1">
        <tbody>
          <tr>
            <td style="min-width:50px;text-align: center;font-weight: 600;" colspan="9">Status of Road &amp; Drain under Ranaghat Municipality as on 02.01.2023</td>
           
          </tr>
          <tr>
            <td style="min-width:50px;font-weight: 600;" rowspan="2">Ward No</td>
            <td style="min-width:50px;font-weight: 600;" colspan="2">Surface Road</td>
            <td style="min-width:50px;font-weight: 600;" rowspan="2">Unsurface Road</td>
            <td style="min-width:50px;font-weight: 600;" rowspan="2">Total Road Length</td>
            <td style="min-width:50px;font-weight: 600;" colspan="2">Pucca Drain</td>
            <td style="min-width:50px;font-weight: 600;" rowspan="2">Kutcha Drain</td>
            <td style="min-width:50px;font-weight: 600;" rowspan="2">Total Drain Length</td>
          </tr>
          <tr>
            
            <td style="min-width:50px;font-weight: 600;">B.T.Road</td>
            <td style="min-width:50px;font-weight: 600;">C.C.Road</td>
            <td style="min-width:50px;font-weight: 600;">Covered</td>
            <td style="min-width:50px;font-weight: 600;">Uncovered</td>
           
          </tr>
          <tr>
            <td style="min-width:50px">1</td>
            <td style="min-width:50px">2.15</td>
            <td style="min-width:50px">3.40</td>
            <td style="min-width:50px">0.60</td>
            <td style="min-width:50px">6.15</td>
            <td style="min-width:50px">0.30</td>
            <td style="min-width:50px">4.70</td>
            <td style="min-width:50px">0.30</td>
            <td style="min-width:50px">5.30</td>
          </tr>
          <tr>
            <td style="min-width:50px">2</td>
            <td style="min-width:50px">1.20</td>
            <td style="min-width:50px">2.10</td>
            <td style="min-width:50px"></td>
            <td style="min-width:50px">3.30</td>
            <td style="min-width:50px"></td>
            <td style="min-width:50px">2.90</td>
            <td style="min-width:50px"></td>
            <td style="min-width:50px">2.90</td>
          </tr>
          <tr>
            <td style="min-width:50px">3</td>
            <td style="min-width:50px">0.70</td>
            <td style="min-width:50px">3.20</td>
            <td style="min-width:50px"></td>
            <td style="min-width:50px">3.90</td>
            <td style="min-width:50px"></td>
            <td style="min-width:50px">3.30</td>
            <td style="min-width:50px"></td>
            <td style="min-width:50px">3.30</td>
          </tr>
          <tr>
            <td style="min-width:50px">4</td>
            <td style="min-width:50px">2.50</td>
            <td style="min-width:50px">3.10</td>
            <td style="min-width:50px"></td>
            <td style="min-width:50px">5.60</td>
            <td style="min-width:50px">0.50</td>
            <td style="min-width:50px">4.40</td>
            <td style="min-width:50px"></td>
            <td style="min-width:50px">4.90</td>
          </tr>
          <tr>
            <td style="min-width:50px">5</td>
            <td style="min-width:50px">2.00</td>
            <td style="min-width:50px">2.60</td>
            <td style="min-width:50px">0.70</td>
            <td style="min-width:50px">5.10</td>
            <td style="min-width:50px"></td>
            <td style="min-width:50px">4.20</td>
            <td style="min-width:50px">0.40</td>
            <td style="min-width:50px">4.60</td>
          </tr>
          <tr>
            <td style="min-width:50px">6</td>
            <td style="min-width:50px">1.00</td>
            <td style="min-width:50px">2.50</td>
            <td style="min-width:50px"></td>
            <td style="min-width:50px">3.50</td>
            <td style="min-width:50px"></td>
            <td style="min-width:50px">3.40</td>
            <td style="min-width:50px"></td>
            <td style="min-width:50px">3.40</td>
          </tr>
          <tr>
            <td style="min-width:50px">7</td>
            <td style="min-width:50px">0.90</td>
            <td style="min-width:50px">2.50</td>
            <td style="min-width:50px"></td>
            <td style="min-width:50px">3.40</td>
            <td style="min-width:50px"></td>
            <td style="min-width:50px">3.00</td>
            <td style="min-width:50px"></td>
            <td style="min-width:50px">3.00</td>
          </tr>
          <tr>
            <td style="min-width:50px">8</td>
            <td style="min-width:50px">1.10</td>
            <td style="min-width:50px">3.80</td>
            <td style="min-width:50px"></td>
            <td style="min-width:50px">4.90</td>
            <td style="min-width:50px"></td>
            <td style="min-width:50px">4.30</td>
            <td style="min-width:50px"></td>
            <td style="min-width:50px">4.30</td>
          </tr>
          <tr>
            <td style="min-width:50px">9</td>
            <td style="min-width:50px">0.70</td>
            <td style="min-width:50px">3.00</td>
            <td style="min-width:50px"></td>
            <td style="min-width:50px">3.70</td>
            <td style="min-width:50px">0.20</td>
            <td style="min-width:50px">3.40</td>
            <td style="min-width:50px"></td>
            <td style="min-width:50px">3.60</td>
          </tr>
          <tr>
            <td style="min-width:50px">10</td>
            <td style="min-width:50px">3.30</td>
            <td style="min-width:50px">5.40</td>
            <td style="min-width:50px">1.10</td>
            <td style="min-width:50px">9.80</td>
            <td style="min-width:50px">0.60</td>
            <td style="min-width:50px">7.30</td>
            <td style="min-width:50px">0.50</td>
            <td style="min-width:50px">8.40</td>
          </tr>
          <tr>
            <td style="min-width:50px">11</td>
            <td style="min-width:50px">3.00</td>
            <td style="min-width:50px">3.20</td>
            <td style="min-width:50px">0.90</td>
            <td style="min-width:50px">7.10</td>
            <td style="min-width:50px">1.10</td>
            <td style="min-width:50px">4.40</td>
            <td style="min-width:50px">0.80</td>
            <td style="min-width:50px">6.30</td>
          </tr>
          <tr>
            <td style="min-width:50px">12</td>
            <td style="min-width:50px">4.00</td>
            <td style="min-width:50px">4.10</td>
            <td style="min-width:50px">1.40</td>
            <td style="min-width:50px">9.50</td>
            <td style="min-width:50px"></td>
            <td style="min-width:50px">8.00</td>
            <td style="min-width:50px">0.40</td>
            <td style="min-width:50px">8.40</td>
          </tr>
          <tr>
            <td style="min-width:50px">13</td>
            <td style="min-width:50px">4.80</td>
            <td style="min-width:50px">2.00</td>
            <td style="min-width:50px">1.50</td>
            <td style="min-width:50px">8.30</td>
            <td style="min-width:50px">0.40</td>
            <td style="min-width:50px">6.40</td>
            <td style="min-width:50px">0.60</td>
            <td style="min-width:50px">7.40</td>
          </tr>
          <tr>
            <td style="min-width:50px">14</td>
            <td style="min-width:50px">3.85</td>
            <td style="min-width:50px">3.40</td>
            <td style="min-width:50px">1.65</td>
            <td style="min-width:50px">8.90</td>
            <td style="min-width:50px"></td>
            <td style="min-width:50px">6.80</td>
            <td style="min-width:50px">0.80</td>
            <td style="min-width:50px">7.60</td>
          </tr>
          <tr>
            <td style="min-width:50px">15</td>
            <td style="min-width:50px">3.10</td>
            <td style="min-width:50px">2.98</td>
            <td style="min-width:50px">1.72</td>
            <td style="min-width:50px">7.80</td>
            <td style="min-width:50px">0.30</td>
            <td style="min-width:50px">6.20</td>
            <td style="min-width:50px"></td>
            <td style="min-width:50px">6.50</td>
          </tr>
          <tr>
            <td style="min-width:50px">16</td>
            <td style="min-width:50px">3.50</td>
            <td style="min-width:50px">3.40</td>
            <td style="min-width:50px">1.80</td>
            <td style="min-width:50px">8.70</td>
            <td style="min-width:50px">1.00</td>
            <td style="min-width:50px">6.30</td>
            <td style="min-width:50px">0.40</td>
            <td style="min-width:50px">7.70</td>
          </tr>
          <tr>
            <td style="min-width:50px">17</td>
            <td style="min-width:50px">2.00</td>
            <td style="min-width:50px">6.95</td>
            <td style="min-width:50px">0.75</td>
            <td style="min-width:50px">9.70</td>
            <td style="min-width:50px">0.30</td>
            <td style="min-width:50px">8.30</td>
            <td style="min-width:50px"></td>
            <td style="min-width:50px">8.60</td>
          </tr>
          <tr>
            <td style="min-width:50px">18</td>
            <td style="min-width:50px">4.55</td>
            <td style="min-width:50px">0.50</td>
            <td style="min-width:50px">1.35</td>
            <td style="min-width:50px">6.40</td>
            <td style="min-width:50px"></td>
            <td style="min-width:50px">4.90</td>
            <td style="min-width:50px">0.60</td>
            <td style="min-width:50px">5.50</td>
          </tr>
          <tr>
            <td style="min-width:50px">19</td>
            <td style="min-width:50px">2.00</td>
            <td style="min-width:50px">4.50</td>
            <td style="min-width:50px">0.80</td>
            <td style="min-width:50px">7.30</td>
            <td style="min-width:50px">3.50</td>
            <td style="min-width:50px">2.50</td>
            <td style="min-width:50px">0.50</td>
            <td style="min-width:50px">6.50</td>
          </tr>
          <tr>
            <td style="min-width:50px">20</td>
            <td style="min-width:50px">1.90</td>
            <td style="min-width:50px">2.85</td>
            <td style="min-width:50px">0.55</td>
            <td style="min-width:50px">5.30</td>
            <td style="min-width:50px">0.30</td>
            <td style="min-width:50px">4.05</td>
            <td style="min-width:50px">0.45</td>
            <td style="min-width:50px">4.80</td>
          </tr>
          <tr>
            
            <td style="min-width:50px;font-weight: 600;">Total</td>
            <td style="min-width:50px;font-weight: 600;">48.25</td>
            <td style="min-width:50px;font-weight: 600;">65.48</td>
            <td style="min-width:50px;font-weight: 600;">14.82</td>
            <td style="min-width:50px;font-weight: 600;">128.35</td>
            <td style="min-width:50px;font-weight: 600;">8.50</td>
            <td style="min-width:50px;font-weight: 600;">98.75</td>
            <td style="min-width:50px;font-weight: 600;">5.75</td>
            <td style="min-width:50px;font-weight: 600;">113.00</td>
          </tr>
        </tbody>
      </table>
      </div>
      

    </div>
 
  </div>
</section>