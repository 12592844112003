import { Component, OnInit } from '@angular/core';
import { Router, ActivatedRoute } from '@angular/router';
import { ApiService } from 'src/app/services/api.service';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { ToastrService } from 'ngx-toastr';
import { NgxSpinnerService } from "ngx-spinner";
import { DatePipe } from '@angular/common'
import { formatDate } from '@angular/common'
import { PagerService } from '../../services/pager.service';

@Component({
  selector: 'app-admin-notice',
  templateUrl: './admin-notice.component.html',
  styleUrls: ['./admin-notice.component.css'],
  providers: [DatePipe]
})
export class AdminNoticeComponent implements OnInit {
  tableData: any = [];
  Noticefile: any;
  adminNoticeForm: any;
  userid: any;
  publishingdate: any;
  closingdate: any;
  preview: boolean = false;
  displayStylePlus = "none";
  displayStyleEdit = "none";
  displayStyleAdd = "none";
  disabledNotice: boolean = false;
  updateNotice: boolean = false;
  currentPage: number = 1;
  pageSize: number = 10;
  totalCount: number = 0;
  pager: any = {};
  pagedItems: any = [];
  pageNo: number = 1;
  searchItem: string = '';
  id: any;
  invalid: boolean = false;

  constructor(private apiService: ApiService,
    private router: Router,
    private formBuilder: FormBuilder,
    private toastrService: ToastrService,
    private SpinnerService: NgxSpinnerService,
    private datePipe: DatePipe,
    private pagerService: PagerService) {
    this.initForm();
  }

  ngOnInit(): void {
    this.getallNotice(this.currentPage);
    const body = this.adminNoticeForm.value;
    body.closingdate = this.datePipe.transform(body.closingdate, 'dd-MM-yyyy');
  }

  searchTable(event: any) {
    this.searchItem = event.target.value;
    this.getallNotice(this.currentPage);
  }
  getallNotice(currentPage: number) {
    let param = {
      // "filter": this.searchItem,
      // "order": "subject",
      // "limit": 10,
      // "page": this.pageNo

      "filter": this.searchItem,
      "order": "subject",
      "limit": this.pageSize,
      "page": currentPage

    }
    //this.SpinnerService.show(); 
    this.apiService.adminNoticegetall(param).subscribe((res: any) => {
      if (res.result == "Success") {
        this.tableData = res.data.notices;
        //this.SpinnerService.hide(); 
        this.totalCount = res.data.count;
        console.log('totalcount', this.totalCount)
        this.setPage(currentPage);
      }
      else {
        this.totalCount = res.data.count;
        console.log('totalcount', this.totalCount)
        this.setPage(currentPage);
      }
    },
      (error: any) => {

      });
  }

  setPage(page: number) {
    this.pager = this.pagerService.getPager(this.totalCount, page, this.pageSize);
    this.pagedItems = this.totalCount > 0 ? this.tableData.slice(this.pager.startIndex, this.pager.endIndex + 1) : [];
  }

  openPopupPlus() {
    this.displayStylePlus = "block";
    this.f1.subject.setValue(null);
    this.f1.financialyear.setValue(null);
    this.f1.memono.setValue(null);
    this.f1.publishingdate.setValue(null);
    this.f1.type.setValue(null);
    this.f1.mappingtype.setValue(null);
    this.f1.closingdate.setValue(null);
    this.f1.ispublished.setValue(null);
  }
  closePopupPlus() {
    this.displayStylePlus = "none";
  }
  closePopupEdit() {
    this.displayStyleEdit = "none";
  }

  onSelectFile(event: any) {
    if (event.target.files.length > 0) {
      if (event.target.files[0].size > 3 * 1024 * 1024) {
        //this.toastr.errorToastr('File size must not exceed 2 MB', 'Error!');
        this.toastrService.error('File size must not exceed 1 MB', 'Error!');
        event.target.value = null;
      }
      else {
        this.Noticefile = <File>event.target.files[0];
        this.f1.Noticefile.setValue(this.Noticefile);
      }
    }
  }
  onSelectType(event: any) {
    this.f1.mappingtype.setValue(event.target.value)
  }


  get f1() { return this.adminNoticeForm.controls; }

  initForm() {
    this.adminNoticeForm = this.formBuilder.group({
      subject: ['', Validators.required],
      financialyear: ['', Validators.required],
      memono: ['', Validators.required],
      publishingdate: ['', Validators.required],
      mappingtype: [''],
      closingdate: ['', Validators.required],
      ispublished: [false],
      Noticefile: ['', Validators.required],
      type: ['']

    });
  }
  openPopupAdd() {
    this.displayStyleAdd = "block";
  }
  closePopupAdd() {
    this.displayStyleAdd = "none";
  }



  Submit() {
    if (this.adminNoticeForm.invalid || (this.f1.publishingdate.value > this.f1.closingdate.value)) {
      this.invalid = true;
      return;
    }
    else {
      let noticeFD = new FormData();
      this.userid = localStorage.getItem('userid');
      if (this.Noticefile) { noticeFD.append('file', this.f1.Noticefile.value) }
      noticeFD.append("subject", this.f1.subject.value)
      noticeFD.append("financialYear", this.f1.financialyear.value)
      noticeFD.append("memoNo", this.f1.memono.value)
      noticeFD.append("publishingDate", this.f1.publishingdate.value)
      noticeFD.append("closingDate", this.f1.closingdate.value)
      noticeFD.append("IsPublished", this.f1.ispublished.value)
      noticeFD.append("type", this.f1.type.value)
      noticeFD.append("mappingtype", this.f1.mappingtype.value)
      noticeFD.append("createdBy", this.userid)
      this.apiService.adminNoticeAdd(noticeFD).subscribe((res: any) => {
        if (res.result == "Success") {
          window.location.reload();
          this.toastrService.success('Notice added successfully');
        }
      },
        (error: any) => {
          this.toastrService.error('Notice not added successfully');
        });
    }

  }

  openPopupEdit(id: any) {
    this.id = id;

    this.apiService.adminNoticeId(id).subscribe((res: any) => {
      if (res.result == "Success") {
        console.log('data :', res.data)
        if (res.data.fileName) {
          this.preview = true;
        }
        this.f1.subject.setValue(res.data.subject);
        this.f1.financialyear.setValue(res.data.financialYear);
        this.f1.memono.setValue(res.data.memoNo);
        this.f1.type.setValue(res.data.type);
        this.f1.ispublished.setValue(res.data.isPublished);
        //this.f1.publishingdate.setValue(res.data.publishingDate);
        //this.f1.publishingdate.setValue(formatDate(res.data.publishingDate,'dd-MM-yyyy','en'));
        this.f1.publishingdate.setValue(this.datePipe.transform(new Date(res.data.publishingDate), 'yyyy-MM-dd'))
        this.f1.mappingtype.setValue(res.data.mappingType);
        //this.f1.closingdate.setValue(res.data.closingDate);
        this.f1.closingdate.setValue(this.datePipe.transform(new Date(res.data.closingDate), 'yyyy-MM-dd'))
      }
    },
      (error: any) => {

      });
    this.displayStyleEdit = "block";

  }
  Update() {
    let noticeFD = new FormData();
    if (this.Noticefile) { noticeFD.append('file', this.Noticefile, this.Noticefile.name); }
    noticeFD.append("subject", this.f1.subject.value)
    noticeFD.append("financialyear", this.f1.financialyear.value)
    noticeFD.append("memono", this.f1.memono.value)
    noticeFD.append("publishingdate", this.f1.publishingdate.value)
    noticeFD.append("mappingtype", this.f1.mappingtype.value)
    noticeFD.append("isPublished", this.f1.ispublished.value)
    noticeFD.append("type", this.f1.type.value)
    noticeFD.append("closingdate", this.f1.closingdate.value)
    noticeFD.append("id", this.id)

    this.apiService.adminNoticeUpdate(noticeFD).subscribe((res: any) => {
      if (res.result == "Success") {
        //this.adminRoleId = res.data.adminRoleId;
        //this.router.navigate(['adminDashboard',{adminRoleId: this.adminRoleId}])
        this.toastrService.success('Notice updated successfully');
        window.location.reload();
      }
    },
      (error: any) => {
        this.toastrService.error("notice not updated successfully");
      });
  }

  Delete(id: any, fileid: any) {
    let noticeFD = new FormData();
    noticeFD.append("id", id)
    noticeFD.append("filename", fileid)
    this.id = id;
    this.apiService.adminNoticeDelete(noticeFD).subscribe((res: any) => {
      if (res.result == "Success") {
        this.toastrService.success('Notice deleted successfully');
        window.location.reload();
      }
    },
      (error: any) => {
        this.toastrService.error("notice not deleted successfully");
      });
  }

  ShowDoc() {
    this.apiService.viewNoticeFile(this.id).subscribe((res: any) => {
      if (res) {
        var mediaType = 'application/pdf';
        var blob = new Blob([res], { type: mediaType });
        var fileURL = URL.createObjectURL(blob);
        //console.log("The Url is ", fileURL);
        window.open(fileURL);
      }
    }, errMsg => {
      console.log(errMsg);
      // this.toastr.errorToastr(errMsg, 'ERROR');
    });
  }

  SelectPublishingDate(event: any) {
    this.publishingdate = event.target.value;
    if (this.publishingdate > this.closingdate) {
      this.f1.publishingdate.value = "";
      this.publishingdate = null;
      alert("Publishing Date should be less than closing date");
    }
  }
  SelectClosingDate(event: any) {
    this.closingdate = event.target.value;
    if (this.publishingdate > this.closingdate) {
      this.f1.closingdate.value = "";
      this.closingdate = null;
      alert("Closing Date should be greater than publising date");
    }
  }

}
