import { Component, OnInit } from '@angular/core';
import { Location } from '@angular/common';
import { Router, ActivatedRoute } from '@angular/router';
import { Observable } from 'rxjs';
import { AuthService } from './services/auth.service';



@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.css']
})
export class AppComponent implements OnInit {
  title = 'ranaghat';
  url: any;
  newmenuList: any = [];
  showMenu: string = '';
  showLogin: string = '';
  isLoggedIn$: Observable<boolean> | undefined;
  roleMenus$: Observable<[]> | undefined;

  roleid: any;

  constructor(private location: Location, private authService: AuthService,private router:Router,) {

      if(this.url == '/adminLogin'){
        localStorage.setItem('loginform', 'loginform');
        console.log('formstatus :',localStorage.getItem('loginform'));   
      }
    this.roleid = localStorage.getItem('userrole');

    this.isLoggedIn$ = this.authService.isLoggedIn;

    if (this.authService.accessToken != "" && this.authService.accessToken != null && this.authService.accessToken != undefined) {
      
      this.authService.accessMenuFromStorage();
      this.isLoggedIn$ = this.authService.isLoggedIn;
      console.log('islogedin :', this.isLoggedIn$);
    }

    this.url = this.location.path();
    
    if (this.url == '/adminLogin' || this.url == '/adminDashboard' 
      || this.url == '/adminNotice' || this.url == '/adminTender' 
      || this.url == '/adminNewsevent' || this.url == '/adminPage' 
      || this.url == '/adminRecruitment' || this.url == '/adminDownload'
      || this.url == '/admingallery' || this.url == '/admin-ward'
      || this.url == '/adminInternalAudit' || this.url == '/change-profile'
      || this.url == '/changepassword') {
      //sessionStorage.setItem('showMenu', 'admin');  
      
      if (sessionStorage.getItem('showMenu') == 'admin') {
        //this.authService.loadJsFile("assets/assetsAdmin/js/main.js");
        //this.authService.loadJsFile("assets/assetsAdmin/js/jquery-3.2.1.min.js");

        this.showMenu = 'admin'

        const temp1 = document.createElement('link');
        temp1.innerHTML = '<link id="default-css1" href="assets/assetsAdmin/css/main.css" rel="stylesheet" media="all">';
        const head1 = document.head;
        while (temp1.firstChild) {
          head1.appendChild(temp1.firstChild);
        }

        const temp2 = document.createElement('link');
        temp2.innerHTML = '<link id="default-css2" href="assets/assetsAdmin/css/normalize.css" rel="stylesheet" media="all">';
        const head2 = document.head;
        while (temp2.firstChild) {
          head2.appendChild(temp2.firstChild);
        }

        const temp3 = document.createElement('link');
        temp3.innerHTML = '<link id="default-css3" href="https://fonts.googleapis.com/css2?family=Roboto:wght@300;400;500&display=swap" rel="stylesheet" media="all">';
        const head3 = document.head;
        while (temp3.firstChild) {
          head3.appendChild(temp3.firstChild);
        }

        const temp4 = document.createElement('link');
        temp3.innerHTML = '<link id="default-css4" href="https://cdnjs.cloudflare.com/ajax/libs/font-awesome/4.7.0/css/font-awesome.css" rel="stylesheet" integrity="sha512-5A8nwdMOWrSz20fDsjczgUidUBR8liPYU+WymTZP1lmY9G6Oc7HlZv156XqnsgNUzTyMefFTcsFH/tnJE/+xBg==" crossorigin="anonymous">';
        const head4 = document.head;
        while (temp4.firstChild) {
          head4.appendChild(temp4.firstChild);
        }
      }
    } else {
      // this.authService.loadJsFile("assets/js/jquery.min-1.11.2.js");
      // this.authService.loadJsFile("assets/js/bootstrap.min.js");
      // this.authService.loadJsFile("assets/js/owl.carousel.js");
      // this.authService.loadJsFile("assets/js/demo.js");

      sessionStorage.setItem('showMenu', 'noAdmin');
      sessionStorage.setItem('showLogin', 'nologin');
      this.showMenu = 'noAdmin';
      this.showLogin = 'nologin';
      if (sessionStorage.getItem('showMenu') == 'noAdmin') {

        const temp1 = document.createElement('link');
        temp1.innerHTML = '<link id="default-css1" href="assets/css/fontawesome/css/all.css" rel="stylesheet" media="all">';
        const head1 = document.head;
        while (temp1.firstChild) {
          head1.appendChild(temp1.firstChild);
        }

        const temp2 = document.createElement('link');
        temp2.innerHTML = '<link id="default-css1" href="assets/css/bootstrap.min.css" rel="stylesheet" media="all">';
        const head2 = document.head;
        while (temp2.firstChild) {
          head2.appendChild(temp2.firstChild);
        }

        const temp3 = document.createElement('link');
        temp3.innerHTML = '<link id="default-css" href="assets/css/style.css" rel="stylesheet" media="all">';
        const head3 = document.head;
        while (temp3.firstChild) {
          head3.appendChild(temp3.firstChild);
        }

        const temp4 = document.createElement('link');
        temp4.innerHTML = '<link id="default-css1" href="assets/css/responsive.css" rel="stylesheet">';
        const head4 = document.head;
        while (temp4.firstChild) {
          head4.appendChild(temp4.firstChild);
        }

        const temp5 = document.createElement('link');
        temp5.innerHTML = '<link id="default-css1" href="assets/css/owl.carousel.min.css" rel="stylesheet">';
        const head5 = document.head;
        while (temp5.firstChild) {
          head5.appendChild(temp5.firstChild);
        }

        const temp6 = document.createElement('link');
        temp6.innerHTML = '<link id="default-css1" href="https://fonts.googleapis.com/css2?family=Roboto:wght@100;300;400;500;700;900&display=swap" rel="stylesheet">';
        const head6 = document.head;
        while (temp6.firstChild) {
          head6.appendChild(temp6.firstChild);
        }
      }
    }
  }

  ngOnInit() {    
  }

}
//this.document.getElementById('theme').setAttribute('href','assets/dark.css');