<app-header></app-header>
<div class="inner-background-two">
  <!-- <img src="assets/images/inner_banner.jpg"> -->
  <div class="latest-overlay-two">
  <div class="container"><h2>Gallery Name</h2></div>
</div>
</div>
  <section class="inner-post">
    <div class="container">
      <div class="row">
        <div class="col-lg-9 col-sm-12 col-12">
  
          <section>
      
              <!-- <div class="gallery-thumbs">
                <div *ngFor="let item of insideGallery">
                    <a class="example-image-link" href="data:image/png;base64,{{item.byteData}}"
                      data-lightbox="example-set" data-title="Click the right half of the image to move forward.">
                      <img class="example-image" src="data:image/png;base64,{{item.byteData}}" alt="" /></a>
                </div>
              </div> -->
      
              <!-- <div *ngFor="let item of insideGallery">
                <img src="data:image/png;base64,{{item.byteData}}" alt="Paris" style="width:150px">
              </div> -->
          </section>
          <div class="container-fluid">
            
            <div class="row">
              <div class="col-md-3"  *ngFor="let item of insideGallery">
                <img src="data:image/png;base64,{{item.byteData}}"  style="width:150px; height: 150px;">
              </div>
            </div>
            <ng-image-slider [images]="imageObject" #navigation></ng-image-slider>
            <!-- <ngx-slick-carousel class="carousel" 
                        #slickModal="slick-carousel" 
                        [config]="slideConfig" 
                        (init)="slickInit($event)"
                        (breakpoint)="breakpoint($event)"
                        (afterChange)="afterChange($event)"
                        (beforeChange)="beforeChange($event)">
                        <div ngxSlickItem *ngFor="let slide of slides" class="slide">
                              <img src="{{ slide.img }}" alt="" width="100%">
                        </div>
            </ngx-slick-carousel> -->
            <!-- <owl-carousel-o [options]="customOptions">
   
              <ng-container *ngFor="let item of insideGallery">
                <ng-template carouselSlide id="item.id">
                  <img src="data:image/png;base64,item.byteData" >
                </ng-template>
              </ng-container>
                 
            </owl-carousel-o> -->
           
          </div>
  
        </div>
      </div>
    </div>
  </section>