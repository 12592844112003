import { Component, OnInit } from '@angular/core';
import { Router, ActivatedRoute } from '@angular/router';
import { ApiService } from 'src/app/services/api.service';
import { FormBuilder, FormGroup, Validators, ValidatorFn } from '@angular/forms';
import { ToastrService } from 'ngx-toastr';
import { NgxSpinnerService } from "ngx-spinner";
import { AuthService } from './../../services/auth.service';
import { DatePipe, Location } from '@angular/common';
import { PagerService } from '../../services/pager.service';

@Component({
  selector: 'app-admin-ward',
  templateUrl: './admin-ward.component.html',
  styleUrls: ['./admin-ward.component.css']
})
export class AdminWardComponent implements OnInit {
  adminRoleId: any;
  getallWardCouncillorList: any = [];
  getallWardList: any = [];
  fileImageCouncillor: any;
  filewardmap: any;
  schoolinfoList: any = [];
  adminWardForm: any;
  TotalPrimarySchool: number = 0;
  TotalUPrimarySchool: number = 0;
  sumTotalSchool: number = 0;
  checkAmount: number = 0;
  pschoolno: any;
  upschoolno: any;
  pschoolnamesplit: any;
  upschoolnamesplit: any;
  userid: any;
  displayStylePlus = "none";
  displayStyleEdit = "none";
  displayStyleAdd = "none";
  disabledWard: boolean = false;
  updateWard: boolean = false;
  currentPage: number = 1;
  pageSize: number = 10;
  totalCount: number = 0;
  pager: any = {};
  pagedItems: any = [];
  pageNo: number = 1;
  searchItem: string = '';
  id: any;
  dateRangeValidator: any;
  invalid: boolean = false;
  bytedataimageforcouncillor: any;
  bytedataimageforwardmap: any;

  constructor(private apiService: ApiService,
    private router: Router, private formBuilder: FormBuilder,
    private toastrService: ToastrService,
    private SpinnerService: NgxSpinnerService,
    private authService: AuthService,
    private datePipe: DatePipe,
    private pagerService: PagerService) {
    this.initForm();
    this.adminRoleId = localStorage.getItem('adminRoleId');

  }

  ngOnInit(): void {
    this.getallWardCouncillor(this.currentPage);
    //this.authService.loadJsFile("assets/assetsAdmin/js/main.js");
  }

  get f1() { return this.adminWardForm.controls; }

  initForm() {
    this.adminWardForm = this.formBuilder.group({
      wardno: ['', Validators.required],
      councillorname: ['', Validators.required],
      councillormobileno: ['', Validators.required],
      councillorwhatsappno: [''],
      councillordesignation: ['', Validators.required],
      councillorimage: [''],
      wardmapimage: [''],
      partyname: ['', Validators.required],
      councilloremail: ['', Validators.required],
      wardaddress: ['', Validators.required],
      wardarea: [''],
      landuse: [''],
      householdno: [''],
      BPLhouseholdno: [''],
      totalpopulation: [''],
      totalmale: [''],
      totalfemale: [''],
      totalroadlength: [''],
      roadcontent: [''],
      population1981: [''],
      population1991: [''],
      population2001: [''],
      population2011: [''],
      population2022: [''],
      floatingpopulation: [''],
      noofhospiatal: [''],
      noofprimaryschool: [''],
      noofuprimaryschool: [''],
      totalnoschool: [''],
      noofcollege: [''],
      schoolstatus: [''],
      descriptioncode: [''],
      enrollmentno: [''],
      primaryschoolname: [''],
      uprimaryschoolname: [''],


    });
  }

  getallWardCouncillor(currentPage: number) {
    let param = {
      // "filter": this.searchItem,
      // "order": "WardNo",
      // "limit": 10,
      // "page": this.pageNo

      "filter": this.searchItem,
      "order": "wardno",
      "limit": this.pageSize,
      "page": currentPage

    }
    //this.SpinnerService.show(); 
    this.apiService.adminWardAll(param).subscribe((res: any) => {

      if (res.result == "Success") {
        this.getallWardList = res.data.wardinfo;
        // console.log('ward-councilor - ', this.getallWardList)
        //this.SpinnerService.hide();
        this.totalCount = res.data.count;
        // console.log('totalcount', this.totalCount)
        this.setPage(currentPage);
      }
      else {
        this.totalCount = res.data.count;
        // console.log('totalcount', this.totalCount)
        this.setPage(currentPage);
      }
    },
      (error: any) => {

      });
  }

  setPage(page: number) {
    this.pager = this.pagerService.getPager(this.totalCount, page, this.pageSize);
    this.pagedItems = this.totalCount > 0 ? this.getallWardList.slice(this.pager.startIndex, this.pager.endIndex + 1) : [];
  }

  searchTable(event: any) {
    this.searchItem = event.target.value;
    this.getallWardCouncillor(this.currentPage);
  }

  onSelectCouncillorImage(event: any) {
    if (event.target.files.length > 0) {
      this.fileImageCouncillor = <File>event.target.files[0];
      this.f1.councillorimage.setValue(this.fileImageCouncillor);

      // if (event.target.files[0].size > 3*1024*1024) {      
      //   event.target.value = null;
      // }
      // else { 
      // 	this.fileImageCouncillor = <File>event.target.files[0]; 
      //   this.f1.councillorimage.setValue(this.fileImageCouncillor);
      // }
    }
  }
  onSelectWardMap(event: any) {
    if (event.target.files.length > 0) {
      this.filewardmap = <File>event.target.files[0];
      this.f1.wardmapimage.setValue(this.filewardmap);
      // if (event.target.files[0].size > 3*1024*1024) {

      //   event.target.value = null;
      // }
      // else { 
      // 	this.fileImageCouncillor = <File>event.target.files[0]; 
      //   this.f1.councillorimage.setValue(this.fileImageCouncillor);
      // }
    }
  }

  Submit() {
    if (this.adminWardForm.invalid) {
      this.invalid = true;
      return;
    }
    else {
      var prschool = "";
      var uprschool = "";
      var schoolstatus = "";
      var schoolname = "";

      let profileFD = new FormData();
      this.userid = localStorage.getItem('userid');
      if (this.fileImageCouncillor) { profileFD.append('filecouncillor', this.f1.councillorimage.value) }
      if (this.filewardmap) { profileFD.append('filewardmap', this.f1.wardmapimage.value) }
      profileFD.append("wardno", this.f1.wardno.value)
      profileFD.append("wardcouncillorname", this.f1.councillorname.value)
      profileFD.append("councillormobileno", this.f1.councillormobileno.value)
      profileFD.append("councillorwhatsappno", this.f1.councillorwhatsappno.value)
      profileFD.append("councillordesignation", this.f1.councillordesignation.value)
      profileFD.append("councillorparty", this.f1.partyname.value)
      profileFD.append("emailid", this.f1.councilloremail.value)
      profileFD.append("wardaddress", this.f1.wardaddress.value)

      profileFD.append("wardarea", this.f1.wardarea.value)
      profileFD.append("landuse", this.f1.landuse.value)
      profileFD.append("householdno", this.f1.householdno.value)
      profileFD.append("BPLhouseholdno", this.f1.BPLhouseholdno.value)
      profileFD.append("totalpopulation", this.f1.totalpopulation.value)
      profileFD.append("totalmale", this.f1.totalmale.value)
      profileFD.append("totalfemale", this.f1.totalfemale.value)
      profileFD.append("totallengthroad", this.f1.totalroadlength.value)

      profileFD.append("roadcontent", this.f1.roadcontent.value)
      profileFD.append("population1981", this.f1.population1981.value)
      profileFD.append("population1991", this.f1.population1991.value)
      profileFD.append("population2001", this.f1.population2001.value)
      profileFD.append("population2011", this.f1.population2011.value)
      profileFD.append("population2022", this.f1.population2022.value)
      profileFD.append("floatingpopulation", this.f1.floatingpopulation.value)
      profileFD.append("nohospital", this.f1.noofhospiatal.value)

      profileFD.append("primaryschool", this.f1.noofprimaryschool.value)
      profileFD.append("uprimaryschool", this.f1.noofuprimaryschool.value)
      profileFD.append("noschool", this.f1.totalnoschool.value)
      profileFD.append("nocollege", this.f1.noofcollege.value)

      schoolname = this.f1.primaryschoolname.value + "," + this.f1.uprimaryschoolname.value
      profileFD.append("schoolname", schoolname)


      this.pschoolnamesplit = this.f1.primaryschoolname.value.split(',')
      this.upschoolnamesplit = this.f1.uprimaryschoolname.value.split(',')
      for (let valitem of this.pschoolnamesplit) {
        prschool = prschool.concat("PRIMARY" + ",");
      }
      for (let valitem of this.upschoolnamesplit) {
        uprschool = uprschool.concat("UPPER PRIMARY" + ",");
      }
      schoolstatus = prschool + uprschool;


      // for(let item of this.f1.noofprimaryschool.value){
      //   prschool = prschool.concat("PRIMARY" + ",");
      // }
      // for(let item of this.f1.noofuprimaryschool.value){
      //   uprschool = uprschool.concat("UPPER PRIMARY" + ",");
      // }
      //schoolstatus = prschool + uprschool;
      schoolstatus = schoolstatus.substring(0, schoolstatus.length - 1);
      profileFD.append("schoolstatus", schoolstatus)

      profileFD.append("disccode", this.f1.descriptioncode.value)
      profileFD.append("enrollment", this.f1.enrollmentno.value)
      profileFD.append("createdBy", this.userid)

      this.apiService.adminWardAdd(profileFD).subscribe((res: any) => {
        if (res.result == "Success") {
          this.toastrService.success('Ward added successfully');
          window.location.reload();
        }
      },
        (error: any) => {
          this.toastrService.error('Ward not added successfully');
        });
    }
  }

  Update() {
    var prschool = "";
    var uprschool = "";
    var schoolstatus = "";
    var schoolname = "";
    let profileFD = new FormData();
    this.userid = localStorage.getItem('userid');
    if (this.fileImageCouncillor) { profileFD.append('filecouncillor', this.f1.councillorimage.value) }
    if (this.filewardmap) { profileFD.append('filewardmap', this.f1.wardmapimage.value) }
    profileFD.append("wardno", this.f1.wardno.value)
    profileFD.append("wardcouncillorname", this.f1.councillorname.value)
    profileFD.append("councillormobileno", this.f1.councillormobileno.value)
    profileFD.append("councillorwhatsappno", this.f1.councillorwhatsappno.value)
    profileFD.append("councillordesignation", this.f1.councillordesignation.value)
    profileFD.append("councillorparty", this.f1.partyname.value)
    profileFD.append("emailid", this.f1.councilloremail.value)
    profileFD.append("wardaddress", this.f1.wardaddress.value)

    profileFD.append("wardarea", this.f1.wardarea.value)
    profileFD.append("landuse", this.f1.landuse.value)
    profileFD.append("householdno", this.f1.householdno.value)
    profileFD.append("BPLhouseholdno", this.f1.BPLhouseholdno.value)
    profileFD.append("totalpopulation", this.f1.totalpopulation.value)
    profileFD.append("totalmale", this.f1.totalmale.value)
    profileFD.append("totalfemale", this.f1.totalfemale.value)
    profileFD.append("totallengthroad", this.f1.totalroadlength.value)

    profileFD.append("roadcontent", this.f1.roadcontent.value)
    profileFD.append("population1981", this.f1.population1981.value)
    profileFD.append("population1991", this.f1.population1991.value)
    profileFD.append("population2001", this.f1.population2001.value)
    profileFD.append("population2011", this.f1.population2011.value)
    profileFD.append("population2022", this.f1.population2022.value)
    profileFD.append("floatingpopulation", this.f1.floatingpopulation.value)
    profileFD.append("nohospital", this.f1.noofhospiatal.value)

    profileFD.append("primaryschool", this.f1.noofprimaryschool.value)
    profileFD.append("uprimaryschool", this.f1.noofuprimaryschool.value)
    profileFD.append("noschool", this.f1.totalnoschool.value)
    profileFD.append("nocollege", this.f1.noofcollege.value)
    schoolname = this.f1.primaryschoolname.value + "," + this.f1.uprimaryschoolname.value
    profileFD.append("schoolname", schoolname)
    this.pschoolnamesplit = this.f1.primaryschoolname.value.split(',')
    this.upschoolnamesplit = this.f1.uprimaryschoolname.value.split(',')
    this.pschoolno = this.f1.noofprimaryschool.value;
    this.upschoolno = this.f1.noofuprimaryschool.value;
    for (let valitem of this.pschoolnamesplit) {
      prschool = prschool.concat("PRIMARY" + ",");
    }
    for (let valitem of this.upschoolnamesplit) {
      uprschool = uprschool.concat("UPPER PRIMARY" + ",");
    }
    schoolstatus = prschool + uprschool;
    schoolstatus = schoolstatus.substring(0, schoolstatus.length - 1);
    profileFD.append("schoolstatus", schoolstatus)
    profileFD.append("disccode", this.f1.descriptioncode.value)
    profileFD.append("enrollment", this.f1.enrollmentno.value)
    profileFD.append("createdBy", this.userid)
    profileFD.append("id", this.id)

    this.apiService.adminWardUpdate(profileFD).subscribe((res: any) => {
      if (res.result == "Success") {
        this.toastrService.success('Document updated successfully');
        window.location.reload();
      }
    },
      (error: any) => {
        this.toastrService.error("Document not updated successfully");
      });
  }

  openPopupEdit(id: any) {
    this.id = id;
    var primaryschoolname = "";
    var descriptioncode = "";
    var enrollmentno = "";
    var uprimaryschoolname = "";

    this.apiService.adminWardId(id).subscribe((res: any) => {
      if (res.result == "Success") {
        console.log('edit data :', res.data)
        this.schoolinfoList = res.data.wardSchoolInfoResponces;
        console.log('school data :', this.schoolinfoList)

        for (let item of this.schoolinfoList) {
          if (item.schoolStatus == "PRIMARY") {
            primaryschoolname = primaryschoolname.concat(item.schoolName + ",");
          }
          if (item.schoolStatus == "UPPER PRIMARY") {
            uprimaryschoolname = uprimaryschoolname.concat(item.schoolName + ",");
          }
          descriptioncode = descriptioncode.concat(item.discCode + ",");
          enrollmentno = enrollmentno.concat(item.enrollment + ",");
        }
        primaryschoolname = primaryschoolname.substring(0, primaryschoolname.length - 1);
        uprimaryschoolname = uprimaryschoolname.substring(0, uprimaryschoolname.length - 1);
        descriptioncode = descriptioncode.substring(0, descriptioncode.length - 1);
        enrollmentno = enrollmentno.substring(0, enrollmentno.length - 1);
        this.f1.primaryschoolname.setValue(primaryschoolname);
        this.f1.uprimaryschoolname.setValue(uprimaryschoolname);
        this.f1.descriptioncode.setValue(descriptioncode);
        this.f1.enrollmentno.setValue(enrollmentno);

        // let viewDataObj: any;
        // this.schoolinfoList.forEach((element: any) => {
        //   viewDataObj = element;
        //   primaryschoolname = viewDataObj.schoolName + ",";
        //   this.f1.primaryschoolname.setValue(primaryschoolname);
        // });
        //console.log('viewObj :', viewDataObj)
        this.f1.wardno.setValue(res.data.wardNo);
        this.f1.councillorname.setValue(res.data.wardCouncillorName);
        this.f1.councillormobileno.setValue(res.data.councillorMobileNo);
        this.f1.councillorwhatsappno.setValue(res.data.councillorWhatsappNo);
        this.f1.councillordesignation.setValue(res.data.councillorDesignation);
        this.f1.partyname.setValue(res.data.councillorParty);
        this.f1.councilloremail.setValue(res.data.emailId);
        this.f1.wardaddress.setValue(res.data.wardAddress);

        this.f1.wardarea.setValue(res.data.wardArea);
        this.f1.landuse.setValue(res.data.landUse);
        this.f1.householdno.setValue(res.data.houseHoldNo);
        this.f1.BPLhouseholdno.setValue(res.data.bplhouseHoldNo);
        this.f1.totalpopulation.setValue(res.data.totalPopulation);
        this.f1.totalmale.setValue(res.data.totalMale);
        this.f1.totalfemale.setValue(res.data.totalFemale);
        this.f1.totalroadlength.setValue(res.data.totalLengthRoad);

        this.f1.roadcontent.setValue(res.data.roadContent);
        this.f1.population1981.setValue(res.data.population1981);
        this.f1.population1991.setValue(res.data.population1991);
        this.f1.population2001.setValue(res.data.population2001);
        this.f1.population2011.setValue(res.data.population2011);
        this.f1.population2022.setValue(res.data.population2022);
        this.f1.floatingpopulation.setValue(res.data.floatingPopulation);
        this.f1.noofhospiatal.setValue(res.data.noHospital);

        this.f1.noofprimaryschool.setValue(res.data.primarySchool);
        this.f1.noofuprimaryschool.setValue(res.data.uprimarySchool);
        this.f1.totalnoschool.setValue(res.data.noSchool);
        this.f1.noofcollege.setValue(res.data.noCollege);

        this.bytedataimageforcouncillor = res.data.byteDataforCouncillor;
        this.bytedataimageforwardmap = res.data.byteDataforWardMap;

        //this.f1.schoolname.setValue(res.data.schoolName);
        //this.f1.descriptioncode.setValue(res.data.discCode);
        //this.f1.enrollmentno.setValue(res.data.enrollment);


        //this.f1.uploaddate.setValue(this.datePipe.transform(res.data.uploadDate, 'yyyy-MM-dd', 'en'));

      }
    },
      (error: any) => {

      });
    this.displayStyleEdit = "block";

  }

  onChangeTotalPSchool(event: any) {
    this.checkAmount = event.target.value;
    this.sumTotalSchool = (this.checkAmount + this.f1.noofuprimaryschool.value);
    this.f1.totalnoschool.setValue(this.sumTotalSchool);
  }
  onChangeTotalUPSchool(event: any) {
    //this.checkAmount = event.target.value;
    this.sumTotalSchool = (event.target.value) + (this.f1.noofprimaryschool.value);
    this.f1.totalnoschool.setValue(this.sumTotalSchool);
  }
  openPopupPlus() {
    this.displayStylePlus = "block";
  }
  closePopupPlus() {
    this.displayStylePlus = "none";
  }

  closePopupEdit() {
    this.displayStyleEdit = "none";
  }

  openPopupAdd() {
    this.displayStyleAdd = "block";
  }
  closePopupAdd() {
    this.displayStyleAdd = "none";
  }

}
