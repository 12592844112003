import { Component, getModuleFactory, OnInit, RendererFactory2 } from '@angular/core';
import { Router, ActivatedRoute } from '@angular/router';
import { ApiService } from 'src/app/services/api.service'

declare var $: any;
@Component({
  selector: 'app-home',
  templateUrl: './home.component.html',
  styleUrls: ['./home.component.css']
})
export class HomeComponent implements OnInit {

  chairman: any;
  noticeList: any;
  constructor(private apiService: ApiService, private router: Router, private route: ActivatedRoute) {
    localStorage.clear();
   }

  ngOnInit(): void {
    this.Render();
    this.apiService.chairman().subscribe((res: any) => {
      this.chairman = res.data;
    },
      (error: any) => {

      });
    this.apiService.NoticeList().subscribe((res: any) => {
      this.noticeList = res.data;

    },
      (error: any) => {

      });

  }
  // goto(location: any) {
  //   this.router.navigate([location]);
  // }




 


  Render() {
    $('#winner-slide').owlCarousel({
      loop: true,
      margin: 10,
      nav: false,
      dots: false,
      autoplay: true,
      autoplayTimeout: 2000,
      responsive: {
        0: {
          items: 1
        },
        600: {
          items: 3
        },
        1000: {
          items: 5
        }
      }
    });

    $('#winner-slide1').owlCarousel({
      loop: true,
      margin: 10,
      nav: false,
      dots: false,
      autoplay: true,
      autoplayTimeout: 2000,
      responsive: {
        0: {
          items: 1
        },
        600: {
          items: 3
        },
        1000: {
          items: 5
        }
      }
    });

    (function () {

      function scroller() {

        var scroll = $('div.scroll');// Sets the div with a class of scroll as a variable

        var height = scroll.height(); // Gets the height of the scroll div

        var topAdj = -height - 30; /* '-height' turns the height                   of the UL into a negative #, 
             * '- 50' subtracts an extra 50 pixels from the height of 
             * the div so that it moves the trail of the UL higher to 
             * the top of the div before the animation                ends
             */

        scroll.animate({
          'top': [topAdj, 'linear']
        }, 8000, function () {
          scroll.css('top', 30); //resets the top position of the Ul for the next cycle
          scroller(); // Recalls the animation cycle to begin.
        });
      }

      scroller();

    })();
  }


}
