import { Component, OnInit } from '@angular/core';
import { NgxSpinnerService } from "ngx-spinner";
import { ApiService } from 'src/app/services/api.service';

@Component({
  selector: 'app-acc-balancesheet-upload',
  templateUrl: './acc-balancesheet-upload.component.html',
  styleUrls: ['./acc-balancesheet-upload.component.css']
})
export class AccBalancesheetUploadComponent implements OnInit {
  
  internalauditList: any;

  constructor(private apiService: ApiService,
    private SpinnerService: NgxSpinnerService,) { }

  ngOnInit(): void {
    this.apiService.InternalAuditFileGet().subscribe((res:any )=> {
      this.SpinnerService.show();
      this.internalauditList= res.data;
      console.log('DownloadList: ',res.data)
      this.SpinnerService.hide();
  },
  (error:any )=> {
   
  });
  }

  downloadFile(internalauditId:any){
    this.apiService.viewInternalAudit(internalauditId).subscribe(res => {
      const fileURL = URL.createObjectURL(res);
      window.open(fileURL, '_blank');
    });
}

}
