<app-header></app-header>
<div class="inner-background-two">
    <img src="assets/images/inner_banner.jpg">
    <div class="latest-overlay-two">
    <div class="container"><h2>Municipality at a Glance</h2></div>
  </div>
  </div>
  <section class="inner-post">
    <div class="container">
      <div class="row">
         <div class="col-lg-9 col-sm-12 col-12">
            <div>
                <h3 style="text-align: center; color: black;">Detail profile of Urban Local Body AT A GLANCE</h3>
             </div>
          <!-- <div style="text-align:right">
            <img src="../../../assets/images/galance1.png" width="800" height="900">
            <img src="../../../assets/images/galance2.png" width="800" height="900">
            <img src="../../../assets/images/galance3.png" width="800" height="900">
            <img src="../../../assets/images/galance4.png" width="800" height="900">
            <img src="../../../assets/images/Screenshot (20).png" width="800" height="900">
          </div> -->
          <div>
            <table border="2" cellpadding="0" cellspacing="0" id="sheet0" class="sheet0 gridlines">
              <tr >
              <td >
              <p ><span  >1</span></p></td>
              <td ><p >
              <span>Name of the District : </span></p></td>
              <td >
              <p >
              <span >Nadia</span></p></td></tr>
              <tr >
              <td >
               <p ><span >2</span></p></td>
               <td ><p >
               <span>Year of establishment : </span></p></td>
               <td ><p >
               <span >17th Sep 1864</span>
               </p></td></tr>
               <tr ><td ><p >
               <span >3</span></p></td>
               <td >
               <p ><span >Area (in sq. Km) : </span></p></td>
               <td ><p>
               <span >7.72</span></p></td></tr><tr >
               <td ><p >
               <span >4</span></p></td>
               <td >
               <p ><span >No. of wards : </span></p></td>
               <td >
               <p >
               <span >20</span></p></td></tr><tr >
               <td >
               <p >
               <span >5</span></p></td>
               <td><p >
               <span >Distance from District Headquarter : </span></p></td>
               <td ><p >
               <span >30 Km</span></p></td></tr>
               <tr><td >
               <p ><span >6</span></p></td>
               <td >
               <p><span  >Population (census 2011) : </span></p></td>
               <td >
               <p ><span >&#xa0;</span></p></td>
               </tr><tr ><td >
               <p ><span >6.1</span></p></td>
               <td >
               <p ><span >Male</span></p></td>
               <td >
               <p >
               <span >37948</span></p></td></tr><tr>
               <td >
               <p ><span>6.2</span></p></td>
               <td ><p><span >Female</span></p></td>
               <td >
               <p ><span>37417</span></p></td></tr>
               <tr >
               <td ><p ><span >6.3</span></p></td>
               <td ><p ><span >Total</span></p></td>
               <td>
               <p ><span >75365</span></p></td></tr><tr >
               <td ><p ><span >&#xa0;</span></p></td>
               <td >
               <p ><span >Projected Population (2021)</span></p></td>
               <td ><p ><span >81967</span></p></td></tr>
               <tr ><td ><p ><span >&#xa0;</span></p></td>
               <td ><p ><span >Projected Population (2031)</span></p></td>
               <td><p ><span>89973</span></p></td></tr>
               <tr >
               <td >
               <p ><span >7</span></p></td>
               <td >
               <p ><span >Density of Population (Per sq. km.) : </span></p></td>
               <td >
               <p ><span >9759.585</span></p></td></tr>
               <tr ><td ><p ><span >8</span></p></td>
               <td> <p ><span >Break up of Population (2011) : </span></p></td>
               <td ><p ><span >&#xa0;</span></p></td></tr>
               <tr ><td ><p ><span >8.1</span></p></td>
               <td ><p ><span >Scheduled Caste</span></p></td>
               <td><p ><span >9841</span></p></td></tr>
               <tr ><td ><p><span >8.2</span></p></td>
               <td ><p ><span >Scheduled Tribe</span></p></td>
               <td ><p ><span >433</span></p></td></tr>
               <tr ><td >
               <p ><span >8.3</span></p></td>
               <td >
               <p ><span >Minorities </span><span >(As per SUDA Household Survey 2006)</span></p></td>
               <td ><p ><span >1619</span></p></td></tr>
               <tr >
               <td><p ><span >9</span></p></td>
               <td ><p ><span >Date when last election held &amp; Board Come into Force</span></p></td>
               <td ><p ><span> 19/03/2022</span></p></td></tr>
               <tr >
               <td >
               <p><span >10</span></p></td>
               <td >
               <p ><span >Assessment of Property: </span>
              </p></td>
               <td >
               <p>
               <span >&#xa0;</span></p></td></tr>
               <tr >
               <td >
               <p >
               <span >10.1</span></p></td>
               <td ><p ><span >Total holdings </span></p></td>
               <td ><p >
               <span >17863</span></p></td></tr>
               <tr><td ><p >
               <span >10.2</span></p></td>
               
               <td ><p >
               <span >Total no. of holdings whose assessment has been done </span></p></td>
               <td ><p ><span >90%</span></p></td></tr>
               <tr ><td><p ><span >10.3</span></p></td>
               <td ><p ><span >No. of holdings to whom demand notice are issued </span></p></td>
               <td ><p ><span>2650</span></p></td></tr>
               <tr 
               >
               <td ><p ><span >11</span></p></td>
               <td ><p ><span >Literacy : (2011)</span></p></td>
               <td><p ><span>&#xa0;</span></p></td></tr>
               <tr ><td>
               <p
               ><span >11.1</span></p></td>
               <td ><p ><span >Male</span></p></td>
               <td ><p ><span >33573</span></p></td></tr>
               <tr >
               <td >
               <p ><span >11.2</span></p></td>
               <td ><p ><span >Female</span></p></td>
               <td>
               <p >
               <span >31982</span></p></td></tr>
               <tr ><td>
               <p ><span >11.3</span></p></td>
               <td >
               <p ><span>Total</span></p></td>
               <td >
               <p ><span>65555</span></p></td></tr>
               <tr ><td >
               <p ><span >11.4</span></p></td>
               <td ><p ><span >Percentage of Literate Population(2011)</span></p></td>
               <td ><p >
               <span>86.98</span></p></td></tr>
               <tr><td >
               <p >
               <span>12</span></p></td>
               <td>
               <p><span>Number of BPL Household (as per SUDA Survey) : </span></p></td>
               <td >
               <p ><span >6732</span></p></td></tr>
               <tr ><td>
               <p ><span>13</span></p></td>
               <td >
               <p >
               <span >Scenario of Slum : </span></p></td>
               <td >
               <p ><span >&#xa0;</span></p></td></tr><tr >
               <td><p ><span>13.1</span></p></td>
               <td >
               <p ><span >Total No. of Slum </span></p></td>
               <td >
               <p>
               <span
               >46</span></p></td></tr><tr >
               <td ><p >
               <span >13.2</span></p></td>
               <td >
               <p ><span >Total Slum Population (as per USHA survey) </span></p></td>
               <td >
               <p >
               <span >5647</span></p></td></tr>
               <tr >
               <td >
               <p >
               <span >13.3</span></p></td>
               <td ><p ><span >Percentage of Slum Population to the total population </span></p></td>
               <td ><p ><span>7.5%</span></p></td></tr>
               <tr >
               <td ><p ><span >&#xa0;</span></p></td><td >
               <p ><span >&#xa0;</span></p></td>
               <td >
               <p ><span >&#xa0;</span></p></td></tr><tr >
               <td><p >
               <span>13.4</span></p></td>
               <td >
               <p ><span >No. of Slum where Slum Infrastructure Improvement sanctioned under BSUP/ IHSDP</span>
               </p></td>
               <td ><p ><span >39</span></p></td></tr>
               <tr ><td >
              <p >
              
              <span >14</span></p>
              </td><td >
              <p ><span >Housing status for Urban Poor :( as on 01.03.2022)</span>
              </p></td>
              <td >
              <p ><span >&#xa0;</span></p>
              </td></tr><tr >
              <td >
              <p ><span >14.3</span></p></td>
              <td >
              <p ><span>No. of beneficiaries provided with Houses under “ Housing for Urban Poor “</span></p></td>
              <td>
              <p ><span >4508</span></p></td></tr>
              <tr ><td>
              <p ><span >15</span></p></td>
              <td >
              <p ><span >Road : </span></p></td>
              <td>
              <p><span >&#xa0;</span></p></td>
              </tr><tr >
              <td >
              <p><span >15.1</span></p></td>
              <td >
              <p ><span >Length of Metalled Road (in km.)</span></p></td>
              <td >
              <p ><span >46.732</span></p></td></tr>
              <tr >
              <td >
              <p ><span>15.2</span></p></td>
              <td >
              <p ><span>Length of Non-Metalled Road (in km.)</span></p></td>
              <td >
              <p ><span >9.82</span></p></td></tr>
              <tr ><td >
              <p ><span >15.3</span></p></td>
              <td>
              <p ><span>Length of other Roads (in km.) ( Cement Concrete)</span></p></td>
              <td >
              <p><span >113.27</span></p></td></tr>
              <tr ><td >
              <p ><span >15.4</span></p></td>
              <td >
              <p><span>Total length of Road (in km.)</span></p></td>
              <td >
              <p ><span>123.099</span></p></td></tr>
              <tr><td >
              <p><span >15.5</span></p></td>
              <td >
              <p ><span >Total no. of wards fully covered with Metal / Cement Concrete Road</span></p></td>
              <td >
              <p><span >20(Approx 90% cover)</span></p></td>
              </tr>
              <tr><td >
              <p ><span>16</span></p></td>
              <td >
              <p ><span >Drainage : </span></p></td>
              <td >
              <p ><span>&#xa0;</span></p></td>
              </tr>
              <tr><td >
              <p ><span >16.1</span></p></td><td >
              <p ><span>Length of Kutcha Drain (in km.) </span></p></td>
               <td>
               <p ><span >8.00</span></p></td></tr>
               <tr ><td >
               <p ><span>16.2</span></p></td>
               <td >
               <p ><span >Length of Pucca Drain (in km.)</span></p></td>
               <td>
               <p><span >92.00</span></p></td></tr>
               <tr><td>
               <p ><span >16.3</span>
               </p></td>
               <td>
               <p ><span >Length of underground / covered Drain (in km.)</span></p></td>
               <td >
               <p ><span >7.50</span></p></td></tr>
               <tr ><td >
               <p ><span >16.4</span></p></td>
               <td >
               <p><span>Total length of Drain (in km.)</span></p></td>
               <td >
               <p ><span>86.00</span></p></td></tr>
               <tr ><td><p >
               <span >16.5</span></p></td><td 
              ><p >
               <span >No.</span><span>&#xa0; </span>
               <span >of wards fully covered with Pucca Drain </span></p></td>
               <td><p ><span >9</span></p></td></tr>
               <tr><td ><p >
               <span >16.6</span></p></td>
               <td >
               <p ><span >No.</span>
               <span>&#xa0; </span>
               <span >of wards partly covered with</span>
               <span >&#xa0; </span>
               <span >Pucca Drain </span></p></td>
               <td >
               <p ><span >10</span></p></td></tr>
               <tr><td >
               <p ><span >17</span></p></td>
               <td >
               <p ><span >Water Supply : -</span></p></td>
               <td>
               <p ><span>&#xa0;</span>
               </p></td></tr><tr ><td ><p ><span >17.1</span></p></td>
               <td >
               <p><span>No. of</span><span>
               &#xa0; </span><span>Water Treatment Plant </span></p></td>
               <td >
               <p><span >2</span></p></td></tr>
               <tr ><td><p ><span >17.2</span></p></td>
               <td>
               <p ><span>No. of Deep Tube well </span></p></td>
               <td ><p>
               <span >14</span></p></td></tr><tr >
               <td >
               <p ><span >17.3</span></p></td>
               <td >
               <p ><span >No. of Hand Tube well </span></p></td>
               <td >
               <p ><span>820</span></p></td></tr>
               <tr ><td ><p ><span>17.4</span></p></td>
               <td><p ><span >No. of Street Stand post </span></p></td>
               <td >
               <p ><span>500</span></p></td></tr>
               <tr ><td ><p ><span>17.5</span></p></td>
               <td><p ><span >Length of Water pipeline (in kilometer) </span></p></td>
               <td><p ><span >134km</span></p></td></tr>
               <tr >
               <td ><p ><span >17.6</span></p></td><td ><p >
               <span >No. of Underground Reservoir </span></p></td>
               <td >
               <p ><span >2</span></p></td></tr>
               <tr >
               <td >
               <p ><span >17.7</span></p></td><td >
               <p ><span >No. of Overhead Reservoir </span></p></td>
               <td >
               <p ><span >4</span></p></td></tr>
               <tr >
               <td >
               <p ><span>17.8</span></p></td><td ><p >
               <span >No. of wards fully covered with water supply pipeline </span></p></td>
               <td >
               <p ><span >20</span></p></td></tr><tr >
               <td >
               <p ><span>17.9</span></p></td>
               <td >
               <p ><span >No. of</span>
               <span  >&#xa0; </span><span >houses connected with Water Supply Network </span></p></td>
               <td ><p ><span >11202</span></p></td></tr>
               <tr ><td >
               <p ><span >17.1</span></p></td>
               <td>
               <p ><span >Who is maintaining water supply – Municipality / PHE Dept./ KMDA / KMWSA</span></p></td>
               <td ><p>
               <span >Municipality</span></p></td></tr>
               <tr ><td ><p ><span >18</span></p></td>
               <td ><p><span >Sewerage and Sanitation : </span></p></td>
               <td ><p ><span >&#xa0;</span></p></td></tr>
               <tr >
               <td ><p ><span >18.1</span></p></td>
               <td ><p ><span >No. of sanitary latrine constructed </span></p></td>
               <td >
               <p ><span >852</span></p></td></tr>
               <tr><td >
               <p ><span>18.2</span></p></td>
               <td><p ><span >No. of family provided with Sanitary Latrine under ILCS + BSUP / IHSDP+ HUP (together)</span></p></td>
               <td>
               <p><span>852</span></p></td></tr>
               <tr >
               <td >
               <p ><span>18.3</span></p></td>
               <td >
               <p ><span >No. of Community Latrine /Public Toilet</span></p></td>
               <td >
               <p ><span >3</span></p></td></tr>
               <tr >
               <td >
               <p><span >18.4</span></p></td>
               <td >
               <p><span >Length of Sewer Line (in kilometer) </span></p></td>
               <td >
               <p >
               <span>Nil</span></p></td></tr>
               <tr >
               <td >
               <p ><span >18.5</span></p></td>
               <td ><p ><span >No. of Sewage Treatment Plant (STP) </span></p></td>
               <td ><p ><span >Nil</span></p></td></tr><tr ><td >
               <p ><span >19</span></p></td>
               <td >
               <p ><span>Solid Waste Management : </span></p></td>
               <td ><p><span >&#xa0;</span></p></td></tr><tr>
               <td ><p ><span >19.1</span></p></td>
               <td ><p ><span >No. of Dumping Ground, if any </span></p></td>
               <td ><p ><span >1</span></p></td></tr>
               <tr ><td ><p ><span >19.2</span></p></td>
               <td ><p ><span >No. of Landfill site , if any </span></p></td>
               <td ><p><span >Nil</span></p></td></tr>
               <tr >
               <td ><p><span >19.3</span></p></td>
               <td ><p ><span >No of Mechanical Sweeper, if any</span></p></td>
               <td ><p ><span>Nil</span></p></td></tr>
               <tr ><td ><p >
               <span >19.4</span></p></td>
               <td >
               <p ><span >No. of Compactors, if any</span></p></td>
               <td ><p ><span >1</span></p></td></tr>
               <tr >
               <td ><p ><span>20</span></p></td>
               <td >
               <p ><span >Street Light : </span></p></td>
               <td ><p >
               <span>&#xa0;</span></p></td></tr>
               <tr >
                <td ><p ><span>20.1</span></p></td>
               <td ><p >
               <span >No. of Light Post</span>
               <span >&#xa0; </span></p></td>
               <td>
               <p ><span >4475</span></p></td></tr>
               <tr ><td ><p >
               <span>20.2</span></p></td><td ><p >
               <span >No. of High Mast Light Post </span></p></td>
               <td ><p >
               <span >25</span></p></td></tr><tr>
               <td >
               <p ><span >20.3</span></p></td>
               <td ><p >
               <span >No. of Trident Light Post </span></p></td>
               <td >
               <p ><span >54</span></p></td></tr>
               <tr ><td ><p >
               <span >20.4</span></p></td><td ><p >
               <span >No. of other Ornamental Light Post </span></p></td>
               <td ><p >
               <span >4225</span></p></td></tr>
               <tr >
               <td >
               <p ><span >20.5</span></p></td>
               <td >
               <p ><span>No. of Wards covered with light posts</span></p></td>
               <td ><p ><span >20</span></p></td></tr><tr ><td ><p  ><span>21</span></p></td>
               <td >
               <p ><span >Health : 
               </span></p></td>
               <td><p ><span >&#xa0;</span></p></td></tr>
               <tr >
               <td ><p><span >21.1</span></p></td>
               <td ><p ><span >No. of Hospital (ULB + Govt. + Others) </span></p></td>
               <td ><p ><span >1</span></p></td></tr>
               <tr ><td ><p ><span >21.2</span></p></td>
               <td ><p ><span style="font-family:'Book Antiqua'">No. of Municipal Maternity Home </span></p></td>
               <td ><p ><span >1</span></p></td></tr>
               <tr>
               <td ><p><span >21.3</span></p></td><td ><p ><span >No. of Regional Diagnostic Centre</span></p></td>
               <td ><p ><span >1</span></p></td></tr>
               <tr >
               <td ><p ><span >21.4</span></p></td>
               <td ><p ><span >No. of Extended Specialist Out Patient Department (ESOPD) (IPP-VIII)</span></p></td>
               <td ><p ><span >2</span></p></td></tr>
               <tr><td ><p >
               <span >21.5</span></p></td>
               <td ><p 
               ><span>No. of Municipal Health Sub-Centre</span></p></td>
               <td ><p><span >6</span></p></td></tr><tr >
               <td >
               <p >
               <span >21.6</span></p></td>
               <td ><p ><span>No. of Municipal Health Administrative Unit (HAU)( IPP-VIII)</span></p></td>
               <td><p >
               <span>Nil</span></p></td></tr>
               <tr >
               <td ><p><span >21.7</span></p></td>
               <td ><p >
               <span>No. of Municipal urban public health centre </span></p></td>
               <td  ><p ><span >3</span></p></td></tr>
               <tr ><td ><p ><span >21.8</span></p></td>
               <td ><p ><span >No. of Municipal Ambulances </span></p></td>
               <td ><p ><span >2</span></p></td></tr>
               <tr><td ><p ><span >21.9</span></p></td><td  ><p ><span>No. of Hearse Car</span></p></td>
               <td ><p >
               <span>1</span></p></td></tr><tr>
               <td ><p ><span >22</span></p></td>
               <td><p><span >Education : </span></p></td>
               <td >
               <p ><span >&#xa0;</span></p></td></tr>
               <tr ><td><p ><span >22.1</span></p></td><td ><p><span >No. of Higher Secondary School (Municipal) </span></p></td>
               <td ><p ><span >Nil</span></p></td></tr>
               <tr><td ><p ><span>22.2</span></p></td>
               <td ><p ><span >No. of Higher Secondary School (others) </span></p></td>
               <td>
               <p><span >11</span></p></td></tr>
               <tr ><td><p ><span>22.3</span></p></td>
               <td><p ><span >No. of Secondary School (Municipal)</span></p></td>
               <td ><p ><span >Nil</span></p></td></tr>
               <tr >
               <td >
               <p ><span>22.4</span></p></td>
               <td ><p ><span >No. of Secondary School (others) </span></p></td>
               <td ><p ><span >3</span></p></td></tr>
               <tr ><td ><p ><span >22.5</span></p></td><td ><p ><span >No. of</span>
               <span>&#xa0; </span>
               <span >Primary School (Municipal) </span></p></td>
               <td ><p><span>Nil</span></p></td></tr>
               <tr ><td  ><p ><span>22.6</span></p></td>
               <td ><p ><span >No. of</span><span >&#xa0; </span><span >Primary School (others)</span></p></td>
               <td ><p ><span>29</span></p></td></tr>
               <tr ><td ><p ><span >22.7</span></p></td>
               <td ><p ><span >No. of </span><span >&#xa0;</span>
               <span >Madrasah</span></p></td>
               <td ><p ><span>1</span></p></td></tr>
               <tr ><td >
               <p ><span >22.8</span></p></td>
               <td ><p ><span>No. of ICDS Centre</span></p></td>
               <td ><p ><span >46</span></p></td></tr>
               <tr ><td ><p ><span >22.9</span></p></td><td>
               <p ><span >No. of Junior High School</span></p></td>
               <td ><p >
               <span >Nil</span></p></td></tr>
               <tr ><td >
               <p ><span>22.10</span></p></td>
               <td>
               <p ><span >No. of beneficiaries under SC/ST scholarship</span></p></td>
               <td >
               <p ><span>Nil</span></p></td></tr>
               <tr ><td ><p >
               <span>22.11</span></p></td>
               <td >
               <p ><span>No. of beneficiaries under Minority scholarship</span></p></td>
               <td >
               <p><span >Nil</span></p></td></tr>
               <tr >
               <td><p >
               <span >23</span></p></td>
               <td ><p >
               <span >Other Infrastructure : </span></p></td>
               <td >
               <p><span >&#xa0;</span>
               </p></td></tr><tr>
               <td >
               <p ><span >23.1</span></p></td>
               <td >
               <p ><span>
               </span><span >Bridge -(Municipal/ others)</span>
               </p></td>
               <td >
               <p ><span>2</span></p></td></tr>
               <tr ><td ><p>
               <span >23.2</span></p></td>
               <td ><p >
               <span >Flyover - (Municipal/ others)</span></p></td>
               <td ><p >
               <span >Nil</span></p></td></tr><tr >
               <td ><p >
               <span >23.3</span></p></td>
               <td ><p >
               <span>Stadium- (Municipal/ others)</span></p></td>
               <td ><p >
               <span >1</span></p></td></tr>
               <tr >
               <td ><p >
               <span >23.4</span></p></td>
               <td><p ><span >Parks- (Municipal/ others)</span></p></td>
               <td >
               <p >
               <span >11</span></p></td></tr>
               <tr ><td ><p >
               <span>23.5</span></p></td>
               <td><p >
               <span >
               <img src="images/Aspose.Words.2d0331ce-66c6-4815-995d-ecd4444955b5.002.png" width="27" height="11" alt=""  /></span>
               <span >Playground- (Municipal/ others)</span></p></td>
               <td ><p >
               <span >2</span></p></td></tr><tr >
               <td ><p >
               <span>23.6</span></p></td>
               <td >
               <p >
               <span>Auditorium/Community Hall- (Municipal/ others)</span></p></td>
               <td ><p >
               <span >3/4</span></p></td></tr><tr >
               <td ><p >
               <span >23.8</span></p></td>
               <td><p >
               <span >
               <img src="images/Aspose.Words.2d0331ce-66c6-4815-995d-ecd4444955b5.002.png" width="27" height="11" alt="" style="margin-top:1.35pt; 
               margin-left:113.2pt; -aw-left-pos:113.95pt; -aw-rel-hpos:column; -aw-rel-vpos:paragraph; -aw-top-pos:2.1pt; -aw-wrap-type:none; position:absolute" /></span>
               <span >Ward office- (Municipal/ others)</span></p></td>
               <td >
               <p ><span>Nil</span></p></td></tr>
               <tr ><td >
               <p ><span>23.9</span></p></td>
               <td >
               <p><span >ULB Market- </span></p></td>
               <td ><p >
               <span >8</span></p></td></tr>
               <tr >
               <td >
               <p ><span >23.10</span></p></td>
               <td >
               <p ><span >Burning Ghat- (Municipal/ others)</span></p></td>
               <td >
               <p ><span>1</span></p></td></tr>
               <tr ><td ><p >
               <span >23.11</span></p></td>
               <td><p >
               <span>Electric Crematorium- (Municipal/ others)</span></p></td>
               <td><p >
               <span >1</span></p></td></tr>
               <tr >
               <td >
               <p >
               <span >23.12</span></p></td>
               <td><p >
               <span >
               
              
               </span><span >Burial Ground- (Municipal/ others)</span></p></td>
               <td >
               <p >
               <span >2</span></p></td></tr>
               <tr >
               <td >
               <p >
               <span >23.13</span></p></td>
               <td >
               <p >
               <span>
               </span>
               <span >Public Library- (Municipal/ others)</span></p></td>
               <td >
               <p >
               <span >2</span></p></td></tr>
               <tr >
               <td ><p >
               <span >23.14</span></p></td>
               <td><p >
               <span >
              </span><span>Bus Terminus- (Municipal/ others)</span></p></td>
                <td ><p><span >2</span></p></td></tr><tr >
                <td ><p ><span >23.15</span></p></td>
                <td  ><p ><span >
                </span><span >Ferry Ghat- (Municipal/ others)</span></p></td>
                 <td  ><p ><span >1</span></p></td></tr><tr>
                 <td ><p ><span >23.16</span></p></td>
                 <td><p ><span >Guest House/ Tourist Lodge- (Municipal/ others)</span></p></td>
                 <td ><p ><span >2</span></p></td></tr>
                 <tr >
                 <td ><p><span>23.17</span></p></td>
                 <td><p ><span>Road Roller- (Municipal/ others)</span></p></td>
                 <td ><p ><span>2</span></p></td></tr><tr >
                 <td ><p>
                 <span >23.18</span></p></td>
                 <td ><p ><span >Cess Pool- (Municipal/ others)</span></p></td>
                 <td ><p ><span >2</span></p></td></tr>
                 <tr ><td ><p ><span>23.19</span></p></td>
                 <td ><p ><span >No. of Slaughter House: </span></p></td>
                 <td ><p ><span >&#xa0;</span></p></td></tr>
                 <tr ><td ><p ><span>23.19.1</span></p></td>
                 <td  ><p ><span >Municipal Slaughter House</span></p></td>
                 <td><p ><span >NIL</span></p></td></tr><tr ><td >
              <p ><span >23.19.2</span></p></td>
              <td ><p ><span >Other</span>
              <span >&#xa0; </span><span >Slaughter House</span></p></td>
              <td ><p ><span >Nil</span></p></td></tr><tr ><td >
              <p ><span >23.20</span></p></td><td >
              <p ><span >
             </span><span >Railway Foot bridge (Municipal/ others)</span></p></td>
               <td ><p ><span>3</span></p></td></tr>
               <tr ><td ><p ><span >23.21</span></p></td><td ><p><span >
               </span><span >Others (Please specify)</span>
               <span >&#xa0; </span><span >/ Flood Shelter - (Municipal/ others)</span></p></td>
               <td ><p ><span>1</span></p></td></tr>
               <tr >
               <td > 
               <p><span >24</span></p></td>
               <td >
               <p ><span >Community Structure under NULM</span>
               <span >&#xa0; </span>
               <span >: -</span></p></td><td ><p >
               <span >&#xa0;</span></p></td></tr><tr >
               <td><p >
               <span >24.1</span></p></td>
               <td ><p >
               <span >Total No. of CDS/RO -</span></p></td><td ><p >
               <span >2</span></p></td></tr><tr >
               <td>
               <p ><span >24.2</span></p></td>
               <td >
              <p ><span >City Livelihood Centre Established</span></p></td>
              <td >
              <p ><span >1</span></p></td></tr>
              <tr ><td ><p ><span >24.5</span></p></td>
              <td ><p >
              <span >No. of SHG-</span></p></td><td ><p ><span >615</span></p></td></tr>
               <tr>
               <td ><p >
               <span >25</span></p></td>
               <td >
               <p ><span >National Social Assistance Programme (NSAP) : -</span></p></td>
               <td ><p >
               <span >&#xa0;</span></p></td></tr><tr >
               <td >
               <p ><span >25.1</span></p></td>
               <td >
               <p ><span >No. of beneficiaries under Indira Gandhi National Old Age Pension Scheme (IGNOAPS)/JAI BANGLA PENSION -</span></p></td>
               <td >
               <p ><span >3882</span></p></td></tr>
               <tr ><td ><p ><span >25.2</span></p></td>
               <td >
               <p><span >No. of beneficiaries under Indira Gandhi National Widow Pension Scheme (IGNWPS) /JAI BAGLA PENSION</span></p></td>
               <td><p >
               <span >2884</span></p></td></tr><tr >
               <td >
               <p ><span >25.3</span></p></td>
               <td >
               <p ><span >
               No. of beneficiaries under Indira Gandhi National Disability Pension Scheme (IGNDPS) -</span></p></td>
               <td ><p >
               <span >236</span></p></td></tr><tr ><td ><p >
               <span>25.4</span></p></td><td ><p >
               <span >No. of beneficiaries under National Family Benefit Scheme (NFBS) -</span></p></td>
               <td >
               <p ><span >81</span></p></td></tr>
               <tr>
               <td >
               <p >
               <span >26</span></p></td>
               <td ><p >
               <span >Unit of MAA CANTEEN running : -</span></p></td>
               <td ><p >
               <span>1</span>
               </p></td></tr><tr >
               <td >
               <p ><span >27</span></p></td>
               <td >
               <p ><span >No of Beneficiaries Lakshmi Bhander Provided:-</span>
               </p></td><td >
               <p ><span >13918</span></p></td></tr>
               <tr><td >
               <p >
               <span >28</span></p></td><td ><p >
               <span >No. of beneficiaries under Swasthyasathi scheme : -</span></p></td>
               <td ><p>
               <span >9175</span></p></td></tr><tr >
               <td >
               <p ><span >29</span></p></td>
               <td >
               <p ><span >No. of beneficiaries under KANYASHREE scheme: -</span>
               </p></td><td >
               <p ><span >N.A</span></p>
               <p ><span >a</span></p></td></tr>
               <tr ><td ><p>
               <span >30</span></p></td><td ><p >
               <span >&#xa0;</span>
               <span>No. of beneficiaries under RUPASHREE scheme: -</span></p></td>
               <td ><p ><span >485</span>
               </p></td></tr><tr ><td ><p >
               <span >31</span></p></td>
               <td >
               <p ><span >No. of beneficiaries under HFA/BANGLAR BARI</span></p>
               <p><span >&#xa0;</span>
               <span >scheme: -</span></p></td>
               <td >
               <p ><span >4508</span></p></td></tr>
               <tr ><td ><p >
               <span >&#xa0;</span></p></td><td ><p >
               <span >&#xa0;</span></p></td>
               <td ><p >
               <span >&#xa0;</span></p></td></tr>
               <tr ><td >
               <p >
               <span >32</span></p></td>
               <td ><p >
               <span >Municipal Staff( as on 01.03.2022) : -</span></p></td>
               <td >
               <p ><span>&#xa0;</span>
               </p></td></tr><tr >
               <td >
               <p >
               <span >32.1</span></p></td>
               <td><p >
               <span >Total No. of sanctioned Post -</span></p></td>
               <td >
               <p ><span >293</span></p></td></tr>
               <tr >
               <td >
               <p ><span >32.2</span></p></td>
               <td >
               <p ><span >Actual Staff Strength(Regular) -</span></p></td>
               <td >
               <p ><span >283</span></p></td></tr>
               <tr ><td ><p >
               <span >32.3</span></p></td>
               <td ><p >
               <span>Actual Staff Strength(Contractual, not Casual) -</span></p></td>
               <td ><p>
               <span >15</span></p></td></tr><tr >
               <td ><p >
               <span >33</span></p></td>
               <td >
               <p >
               <span >Registration of Births and Deaths during 2021-22 : -</span></p></td>
               <td>
               <p >
               <span>&#xa0;</span></p></td></tr>
               <tr >
               <td >
               <p ><span >33.1</span></p></td>
               <td >
               <p >
               <span >Whether Birth &amp; Death Certificate issued through e-governance System – Yes / No.</span></p></td>
               <td >
               <p ><span>Yes</span></p></td></tr>
               <tr >
               <td ><p> 
               <span >33.2</span></p></td>
               <td ><p >
               <span >No. of Births Registered -</span></p></td>
               <td ><p >
               <span >3459</span></p></td></tr><tr >
               <td><p >
               <span >33.3</span></p></td>
               <td >
               <p><span >No. of Birth Certificate issued -</span></p></td>
               <td ><p >
               <span >3459</span></p></td></tr>
               <tr >
               <td >
               <p ><span >33.3.1</span></p></td>
               <td >
               <p ><span >Male</span></p></td>
               <td >
               <p ><span >1742</span></p></td></tr>
               <tr><td ><p >
               <span >33.3.2</span></p></td>
               <td ><p ><span >Female</span></p></td>
               <td ><p >
               <span >1717</span></p></td></tr><tr >
               <td ><p >
               <span >33.4</span></p></td><td ><p >
               <span >No. of Death Registered -</span></p></td>
               <td ><p >
               <span >745</span></p></td></tr>
               <tr ><td >
               <p ><span >33.5</span></p></td>
               <td ><p ><span >No. of Death Certificate issued -</span>
               </p></td><td ><p >
               <span>601</span></p></td></tr>
               <tr><td ><p >
               <span >33.5.1</span></p></td>
               <td>
               <p ><span>Male</span></p></td>
               <td>
               <p ><span >390</span></p></td></tr>
               <tr ><td ><p>
               <span >33.5.2</span></p></td>
               <td ><p ><span >Female</span></p></td>
               <td >
               <p><span >355</span></p></td></tr>
               <tr >
               <td >
               <p ><span >34</span></p></td>
               <td >
               <p ><span>Shelter For Urban Houseless constructed under NULM</span></p></td>
               <td >
               <p ><span >1</span></p></td></tr>
               <tr >
               <td ><p >
               <span >35</span></p></td>
               <td ><p >
               <span>E-Commencing Outlet for SHG product Display</span></p></td>
               <td ><p ><span >1</span>
               </p></td></tr>
               </table>
          </div>
           </div>
           <!-- <div class="row">
              <div class="cityprofile">
               <span  [innerHtml]="cityProfiledata.mainContent"></span>
              </div>
           </div> -->
      </div>
    </div>
  </section>

