<div class="login-page">
    <div class="log-box">
        <form class="log-frm" [formGroup]="changePasswordParameter">
            <div style="text-align:center; margin-bottom:50px">
                <img src="../../../assets/assetsAdmin/img/logo1.png">
            </div>

            <h4>Change Password</h4>
            <div class="form-group">
                <label>Password</label>
                <div [ngClass]="{'err-bdr': submitted && f1.password.errors }">
                    <input type="Password" name="password" placeholder formControlName="password" required="" />
                    <div *ngIf="f1.password.errors && f1.password.touched">
                        <small *ngIf="f1.password.errors.required" class="required">password is Required</small>
                    </div>
                    <!-- <a href="javascript:void(0);" class="pas-view"></a> -->
                </div>
            </div>
            <div class="form-group">
                <label>New Password</label>
                <input type="Password" name="newpassword" placeholder formControlName="newpassword">
                <div *ngIf="f1.newpassword.errors && f1.newpassword.touched">
                    <small *ngIf="f1.newpassword.errors.required" class="required">New Password is Required</small>
                </div>
            </div>
            <div class="form-group">
                <label>Confirm Password</label>
                <input type="Password" name="confpassword" placeholder formControlName="confpassword">
                <div *ngIf="f1.confpassword.errors && f1.confpassword.touched">
                    <small *ngIf="f1.confpassword.errors.required" class="required">Confirm Password is Required</small>
                </div>
            </div>
            <div class="form-group text-center pb-0">
                <button type="button" class="login-btn" (click)="onLogin()">Change</button>
                <!-- <a href="javascript:void(0);">Forgot Password ?</a> -->
            </div>
        </form>
    </div>
</div>

<footer class="log-footer">
    <p>Copyright &copy; 2022 company name. All right reserved.</p>
</footer>