import { Component, OnInit } from '@angular/core';
import { NgxSpinnerService } from "ngx-spinner";
import { ApiService } from 'src/app/services/api.service'

@Component({
  selector: 'app-town-profile',
  templateUrl: './town-profile.component.html',
  styleUrls: ['./town-profile.component.css']
})
export class TownProfileComponent implements OnInit {
  about:any;
  cityProfiledata:any;
  isShow = false;
  citydata = false;
  worker = false;
  literacy = false;
  sRatio = false;
  religion = false;
  constructor(private apiService: ApiService,
    private SpinnerService: NgxSpinnerService,) { }

  ngOnInit(): void {
  //   this.apiService.aboutUs().subscribe((res:any )=> {
  //     this.SpinnerService.show();
  //     this.about=res.data;
  //     this.SpinnerService.hide();

  //  },
  //  (error:any )=> {
 
  //  });
   this.apiService.cityProfile().subscribe((res:any )=> {
    this.SpinnerService.show();
  this.cityProfiledata =res.data;
  this.SpinnerService.hide();

  
},
(error:any )=> {

});
  }
  townCensus(){
    this.isShow =true;
    this.citydata =false;
    this.literacy = false;
    this.worker = false;
    this.sRatio = false;
    this.religion = false;
  }
  cityortownData(){
    this.citydata =true;
    this.isShow =false;
    this.literacy = false;
    this.worker = false;
    this.sRatio = false;
    this.religion = false;
  }
  workerProfile(){
   this.worker = true;
   this.citydata =false;
    this.isShow =false;
    this.literacy = false;
    this.sRatio = false;
    this.religion = false;
  }
  literacyRate(){
    this.literacy = true;
    this.worker = false;
   this.citydata =false;
    this.isShow =false;
    this.sRatio = false;
    this.religion = false;
  }
  sexRatio(){
    this.sRatio = true;
    this.literacy = false;
    this.worker = false;
   this.citydata =false;
    this.isShow =false;
    this.religion = false;
  }
  ReligionPopulation(){
    this.religion = true;
    this.sRatio = false;
    this.literacy = false;
    this.worker = false;
   this.citydata =false;
    this.isShow =false;
  }

}
