<app-header></app-header>
<div class="inner-background-two">
    <img src="assets/images/inner_banner.jpg">
    <div class="latest-overlay-two">
    <div class="container"><h2>Census-2011</h2></div>
  </div>
  </div>
  <section class="inner-post">
    <div class="container">
      <div class="row">
         <div class="col-lg-9 col-sm-12 col-12">
          <div>
           <h3>Ranaghat City Population Census 2011 Data- WEST BENGAL</h3>
           <p>Ranaghat (M) town is situated in district NADIA, WEST BENGAL. 
            The Ranaghat town has population of 75365, male population is 37948 and female population is 37417 as per the Census 2011 data. Population of Children under the age of 0-6 is 5022, male child population under the age of six is 2623 and female child population under the age of six is 2399. Total literacy rate of Ranaghat city is 93.19%, male literacy rate is 95.04% and female literacy rate is 91.33%. In Ranaghat Female Sex Ratio is 986 per 1000 male persons. Child sex ratio is 915 per 1000 male child under
             the age of six. Total number of house hold in Ranaghat is 17863.</p>
             <br>
             <h4>Census Data of Ranaghat District NADIA, State WEST BENGAL- India --Census 2011</h4>
             <div class="table-responsive">
                <table class="table" >
                    <thead>
                    <tr>
                        <th style="width:20px; font-size:large; font-weight:bolder; color: black;">Population</th>
                        <th style="width:20px; font-size:large; font-weight:bolder; color: black;">Area (Ha)</th>
                        <th style="width:20px; font-size:large; font-weight:bolder; color: black;">Density (P/Ha)</th>
                        <th style="width:20px; font-size:large; font-weight:bolder; color: black;">Sex Ratio</th>
                        <th style="width:20px; font-size:large; font-weight:bolder; color: black;">Literacy</th>
                    </tr>
                    </thead>
                    <tbody >
                    <tr>
                        <td style="width:20px; font-size:medium; font-weight: 500;">75365</td>
                        <td style="width:20px; font-size:medium; font-weight: 500;">7.72</td>
                        <td style="width:20px; font-size:medium; font-weight: 500;">9762</td>
                        <td style="width:20px; font-size:medium; font-weight: 500;">986</td>
                        <td style="width:20px; font-size:medium; font-weight: 500;">93.19%</td>
                       
                    </tr>							 
                    </tbody>
                    
                  </table>
                  <br>
                  <h4>Population of Ranaghat, District NADIA in state WEST BENGAL, India</h4>
                  <table class="table" >
                    <thead>
                    <tr>
                        <th style="width:20px; font-size:large; font-weight:bolder; color: black;">Total Population</th>
                        <th style="width:20px; font-size:large; font-weight:bolder; color: black;">Male Population</th>
                        <th style="width:20px; font-size:large; font-weight:bolder; color: black;">Female Population</th>
    
                    </tr>
                    </thead>
                    <tbody >
                    <tr>
                        <td style="width:20px; font-size:medium; font-weight: 500;">75365</td>
                        <td style="width:20px; font-size:medium; font-weight: 500;">37948</td>
                        <td style="width:20px; font-size:medium; font-weight: 500;">37417</td>
                       
                       
                    </tr>							 
                    </tbody>
                    
                  </table>
              </div>
              <div class="row">
                <div class="col-md-12">
                  <div class="btn-grp" style="margin: 32px -47px 31px -20px;">
                    <button type="button" class="done-btn" style="margin: 10px 20px 21px -11px;
                    background-color: #227ba7;
                    border: none;
                    padding: 10px 25px;
                    color: white;" (click)="townCensus()" title="Click to View">Town Census</button>
                    
                    <button type="button" class="done-btn" style="margin:10px 9px 21px -14px;
                    background-color: #227ba7;
                    border: none;
                    padding: 10px 25px;
                    color: white;" (click)="cityortownData()" title="Click to View">Town/City Data</button>

                    <button type="button" class="cancl-btn" style="
                    background-color: #227ba7;
                    border: none;
                    padding: 10px 25px;
                    color: white;" (click)="workerProfile()" title="Click to View">Worker Profile</button>
                       <button type="button" class="cancl-btn" style="margin:10px 18px 10px 10px;;
                       background-color: #227ba7;
                       border: none;
                       padding: 10px 25px;
                       color: white;" (click)="literacyRate()" title="Click to View">Literacy Rate</button>
                       <button type="button" class="cancl-btn"style="margin: 10px 20px 10px -10px;

                       background-color: #227ba7;
                       border: none;
                       padding: 10px 25px;
                       color: white;" (click)="sexRatio()" title="Click to View">Sex ratio</button>
                       <button type="button" class="cancl-btn"style="margin:10px -16px 10px -14px;
                       background-color: #227ba7;
                       border: none;
                       padding: 10px 25px;
                       color: white;" (click)="ReligionPopulation()" title="Click to View">Religion wise po.</button>
      
                  </div>
                </div>
              </div>
              <div class="table-responsive" *ngIf="isShow">
                 <h4>Ranaghat Town Census 2011 Data ---Census 2011</h4>
                 <table class="table">
                  <thead>
                    <tr>
                      <th style="width:20px; font-size:large; font-weight:bolder; color: black;">Description</th>
                      <th style="width:20px; font-size:large; font-weight:bolder; color: black;">
                        Census 2011 Data
                      </th>
                    </tr>
                  </thead>
                  <tbody>
                    <tr>
                      <td>Town Name</td>
                      <td>Ranaghat</td>
                    </tr>
                    <tr>
                      <td>Civic Type</td>
                      <td>Municipality</td>
                    </tr>
                    <tr>
                      <td>Teshil Name</td>
                      <td>RANAGHAT</td>
                    </tr>
                    <tr>
                      <td>District Name</td>
                      <td>NADIA</td>
                    </tr>
                    <tr>
                      <td>State Name</td>
                      <td>WEST BENGAL</td>
                    </tr>
                    <tr>
                      <td>Total Population</td>
                      <td>75365</td>
                    </tr>
                    <tr>
                      <td>Total Area</td>
                      <td>7.72 (Ha)</td>
                    </tr>
                    <tr>
                      <td>Total No of House Holds</td>
                      <td>17863</td>
                    </tr>
                    <tr>
                      <td>Total No of House Holds</td>
                      <td>17863</td>
                    </tr>
                  </tbody>
                 </table>
                 <table class="table">
                  <thead>
                    <tr>
                      <th style="width:20px; font-size:large; font-weight:bolder; color: black;">Description</th>
                      <th style="width:20px; font-size:large; font-weight:bolder; color: black;">Census 2011 Data</th>
                    </tr>
                  </thead>
                  <tbody>
                    <tr>
                      <td>Total Male Population</td>
                      <td>37948</td>
                    </tr>
                    <tr>
                      <td>Total Female Population</td>
                      <td>37417</td>
                    </tr>
                    <tr>
                      <td>0-6 Age group Total Population</td>
                      <td>5022</td>
                    </tr>
                    <tr>
                      <td>0-6 Age group Male Population</td>
                      <td>2623</td>
                    </tr>
                    <tr>
                      <td>0-6 Age group Male Population</td>
                      <td>2399</td>
                    </tr>
                    <tr>
                      <td>Total Person Literates</td>
                      <td>65555</td>
                    </tr>
                    <tr>
                      <td>Total Male Literates</td>
                      <td>33573</td>
                    </tr>
                    <tr>
                      <td>Total Male Literates</td>
                      <td>31982</td>
                    </tr>
                    <tr>
                      <td>Total Person Illiterates</td>
                      <td>9810</td>
                    </tr>
                    <tr>
                      <td>Total Male Illiterates</td>
                      <td>4375</td>
                    </tr>
                    <tr>
                      <td>Total Male Illiterates</td>
                      <td>5435</td>
                    </tr>
                    <tr>
                      <td>Scheduled Cast Persons</td>
                      <td>9841</td>
                    </tr>
                    <tr>
                      <td>Scheduled Cast Males</td>
                      <td>5023</td>
                    </tr>
                    <tr>
                      <td>Scheduled Cast Females</td>
                      <td>4818</td>
                    </tr>
                    <tr>
                      <td>Scheduled Tribe Persons</td>
                      <td>202</td>
                    </tr>
                    <tr>
                      <td>Scheduled Tribe Males</td>
                      <td>231</td>
                    </tr>
                    <tr>
                      <td>Scheduled Tribe Females</td>
                      <td>202</td>
                    </tr>
                  </tbody>
                 </table>
                 <p>Ranaghat town of NADIA district has civic type is M. Teshil name of Ranaghat is .
                   District name of Ranaghat town/city is NADIA (WEST BENGAL). 
                   Data Reference year is 2009 of Census 2011.
                    Sub District HQ name of Ranaghat is Ranaghat and Sub District HQ Distance is 0 Km from Ranaghat. 
                    District Head Quarter name is Krishnanagar and it’s distance from the town is 27KM.
                   As per census 2011 town code of Ranaghat is 801686.</p>
                </div>
                <br>
                <div class="table-responsive" *ngIf="citydata">
                    <h4>Ranaghat Town/City Data ---Census 2011</h4>
                    <table class="table" >
                      <thead>
                        <tr>
                          <th style="width:20px; font-size:large; font-weight:bolder; color: black;">Description</th>
                          <th style="width:20px; font-size:large; font-weight:bolder; color: black;">Data</th>
                        </tr>
                        </thead>
                        <tbody>
                          <tr>
                            <td style="width:20px; font-size:medium; font-weight: 500;">Town Name</td>
                            <td style="width:20px; font-size:medium; font-weight: 500;">Ranaghat</td>
                          </tr>
                          <tr>
                            <td style="width:20px; font-size:medium; font-weight: 500;">CD Block Name</td>
                            <td style="width:20px; font-size:medium; font-weight: 500;"></td>
                          </tr>
                          <tr>
                            <td style="width:20px; font-size:medium; font-weight: 500;">Teshil Name</td>
                            <td style="width:20px; font-size:medium; font-weight: 500;">Ranaghat</td>
                          </tr>
                          <tr>
                            <td style="width:20px; font-size:medium; font-weight: 500;">Reference Year</td>
                            <td style="width:20px; font-size:medium; font-weight: 500;">2009</td>
                          </tr>
                          <tr>
                            <td style="width:20px; font-size:medium; font-weight: 500;">Sub District HQ Name</td>
                            <td style="width:20px; font-size:medium; font-weight: 500;">Ranaghat</td>
                          </tr>
                          <tr>
                            <td style="width:20px; font-size:medium; font-weight: 500;">Sub District HQ Name</td>
                            <td style="width:20px; font-size:medium; font-weight: 500;">0 Km</td>
                          </tr>
                           <tr>
                            <td style="width:20px; font-size:medium; font-weight: 500;">District HQ Name</td>
                            <td style="width:20px; font-size:medium; font-weight: 500;">KRISHNANAGAR</td>
                          </tr>
                          <tr>
                            <td style="width:20px; font-size:medium; font-weight: 500;">District HQ Distance</td>
                            <td style="width:20px; font-size:medium; font-weight: 500;">27 Km</td>
                          </tr>
                          <tr>
                            <td style="width:20px; font-size:medium; font-weight: 500;">Nearest City of 1 Lakh Population</td>
                            <td style="width:20px; font-size:medium; font-weight: 500;">Santipur</td>
                          </tr>
                          <tr>
                            <td style="width:20px; font-size:medium; font-weight: 500;">Nearest City of 1 Lakh Population Distance</td>
                            <td style="width:20px; font-size:medium; font-weight: 500;">17 Km</td>
                          </tr>
                          <tr>
                            <td style="width:20px; font-size:medium; font-weight: 500;">Nearest City of 5 Lakh Population</td>
                            <td style="width:20px; font-size:medium; font-weight: 500;">Kolkata</td>
                          </tr>
                          <tr>
                            <td style="width:20px; font-size:medium; font-weight: 500;">Nearest City of 5 Lakh Population Distance</td>
                            <td style="width:20px; font-size:medium; font-weight: 500;">74 Km</td>
                          </tr>
                          
                        </tbody>
                        </table>
                    
                   </div>
                   <div class="table-responsive" *ngIf="worker">
                    <h4>Workers profile of Ranaghat</h4>
                    <p>Total working population of Ranaghat is 26687 which are either 
                      main or marginal workers. Total workers in the town/city are 26687 out
                       of which 22520 are male and 4167 are female. 
                       Total main workers are 25298 out of which male main workers are 21723 and 
                       female main workers are 3575.
                       Total marginal workers of Ranaghat are 1389.</p>
                       <h4>Ranaghat town Working Population ---Census 2011</h4>
                       <table class="table">
                        <thead>
                          <tr>
                            <th></th>
                            <th style="width:20px; font-size:large; font-weight:bolder; color: black;">Total</th>
                            <th style="width:20px; font-size:large; font-weight:bolder; color: black;">Male</th>
                            <th style="width:20px; font-size:large; font-weight:bolder; color: black;">Female</th>
                          </tr>
                        </thead>
                        <tbody>
                          <tr>
                            <td style="width:20px; font-size:medium; font-weight: 500;">Total Workers</td>
                            <td style="width:20px; font-size:medium; font-weight: 500;">26687</td>
                            <td style="width:20px; font-size:medium; font-weight: 500;">22520</td>
                            <td style="width:20px; font-size:medium; font-weight: 500;">4167</td>
                          </tr>
                          <tr>
                            <td style="width:20px; font-size:medium; font-weight: 500;">Main Workers</td>
                            <td style="width:20px; font-size:medium; font-weight: 500;">25298</td>
                            <td style="width:20px; font-size:medium; font-weight: 500;">21723</td>
                            <td style="width:20px; font-size:medium; font-weight: 500;">3575</td>
                          </tr>
                          <tr>
                            <td style="width:20px; font-size:medium; font-weight: 500;">Main Workers Cultivators</td>
                            <td style="width:20px; font-size:medium; font-weight: 500;">34</td>
                            <td style="width:20px; font-size:medium; font-weight: 500;">32</td>
                            <td style="width:20px; font-size:medium; font-weight: 500;">2</td>
                          </tr>
                          <tr>
                            <td style="width:20px; font-size:medium; font-weight: 500;">Agriculture Labourer</td>
                            <td style="width:20px; font-size:medium; font-weight: 500;">30</td>
                            <td style="width:20px; font-size:medium; font-weight: 500;">29</td>
                            <td style="width:20px; font-size:medium; font-weight: 500;">1</td>
                          </tr>
                          <tr>
                            <td style="width:20px; font-size:medium; font-weight: 500;">Household Industries</td>
                            <td style="width:20px; font-size:medium; font-weight: 500;">601</td>
                            <td style="width:20px; font-size:medium; font-weight: 500;">341</td>
                            <td style="width:20px; font-size:medium; font-weight: 500;">260</td>
                          </tr>
                          <tr>
                            <td style="width:20px; font-size:medium; font-weight: 500;">Other Workers</td>
                            <td style="width:20px; font-size:medium; font-weight: 500;">24633</td>
                            <td style="width:20px; font-size:medium; font-weight: 500;">21321</td>
                            <td style="width:20px; font-size:medium; font-weight: 500;">3312</td>
                          </tr>
                          <tr>
                            <td style="width:20px; font-size:medium; font-weight: 500;">Marginal Workers</td>
                            <td style="width:20px; font-size:medium; font-weight: 500;">1389</td>
                            <td style="width:20px; font-size:medium; font-weight: 500;">797</td>
                            <td style="width:20px; font-size:medium; font-weight: 500;">592</td>
                          </tr>
                          <tr>
                            <td style="width:20px; font-size:medium; font-weight: 500;">Non Working Persons</td>
                            <td style="width:20px; font-size:medium; font-weight: 500;">48678</td>
                            <td style="width:20px; font-size:medium; font-weight: 500;">15428</td>
                            <td style="width:20px; font-size:medium; font-weight: 500;">33250</td>
                          </tr>
                        </tbody>
                       </table>

                   </div>
                   <div class="table-responsive" *ngIf="literacy">
                    <h4>Literacy Rate - Ranaghat</h4>
                    <p>The total literacy rate of Ranaghat was 88.58% in 2011 which is greater than average literacy rate 76.26% of West Bengal. 
                      Population-wise, out of total 3,273 literates, males were 1,711 while females were 1,562. 
                      Also the male literacy rate was 92.24% and the female literacy rate was 84.89% in Ranaghat.</p>
                      <table class="table" >
                        <thead>
                        <tr>
                            <th style="width:20px; font-size:large; font-weight:bolder; color: black;"></th>
                            <th style="width:20px; font-size:large; font-weight:bolder; color: black;">Ranaghat</th>
                            <th style="width:20px; font-size:large; font-weight:bolder; color: black;">West Bengal</th>
                           
                            
                        </tr>
                        </thead>
                        <tbody>
                        <tr>
                            <td style="width:20px; font-size:medium; font-weight: 500;">Female</td>
                            <td style="width:20px; font-size:medium; font-weight: 500;">84.89%</td>
                            <td style="width:20px; font-size:medium; font-weight: 500;">70.54%</td>
                        </tr>	
                        <tr>
                          <td style="width:20px; font-size:medium; font-weight: 500;">Male</td>
                          <td style="width:20px; font-size:medium; font-weight: 500;">92.24%</td>
                          <td style="width:20px; font-size:medium; font-weight: 500;">81.69%</td>
                      </tr>		
                      <tr>
                        <td style="width:20px; font-size:medium; font-weight: 500;">Total</td>
                        <td style="width:20px; font-size:medium; font-weight: 500;">88.58%</td>
                        <td style="width:20px; font-size:medium; font-weight: 500;">76.26%</td>
                    </tr>					 
                        </tbody>
                      </table>
                   </div>
                   <div class="table-responsive" *ngIf="sRatio">
                    <h4>Sex Ratio - Ranaghat</h4>
                    <p>The Sex Ratio of Ranaghat is 1,006. 
                      Thus per every 1000 men there were 1,006 females in Ranaghat. Also as per Census 2011, 
                      the Child Sex Ration was 1,208 which is greater than Average Sex Ratio ( 1,006 ) of Ranaghat.</p>
                   </div>
                   <div class="table-responsive" *ngIf="religion">
                    <h4>Religion-wise Population - Ranaghat</h4>
                    <p>As per the Census 2011,
                       the total Hindu population in Ranaghat is 3,843 which is 96.51% of the total population. 
                       Also the total Muslim population in Ranaghat is 137 which is 3.44% of the total population. 
                      Below is religion-wise population of Ranaghat as per Census 2011.</p>
                      <p>Religious Demography of Ranaghat (M) - Census 2011HinduMuslimChristianReligion not statedOther97.2%</p>
                      <table class="table" >
                        <thead>
                        <tr>
                            <th style="width:20px; font-size:large; font-weight:bolder; color: black;">Topping</th>
                            <th style="width:20px; font-size:large; font-weight:bolder; color: black;">Slices</th>
                            
                           
                            
                        </tr>
                        </thead>
                        <tbody>
                        <tr>
                            <td style="width:20px; font-size:medium; font-weight: 500;">Hindu</td>
                            <td style="width:20px; font-size:medium; font-weight: 500;">73,219</td>
                           
                        </tr>	
                        <tr>
                          <td style="width:20px; font-size:medium; font-weight: 500;">Muslim</td>
                          <td style="width:20px; font-size:medium; font-weight: 500;">1,827</td>
                       
                      </tr>		
                      <tr>
                        <td style="width:20px; font-size:medium; font-weight: 500;">Christian</td>
                        <td style="width:20px; font-size:medium; font-weight: 500;">137</td>
                       
                    </tr>		
                    <tr>
                      <td style="width:20px; font-size:medium; font-weight: 500;">Sikh</td>
                      <td style="width:20px; font-size:medium; font-weight: 500;">33</td>
                     
                  </tr>	
                  <tr>
                    <td style="width:20px; font-size:medium; font-weight: 500;">Buddhist</td>
                    <td style="width:20px; font-size:medium; font-weight: 500;">13</td>
                   
                </tr>	
                <tr>
                  <td style="width:20px; font-size:medium; font-weight: 500;">Jain</td>
                  <td style="width:20px; font-size:medium; font-weight: 500;">2</td>
                 
              </tr>	
              <tr>
                <td style="width:20px; font-size:medium; font-weight: 500;">Other religions and persuasions</td>
                <td style="width:20px; font-size:medium; font-weight: 500;">3</td>
               
            </tr>	
            <tr>
              <td style="width:20px; font-size:medium; font-weight: 500;">Religion not stated</td>
              <td style="width:20px; font-size:medium; font-weight: 500;">131</td>
             
          </tr>		 
                        </tbody>
                      </table>
                     <table class="table">
                      <thead>
                        <tr>
                          <th style="width:20px; font-size:large; font-weight:bolder; color: black;">Religion</th>
                          <th style="width:20px; font-size:large; font-weight:bolder; color: black;">Total Population</th>
                          <th style="width:20px; font-size:large; font-weight:bolder; color: black;">Male Population</th>
                          <th style="width:20px; font-size:large; font-weight:bolder; color: black;">Female Population</th>
                          <th style="width:20px; font-size:large; font-weight:bolder; color: black;">Popultation %</th>
                          <th style="width:20px; font-size:large; font-weight:bolder; color: black;">Literacy</th>
                        </tr>
                      </thead>
                      <tbody>
                        <tr>
                          <td>Hindu</td>
                          <td>73219</td>
                          <td>36841</td>
                          <td>36378</td>
                          <td>97.15%</td>
                          <td>90.82%</td>
                        </tr>
                        <tr>
                          <td>Muslim</td>
                          <td>1827</td>
                          <td>947</td>
                          <td>880</td>
                          <td>2.42%</td>
                          <td>2.02%</td>
                        </tr>
                        <tr>
                          <td>Christian</td>
                          <td>1827</td>
                          <td>64</td>
                          <td>73</td>
                          <td>0.18%</td>
                          <td>0.17%</td>
                        </tr>
                        <tr>
                          <td>Sikh</td>
                          <td>33</td>
                          <td>17</td>
                          <td>16</td>
                          <td>0.04%</td>
                          <td>0.04%</td>
                        </tr>
                        <tr>
                          <td>Buddhist</td>
                          <td>13</td>
                          <td>7</td>
                          <td>6</td>
                          <td>0.02%</td>
                          <td>0.02%</td>
                        </tr>
                        <tr>
                          <td>Jain</td>
                          <td>2</td>
                          <td>1</td>
                          <td>1</td>
                          <td>0%</td>
                          <td>0%</td>
                        </tr>
                        <tr>
                          <td>Other religions and </td>
                          <td>3</td>
                          <td>2</td>
                          <td>1</td>
                          <td>0%</td>
                          <td>0%</td>
                        </tr>
                        <tr>
                          <td>persuasions</td>
                          <td></td>
                          <td></td>
                          <td></td>
                          <td></td>
                          <td></td>
                        </tr>
                        <tr>
                          <td>Religion not stated</td>
                          <td>131</td>
                          <td>69</td>
                          <td>62</td>
                          <td>0.17%</td>
                          <td>0.12%</td>
                        </tr>
                      </tbody>
                     </table>
                   </div>
          </div>
        		    
  
           </div>
       
      </div>
    </div>
  </section>

