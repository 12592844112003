import { Component, OnInit } from '@angular/core';
import { NgxSpinnerService } from "ngx-spinner";
import { ApiService } from 'src/app/services/api.service';

@Component({
  selector: 'app-rti',
  templateUrl: './rti.component.html',
  styleUrls: ['./rti.component.css']
})
export class RtiComponent implements OnInit {
rti:any;
  constructor(private apiService: ApiService,
    private SpinnerService: NgxSpinnerService,) { }

  ngOnInit(): void {
    this.apiService.Rti().subscribe((res:any )=> {
      this.SpinnerService.show();
       this.rti =res.data;
       this.SpinnerService.hide();
   
      
   },
   (error:any )=> {
   
   });

  }

}
