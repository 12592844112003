<app-header></app-header>
<div class="inner-background-two">
    <img src="assets/images/inner_banner.jpg">
    <div class="latest-overlay-two">
    <div class="container"><h2>Internal Audit</h2></div>
  </div>
  </div>
  <section class="inner-post">
    <div class="container">
      <div class="row">
         <div class="col-lg-9 col-sm-12 col-12">
            <div class="table-responsive">
              <table class="table">
                <thead>
                <tr>
                    <th style="width:20px; font-size:medium; font-weight: 700;">Sr No.</th>
                    <th style="width:20px; font-size:medium; font-weight: 700;">Title</th>
                    <th style="width:20px; font-size:medium; font-weight: 700;">Financial Year</th>
                    <th style="width:20px; font-size:medium; font-weight: 700;">Uploaded Date</th>
                    <th style="width:20px; font-size:medium; font-weight: 700;">Action</th>                       
                </tr>
                </thead>
                <tbody *ngFor="let item of internalauditList ; let i = index">
                <tr>
                    <td style="width:20px; font-size:medium; font-weight: 500;">{{i+1}}</td>
                    <td style="width:20px; font-size:medium; font-weight: 500;">{{item.title}}</td>
                     <td style="width:20px; font-size:medium; font-weight: 500;">{{item.financialYear}}</td>
                    <td style="width:20px; font-size:medium; font-weight: 500;">{{item.uploadDate}}</td>                   
                    <td><a href="javascript:void(0);"><img src="../../../assets/images/pdf_icon.png"  (click)="downloadFile(item.id)"></a> </td>
                </tr>							 
                </tbody>
              </table>
                <!-- <table  class="table">
                    <thead>
                    <tr>
                        <th style="width:10px; font-size:large; font-weight:bolder; color: black;text-align: left;">SLNO.</th>
                        <th style="width:10px; font-size:large; font-weight:bolder; color: black;text-align: left;">FinancialYear.</th>
                        <th style="width:10px; font-size:large; font-weight:bolder; color: black;text-align: left;">Action</th>
                    </tr>
                    </thead>
                    <tbody >
                    <tr>
                        <td style="font-size:medium; font-weight: 500;text-align:left">1</td>
                        <td style="width:15px; font-size:medium; font-weight: 500;text-align: left;">2014-15</td>
                        <td  style=" font-size:medium; font-weight: 500;text-align: left;">
                        <a href="javascript:void(0);" target="_blank" href="../../../assets/images/AUDIT 2014-15.pdf"><img src="../../../assets/images/pdf_icon.png">Download </a> </td>
                    </tr>
                    <tr>
                        <td style="font-size:medium; font-weight: 500;text-align:left">2</td>
                        <td style="width:15px; font-size:medium; font-weight: 500;text-align:left">2015-16</td>
                        <td  style=" font-size:medium; font-weight: 500;text-align: left;">
                        <a href="javascript:void(0);" target="_blank" href="../../../assets/images/AUDIT 2015-16.pdf"><img src="../../../assets/images/pdf_icon.png">Download </a> </td>
                    </tr>	
                     <tr>
                        <td style="font-size:medium; font-weight: 500;text-align:left">3</td>
                        <td style="width:15px; font-size:medium; font-weight: 500;text-align:left">2016-17</td>
                        <td style="font-size:medium; font-weight: 500;text-align: left;">
                        <a href="javascript:void(0);" target="_blank" href="../../../assets/images/AUDIT 2016-17.pdf"><img src="../../../assets/images/pdf_icon.png">Download </a> </td>
                    </tr>			
                     <tr>
                        <td style="font-size:medium; font-weight: 500;text-align:left">4</td>
                        <td style="width:15px; font-size:medium; font-weight: 500;text-align:left">2017-18 (1)</td>
                        <td  style="font-size:medium; font-weight: 500;text-align: left;">
                        <a href="javascript:void(0);" target="_blank" href="../../../assets/images/New Doc 2019-09-23 16.12.33.pdf"><img src="../../../assets/images/pdf_icon.png">Download </a> </td>
                    </tr>	
                      <tr>
                        <td style="font-size:medium; font-weight: 500;text-align:left">5</td>
                        <td style="width:15px; font-size:medium; font-weight: 500;text-align:left">2017-18 (2)</td>
                        <td  style="font-size:medium; font-weight: 500;text-align:left">
                        <a href="javascript:void(0);" target="_blank" href="../../../assets/images/New Doc 2019-09-23 16.05.31.pdf"><img src="../../../assets/images/pdf_icon.png">Download </a> </td>
                    </tr>	
                      <tr>
                        <td style=" font-size:medium; font-weight: 500;text-align:left">6</td>
                        <td style="width:15px; font-size:medium; font-weight: 500;text-align:left">2018-19</td>
                        <td  style="font-size:medium; font-weight: 500;text-align: left;">
                        <a href="javascript:void(0);" target="_blank" href="../../../assets/images/Internal Audit Report_2018-19_WB_Ranaghat Municipality.pdf"><img src="../../../assets/images/pdf_icon.png">Download </a> </td>
                    </tr>		
                    <tr>
                      <td style="font-size:medium; font-weight: 500;text-align:left">7</td>
                      <td style="width:15px; font-size:medium; font-weight: 500;text-align: left;">2019-20</td>
                      <td  style=" font-size:medium; font-weight: 500;text-align: left;">
                      <a href="javascript:void(0);" target="_blank" href="../../../assets/images/Internal Audit 2019-2020.pdf"><img src="../../../assets/images/pdf_icon.png">Download </a> </td>
                  </tr>				 
                    </tbody>
                  </table> -->
              </div>
           </div>
      </div>
    </div>
  </section>


