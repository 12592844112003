import { Component, OnInit } from '@angular/core';
import { NgxSpinnerService } from "ngx-spinner";
import { ApiService } from 'src/app/services/api.service';

@Component({
  selector: 'app-ward-councillor',
  templateUrl: './ward-councillor.component.html',
  styleUrls: ['./ward-councillor.component.css']
})
export class WardCouncillorComponent implements OnInit {
wardCouncilor:any;
  constructor(private apiService: ApiService,
    private SpinnerService: NgxSpinnerService,) { }

  ngOnInit(): void {
    this.apiService.wardCouncilor().subscribe((res:any )=> {
      this.SpinnerService.show();
       this.wardCouncilor =res.data;
       console.log("Data is ",this.wardCouncilor);
       this.SpinnerService.hide(); 
     
  },
  (error:any )=> {
  
  });
  }

}
