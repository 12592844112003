import { Component, OnInit } from '@angular/core';
import { NgxSpinnerService } from "ngx-spinner";
import { ApiService } from 'src/app/services/api.service'

@Component({
  selector: 'app-tender-current',
  templateUrl: './tender-current.component.html',
  styleUrls: ['./tender-current.component.css']
})
export class TenderCurrentComponent implements OnInit {

  tenderC: any;
  constructor(private apiService: ApiService,
    private SpinnerService: NgxSpinnerService,) { }
  ngOnInit(): void {
    this.apiService.tenderCurrent().subscribe((res: any) => {
      this.SpinnerService.show();
      this.tenderC = res.data;
      this.SpinnerService.hide();

    },
      (error: any) => {

      });
  }
  tenderCurrent(tenderId: any) {
    this.apiService.viewtenderCFile(tenderId).subscribe(res => {
      const fileURL = URL.createObjectURL(res);
      window.open(fileURL, '_blank');
    });
  }

}
