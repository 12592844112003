<app-header></app-header>
<div class="inner-background-two">
    <img src="assets/images/inner_banner.jpg">
    <div class="latest-overlay-two">
    <div class="container"><h2>RTI</h2></div>
  </div>
  </div>
  <section class="inner-post">
    <div class="container">
      <div class="row">
         <div class="col-lg-9 col-sm-12 col-12">
          <div >
           <h1 style="color:rgb(4, 61, 167)">Right to Information Act & Rules</h1> 
           
           <div class="table-responsive">
            <table class="table table-bordered table-striped">
                <thead>
                <tr>
                    <th style="width:20px; font-size:large; font-weight:bolder; color: black;">SLNO.</th>
                    <th style="width:20px; font-size:large; font-weight:bolder; color: black;">Act & Rules.</th>
                    <th style="width:20px; font-size:large; font-weight:bolder; color: black;">Action</th>
                </tr>
                </thead>
                <tbody >
                <tr>
                    <td class="text-left col-md-1" style="font-size:medium; font-weight: 500;text-align:left">1</td>
                    <td style="width:20px; font-size:medium; font-weight: 500;text-align:left">
                      RTI Act, 2005 for India</td>
                    <td style="width:20px; font-size:medium; font-weight: 500;">
                    <a href="javascript:void(0);" target="_blank" href="../../../assets/images/pdfs_RTI-Act-2005_India_english.pdf"><img src="../../../assets/images/pdf_icon.png">Download </a> </td>
                </tr>
                <tr>
                    <td style=" font-size:medium; font-weight: 500;text-align:left">2</td>
                    <td style="width:20px; font-size:medium; font-weight: 500;text-align:left">RTI Rules, 2006 for WB</td>
                    <td style="width:20px; font-size:medium; font-weight: 500;">
                    <a href="javascript:void(0);" target="_blank" href="../../../assets/images/pdfs_rti_rules_WB_english.pdf"><img src="../../../assets/images/pdf_icon.png">Download </a> </td>
                </tr>	
                 <tr>
                    <td style=" font-size:medium; font-weight: 500;text-align:left">3</td>
                    <td style="width:20px; font-size:medium; font-weight: 500;text-align:left">RTI Rules, 2006 for WB Bangla</td>
                    <td style="width:20px; font-size:medium; font-weight: 500;">
                    <a href="javascript:void(0);" target="_blank" href="../../../assets/images/pdfs_RTI_WestBengal_Bengali.pdf"><img src="../../../assets/images/pdf_icon.png">Download </a> </td>
                </tr>			
                </tbody>
              </table>
          </div>
          <br>
          <h5 style="font-weight: 500; color:rgb(4, 61, 167)"><u>Appellate Authority</u></h5>
          <div class="table-responsive">
            <table class="table table-bordered table-striped">
              <thead>
                <tr>
                  <th style="width:20px; font-size:large; font-weight:bolder; color: black;">Name of the Designated Officer
                  </th>
                  <th style="width:20px; font-size:large; font-weight:bolder; color: black;">Address
                  </th>
                  <th style="width:20px; font-size:large; font-weight:bolder; color: black;">Phone No
                  </th>
              </tr>
              </thead>
                <tbody >
                <tr>
                <td style="width:20px; font-size:medium; font-weight: 500; text-align:left">Koshaldev Bandyopadhyay
                </td>
                <td style="width:20px; font-size:medium; font-weight: 500;text-align:left">Ranaghat Municipality, Ranaghat , Nadia
                </td>
                <td style="width:20px; font-size:medium; font-weight: 500;text-align:left">9851176166
                </td>   
                </tr>
              		
                </tbody>
              </table>
          </div>
          <br>
          <h5 style="font-weight: 500; color:rgb(4, 61, 167)"><u>State Public Information Officer</u></h5>
          <div class="table-responsive">
            <table class="table table-bordered table-striped">
              <thead>
                <tr>
                  <th style="width:20px; font-size:large; font-weight:bolder; color: black;">Name of the Designated Officer
                  </th>
                  <th style="width:20px; font-size:large; font-weight:bolder; color: black;">Address
                  </th>
                  <th style="width:20px; font-size:large; font-weight:bolder; color: black;">Phone No
                  </th>
              </tr>
              </thead>
                <tbody >
                <tr>
                <td style="width:20px; font-size:medium; font-weight: 500;text-align:left">Bipul Chakraborti
                </td>
                <td style="width:20px; font-size:medium; font-weight: 500;text-align:left">Ranaghat Municipality, Ranaghat , Nadia
                </td>
                <td style="width:20px; font-size:medium; font-weight: 500;text-align:left">9433312780
                </td>   
                </tr>
              		
                </tbody>
              </table>
          </div>
          <br>
          <h5 style="font-weight: 500; color:rgb(4, 61, 167)"><u>State Assistant Public Information Officers</u></h5>
          <div class="table-responsive">
            <table class="table table-bordered table-striped">
              <thead>
                <tr>
                  <th style="width:20px; font-size:large; font-weight:bolder; color: black;">Name of the Designated Officer
                  </th>
                  <th style="width:20px; font-size:large; font-weight:bolder; color: black;">Address
                  </th>
                  <th style="width:20px; font-size:large; font-weight:bolder; color: black;">Phone No
                  </th>
              </tr>
              </thead>
                <tbody >
                <tr>
                <td style="width:20px; font-size:medium; font-weight: 500;text-align:left">Shamik Majumder
                </td>
                <td style="width:20px; font-size:medium; font-weight: 500;text-align:left">Ranaghat Municipality, Ranaghat , Nadia
                </td>
                <td style="width:20px; font-size:medium; font-weight: 500;text-align:left">9333515145
                </td>   
                </tr>
              		
                </tbody>
              </table>
          </div>
         
          </div>
            
         
          
           </div>
         <!-- <div class="col-lg-3 col-sm-12 col-12">
         <div class="management-section-two">
        <h2>COVID management &amp; dead body disposal team</h2>
        <ul>
          <li><a routerLink="/notice">Notice</a></li>
          <li><a routerLink="/LockdownOrder">Lockdown Order</a></li>
                      <li><a routerLink="/covid-control-room">Covid Control Room</a></li>
                      <li><a href="https://wb.gov.in/" target="_blank ">Prachesta scheme - to outbreak of COVID-19.</a></li>
        </ul>
      </div>
    
         </div> -->
      </div>
    </div>
  </section>
