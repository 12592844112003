<app-header></app-header>
<div class="inner-background-two">
    <img src="assets/images/inner_banner.jpg">
    <div class="latest-overlay-two">
    <div class="container"><h2> Mission & Vision</h2></div>
  </div>
  </div>
  <section class="inner-post">
    <div class="container">
      <div class="row">
         <div class="col-lg-9 col-sm-12 col-12">
             <div class="vison-section">
               <h2>Mission & Vision</h2>
               <div *ngIf="vission">
                <span [innerHtml]="vission.mainContent"></span>
             
              </div>
               
              
               
             
             </div>  
         </div>
      </div>
    </div>
  </section>

      
  <!-- <div class="inner-background-two">
    <img src="assets/images/inner_banner.jpg">
    <div class="latest-overlay-two">
    <div class="container"><h2>Mission & Vision</h2></div>
  </div>
  </div> -->
  <!-- <section class="inner-post">
    <div class="container">
      <div class="row">
         <div class="col-lg-9 col-sm-12 col-12">
          <div >
          <br>
          <h4 style="font-weight: 400; color:rgb(1, 4, 10)"><u>To transform Ranaghat into a beautiful vibrant greener and healthier city to live, work and invest in.</u></h4>
        <br>
            <h4>Our mission & vision is-</h4>
            <br>
            <ol >
              <li>To provide enhanced and improved basic amenities to all residents especially the urban poor.</li>
              <li>To render Ranaghat Municipality in to a modern, self sufficient institution, capable of delivering better civic services.</li>
              <li>To utilize its vast human resources to promote faster economic growth.</li>
              <li>To see youths of the town, both male and female are engaged in gainful pursuits – employment or self employed.</li>
              </ol>
        
         
          </div>
            
         
          
           </div>
   
      </div>
    </div>
  </section> -->

