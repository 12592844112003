import { Component, OnInit } from '@angular/core';
import { NgxSpinnerService } from "ngx-spinner";
import { ApiService } from 'src/app/services/api.service';

@Component({
  selector: 'app-speech',
  templateUrl: './speech.component.html',
  styleUrls: ['./speech.component.css']
})
export class SpeechComponent implements OnInit {
  chairman:any;
  constructor(private apiService: ApiService,
    private SpinnerService: NgxSpinnerService,) { }

  ngOnInit(): void {
    this.apiService.chairman().subscribe((res:any )=> {
      this.SpinnerService.show();
       this.chairman = res.data;
       this.SpinnerService.hide();
   },
   (error:any )=> {
 
   });
   window.scroll({ 
    top: 0, 
    left: 0, 
    behavior: 'smooth' 
});

  }

}
