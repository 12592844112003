export class UserModel {
    private _userId: string = "";
    private _firstName: string = "";
    private _lastName: string = "";
    private _roleId: string = "";
    private _sidemenu:any[] = [];

    get userId(): string {
        return this._userId;
    }
    set userId(userId: string) {
        this._userId = userId;
    }

    get firstName(): string {
        return this._firstName;
    }
    set firstName(firstName: string) {
        this._firstName = firstName;
    }

    get lastName(): string {
        return this._lastName;
    }
    set lastName(lastName: string) {
        this._lastName = lastName;
    }

    get roleId(): string {
        return this._roleId;
    }
    set roleId(roleId: string) {
        this._roleId = roleId;
    }

    get sidemenu(): any[] {
        return this._sidemenu;
    }
    set sidemenu(sidemenu: any[]) {
        this._sidemenu = sidemenu;
    }
}
