import { NgModule } from '@angular/core';
import { BrowserModule } from '@angular/platform-browser';
import { HttpClientModule } from '@angular/common/http';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { ToastrModule } from 'ngx-toastr';
import { AppRoutingModule } from './app-routing.module';
import { AppComponent } from './app.component';
import { HomeComponent } from './component/home/home.component';
import { HeaderComponent } from './shared/header/header.component';
import { FooterComponent } from './shared/footer/footer.component';
import { SitemapComponent } from './shared/sitemap/sitemap.component';
import { AboutUsComponent } from './component/about-us/about-us.component';
import { AdminDashboardComponent } from './component/admin-dashboard/admin-dashboard.component';
import { AdminLoginComponent } from './component/admin-login/admin-login.component';
import { AuditComponent } from './component/audit/audit.component';
import { ContactsComponent } from './component/contacts/contacts.component';
import { GalleryInsideComponent } from './component/gallery-inside/gallery-inside.component';
import { MissionVissionComponent } from './component/mission-vission/mission-vission.component';
import { NoticeComponent } from './component/notice/notice.component';
import { OtherinfoComponent } from './component/otherinfo/otherinfo.component';
import { PhotoGalaryComponent } from './component/photo-galary/photo-galary.component';
import { RtiComponent } from './component/rti/rti.component';
import { SpeechComponent } from './component/speech/speech.component';
import { TenderArchiveComponent } from './component/tender-archive/tender-archive.component';
import { TenderCurrentComponent } from './component/tender-current/tender-current.component';
import { TownProfileComponent } from './component/town-profile/town-profile.component';
import { ViceChairmanComponent } from './component/vice-chairman/vice-chairman.component';
import { WardCouncillorComponent } from './component/ward-councillor/ward-councillor.component';
import { WordwiseinfoComponent } from './component/wordwiseinfo/wordwiseinfo.component';
import { AdminTenderComponent } from './component/admin-tender/admin-tender.component';
import { AdminNoticeComponent } from './component/admin-notice/admin-notice.component';
import { AdminNewseventComponent } from './component/admin-newsevent/admin-newsevent.component';
import { AdminPageComponent } from './component/admin-page/admin-page.component';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { NgxSpinnerModule } from "ngx-spinner";
import { AdminRecruitmentComponent } from './component/admin-recruitment/admin-recruitment.component'; 
//import { CKEditorModule } from 'ngx-ckeditor';
import { AuthGuard } from './auth.guard';
import { RecruitmentComponent } from './component/recruitment/recruitment.component';
import { LockdownOrderComponent } from './component/lockdown-order/lockdown-order.component';
import { CovidControlRoomComponent } from './component/covid-control-room/covid-control-room.component';
import { OutbreakOfCovidComponent } from './component/outbreak-of-covid/outbreak-of-covid.component';
import { NgImageSliderModule } from 'ng-image-slider';
import { AdminDownloadComponent } from './component/admin-download/admin-download.component';
import { HeadOfDeptComponent } from './component/head-of-dept/head-of-dept.component';
import { MunicipalityAtAGlanceComponent } from './component/municipality-at-a-glance/municipality-at-a-glance.component';
import { CensusComponent } from './component/census/census.component';
import { AdminGalleryComponent } from './component/admin-gallery/admin-gallery.component';
import { ChangePasswordComponent } from './component/change-password/change-password.component';
import { MyModalComponent } from './component/modal/my-modal/my-modal.component';
import { WbrtpsComponent } from './component/wbrtps/wbrtps.component';
import { MunicipalityOperationalStructureComponent } from './component/municipality-operational-structure/municipality-operational-structure.component';
import { WardwiseDetailsComponent } from './component/wardwise-details/wardwise-details.component';
import { AccBalancesheetUploadComponent } from './component/acc-balancesheet-upload/acc-balancesheet-upload.component';
import { WardCommityComponent } from './component/ward-commity/ward-commity.component';
import { SlumPopulationComponent } from './component/slum-population/slum-population.component';
import { BalancesheetComponent } from './component/balancesheet/balancesheet.component';
import { AnnualReportComponent } from './component/annual-report/annual-report.component';
import { DepartmentalwiseContactsComponent } from './component/departmentalwise-contacts/departmentalwise-contacts.component';
import { SelfHelpGrpComponent } from './component/self-help-grp/self-help-grp.component';
import { MunicipalBasicInfoComponent } from './component/municipal-basic-info/municipal-basic-info.component';
import { CtPtComponent } from './component/ct-pt/ct-pt.component';
import { AdminWardComponent } from './component/admin-ward/admin-ward.component';
import { DatePipe } from '@angular/common';
import { CKEditorModule } from '@ckeditor/ckeditor5-angular';
import { PagerService } from './services/pager.service';
import { NgxPaginationModule } from 'ngx-pagination';
import { DownloadComponent } from './component/download/download.component';
import { AdminInternalauditComponent } from './component/admin-internalaudit/admin-internalaudit.component';
import { ChangeProfileComponent } from './component/change-profile/change-profile.component';
import { AdminHeaderComponent } from './component/admin-header/admin-header.component';




@NgModule({
  declarations: [
    AppComponent,
    HomeComponent,
    HeaderComponent,
    FooterComponent,
    SitemapComponent,
    AboutUsComponent,
    AdminDashboardComponent,
    AdminLoginComponent,
    AuditComponent,
    ContactsComponent,
    GalleryInsideComponent,
    MissionVissionComponent,
    NoticeComponent,
    OtherinfoComponent,
    PhotoGalaryComponent,
    RtiComponent,
    SpeechComponent,
    TenderArchiveComponent,
    TenderCurrentComponent,
    TownProfileComponent,
    ViceChairmanComponent,
    WardCouncillorComponent,
    WordwiseinfoComponent,
    AdminTenderComponent,
    AdminNoticeComponent,
    AdminNewseventComponent,
    AdminPageComponent,
    AdminRecruitmentComponent,
    RecruitmentComponent,
    LockdownOrderComponent,
    CovidControlRoomComponent,
    OutbreakOfCovidComponent,
    AdminDownloadComponent, 
    HeadOfDeptComponent,
    MunicipalityAtAGlanceComponent,
    CensusComponent,
    AdminGalleryComponent,
    ChangePasswordComponent,
    MyModalComponent,
    WbrtpsComponent,
    MunicipalityOperationalStructureComponent,
    WardwiseDetailsComponent,
    AccBalancesheetUploadComponent,
    WardCommityComponent,
    SlumPopulationComponent,
    BalancesheetComponent,
    AnnualReportComponent,
    DepartmentalwiseContactsComponent,
    SelfHelpGrpComponent,
    MunicipalBasicInfoComponent,
    CtPtComponent,
    AdminWardComponent,
    DownloadComponent,
    AdminInternalauditComponent,
    ChangeProfileComponent,
    AdminHeaderComponent
  ],
  imports: [
    BrowserModule,
    AppRoutingModule,
    FormsModule,
    ReactiveFormsModule,
    HttpClientModule,
    CKEditorModule,
    NgxPaginationModule,
    BrowserAnimationsModule,
    ToastrModule.forRoot({
      closeButton: true,
      timeOut: 2000, // 2 seconds
      progressBar: true,
      enableHtml: true
    }),
    NgxSpinnerModule,
    CKEditorModule,
    NgImageSliderModule,
  ],
  providers: [
    DatePipe,
    AuthGuard,
    PagerService
  ],
  bootstrap: [AppComponent]
})
export class AppModule { }
