<app-header></app-header>
<div class="main-wapper">
  <div class="wapper-body">
    <div class="row">
      <div class="col-lg-12 col-md-12 col-sm-12 col-xs-12 col-mob-12">
        <div class="top-panel">
          <form>
            <div class="form-group">
              <input type="text" name="text" (keyup)="searchTable($event)" placeholder="Search files">
              <a href="#" class="src-icon"><img src="assets/assetsAdmin/img/loupe.png"></a>
            </div>
          </form>
          <a href="javascript:void(0);" class="add-btn"><i class="fa fa-plus" (click)="openPopupAdd()" aria-hidden="true"
              title="Add Page"></i></a>
        </div>
        <div class="bottom-panel">
          <div class="table-responsive">
            <table class="table table-striped jambo_table">
              <thead>
                <tr style="color: black;">
                  <th class="column-title">Sl No</th>
                  <th class="column-title">Subject</th>
                  <th class="column-title">Title</th>
                  <th class="column-title">Upload Date</th>
                  <th class="column-title">File Name</th>
                  <th class="column-title">Action</th>
                </tr>
              </thead>
              <tbody *ngFor="let item of downloadList;let i=index">
                <tr>
                  <td>{{(pager.startIndex -1) + i + 2}}</td>
                  <td>{{item.subject}}</td>
                  <td>{{item.title}}</td>
                  <td>{{item.uploadDate}}</td>
                  <td>{{item.fileName}}</td>

                  <td class="last">
                    <div class="action-button">
                      <!-- <a href="javascript:void(0);" class="plus-btn"><i class="fa fa-plus" aria-hidden="true"></i></a> -->
                      <a href="javascript:void(0);" class="edit-btn" (click)="openPopupEdit(item.id)"><i class="fa fa-edit" aria-hidden="true"
                          title="Edit"></i></a>
                          <!-- <a class="delete-btn"><i class="fa fa-trash" aria-hidden="true" (click)="Delete(item.id, item.fileName)"></i></a> -->
                      <!-- <a href="javascript:void(0);" class="delete-btn"><i class="fa fa-times" aria-hidden="true"></i></a> -->
                    </div>
                  </td>
                </tr>

              </tbody>
            </table>
          </div>
    <!-- #region  Pagination-->
      <div class="row">
        <div class="col-md-5">
          <div class="dataTables_info" *ngIf="totalCount>0">Showing {{pager.startIndex +1}} to {{pager.endIndex+1}}
            of {{totalCount}} entries</div>
        </div>

        <div class="col-md-7">
          <div class="dataTables_paginate paging_simple_numbers">
            <ul *ngIf="pager.pages && pager.pages.length" class="pagination">
              <li class="paginate_button previous disabled" ng-disabled="true"
                [ngClass]="{'disabled':pager.currentPage === 1}">
                <a aria-controls="datatable-fixed-header" (click)="getallDownload(1)">First</a>
              </li>
              <li class="paginate_button previous disabled" [ngClass]="{disabled:pager.currentPage === 1}">
                <a aria-controls="datatable-fixed-header"
                  (click)="getallDownload(pager.currentPage - 1)">Previous</a>
              </li>
              <li class="paginate_button previous disabled" *ngFor="let page of pager.pages"
                [ngClass]="{active:pager.currentPage === page}">
                <a aria-controls="datatable-fixed-header" (click)="getallDownload(page)">{{page}}</a>
              </li>
              <li class="paginate_button previous disabled"
                [ngClass]="{disabled:pager.currentPage === pager.totalPages}">
                <a aria-controls="datatable-fixed-header"
                  (click)="getallDownload(pager.currentPage + 1)">Next</a>
              </li>
              <li class="paginate_button previous disabled"
                [ngClass]="{disabled:pager.currentPage === pager.totalPages}">
                <a aria-controls="datatable-fixed-header" (click)="getallDownload(pager.totalPages)">Last</a>
              </li>
            </ul>
          </div>
        </div>
      </div>
    <!-- #endregion -->  
        </div>
      </div>
    </div>
  </div>
</div>
<ngx-spinner bdColor="rgba(51, 51, 51, 0.8)" size="default" type="ball-spin-clockwise">
  <p style="color: white">Please Wait... </p>
</ngx-spinner>

   <!---pop-new-poroject-->
   <div class="new-pop" class="modal" [ngStyle]="{'display':displayStyleAdd}">
    <div class="new-pop-body" ><a href="javascript:void(0);"  (click)="closePopupAdd()" class="cls-new-pop"><img
          src="assets/assetsAdmin/img/close.png" /></a>
      <h4>Add Document</h4>
      <form id="contact-form" [formGroup]="adminDownloadForm">
        <div class="controls">
          <div class="row">
            <div class="col-md-4">
              <div class="form-group">
                <label for="form_name">Subject *</label>
                <input id="form_name" type="text" class="form-control" formControlName="subject">
                  <div *ngIf="f1.subject.invalid && (f1.subject.dirty || f1.subject.touched)" >
                    <div *ngIf="f1.subject.errors.required" style="color: red;">
                    Please enter subject
                  </div>
                </div>
              </div>
            </div> 
            <div class="col-md-4">
              <div class="form-group">
                <label for="form_route">Title *</label>
                <input id="form_route" type="text" name="title" class="form-control" formControlName="title">
                  <div *ngIf="f1.title.invalid && (f1.title.dirty || f1.title.touched)" >
                    <div *ngIf="f1.title.errors.required" style="color: red;">
                    Please enter title
                  </div>
                </div>
              </div>
            </div>
            <div class="col-md-4">
              <div class="form-group">
                <label for="form_cption">Upload Date</label>
                <input id="form_cption" type="date" name="publishingdate" formControlName="uploaddate" class="form-control">
                <div *ngIf="f1.uploaddate.invalid && (f1.uploaddate.dirty || f1.uploaddate.touched)">
                  <div *ngIf="f1.uploaddate.errors.required" style="color: red;">
                  Please enter upload date
                </div>
              </div>
              </div>
            </div>            
          </div>
          <div class="row">
            <div class="col-md-4">
              <div class="form-group">
                <label for="form_name">File *</label>
                <input type="file"  (change)="onSelectFile($event)"  formControlName="fileRecruitment" class="file">
                <font color="sky blue">(Maximum 3 MB)</font>
                <div *ngIf="f1.fileRecruitment.invalid && (f1.fileRecruitment.dirty || f1.fileRecruitment.touched)" >
                  <div *ngIf="f1.fileRecruitment.errors.required" style="color: red;">
                  Please enter fileRecruitment
                </div>
              </div>
              </div>
            </div>
            <div class="col-md-4">
              <div class="form-group">
                <label>Upload Type</label>
                <select id="type" name="uploadtype" formControlName="uploadtype" class="form-control">
                  <option value="Forms">Forms</option>
                  <option value="Circular">Circular</option>
                  <option value="Notice">Notice</option>
                </select>
                <div *ngIf="f1.uploadtype.invalid && (f1.uploadtype.dirty || f1.uploadtype.touched)" class="alert">
                  <div *ngIf="f1.uploadtype.errors.required" style="color: red;">
                    Please select upload type
                  </div>
                </div>
              </div>
            </div>
            <div class="col-md-4">
              <div class="form-group">
                <label >Is Published</label>&nbsp;
              <input type="checkbox" id="published" name="published" formControlName="ispublished"
                style="width:20px;height:20px;">
                <div *ngIf="f1.ispublished.invalid && (f1.ispublished.dirty || f1.ispublished.touched)" class="alert">
                  <div *ngIf="f1.ispublished.errors.required" style="color: red;">
                  Please enter ispublished
                </div>
              </div>
              </div>
            </div>            
          </div>          
            
          <div class="row">
            <div class="col-md-12">
              <div class="btn-grp">
                <button type="button" class="done-btn " (click)="Submit()">Submit</button>
                <button type="button" class="cancl-btn" (click)="closePopupAdd()" >Cancel</button> 
  
              </div>
            </div>
          </div>
        </div>
      </form>
    </div>
  </div>
  <!---pop-Edit Pge-->
<div class="edit-pop" class="modal" [ngStyle]="{'display':displayStyleEdit}">
    <div class="new-pop-body"><a href="javascript:void(0);" (click)="closePopupEdit()" class="cls-edit-pop"><img src="./assets/img/close.png" /></a>
      <h4>Edit Document</h4>
      <!-- <p>Set frame rate, size resolution</p> -->
      <form id="contact-form" role="form" [formGroup]="adminDownloadForm">
        <div class="controls">
          <div class="row">
            <div class="col-md-4">
              <div class="form-group">
                <label for="form_name">Subject *</label>
                <input id="form_name" type="text" class="form-control" formControlName="subject">
                  <div *ngIf="f1.subject.invalid && (f1.subject.dirty || f1.subject.touched)" >
                    <div *ngIf="f1.subject.errors.required" style="color: red;">
                    Please enter subject
                  </div>
                </div>
              </div>
            </div>
            <div class="col-md-4">
              <div class="form-group">
                <label for="form_route">Title *</label>
                <input id="form_route" type="text" name="memono" class="form-control" formControlName="title">
                  <div *ngIf="f1.title.invalid && (f1.title.dirty || f1.title.touched)" >
                    <div *ngIf="f1.title.errors.required" style="color: red;">
                    Please enter title
                  </div>
                </div>
              </div>
            </div> 
            <div class="col-md-4">
              <div class="form-group">
                <label for="form_cption">Upload Date</label>
                <input id="form_cption" type="date" name="uploaddate" formControlName="uploaddate" class="form-control">
                <div *ngIf="f1.uploaddate.invalid && (f1.uploaddate.dirty || f1.uploaddate.touched)">
                  <div *ngIf="f1.uploaddate.errors.required" style="color: red;">
                  Please enter upload date
                </div>
              </div>
              </div>
            </div>             
          </div>
          <div class="row">
            <div class="col-md-4">
              <div class="form-group">
                <label for="form_name">File *</label>
                <input type="file"  (change)="onSelectFile($event)"  formControlName="fileRecruitment" class="file">
                <font color="sky blue">(Maximum 3 MB)</font>
                <div *ngIf="f1.fileRecruitment.invalid && (f1.fileRecruitment.dirty || f1.fileRecruitment.touched)" >
                  <div *ngIf="f1.fileRecruitment.errors.required" style="color: red;">
                  Please enter fileRecruitment
                </div>
              </div>
              <div class="form-group" *ngIf="preview == true">
                <a class="btn btn-primary" (click)="ShowDoc()" target="_blank"
                  style="margin-top: 25px;">Preview Uploaded File</a>
              </div>
              </div>
            </div>
            <div class="col-md-4">
              <div class="form-group">
                <label>Upload Type</label>
                <select id="type" name="uploadtype" formControlName="uploadtype" class="form-control">
                  <option value="Forms">Forms</option>
                  <option value="Circular">Circular</option>
                  <option value="Notice">Notice</option>
                </select>
                <div *ngIf="f1.uploadtype.invalid && (f1.uploadtype.dirty || f1.uploadtype.touched)" class="alert">
                  <div *ngIf="f1.uploadtype.errors.required" style="color: red;">
                    Please select upload type
                  </div>
                </div>
              </div>
            </div>
            <div class="col-md-4">
              <div class="form-group">
                <label >Is Published</label>&nbsp;
              <input type="checkbox" id="published" name="published" formControlName="ispublished"
                style="width:20px;height:20px;">
                <div *ngIf="f1.ispublished.invalid && (f1.ispublished.dirty || f1.ispublished.touched)" class="alert">
                  <div *ngIf="f1.ispublished.errors.required" style="color: red;">
                  Please enter ispublished
                </div>
              </div>
              </div>
            </div>            
          </div>        
            
          <div class="row">
          <div class="col-md-12">
            <div class="btn-grp">
              <button type="button" class="done-btn" (click)="Update()">Update</button>
              <button type="button" class="cancl-btn" (click)="closePopupEdit()">Cancel</button>
            </div>
          </div>
          </div>
        </div>
      </form>
    </div>
  </div>
  