import { Component, OnInit } from '@angular/core';
import { NgxSpinnerService } from "ngx-spinner";
import { ApiService } from 'src/app/services/api.service'

@Component({
  selector: 'app-contacts',
  templateUrl: './contacts.component.html',
  styleUrls: ['./contacts.component.css']
})
export class ContactsComponent implements OnInit {
  contact: any;
  constructor(private apiService: ApiService,
    private SpinnerService: NgxSpinnerService,) { }

  ngOnInit(): void {
    this.apiService.contactUs().subscribe((res:any )=> {
      console.log(res);
      this.SpinnerService.show();
      this.contact = res.data;
      this.SpinnerService.hide();
  },
  (error:any )=> {
    this.SpinnerService.hide();
  });
  window.scroll({ 
    top: 0, 
    left: 0, 
    behavior: 'smooth' 
});
  }
   

}
