<app-header></app-header>
<div class="inner-background-two">
    <img src="assets/images/inner_banner.jpg">
    <div class="latest-overlay-two">
    <div class="container"><h2>Other Information</h2></div>
  </div>
  </div>
  <section class="inner-post">
    <div class="container">
      <div class="row">
         <div class="col-lg-9 col-sm-12 col-12">
          <div *ngIf="otherInfo">
            <span [innerHtml]="otherInfo.mainContent"></span>
          </div>
          
           </div>
         <!-- <div class="col-lg-3 col-sm-12 col-12">
         <div class="management-section-two">
        <h2>COVID management &amp; dead body disposal team</h2>
        <ul>
          <li><a routerLink="/notice">Notice</a></li>
          <li><a routerLink="/LockdownOrder">Lockdown Order</a></li>
          <li><a routerLink="/covid-control-room">Covid Control Room</a></li>
          <li><a href="https://wb.gov.in/" target="_blank ">Prachesta scheme - to outbreak of COVID-19.</a></li>
        </ul>
      </div>
   
         </div> -->
      </div>
    </div>
  </section>
