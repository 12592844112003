<app-header></app-header>
<div class="main-wapper">
  <div class="wapper-body">
    <div class="row">
      <div class="col-lg-12 col-md-12 col-sm-12 col-xs-12 col-mob-12">
        <div class="top-panel">
          <form>
            <div class="form-group">
              <input type="text" name="text" (keyup)="searchTable($event)" placeholder="Search files">
              <a href="#" class="src-icon"><img src="assets/assetsAdmin/img/loupe.png"></a>
            </div>
          </form>
          <a href="javascript:void(0);" class="add-btn" (click)="openPopupAdd()"><i class="fa fa-plus"
              aria-hidden="true" title="Add Page"></i></a>
        </div>
        <div class="bottom-panel">
          <div class="table-responsive">
            <table class="table table-striped jambo_table">
              <thead>
                <tr style="color: black;">
                  <th class="column-title">Sl No</th>
                  <th class="column-title">Name</th>
                  <th class="column-title">MainCaption1</th>
                  <th class="column-title">MainCaption2</th>
                  <th class="column-title">Action</th>
                </tr>
              </thead>
              <tbody *ngFor="let item of getallPages; let i = index">
                <td>{{(pager.startIndex -1) + i + 2}}</td>
                <td>{{item.name}}</td>
                <!-- <td>{{item.createdOn | date: 'dd-MM-yyyy': 'desc'}}</td> -->
                <td>{{item.mainCaption1}}</td>
                <td>{{item.mainCaption2}}</td>
                <!-- <td><a href="javascript:void(0);"><i class="fa fa-file-download"></i></a></td> -->

                <td class="last">
                  <div class="action-button">
                    <!-- <a class="plus-btn"><i class="fa fa-plus" aria-hidden="true" (click)="openPopupPlus()"></i></a>                     -->
                    <a href="javascript:void(0);" class="edit-btn"><i class="fa fa-edit" aria-hidden="true"
                        (click)="openPopupEdit(item.id)" title="Edit"></i></a>
                    <!-- <a class="delete-btn"><i class="fa fa-times" aria-hidden="true"></i></a> -->
                    <!-- <a class="delete-btn"><i class="fa fa-trash" aria-hidden="true" (click)="Delete(item.id, item.fileName)"></i></a> -->
                  </div>
                </td>
              </tbody>
            </table>
          </div>
          
          <!-- <div class="row" style="float:right">
            <div class="pagination-nav" style="float:inline-end; padding: 10px 20px 20px 10px;">
                <pagination-controls (pageChange)="pageChangeEvent($event)" style="float: right; text-align: right;"></pagination-controls>  
            </div>
          </div>  -->

    <!-- #region  Pagination-->
      <div class="row">
        <div class="col-md-5">
          <div class="dataTables_info" *ngIf="totalCount>0">Showing {{pager.startIndex +1}} to {{pager.endIndex+1}}
            of {{totalCount}} entries</div>
        </div>

        <div class="col-md-7">
          <div class="dataTables_paginate paging_simple_numbers">
            <ul *ngIf="pager.pages && pager.pages.length" class="pagination">
              <li class="paginate_button previous disabled" ng-disabled="true"
                [ngClass]="{'disabled':pager.currentPage === 1}">
                <a aria-controls="datatable-fixed-header" (click)="getallPage(1)">First</a>
              </li>
              <li class="paginate_button previous disabled" [ngClass]="{disabled:pager.currentPage === 1}">
                <a aria-controls="datatable-fixed-header"
                  (click)="getallPage(pager.currentPage - 1)">Previous</a>
              </li>
              <li class="paginate_button previous disabled" *ngFor="let page of pager.pages"
                [ngClass]="{active:pager.currentPage === page}">
                <a aria-controls="datatable-fixed-header" (click)="getallPage(page)">{{page}}</a>
              </li>
              <li class="paginate_button previous disabled"
                [ngClass]="{disabled:pager.currentPage === pager.totalPages}">
                <a aria-controls="datatable-fixed-header"
                  (click)="getallPage(pager.currentPage + 1)">Next</a>
              </li>
              <li class="paginate_button previous disabled"
                [ngClass]="{disabled:pager.currentPage === pager.totalPages}">
                <a aria-controls="datatable-fixed-header" (click)="getallPage(pager.totalPages)">Last</a>
              </li>
            </ul>
          </div>
        </div>
      </div>
    <!-- #endregion -->
          
          <!-- <div class="row" style="float:right">
            <div class="pagination-nav" style="float:inline-end; padding: 10px 20px 20px 10px;">
              <button class="btn btn-outline-primary pgn" [disabled]="pageNo <= 0" (click)="moveToPage('first')">|
                << <span></span>
              </button>
              <button class="btn btn-outline-primary pgn" [disabled]="pageNo <= 0" (click)="moveToPage('previous')">
                < <span></span>
              </button>
              <button class="btn btn-outline-primary pgn" *ngFor="let pageLink of paginationLinks"
                (click)="moveToPage(pageLink)">
                <span [class.selectedPaginationLink]="(pageLink === (pageNo + 1))">{{pageLink}}</span></button>
              <button class="btn btn-outline-primary pgn" [disabled]="pageNo >= maxPageNo"
                (click)="moveToPage('next')">>
                <span></span></button>
              <button class="btn btn-outline-primary pgn" [disabled]="pageNo >= maxPageNo"
                (click)="moveToPage('last')">>>|
                <span></span></button>
            </div>
          </div> -->
          

        </div>        
      </div>
    </div>
  </div>
</div>

<ngx-spinner bdColor="rgba(51, 51, 51, 0.8)" size="default" type="ball-spin-clockwise">
  <p style="color: white">Please Wait... </p>
</ngx-spinner>

<!---pop-new-poroject-->
<div class="new-pop" class="modal" [ngStyle]="{'display':displayStyleAdd}">
  <div class="new-pop-body"><a href="javascript:void(0);" (click)="closePopupAdd()" class="cls-new-pop"><img
        src="assets/assetsAdmin/img/close.png" /></a>
    <h4>Add Pages</h4>
    <form id="contact-form" role="form" [formGroup]="adminPageForm">
      <div class="controls">
        <div class="row">
          <div class="col-md-4">
            <div class="form-group">
              <label for="form_name">Name *</label>
              <input id="form_name" type="text" name="name" class="form-control" formControlName="name"
                required="required" data-error="Name is required.">
              <div *ngIf="f1.name.invalid && (f1.name.dirty || f1.name.touched)" class="alert">
                <div *ngIf="f1.name.errors.required" style="color: red;">
                  Please enter name
                </div>
              </div>
            </div>
          </div>
          <div class="col-md-4">
            <div class="form-group">
              <label for="form_route">Route *</label>
              <input id="form_route" type="text" name="route" class="form-control" formControlName="route"
                required="required" data-error="Route is required.">
              <div *ngIf="f1.route.invalid && (f1.route.dirty || f1.route.touched)" class="alert">
                <div *ngIf="f1.route.errors.required" style="color: red;">
                  Please enter route
                </div>
              </div>
            </div>
          </div>
        </div>
        <div class="row">
          <div class="col-md-4">
            <div class="form-group">
              <label for="form_cption">Main Caption1</label>
              <input id="form_cption" type="text" name="maincaption1" formControlName="maincaption1"
                class="form-control">
              <div *ngIf="f1.maincaption1.invalid && (f1.maincaption1.dirty || f1.maincaption1.touched)" class="alert">
                <div *ngIf="f1.maincaption1.errors.required" style="color: red;">
                  Please enter maincaption1
                </div>
              </div>
            </div>
          </div>
          <div class="col-md-4">
            <div class="form-group">
              <label for="form_email">Main Caption2</label>
              <input id="form_email" type="text" name="maincaption2" formControlName="maincaption2"
                class="form-control">
              <div *ngIf="f1.maincaption2.invalid && (f1.maincaption2.dirty || f1.maincaption2.touched)" class="alert">
                <div *ngIf="f1.maincaption2.errors.required" style="color: red;">
                  Please enter maincaption2
                </div>
              </div>
            </div>
          </div>
          <div class="col-md-4">
            <div class="form-group">
              <label for="form_name">File </label>
              <input type="file" (change)="onSelectFile($event)" class="file">
              <font color="sky blue">(Maximum 3 MB)</font>
            </div>
          </div>
        </div>
        <!-- <div class="row">
          <div class="col-md-6">
            <div class="form-group">
              <label for="form_name">Maincontent</label>
              <input id="form_name" type="text" size="25" name="maincontent" formControlName="maincontent"
                class="form-control">
              <div *ngIf="f1.maincontent.invalid && (f1.maincaption2.dirty || f1.maincontent.touched)" class="alert">
                <div *ngIf="f1.maincontent.errors.required" style="color: red;">
                  Please enter maincontent
                </div>
              </div>
            </div>
          </div>
        </div> -->
        <div class="row">
          <div class="col-md-12">
            <div class="form-group">
              <label for="form_name">Main Content</label>
              <textarea name="maincontent" formControlName="maincontent" class="form-control" rows="6">
              </textarea>              
            </div>
          </div>
        </div>
        <div class="row">
          <div class="col-md-12">
            <div class="btn-grp">
              <button type="button" class="done-btn " (click)="pageSave()">Submit</button>
              <button type="button" class="cancl-btn" (click)="closePopupAdd()">Cancel</button>

            </div>
          </div>
        </div>
      </div>
    </form>
  </div>
</div>

<!---pop-plus Pge-->
<div class="plus-pop" class="modal" [ngStyle]="{'display':displayStylePlus}">
  <div class="new-pop-body"><a href="javascript:void(0);" (click)="closePopupPlus()" class="cls-plus-pop"><img
        src="assets/assetsAdmin/img/close.png" /></a>
    <h4>Upload Files</h4>
    <!-- <p>Set frame rate, size resolution</p> -->
    <form id="contact-form" role="form">
      <div class="controls">
        <div class="row">
          <div class="col-md-4">
            <div class="form-group">
              <label for="form_name">Subject *</label>
              <input id="form_name" type="text" name="name" class="form-control" placeholder="Please enter Subject *"
                required="required" data-error="Subject is required.">
              <small class="required">Subject Is Required</small>
            </div>
          </div>
        </div>



        <div class="row">
          <div class="col-md-12">
            <div class="btn-grp">
              <button type="button" class="done-btn ">Upload</button>
              <button type="button" class="cancl-btn" (click)="closePopupPlus()">Cancel</button>
            </div>
          </div>
        </div>
      </div>
    </form>
  </div>
</div>

<!---pop-Edit Pge-->
<div class="edit-pop" class="modal" [ngStyle]="{'display':displayStyleEdit}">
  <div class="new-pop-body"><a (click)="closePopupEdit()" class="cls-edit-pop">
      <img src="assets/assetsAdmin/img/close.png" /></a>
    <h4>Edit Page</h4>
    <form id="contact-form" role="form" [formGroup]="adminPageForm">
      <div class="controls">
        <div class="row">
          <div class="col-md-4">
            <div class="form-group">
              <label for="form_name">Name *</label>
              <input id="form_name" type="text" name="name" class="form-control" formControlName="name"
                required="required" data-error="Name is required.">
              <div *ngIf="f1.name.invalid && (f1.name.dirty || f1.name.touched)" class="alert">
                <div *ngIf="f1.name.errors.required" style="color: red;">
                  Please enter name
                </div>
              </div>
            </div>
          </div>
          <div class="col-md-4">
            <div class="form-group">
              <label for="form_route">Route *</label>
              <input id="form_route" type="text" name="route" class="form-control" formControlName="route"
                required="required" data-error="Route is required.">
              <div *ngIf="f1.route.invalid && (f1.route.dirty || f1.route.touched)" class="alert">
                <div *ngIf="f1.route.errors.required" style="color: red;">
                  Please enter route
                </div>
              </div>
            </div>
          </div>
        </div>
        <div class="row">
          <div class="col-md-4">
            <div class="form-group">
              <label for="form_cption">Main Caption1</label>
              <input id="form_cption" type="text" name="maincaption1" formControlName="maincaption1"
                class="form-control">
              <div *ngIf="f1.maincaption1.invalid && (f1.maincaption1.dirty || f1.maincaption1.touched)" class="alert">
                <div *ngIf="f1.maincaption1.errors.required" style="color: red;">
                  Please enter maincaption1
                </div>
              </div>
            </div>
          </div>
          <div class="col-md-4">
            <div class="form-group">
              <label for="form_email">Main Caption2</label>
              <input id="form_email" type="text" name="maincaption2" formControlName="maincaption2"
                class="form-control">
              <div *ngIf="f1.maincaption2.invalid && (f1.maincaption2.dirty || f1.maincaption2.touched)" class="alert">
                <div *ngIf="f1.maincaption2.errors.required" style="color: red;">
                  Please enter maincaption2
                </div>
              </div>
            </div>
          </div>
          <div class="col-md-4">
            <div class="form-group">
              <label for="form_name">MainImage *</label>
              <input type="file" (change)="onSelectFile($event)" class="file">
              <font color="sky blue">(Maximum 3 MB)</font>
              <!-- <div *ngIf="f1.filePage.invalid && (f1.filePage.dirty || f1.filePage.touched)" class="alert">
                <div *ngIf="f1.filePage.errors.required" style="color: red;">
                Please enter filePage
              </div>
            </div> -->
            </div>
          </div>
        </div>
        <div class="row">
          <div class="col-md-12">
            <div class="form-group">
              <label for="form_name">Main Content</label>
              <textarea name="maincontent" formControlName="maincontent" class="form-control" rows="6">
              </textarea>              
            </div>
          </div>
        </div>
      </div>

      <!-- <div class="row">
        <div class="col-md-6">
          <div class="form-group">
            <label for="form_name">Main Content</label>
            <ck-editor name="maincontent" formControlName="maincontent" skin="moono-lisa" language="en"
              class="form-control full-width" (ready)="onCKEditorReady($event)">
            </ck-editor>
          </div>
        </div>        
      </div>
      <div>
        <ckeditor data="<p>Hello, world!</p>"></ckeditor>
      </div> --> 
      <div class="row">
        <div class="col-md-12">
          <div class="btn-grp">
            <button type="button" class="done-btn " (click)="Update()">Update</button>
            <button type="button" class="cancl-btn" (click)="closePopupEdit()">Cancel</button>

          </div>
        </div>
      </div>
      <!-- </div> -->
      <!-- </form> -->
      <!-- </div> -->
      <!-- </div> -->