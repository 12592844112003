<app-header></app-header>
  <section class="banner-deskstop">
    <div class="row">
      <div class="col-lg-9 col-sm-12 col-12">
        <div class="slider_container">
          <div class="bd-example">
            <div id="carouselExampleCaptions" class="carousel slide" data-ride="carousel">
              <ol class="carousel-indicators">
                <li data-target="#carouselExampleCaptions" data-slide-to="0" class="active"></li>
                <li data-target="#carouselExampleCaptions" data-slide-to="1"></li>
              </ol>
              <div class="carousel-inner">
                <div class="carousel-item active">
                  <img src="./../../../assets/images/banner9.jpg" alt="" title="" />
                  <!--div class="carousel-caption d-md-block">
				  <h5><strong>First slide label</strong></h5>
				  <p>is simply dummy text of the printing and typesetting industry.</p>
				</div-->
                </div>
                <div class="carousel-item">
                  <img src="./../../../assets/images/banner2-edit.jpg" alt="" title="" />
                  <!--div class="carousel-caption d-md-block">
				  <h5><strong>Second slide label</strong></h5>
				  <p>is simply dummy text of the printing and typesetting industry.</p>
				</div-->
                </div>
                <div class="carousel-item">
                  <img src="./../../../assets/images/banner1.jpeg" alt="" title="" />
                  <!--div class="carousel-caption d-md-block">
				  <h5><strong>Second slide label</strong></h5>
				  <p>is simply dummy text of the printing and typesetting industry.</p>
				</div-->
                </div>
                <div class="carousel-item">
                  <img src="./../../../assets/images/banner5.jpg" alt="" title="" />
                  <!--div class="carousel-caption d-md-block">
				  <h5><strong>Second slide label</strong></h5>
				  <p>is simply dummy text of the printing and typesetting industry.</p>
				</div-->
                </div>
                <div class="carousel-item">
                  <img src="./../../../assets/images/banner6.jpg" alt="" title="" />
                  <!--div class="carousel-caption d-md-block">
				  <h5><strong>Second slide label</strong></h5>
				  <p>is simply dummy text of the printing and typesetting industry.</p>
				</div-->
                </div>
                <div class="carousel-item">
                  <img src="./../../../assets/images/banner7.jpg" alt="" title="" />
                  <!--div class="carousel-caption d-md-block">
				  <h5><strong>Second slide label</strong></h5>
				  <p>is simply dummy text of the printing and typesetting industry.</p>
				</div-->
                </div>

              </div>
              <a class="carousel-control-prev" href="#carouselExampleCaptions" role="button" data-slide="prev">
                <span class="carousel-control-prev-icon" aria-hidden="true"></span>
                <span class="sr-only">Previous</span>
              </a>
              <a class="carousel-control-next" href="#carouselExampleCaptions" role="button" data-slide="next">
                <span class="carousel-control-next-icon" aria-hidden="true"></span>
                <span class="sr-only">Next</span>
              </a>
            </div>
          </div>
        </div>
      </div>
      <div class="col-lg-3 col-sm-12 col-12 rightpannel">
        <div class="banner-nav color1">
          <i class="fa fa-certificate" aria-hidden="true"></i> <a href="https://wburbanservices.org/" title="e-Mutation"
            target="_blank">e-Mutation</a>
        </div>
        <div class="banner-nav color2">
          <i class="fa fa-calendar" aria-hidden="true"></i> <a href="http://holdingtax.co.in/" title="e-Mutation"
            target="_blank"> Online Property Tax</a>
        </div>
        <div class="banner-nav color3">
          <i class="fa fa-trash" aria-hidden="true"></i> 
          <a  href="https://edistrict.wb.gov.in/PACE/login.do" title="e-Mutation" target="_blank" >Online Trade License</a>
        </div>
        <div class="banner-nav color1">
          <i class="fa fa-user-circle" aria-hidden="true"></i> 
          <a href="../../../assets/images/LC-Certificate.pdf"  title="Life Certificate"
            target="_blank">Life Certificate</a>
        </div>
        <div class="banner-nav color2">
          <i class="fa fa-home" aria-hidden="true"></i> 
          <a href="https://pis.ranaghatmunicipality.in/collection/login.aspx" title="Solid Waste Management" target="_blank">Solid Waste Management</a>
        </div>
        <div class="banner-nav color3">
          <i class="fa fa-cloud" aria-hidden="true"></i>
           <a href="javascript:void(0);" id="edit-popup"  title="Booking" data-toggle="modal" data-target="#bookingModal">All Types Municipality Booking</a>
           
         
        
          </div>
        <div class="banner-nav color4">
          <i class="fa fa-id-card" aria-hidden="true"></i> 
          <a  title="Certificate Issue" target="_blank">Certificate Issue</a>
        </div>
     
      
      </div>
    </div>

  </section>

  <!-- END NAVBAR -->
  <div class="modal fade bs-example-modal-new" id="bookingModal" role="dialog" aria-labelledby="myLargeModalLabel"
  aria-hidden="true">
  <div class="modal-dialog modal-dialog-centered modal-xl" role="document">
    <div class="modal-content">
      <div class="modal-body">
        <button type="button" #closebutton class="close" data-dismiss="modal">&times;</button>
        <div class="body-message">
          <div class="head-panel">
            <h5>Ranaghat Municipality - Online Booking</h5>
          </div>
          <table class="table table-striped table-responsive" style="margin-top: 30px;">
            <thead>
              <tr>
                <th style="width:604px; font-size:medium; font-weight:bolder; color: black;">Name</th>
                <th style="width:404px; font-size:medium; font-weight:bolder; color: black;">URL</th>
              </tr>
            </thead>
            <tbody>
              <tr>
                <td style=" font-size:medium; font-weight: 500;text-align:left">RANAGHAT MUNICIPALITY ADVERTISEMENT AND
                  HOSPITAL</td>
                <td style=" font-size:medium; font-weight: 500;text-align:left;color: blue;">
                  <a href="https://advertise.ranaghatmunicipality.in/"
                    target="_blank">https://advertise.ranaghatmunicipality.in/</a>
                </td>
              </tr>
              <tr>
                <td style=" font-size:medium; font-weight: 500;text-align:left">RANAGHAT MUNICIPALITY SOLID WASTE </td>
                <td style=" font-size:medium; font-weight: 500;text-align:left;color: blue;">
                  <a href="https://pis.ranaghatmunicipality.in/collection/login.aspx"
                    target="_blank">https://pis.ranaghatmunicipality.in/collection/login.aspx</a>
                </td>
              </tr>
              <tr>
                <td style=" font-size:medium; font-weight: 500;text-align:left">RANAGHAT MUNICIPALITY A/C COMMUNITY HALL
                </td>
                <td style=" font-size:medium; font-weight: 500;text-align:left;color: blue;">
                  <a href="booking.ranaghatmunicipality.in" target="_blank">booking.ranaghatmunicipality.in</a>
                </td>
              </tr>
              <tr>
                <td style=" font-size:medium; font-weight: 500;text-align:left">পৌর জনসংযোগ</td>
                <td style=" font-size:medium; font-weight: 500;text-align:left;color: blue;">
                  <a href="https://play.google.com/store/apps/details?id=pj.info.rana"
                    target="_blank">https://play.google.com/store/apps/details?id=pj.info.rana</a>
                </td>
              </tr>
              <tr>
                <td style=" font-size:medium; font-weight: 500;text-align:left">ONLINE MUNICIPAL ADVERTISEMENT, HOARDING
                  BOOKING FACILITY.</td>
                <td style=" font-size:medium; font-weight: 500;text-align:left;color: blue;">
                  <a href="https://advertise.ranaghatmunicipality.in/Advertisement/AdvertisementRegForm.aspx"
                    target="_blank">https://advertise.ranaghatmunicipality.in/Advertisement/AdvertisementRegForm.aspx</a>
                </td>
              </tr>
              <tr>
                <td style=" font-size:medium; font-weight: 500;text-align:left">ONLINE PROPERTY BOOKING FACILITY.</td>
                <td style=" font-size:medium; font-weight: 500;text-align:left;color: blue;">
                  <a href="https://booking.ranaghatmunicipality.in/"
                    target="_blank">https://booking.ranaghatmunicipality.in/</a>
                </td>
              </tr>
              <tr>
                <td style=" font-size:medium; font-weight: 500;text-align:left">Online Building Plan Approval System
                  (e-Grihanaksha)</td>
                <td style=" font-size:medium; font-weight: 500;text-align:left;color: blue;">
                  <a href="https://obpsudma.wb.gov.in" target="_blank">https://obpsudma.wb.gov.in</a>
                </td>
              </tr>
              <tr>
                <td style=" font-size:medium; font-weight: 500;text-align:left">Common Application Form (CAF) for the
                  issuance of Trade Licence (Certificate of Enlistment) and Registration under Shops and Establishment
                  (e-tender licence)</td>
                <td style=" font-size:medium; font-weight: 500;text-align:left;color: blue;">
                  <a href="https://edistrict.wb.gov.in/PACE/login.do"
                    target="_blank">https://edistrict.wb.gov.in/PACE/login.do</a>
                </td>
              </tr>
              <tr>
                <td style=" font-size:medium; font-weight: 500;text-align:left">Online municipal Mutation (e-mutation)
                </td>
                <td style=" font-size:medium; font-weight: 500;text-align:left;color: blue;">
                  <a href="https://wburbanservice.org" target="_blank">https://wburbanservice.org</a>
                </td>
              </tr>
              <tr>
                <td style=" font-size:medium; font-weight: 500;text-align:left">Road Cutting Permission (Right of Way)
                  in Municipal Areas</td>
                <td style=" font-size:medium; font-weight: 500;text-align:left;color: blue;">
                  <a href="https://wburbanservices.org" target="_blank">https://wburbanservices.org</a>
                </td>
              </tr>
              <tr>
                <td style=" font-size:medium; font-weight: 500;text-align:left">Sanction for the Storage of Construction
                  materials in Municipal Areas</td>
                <td style=" font-size:medium; font-weight: 500;text-align:left;color: blue;">
                  <a href="htpps://wburbanservices.org" target="_blank">htpps://wburbanservices.org</a>
                </td>
              </tr>

              <tr>
                <td style=" font-size:medium; font-weight: 500;text-align:left">Sanction for the Storage of Construction
                  materials in Municipal Areas</td>
                <td style=" font-size:medium; font-weight: 500;text-align:left;color: blue;">
                  <a href="https://wburbanservices.org" target="_blank">https://wburbanservices.org</a>
                </td>
              </tr>

            </tbody>
          </table>
        </div>
      </div>
    </div>
  </div>
</div>

  <main class="main">
    <div class="icon-section-list">
      <div class="container">
        <div class="row">
          <div id="winner-slide" class="owl-carousel owl-theme">
            <div class="item">
              <div class="text-icon-box">
                <span class="icon-point"><img src="./../../../assets/images/icon6.png" routerLink="/otherinfo"></span>
                <h2 routerLink="/otherinfo">Other Info</h2>
                <p></p>
              </div>
            </div>
            <div class="item">
              <div class="text-icon-box">
                <span class="icon-point"><img src="assets/images/icon2.png" routerLink="/tender-current"></span>
                <h2 routerLink="/tender-current">e-Tender</h2>
                <p></p>
              </div>
            </div>
            <div class="item" >
              <div class="text-icon-box">
                <span class="icon-point"><img src="./../../../assets/images/icon3.png"></span>
                <h2>Activity</h2>
                <p></p>
              </div>
            </div>
            <div class="item" >
              <div class="text-icon-box">
                <span class="icon-point"><img src="./../../../assets/images/icon4.png"></span>
                <h2> Developments</h2>
                <p></p>
              </div>
            </div>
            <div class="item" >
              <div class="text-icon-box">
                <span class="icon-point"><img src="./../../../assets/images/icon5.png"></span>
                <h2> Municipality Data</h2>
                <p></p>
              </div>
            </div>
            <div class="item">
              <div class="text-icon-box">
                <span class="icon-point"><img src="../../../assets/images/username-icon-png-6.png"></span>
                <h2 routerLink="/RTI">RTI</h2>
                <p></p>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
    <section class="main-body-section">
      <div class="container">
        <div class="row">
          <div class="col-lg-6 col-sm-12 col-12">
            <div class="about-section-two">
              <div class="about-pic"><img src="./../../../assets/images/image1.png"></div>
              <h2>ABOUT US</h2>
              <div style="width:100%; height:262px; overflow-y:scroll">
                <p>Ranaghat Municipality was established in 1864. It started functioning on 21.9.1984
                  with its office housed in a building donated by the then Zaminder family viz. Pal Chowdhury family.
                   In the beginning area of the municipality was 3.32 sq. miles and this area was demarcated into 6 wards covering a total population of 6000. It was the 7th Municipality of undivided Bengal. No. of councilors of the first Board was 18 out of which 12 were elected by citizens, 4 were nominated by the state Govt. and the remaining 2 were ex-officio councilors. In course of time Nasra Colony, a refugee settlement, was added to the Municipality and its area became 7.72 sq.k.m. With the increase of area and population the municipality had to be demarcated into 16 wards in 1990 and to 19 wards since 1995 by bifurcation of ward nos. 11, 12,14,16,17.
                  Present population of the municipality is 68,754 as per census 2001.</p>
              </div>
              <div class="row">
                <div class="col-lg-6 col-sm-6 col-6">
                  <div class="more-i"><a routerLink="/about-us">More</a></div>
                </div>
                <div class="col-lg-6 col-sm-6 col-6">
                  <!-- <div class="download-load"><span><img src="./../../../assets/images/pdf.png"></span><a href="#" class="ft">Download</a>
                  </div> -->
                </div>
              </div>
            </div>
            <div class="row">
              <div class="col-sm-6 col-md-6 col-lg-6">
                <div class="add-banner Banner1">
                <div class="banner-add1">
                  <a href="#">
                    <img src="./../../../assets/images/Evacuation-Service-Booking.jpg"></a>
                    <div style="
                    padding: 7px 13px;
                    font-size: large;
                    font-weight: 500;">Evacuation Service Booking</div>
              </div>
            </div>
              </div>
              <div class="col-sm-6 col-md-6 col-lg-6">
                <div class="add-banner Banner1">
                <div class="banner-add1"><a href="#">
                  <img src="./../../../assets/images/Online-Property-Tax.jpg" ></a>
                  <div style="
                padding: 7px 41px;
                font-size: large;
                font-weight: 500;">Online Property Tax</div>
                </div>
                </div>
                
              </div>
            </div>
          </div>
          <div class="col-lg-6 col-sm-12 col-12 ">
            <div class="sky-section">
              <div class="row">
                <div class="col-sm-12 col-md-12 col-lg-12" routerLink="/notice"
                  style="background-color:#f5f5dc; padding-top:14px; border-radius:8px; cursor: pointer;">
                  <div id="marquee-vertical" class="notice-section">
                    <h2 >Notice</h2>
                    <div class="box">
                      <!-- Div that will hold the UL -->
                      <div class="scroll">
                        <ul *ngFor="let item of noticeList">
                          <li>
                            {{item.subject}} | {{item.financialYear}} | {{item.publishingDate}}
                          </li>
                         
                        </ul>
                      </div>
                    </div><!-- End Box -->
                  </div>
                </div>
                <!-- <div class="col-sm-6 col-md-6 col-lg-6">
                  <div class="management-section">
                    <h2>COVID management & dead body disposal team</h2>
                    <ul>
                      <li><a routerLink="/notice">Notice</a></li>
                      <li><a routerLink="/LockdownOrder">Lockdown Order</a></li>
                      <li><a routerLink="/covid-control-room">Covid Control Room</a></li>
                      <li><a href="https://wb.gov.in/" target="_blank ">Prachesta scheme - to outbreak of COVID-19.</a></li>
                    </ul>
                  </div>
                </div> -->
              </div>
            </div>
            <div class="help-line">
            <div class="row">
                <div class="sky-section help">
                  <div class="row">
                  <img src="../../../assets/images/Help-Line-No.jpeg" width="100%" height="90%">
                  </div>
                </div>
            </div>
          </div>
          </div>
        </div>
      </div>
    </section>
    <div class="client-section">
      <div class="container">
        <div class="row">
          <div class="col-lg-6 col-sm-12 col-12">

            <div class="testimonial">
              <div class="tt-b-body">
                <div class="testimonial-pic">
                  <img src="./../../../assets/images/test-pic1.jpg" alt="">
                </div>
                <div class="testimonial-review">
                  <h3 class="testimonial-title">Koshaldev Bandyopadhyay</h3>
                  <span>Chairman,Ranaghat Municipality</span>
                </div>
              </div>
              <div class="description">
                <p>
                  সুধীনাগরিকবৃন্দ,
                  রাণাঘাট মানুষের আশীর্বাদ মাথায় নিয়ে বর্তমান পৌরবোর্ডের দায়িত্বভার গ্রহণ করি।
                   নূন্যতম নাগরিক চাহিদার অনেকক্ষেত্রেরই আমরা অন্যান্য পৌরসভাগুলির তুলনায় অনেক পিছিয়ে।রাণাঘাটশহরে 
                   একটি উন্নতমানের অডিটোরিয়ামের জন্য শিল্প-সংস্কৃতিজগতের মানুষসরব।
                   পানীয়জলের সরবরাহ ব্যবস্থা সংস্কার, বৈদ্যুতিকচুল্লী,
                    যানজট সমস্যার সমাধান, স্বাস্থ্যক্ষেত্রে সু- চিকিৎসা ও রোগনির্ণয়ের সুবিধাসহ নানা প্রত্যাশা নিয়ে পুরবাসী আমাদের 
                    হাতে দায়িত্ব তুলে দিয়েছিলেন।নানা প্রতিবন্ধকতাকে জয় করে ঘরে ঘরে উন্নত নাগরিক পরিসেবা পৌঁছে দেওয়াই আমাদের 
                    লক্ষ্য।সেই লক্ষ্যে কতটা সফল হতে পারবো তার মূল্যায়নের ভার আপনাদের হাতে।
                  <a routerLink="/speech">more</a>
                </p>
              </div>

            </div>

          </div>
          <div class="col-lg-6 col-sm-12 col-12">
            <div class="testimonial-two">
              <div class="tt-b-body">
                <div class="testimonial-pic-two">
                  <img src="./../../../assets/images/test-pic2.jpg" alt="">
                </div>
                <div class="testimonial-review-two">
                  <h3 class="testimonial-title-two">Ananda Dey</h3>
                  <span>Vice Chairman,Ranaghat Municipality</span>
                </div>
              </div>
              <div class="description-two">
                <p>
                “Ranaghat is a small but growing town in West Bengal,
                and poised to grow more because of the excellent transport linkages;
                but will require more and more infrastructure facilities to offer good quality of life it’s citizens.
                The industrial development presently is in terms of small units consisting of handlooms and power-looms. Ranaghat, 
                inhabited by a population of 68,754 (according to 2001 census) has a total area of 7. 72 sq. k.m.
                  <a routerLink="/vice-chairman">more</a>
                 </p>
               
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
    <!-- </div> -->

    <div class="footer-logos">
      <div class="container">
        <h2>Useful Links</h2>
        <div class="row">
          <div id="winner-slide1" class="owl-carousel owl-theme">
            <div class="item">
              <div class="text-icon-box">
                <span class="icon-point"><a href="https://uidai.gov.in/" target="_blank"><img
                      src="./../../../assets/images/logo-aadhaar.jpg"></a></span>
              </div>
            </div>
            <!-- <div class="item">
              <div class="text-icon-box">
                <span class="icon-point"><a href="http://uttarparamunicipality.in/index1.php#" target="_blank"><img
                      src="./../../../assets/images/logo-banglar-mukh.jpg"></a></span>
              </div>
            </div> -->
            <div class="item">
              <div class="text-icon-box">
                <span class="icon-point"><a href="http://policewb.gov.in/" target="_blank"><img
                      src="./../../../assets/images/logo-bengal-police.jpg"></a></span>
              </div>
            </div>
            <div class="item">
              <div class="text-icon-box">
                <span class="icon-point"><a href="http://www.biswabangla.in/" target="_blank"><img
                      src="./../../../assets/images/logo-biswa-bangla.jpg"></a></span>
              </div>
            </div>
            <div class="item">
              <div class="text-icon-box">
                <span class="icon-point"><a href="http://digitalindia.gov.in/" target="_blank"><img
                      src="./../../../assets/images/logo-digital-india.jpg"></a></span>
              </div>
            </div>
            <div class="item">
              <div class="text-icon-box">
                <span class="icon-point"><a href="https://www.india.gov.in/" target="_blank"><img
                      src="./../../../assets/images/logo-india-gov-in.jpg"></a></span>
              </div>
            </div>
            <div class="item">
              <div class="text-icon-box">
                <span class="icon-point"><a href="https://www.wbkanyashree.gov.in/" target="_blank"><img
                      src="./../../../assets/images/logo-kanyashree.jpg"></a></span>
              </div>
            </div>
            <div class="item">
              <div class="text-icon-box">
                <span class="icon-point"><a href="http://swasthyasathi.gov.in/" target="_blank"><img
                      src="./../../../assets/images/logo-swasthya-sathi.jpg"></a></span>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>



  </main>

 