<app-header></app-header>
<div class="inner-background-two">
    <img src="assets/images/inner_banner.jpg">
    <div class="latest-overlay-two">
    <div class="container"><h2>Recruitment</h2></div>
  </div>
  </div>
  <section class="inner-post">
    <div class="container">
      <div class="row">
         <div class="col-lg-9 col-sm-12 col-12">
        
            <div class="table-responsive">
                <table class="table">
                    <thead>
                    <tr>
                        <th style="width:20px; font-size:medium; font-weight: 700;">Sr No.</th>
                        <th style="width:20px; font-size:medium; font-weight: 700;">Subject</th>
                        <th style="width:20px; font-size:medium; font-weight: 700;">Memo No</th>
                        <th style="width:20px; font-size:medium; font-weight: 700;">Publishing Date</th>
                        <th style="width:20px; font-size:medium; font-weight: 700;">Closing Date</th>
                        <th style="width:20px; font-size:medium; font-weight: 700;">Action</th>
                       
                    </tr>
                    </thead>
                    <tbody *ngFor="let item of recruitment ; let i = index">
                    <tr>
                        <td style="width:20px; font-size:medium; font-weight: 500;">{{i+1}}</td>
                        <td style="width:20px; font-size:medium; font-weight: 500;">{{item.subject}}</td>
                         <td style="width:20px; font-size:medium; font-weight: 500;">{{item.memoNo}}</td>
                        <td style="width:20px; font-size:medium; font-weight: 500;">{{item.publishingDate}}</td>
                        <td style="width:20px; font-size:medium; font-weight: 500;">{{item.closingDate}}</td>
                    
                        <td><a href="javascript:void(0);"><img src="../../../assets/images/pdf_icon.png"  (click)="downloadRecruitment(item.id)"></a> </td>
                    </tr>							 
                    </tbody>
                  </table>
              </div>
           </div>
         <!-- <div class="col-lg-3 col-sm-12 col-12">
         <div class="management-section-two">
        <h2>COVID management &amp; dead body disposal team</h2>
        <ul>
          <li><a routerLink="/notice">Notice</a></li>
          <li><a routerLink="/LockdownOrder">Lockdown Order</a></li>
          <li><a routerLink="/covid-control-room">Covid Control Room</a></li>
          <li><a href="https://wb.gov.in/" target="_blank ">Prachesta scheme - to outbreak of COVID-19.</a></li>
        </ul>
      </div>
    
         </div> -->
      </div>
    </div>
  </section>