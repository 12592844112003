import { Component, OnInit } from '@angular/core';
import { NgxSpinnerService } from "ngx-spinner";
import { ApiService } from 'src/app/services/api.service';

@Component({
  selector: 'app-wardwise-details',
  templateUrl: './wardwise-details.component.html',
  styleUrls: ['./wardwise-details.component.css']
})
export class WardwiseDetailsComponent implements OnInit {
  wardDetails:any;
  constructor(private apiService: ApiService,
    private SpinnerService: NgxSpinnerService,) { }

  ngOnInit(): void {
    this.apiService.wardDetails().subscribe((res:any )=> {
      this.SpinnerService.show();
       this.wardDetails =res.data;
       this.SpinnerService.hide(); 
     
  },
  (error:any )=> {
  
  });
  }

}
