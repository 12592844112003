import { Component, OnInit } from '@angular/core';
import { Router,ActivatedRoute } from '@angular/router';
import { ApiService } from 'src/app/services/api.service';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { ToastrService } from 'ngx-toastr';
import { NgxSpinnerService } from "ngx-spinner";
import { PagerService } from '../../services/pager.service';
import { DatePipe } from '@angular/common';

//import ClassicEditor from '@ckeditor/ckeditor5-angular';
//import * as ClassicEditor from '@ckeditor/ckeditor5-angular';
// import * as DecoupledEditor from '@ckeditor/ckeditor5-build-decoupled-document';
@Component({
  selector: 'app-admin-page',
  templateUrl: './admin-page.component.html',
  styleUrls: ['./admin-page.component.css']
})
export class AdminPageComponent implements OnInit {
  getallPages:any = [];
  sortedPage: any =[];
  filePage:any;
  adminPageForm:any;
  displayStylePlus = "none";
  displayStyleEdit = "none";
  displayStyleAdd ="none";

  currentPage: number = 1;
  pageSize: number = 10;
  totalCount: number = 0;
  pager: any = {};
  pagedItems: any = [];
  pageNo: number = 1;
  
  maxPageNo: number = 0;
  paginationLinks: number[] = [1, 2, 3, 4, 5];
  searchItem: string = '';
  id:any;
  Boolean=false;

  p: number = 1;
  total: number = 0;

  //public Editor = ClassicEditor;
  public model = {
    editorData: '<p>Hello, world!</p>'
};
  //public editor = ClassicEditor;
  //public Editor = DecoupledEditor;
  
  routeSub: any = {};

  constructor(private apiService:ApiService,
    private router:Router,private formBuilder: FormBuilder,
    private toastrService: ToastrService,
    private SpinnerService: NgxSpinnerService,
    private route:ActivatedRoute,
    private pagerService: PagerService,
    private datePipe: DatePipe) {
    this.initForm();
   }
   
  ngOnInit(): void {
    this.getallPage(this.currentPage);
  }

  // public onReady( editor:any) {
  //   editor.ui.getEditableElement().parentElement.insertBefore(
  //       editor.ui.view.toolbar.element,
  //       editor.ui.getEditableElement()
  //   );
  // }


  moveToPage(pagePosition: any) {
    switch (pagePosition) {
      case 'first': this.pageNo = 0; break;
      case 'previous': if (this.pageNo > 0) { this.pageNo -= 1 }; break;
      case 'next': if (this.pageNo < this.maxPageNo) { this.pageNo += 1 }; break;
      case 'last': this.pageNo = this.maxPageNo; break;
      default: this.pageNo = (pagePosition - 1); break;
    }
    this.getallPage(this.currentPage);
    
  }
  getallPage(currentPage: number) {
    let param = {
      // "filter": this.searchItem,
      // "order": "Name",
      // "limit": 10,
      // "page": this.pageNo

      "filter": this.searchItem,
      "order": "Name",
      "limit": this.pageSize,
      "page": currentPage
    }
    //this.SpinnerService.show(); 
    this.apiService.adminPageGetall(param).subscribe((res:any )=> {
      if (res.result == "Success") {
        this.getallPages = res.data.pages;
        // this.sortedPage = this.getallPages.sort((a: any)=>
        //                   new Date(a.createdOn).getTime())        
       
        console.log('pageList', this.getallPages)
        //this.SpinnerService.hide(); 

        this.totalCount = res.data.count;
        console.log('totalcount', this.totalCount)
        this.setPage(currentPage);

        // this.maxPageNo = (Math.ceil(res.data.count / this.pageSize)-1);
        // console.log('maxPageNo', this.maxPageNo)
        // this.paginationLinks = [];
        // for (let pageLink = (this.pageNo - 2); pageLink <= (this.pageNo + 2); pageLink++) {
        //   if (pageLink < 0 || pageLink > this.maxPageNo) { continue; }
        //   this.paginationLinks.push(pageLink + 1);
        // } 
        // console.log('pagination', this.paginationLinks)
      }
      else
      {
        this.totalCount = res.data.count;
        console.log('totalcount', this.totalCount)
        this.setPage(currentPage);
      }
   },
   (error:any )=> {
   });
  }

  setPage(page: number) {
    this.pager = this.pagerService.getPager(this.totalCount, page, this.pageSize);
    this.pagedItems = this.totalCount > 0 ? this.getallPages.slice(this.pager.startIndex, this.pager.endIndex + 1) : [];
  }

  openPopupPlus() {
    this.displayStylePlus = "block";
  }
  closePopupPlus() {
    this.displayStylePlus = "none";
  }
  closePopupEdit() {
    this.displayStyleEdit = "none";
  }
 
  onSelectFile(event:any){
		if (event.target.files.length > 0) {
			if (event.target.files[0].size > 3*1024*1024) {
			  event.target.value = null;
			}
			else { 
				this.filePage = <File>event.target.files[0]; 
			}
		}
	}

  get f1() { return this.adminPageForm.controls; }


  initForm() {
    this.adminPageForm = this.formBuilder.group({
        name: ['', Validators.required],
        route: ['', Validators.required],
        maincaption1: ['', Validators.required],
        maincaption2: ['', Validators.required],
        maincontent: ['', Validators.required],
    
    });
  }
  onCKEditorReady(event: any) {
  
    this.routeSub = this.route.queryParams
      .subscribe(params => {
        if (params.templateId && params.hasOwnProperty('templateId')) {
          this.id = params.templateId;
          if (this.id) {
          }
        }
      });
  }
  openPopupAdd() {
    let boolean=false;
    this.displayStyleAdd = "block";
    this.f1.name.setValue(null);
    this.f1.route.setValue(null);
    this.f1.maincaption1.setValue(null);
    this.f1.maincaption2.setValue(null);
    this.f1.maincontent.setValue(null);
   
  }
  closePopupAdd() {
    this.displayStyleAdd = "none";
  }
 
  searchTable(event: any) {
    this.searchItem = event.target.value;
    this.getallPage(this.currentPage);
  }

  pageSave(){
    let pageFD = new FormData();
    if(this.filePage){pageFD.append('file', this.filePage, this.filePage.name);}
    pageFD.append("name",this.f1.name.value)
    pageFD.append("route",this.f1.route.value)
    pageFD.append("maincaption1",this.f1.maincaption1.value)
    pageFD.append("maincaption2",this.f1.maincaption2.value)
    pageFD.append("maincontent",this.f1.maincontent.value)

    this.apiService.adminPageAdd(pageFD).subscribe((res:any )=> {
      if(res.result == "Success"){
        this.toastrService.success('Page added successfully');
        window.location.reload();
      }
   },
   (error:any )=> {
    this.toastrService.error("Page not added successfully");
    
   });
   this.displayStyleAdd = "none";
  }
  openPopupEdit(id: any){
    this.id = id;
    this.apiService.adminPageId(id).subscribe((res: any) => {
      if (res.result == "Success") {
        this.f1.name.setValue(res.data.name);
        this.f1.route.setValue(res.data.route);
        this.f1.maincaption1.setValue(res.data.mainCaption1);
        this.f1.maincaption2.setValue(res.data.mainCaption2);
        this.f1.maincontent.setValue(res.data.mainContent);
      }
    },
      (error: any) => {
      });
    this.displayStyleEdit = "block";
  }
  Update(){
    let pageFD = new FormData();
    if(this.filePage){pageFD.append('file',this.filePage,this.filePage.name);}
    pageFD.append("name",this.f1.name.value)
    pageFD.append("route", this.f1.route.value)
    pageFD.append("maincaption1", this.f1.maincaption1.value)
    pageFD.append("maincaption2", this.f1.maincaption2.value)
    pageFD.append("maincontent", this.f1.maincontent.value)
    pageFD.append("id",this.id)
  
    this.apiService.adminPageUpdate(pageFD).subscribe((res: any) => {
      if (res.result == "Success") {
        this.toastrService.success('Page updated successfully');
        window.location.reload();
      
      }
    },
      (error: any) => {
        this.toastrService.error("Page not updated successfully");
      });

  }

  Delete(id: any, fileid: any) {
    let noticeFD = new FormData();    
    noticeFD.append("id", id)
    noticeFD.append("filename", fileid)
    this.id = id;    
    this.apiService.adminPageDelete(noticeFD).subscribe((res: any) => {
      if (res.result == "Success") {
        this.toastrService.success('Page deleted successfully');
        window.location.reload();
      }
    },
      (error: any) => {
        this.toastrService.error("Page not deleted successfully");
      });
  }
pageChangeEvent(event: number){
  this.p = event;
  this.getallPage(this.currentPage);
}
}
