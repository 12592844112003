<app-header></app-header>
<div class="inner-background-two">
    <img src="assets/images/inner_banner.jpg">
    <div class="latest-overlay-two">
    <div class="container"><h2>Head-Of-The-Department</h2></div>
  </div>
  </div>
  <section class="inner-post">
    <div class="container">
      <div class="row">
         <div class="col-lg-9 col-sm-12 col-12">
          <!-- <div style="text-align:right">
            <img src="./../../assets/images/sitemap (2).jpg" width="800" height="900">
          </div> -->
            <div>
                <!-- <h3 style="text-align: center; color: black;"> Ranaghat Municipality</h3>
                <h4 style="text-align: center; color: black;"> Name with Phone No. of the head of department &amp; some responsible person</h4> -->
                <!-- <table border="2" cellpadding="2" cellspacing="0" id="sheet0" class="sheet0 gridlines">
                    <col class="col0">
                    <col class="col1">
                    <col class="col2">
                    <col class="col3">
                    <col class="col4">
                    <col class="col5">
                    <tbody>
                    
                      <tr class="row2">
                        <td class="column0 style3 s" style="font-size:medium; font-weight:bolder; color: black;">Sl.No</td>
                        <td class="column1 style3 s" style="font-size:medium; font-weight:bolder; color: black;">Department</td>
                        <td class="column2 style3 s" style="font-size:medium; font-weight:bolder; color: black;">Name of the Official / Departmental Head</td>
                        <td class="column3 style3 s" style="font-size:medium; font-weight:bolder; color: black;">Designation/Department Name</td>
                        <td  style="font-size:medium; font-weight:bolder; color: black;">Work Distribution</td>
                        <td class="column5 style3 s" style="font-size:medium; font-weight:bolder; color: black;">Phone No.</td>
                      </tr>
                      <tr class="row3">
                        <td class="column0 style4 n" style="text-align: center;">1</td>
                        <td class="column1 style13 s style15" rowspan="4">Establishment</td>
                        <td class="column2 style5 s">Bipul Chakraborti</td>
                        <td class="column3 style4 s">Executive Officer</td>
                        <td class="column4 style6 s">HoD of Establishment, SPIO</td>
                        <td class="column5 style4 n">9433312780</td>
                      </tr>
                      <tr class="row4">
                        <td class="column0 style4 n"  style="text-align: center;">2</td>
                        <td class="column2 style5 s">Nitish Chandra Bain</td>
                        <td class="column3 style4 s">Finance Officer</td>
                        <td class="column4 style6 s">All Financial Issues</td>
                        <td class="column5 style4 n">9474929510</td>
                      </tr>
                      <tr class="row5">
                        <td class="column0 style4 n"  style="text-align: center;">3</td>
                        <td class="column2 style5 s">Shamik Majumder</td>
                        <td class="column3 style4 s">Head Clerk</td>
                        <td class="column4 style6 s">All Information </td>
                        <td class="column5 style4 n">9333515145</td>
                      </tr>
                      <tr class="row6">
                        <td class="column0 style4 null"></td>
                        <td class="column2 style5 s">Hari Narayan Biswas</td>
                        <td class="column3 style4 s">TC </td>
                        <td class="column4 style6 s">Staff Statistic and Government Dealings</td>
                        <td class="column5 style4 n">9832222502</td>
                      </tr>
                      <tr class="row7">
                        <td class="column0 style4 n"  style="text-align: center;">4</td>
                        <td class="column1 style13 s style15" rowspan="3">Tax &amp; Mutation</td>
                        <td class="column2 style5 s">Sulochona Ghosh</td>
                        <td class="column3 style4 s">Assessment in Charge</td>
                        <td class="column4 style6 s">Mutation &amp; Tax  Related issues</td>
                        <td class="column5 style4 n">7001748147</td>
                      </tr>
                      <tr class="row8">
                        <td class="column0 style4 null"></td>
                        <td class="column2 style20 s">Md Sirajuddin</td>
                        <td class="column3 style6 s">LDC</td>
                        <td class="column4 style6 s">Mutation and Building </td>
                        <td class="column5 style4 n">9434226046</td>
                      </tr>
                      <tr class="row9">
                        <td class="column0 style4 n"  style="text-align: center;">5</td>
                        <td class="column2 style22 s">Md Babuiddin</td>
                        <td class="column3 style21 s">Group D</td>
                        <td class="column4 style21 s">Dealing with Property Tax</td>
                        <td class="column5 style23 n">9332224694</td>
                      </tr>
                      <tr class="row10">
                        <td class="column0 style4 n"  style="text-align: center;">6</td>
                        <td class="column1 style3 s">Accounts</td>
                        <td class="column2 style5 s">Sri Sankha Nath Saha</td>
                        <td class="column3 style4 s">A &amp; F.C</td>
                        <td class="column4 style6 s">For Preparation of Municipal Accounts</td>
                        <td class="column5 style4 n">9836671583</td>
                      </tr>
                      <tr class="row11">
                        <td class="column0 style4 n"  style="text-align: center;">7</td>
                        <td class="column1 style3 s">IT </td>
                        <td class="column2 style5 s">Priyanka Barik</td>
                        <td class="column3 style4 s">IT</td>
                        <td class="column4 style6 s">IT related issues</td>
                        <td class="column5 style6 n">9432179654</td>
                      </tr>
                      <tr class="row12">
                        <td class="column0 style4 n"  style="text-align: center;">8</td>
                        <td class="column1 style10 s">NULM</td>
                        <td class="column2 style5 s">Bapan Chakraborty</td>
                        <td class="column3 style4 s">Manager NULM Social Dev</td>
                        <td class="column4 style6 s">SFG / NULM</td>
                        <td class="column5 style4 n">8946069220</td>
                      </tr>
                      <tr class="row13">
                        <td class="column0 style4 n"  style="text-align: center;">9</td>
                        <td class="column1 style13 s style15" rowspan="3">Health NUHM</td>
                        <td class="column2 style5 s">Dr Saikat Chattjerjee</td>
                        <td class="column3 style4 s">FTMO (NUHM)</td>
                        <td class="column4 style6 s">All Health Related issues</td>
                        <td class="column5 style4 n">9434218927</td>
                      </tr>
                      <tr class="row14">
                        <td class="column0 style4 null"></td>
                        <td class="column2 style5 s">Dr Lili Joyadder </td>
                        <td class="column3 style4 s">Health Officer</td>
                        <td class="column4 style6 s">All Health Related issues</td>
                        <td class="column5 style4 n">7439261808</td>
                      </tr>
                      <tr class="row15">
                        <td class="column0 style4 n"  style="text-align: center;">10</td>
                        <td class="column2 style5 s">Nilima Das</td>
                        <td class="column3 style4 s">S.I (Health Wings) CBPHC</td>
                        <td class="column4 style4 s">Dengue and CBPHC</td>
                        <td class="column5 style4 n">9332151186</td>
                      </tr>
                      <tr class="row16">
                        <td class="column0 style4 n"  style="text-align: center;">11</td>
                        <td class="column1 style13 s style15" rowspan="9">Engineering Section</td>
                        <td class="column2 style5 s">Mrinal Kanti Basak</td>
                        <td class="column3 style4 s">&nbsp;Assistant Engineer In charge</td>
                        <td class="column4 style4 s">All Government Projects Work</td>
                        <td class="column5 style4 n">7908386458</td>
                      </tr>
                      <tr class="row17">
                        <td class="column0 style4 n"  style="text-align: center;">12</td>
                        <td class="column2 style5 s">Sudipta Mukherjee</td>
                        <td class="column3 style4 s">SAE P.W.D(Civil) </td>
                        <td class="column4 style4 s">Development Work Estimate preparing and Bill</td>
                        <td class="column5 style4 n">7001323900</td>
                      </tr>
                      <tr class="row18">
                        <td class="column0 style4 n"  style="text-align: center;">13</td>
                        <td class="column2 style5 s">Subrata Sarkar</td>
                        <td class="column3 style4 s">SAE P.W.D(Civil) </td>
                        <td class="column4 style4 s">Development Work Estimate preparing and Bill</td>
                        <td class="column5 style4 n">8509677756</td>
                      </tr>
                      <tr class="row19">
                        <td class="column0 style4 n"  style="text-align: center;">14</td>
                        <td class="column2 style5 s">Ashoke Kr Bose</td>
                        <td class="column3 style4 s">SAE P.W.D(Civil) </td>
                        <td class="column4 style4 s">Development Work Estimate preparing and Bill</td>
                        <td class="column5 style4 n">9434129443</td>
                      </tr>
                      <tr class="row20">
                        <td class="column0 style4 n"  style="text-align: center;">15</td>
                        <td class="column2 style5 s">Surojit Das</td>
                        <td class="column3 style4 s">SAE P.W.D(Civil) </td>
                        <td class="column4 style4 s">CLTC for SUDA , Government approved Project</td>
                        <td class="column5 style4 n">7980925043</td>
                      </tr>
                      <tr class="row21">
                        <td class="column0 style4 n"  style="text-align: center;">16</td>
                        <td class="column2 style5 s">Munumun Halder</td>
                        <td class="column3 style4 s">SAE P.W.D(Civil) </td>
                        <td class="column4 style4 s">Development Work Estimate preparing and Bill</td>
                        <td class="column5 style4 n">7478878348</td>
                      </tr>
                      <tr class="row22">
                        <td class="column0 style4 n"  style="text-align: center;">17</td>
                        <td class="column2 style5 s">Subhankar Chakraborty</td>
                        <td class="column3 style4 s">SAE P.W.D(Civil) </td>
                        <td class="column4 style4 s">Development Work Estimate preparing and Bill</td>
                        <td class="column5 style4 n">7908432122</td>
                      </tr>
                      <tr class="row23">
                        <td class="column0 style4 n"  style="text-align: center;">18</td>
                        <td class="column2 style5 s">Rajesh Karmakar</td>
                        <td class="column3 style4 s">SAE P.W.D(Civil) </td>
                        <td class="column4 style4 s">Development Work Estimate preparing and Bill</td>
                        <td class="column5 style4 n">9804769997</td>
                      </tr>
                      <tr class="row24">
                        <td class="column0 style4 n"  style="text-align: center;">19</td>
                        <td class="column2 style5 s">Gorachand Mukherjee</td>
                        <td class="column3 style4 s">SAE</td>
                        <td class="column4 style6 s">Enquirty and building plan </td>
                        <td class="column5 style4 n">9382293862</td>
                      </tr>
                      <tr class="row25">
                        <td class="column0 style4 null"></td>
                        <td class="column1 style13 s style15" rowspan="3">License Dept</td>
                        <td class="column2 style5 s">Buddhadev Chakraborty</td>
                        <td >UDC</td>
                        <td class="column4 style4 s">Puri, Aheli &amp; Nazrul Mancha, Srija</td>
                        <td class="column5 style4 n">9434386241</td>
                      </tr>
                      <tr class="row26">
                        <td class="column0 style4 null"></td>
                        <td class="column2 style5 s">Swarnava Mukherjee</td>
                        <td class="column3 style4 s">Group D</td>
                        <td class="column4 style6 s">Trade License issue</td>
                        <td class="column5 style4 n">8906855692</td>
                      </tr>
                      <tr class="row27">
                        <td class="column0 style4 n"  style="text-align: center;">20</td>
                        <td class="column2 style5 s">Jayanta Kumar Kundu</td>
                        <td class="column3 style4 s">Group C   /LDC</td>
                        <td class="column4 style6 s">Shop related issues , Rent Collection </td>
                        <td class="column5 style4 n">9775181517</td>
                      </tr>
                      <tr class="row28">
                        <td class="column0 style4 n"  style="text-align: center;">21</td>
                        <td class="column1 style13 s style15" rowspan="2">Conservancy Dept &amp; SWM</td>
                        <td class="column2 style5 s">Raja Ghosh</td>
                        <td class="column3 style4 s">Sanitary Inspector</td>
                        <td class="column4 style4 s">Conservancy , Death &amp; Birth, Marriage, SWM</td>
                        <td class="column5 style4 n">8918791161</td>
                      </tr>
                      <tr class="row29">
                        <td class="column0 style4 n"  style="text-align: center;">22</td>
                        <td class="column2 style5 s">Satya Narayan Nayek</td>
                        <td class="column3 style4 s">LDC</td>
                        <td class="column4 style6 s">SWM H to H collection</td>
                        <td class="column5 style4 n">7872004242</td>
                      </tr>
                      <tr class="row30">
                        <td class="column0 style4 n"  style="text-align: center;">23</td>
                        <td class="column1 style3 s">PWD -II</td>
                        <td class="column2 style5 s">Prasenjit Dey</td>
                        <td class="column3 style4 s">P.W.D (Road )</td>
                        <td class="column4 style6 s">Grass Cleaning in ward No entry other Disaster Management, Plastic Abhijan</td>
                        <td class="column5 style4 n">9734061284</td>
                      </tr>
                      <tr class="row31">
                        <td class="column0 style4 null"></td>
                        <td class="column1 style3 s">Law Cell</td>
                        <td class="column2 style20 s">Gouranga Mitra</td>
                        <td class="column3 style6 s">LDC</td>
                        <td class="column4 style6 s">Law Related Issue</td>
                        <td class="column5 style4 n">9433789199</td>
                      </tr>
                      <tr class="row32">
                        <td class="column0 style4 n"  style="text-align: center;">24</td>
                        <td class="column1 style3 s">Cash</td>
                        <td class="column2 style5 s">Pradip Samaddar</td>
                        <td class="column3 style4 s">Store Keeper Cum Clerk</td>
                        <td class="column4 style4 s">Cash In Charge</td>
                        <td class="column5 style4 n">9434322238</td>
                      </tr>
                      <tr class="row33">
                        <td class="column0 style4 null"></td>
                        <td class="column1 style3 s">RTI</td>
                        <td class="column2 style20 s">Samiran Bose</td>
                        <td class="column3 style6 s">LDC</td>
                        <td class="column4 style6 s">Dealting with RTI application</td>
                        <td class="column5 style4 n">9433469803</td>
                      </tr>
                      <tr class="row34">
                        <td class="column0 style4 n"  style="text-align: center;">25</td>
                        <td class="column1 style3 s">Joy Bangla Scheme</td>
                        <td class="column2 style5 s">Shiba Prasad Roy</td>
                        <td class="column3 style4 s">LDC</td>
                        <td class="column4 style4 s">Manabik, samabyathi, Rupashree, Joybangala scheme</td>
                        <td class="column5 style4 n">9333178542</td>
                      </tr>
                      <tr class="row35">
                        <td class="column0 style4 n"  style="text-align: center;">26</td>
                        <td class="column1 style13 s style15" rowspan="2">Electric</td>
                        <td class="column2 style5 s">Krinjjwal Bose</td>
                        <td class="column3 style4 s">SAE P.W.D(Electrical) </td>
                        <td class="column4 style4 s">Development Work Estimate preparring and Bill</td>
                        <td class="column5 style4 n">8637833214</td>
                      </tr>
                      <tr class="row36">
                        <td class="column0 style4 n"  style="text-align: center;">27</td>
                        <td class="column2 style5 s">Raju Roy</td>
                        <td class="column3 style4 s">LDC</td>
                        <td class="column4 style6 s">Road and Electric Prob</td>
                        <td class="column5 style4 n">9775688564</td>
                      </tr>
                      <tr class="row37">
                        <td class="column0 style4 n"  style="text-align: center;">28</td>
                        <td class="column1 style3 s">Store</td>
                        <td class="column2 style5 s">Amit Sau</td>
                        <td class="column3 style4 s">Group D</td>
                        <td class="column4 style6 s">Store</td>
                        <td class="column5 style4 n">9434302612</td>
                      </tr>
                      <tr class="row38">
                        <td class="column0 style4 n"  style="text-align: center;">29</td>
                        <td class="column1 style3 s">SWM</td>
                        <td class="column2 style5 s">Satya Narayan Nayek</td>
                        <td class="column3 style4 s">LDC</td>
                        <td class="column4 style6 s">SWM H to H collection</td>
                        <td class="column5 style4 n">7872004242</td>
                      </tr>
                      <tr class="row39">
                        <td class="column0 style4 n"  style="text-align: center;">30</td>
                        <td class="column1 style13 s style15" rowspan="5">Municipal Office No</td>
                        <td class="column2 style9 s">OFFICE PHONE NO</td>
                        <td class="column3 style12 s style12" colspan="3">03473 210 - 030 / 215-830  (Chairman Room)</td>
                      </tr>
                      <tr class="row40">
                        <td class="column0 style4 n"  style="text-align: center;">31</td>
                        <td class="column2 style9 s">OFFICE FAX NO </td>
                        <td class="column3 style12 s style12" colspan="3">03473 210 - 029  (also Vice Chairman Room)</td>
                      </tr>
                      <tr class="row41">
                        <td class="column0 style4 null"></td>
                        <td class="column2 style16 s">Municipal Whatsapp</td>
                        <td class="column3 style17 n style17" colspan="3">9434382222</td>
                      </tr>
                      <tr class="row42">
                        <td class="column0 style4 n"  style="text-align: center;">32</td>
                        <td class="column2 style9 s">Office email</td>
                        <td class="column3 style18 s style18" colspan="3"><a href="mailto:ranaghatmunicipality@gmail.com" title="">ranaghatmunicipality@gmail.com</a></td>
                      </tr>
                      <tr class="row43">
                        <td class="column0 style4 n"  style="text-align: center;">33</td>
                        <td class="column2 style9 s">Web Site</td>
                        <td class="column3 style19 s style19" colspan="3"><a href="http://www.ranaghatmunicipality.org/" title="">www.ranaghatmunicipality.org</a></td>
                      </tr>
                    </tbody>
                </table> -->
                <table border="1" cellpadding="0" cellspacing="0" >
       
                  <tbody>
                    <tr >
                      <td  style="font-weight: 700;text-align: center; font-size: larger;" colspan="7">Ranaghat Municipality</td>
                    </tr>
                    <tr >
                      <td style="font-weight: 700;text-align: center; font-size: larger;" colspan="7">Name with Phone No. of the head of department &amp; some responsible person</td>
                    </tr>
                    <tr >
                      <td style="font-weight: 700;text-align: center; font-size: large;">Sl.No</td>
                      <td style="font-weight: 700;text-align: center; font-size: large;">Office Floor location</td>
                      <td style="font-weight: 700;text-align: center; font-size: large;">Department</td>
                      <td style="font-weight: 700;text-align: center; font-size: large;">Name of the Official / Departmental Head</td>
                      <td style="font-weight: 700;text-align: center; font-size: large;">Designation/Department Name</td>
                      <td style="font-weight: 700;text-align: center; font-size: large;">Work Distribution</td>
                      <td style="font-weight: 700;text-align: center; font-size: large;">Phone No.</td>
                    </tr>
                    <tr >
                      <td >1</td>
                      <td >1st Floor</td>
                      <td  rowspan="7">Establishment</td>
                      <td >Koshal Deb Bandyopadhyay</td>
                      <td >Chairman</td>
                      <td >Chairman Desk</td>
                      <td >9857776166</td>
                    </tr>
                    <tr >
                      <td >2</td>
                      <td >1st Floor</td>
                      <td >Ananday Dey</td>
                      <td >Vice Chairman</td>
                      <td >Vice Chairman Desk</td>
                      <td >9434321956</td>
                    </tr>
                    <tr >
                      <td >3</td>
                      <td >Ground Floor</td>
                      <td >Other Member</td>
                      <td >Councillors Room</td>
                      <td >Counicllors Desk</td>
                      <td >Nil</td>
                    </tr>
                    <tr >
                      <td >4</td>
                      <td >1st Floor</td>
                      <td >Bipul Chakraborti</td>
                      <td >Executive Officer</td>
                      <td class="column5 style10 s">HoD of Establishment, SPIO</td>
                      <td class="column6 style3 n">9433312780</td>
                    </tr>
                    <tr class="row7">
                      <td >5</td>
                      <td class="column1 style11 s">2nd Floor</td>
                      <td >Nitish Chandra Bain</td>
                      <td >Finance Officer</td>
                      <td class="column5 style10 s">All Financial Issues</td>
                      <td class="column6 style3 n">9474929510</td>
                    </tr>
                    <tr class="row8">
                      <td >6</td>
                      <td class="column1 style11 s">2nd Floor</td>
                      <td >Shamik Majumder</td>
                      <td >Head Clerk</td>
                      <td class="column5 style10 s">All Information </td>
                      <td class="column6 style3 n">9333515145</td>
                    </tr>
                    <tr class="row9">
                      <td >7</td>
                      <td class="column1 style12 s">2nd Floor</td>
                      <td >Hari Narayan Biswas</td>
                      <td >TC </td>
                      <td class="column5 style10 s">Staff Statistic and Government Dealings</td>
                      <td class="column6 style3 n">9832222502</td>
                    </tr>
                    <tr class="row10">
                      <td >8</td>
                      <td >2nd Floor</td>
                      <td class="column2 style5 s style13" rowspan="3">Tax &amp; Mutation</td>
                      <td >Sulochona Ghosh</td>
                      <td >Assessment in Charge</td>
                      <td class="column5 style10 s">Mutation &amp; Tax  Related issues</td>
                      <td class="column6 style3 n">7001748147</td>
                    </tr>
                    <tr class="row11">
                      <td >9</td>
                      <td class="column1 style11 s">2nd Floor</td>
                      <td class="column3 style14 s">Md Sirajuddin</td>
                      <td class="column4 style10 s">LDC</td>
                      <td class="column5 style10 s">Mutaition and Building </td>
                      <td class="column6 style3 n">9434226046</td>
                    </tr>
                    <tr class="row12">
                      <td >10</td>
                      <td class="column1 style12 s">2nd Floor</td>
                      <td class="column3 style15 s">Md Babuiddin</td>
                      <td class="column4 style16 s">Group D</td>
                      <td class="column5 style16 s">Dealing with Property Tax</td>
                      <td class="column6 style17 n">9332224694</td>
                    </tr>
                    <tr class="row13">
                      <td >11</td>
                      <td class="column1 style3 s">2nd Floor</td>
                      <td class="column2 style2 s">Accounts</td>
                      <td >Sri Sankha Nath Saha</td>
                      <td >A &amp; F.C</td>
                      <td class="column5 style10 s">for Preparation of Municipal Accounts</td>
                      <td class="column6 style3 n">9836671583</td>
                    </tr>
                    <tr class="row14">
                      <td >12</td>
                      <td class="column1 style3 s">1st Floor</td>
                      <td class="column2 style2 s">IT </td>
                      <td >Priyanka Barik</td>
                      <td >IT</td>
                      <td class="column5 style10 s">It related issues</td>
                      <td class="column6 style10 n">9432179654</td>
                    </tr>
                    <tr class="row15">
                      <td >13</td>
                      <td >Ground Floor( Beside Maa Canteen)</td>
                      <td class="column2 style4 s">NULM</td>
                      <td >Bapan Chakraborty</td>
                      <td >Manager NULM Social Dev</td>
                      <td class="column5 style10 s">SFG / NULM</td>
                      <td class="column6 style3 n">8946069220</td>
                    </tr>
                    <tr class="row16">
                      <td >14</td>
                      <td >Dispensary Lane</td>
                      <td class="column2 style18 s style18" rowspan="2">NUHM</td>
                      <td >Dr Lili Joyadder </td>
                      <td >Health Officer</td>
                      <td class="column5 style10 s">All Health Related issues</td>
                      <td class="column6 style3 n">7439261808</td>
                    </tr>
                    <tr class="row17">
                      <td >15</td>
                      <td >Dispensary Lane</td>
                      <td >Nilima Das</td>
                      <td >S.I (Health Wings) CBPHC</td>
                      <td class="column5 style3 s">Dengue and CBPHC</td>
                      <td class="column6 style3 n">9332151186</td>
                    </tr>
                    <tr class="row18">
                      <td >16</td>
                      <td class="column1 style3 s">Ground Floor( Back Side of the office</td>
                      <td class="column2 style5 s style13" rowspan="9">Engineering Section</td>
                      <td >Mrinal Kanti Basak</td>
                      <td >&nbsp;Assistant Engineer In charge</td>
                      <td class="column5 style3 s">All Government Projects Wrok</td>
                      <td class="column6 style3 n">7908386458</td>
                    </tr>
                    <tr class="row19">
                      <td >17</td>
                      <td class="column1 style3 s">Ground Floor( Back Side of the office</td>
                      <td >Sudipta Mukherjee</td>
                      <td >SAE P.W.D(Civil) </td>
                      <td class="column5 style3 s">Development Work Estimate preparring and Bill</td>
                      <td class="column6 style3 n">7001323900</td>
                    </tr>
                    <tr class="row20">
                      <td >18</td>
                      <td class="column1 style3 s">1st Floor ( Besides Bank of Baroda )</td>
                      <td >Subrata Sarkar</td>
                      <td >SAE P.W.D(Civil) </td>
                      <td class="column5 style3 s">Development Work Estimate preparring and Bill</td>
                      <td class="column6 style3 n">8509677756</td>
                    </tr>
                    <tr class="row21">
                      <td >19</td>
                      <td class="column1 style3 s">1st Floor ( Besides Bank of Baroda )</td>
                      <td >Ashoke Kr Bose</td>
                      <td >SAE P.W.D(Civil) </td>
                      <td class="column5 style3 s">Development Work Estimate preparring and Bill</td>
                      <td class="column6 style3 n">9434129443</td>
                    </tr>
                    <tr class="row22">
                      <td >20</td>
                      <td class="column1 style3 s">1st Floor ( Besides Bank of Baroda )</td>
                      <td >Surojit Das</td>
                      <td >SAE P.W.D(Civil) </td>
                      <td class="column5 style3 s">CLTC for SUDA , Government approved Project</td>
                      <td class="column6 style3 n">7980925043</td>
                    </tr>
                    <tr class="row23">
                      <td >21</td>
                      <td class="column1 style3 s">Ground Floor( Back Side of the office</td>
                      <td >Munumun Halder</td>
                      <td >SAE P.W.D(Civil) </td>
                      <td class="column5 style3 s">Development Work Estimate preparring and Bill</td>
                      <td class="column6 style3 n">7478878348</td>
                    </tr>
                    <tr class="row24">
                      <td >22</td>
                      <td class="column1 style3 s">1st Floor ( Besides Bank of Baroda )</td>
                      <td >Subhankar Chakraborty</td>
                      <td >SAE P.W.D(Civil) </td>
                      <td class="column5 style3 s">Development Work Estimate preparring and Bill</td>
                      <td class="column6 style3 n">7908432122</td>
                    </tr>
                    <tr class="row25">
                      <td >23</td>
                      <td class="column1 style3 s">1st Floor ( Besides Bank of Baroda )</td>
                      <td >Rajesh Karmakar</td>
                      <td >SAE P.W.D(Civil) </td>
                      <td class="column5 style3 s">Development Work Estimate preparring and Bill</td>
                      <td class="column6 style3 n">9804769997</td>
                    </tr>
                    <tr class="row26">
                      <td >24</td>
                      <td class="column1 style3 s">Ground Floor( Back Side of the office</td>
                      <td >Gorachand Mukherjee</td>
                      <td >SAE</td>
                      <td class="column5 style10 s">Enquirty and building plan </td>
                      <td class="column6 style3 n">9382293862</td>
                    </tr>
                    <tr class="row27">
                      <td >25</td>
                      <td class="column1 style3 s">2nd Floor</td>
                      <td class="column2 style5 s style13" rowspan="3">License Dept</td>
                      <td >Buddhadev Chakraborty</td>
                      <td class="column4 style19 s">UDC</td>
                      <td class="column5 style3 s">Puri, Aheli &amp; Nazrul Mancha, Srija</td>
                      <td class="column6 style3 n">9434386241</td>
                    </tr>
                    <tr class="row28">
                      <td >26</td>
                      <td class="column1 style3 s">2nd Floor</td>
                      <td >Swarnava Mukherjee</td>
                      <td >Group D</td>
                      <td class="column5 style10 s">Trade License issue</td>
                      <td class="column6 style3 n">8906855692</td>
                    </tr>
                    <tr class="row29">
                      <td >27</td>
                      <td class="column1 style3 s">2nd Floor</td>
                      <td >Jayanta Kumar Kundu</td>
                      <td >Group C   /LDC</td>
                      <td class="column5 style10 s">Shop related issues , Rent Collection </td>
                      <td class="column6 style3 n">9775181517</td>
                    </tr>
                    <tr class="row30">
                      <td >28</td>
                      <td >Ground Floor</td>
                      <td class="column2 style5 s style13" rowspan="3">Conservancy Dept &amp; SWM</td>
                      <td >Raja Ghosh</td>
                      <td >Senitary Inspector</td>
                      <td class="column5 style3 s">Conservancy , Death &amp; Birth, Garriage, SWM</td>
                      <td class="column6 style3 n">8918791161</td>
                    </tr>
                    <tr class="row31">
                      <td class="column0 style3 null"></td>
                      <td class="column1 style8 null"></td>
                      <td >Sankayan Mukherjee</td>
                      <td >Sanitary Assistant</td>
                      <td class="column5 style3 s">Dealing with Birth certificate</td>
                      <td class="column6 style3 n">7001096089</td>
                    </tr>
                    <tr class="row32">
                      <td >29</td>
                      <td >Ground Floor</td>
                      <td >Satya Narayan Nayek</td>
                      <td >LDC</td>
                      <td class="column5 style10 s">SWM H to H collection</td>
                      <td class="column6 style3 n">7872004242</td>
                    </tr>
                    <tr class="row33">
                      <td >30</td>
                      <td class="column1 style3 s">Ground Floor</td>
                      <td class="column2 style2 s">PWD -II</td>
                      <td >Prasenjit Dey</td>
                      <td >P.W.D (Road )</td>
                      <td class="column5 style10 s">Grass Cleaning in ward No entry other Disaster Management, Plastic Abhijan</td>
                      <td class="column6 style3 n">9734061284</td>
                    </tr>
                    <tr class="row34">
                      <td >31</td>
                      <td class="column1 style3 s">2nd Floor</td>
                      <td class="column2 style2 s">Law Cell</td>
                      <td class="column3 style14 s">Gouranga Mitra</td>
                      <td class="column4 style10 s">LDC</td>
                      <td class="column5 style10 s">Law Related Issue</td>
                      <td class="column6 style3 n">9433789199</td>
                    </tr>
                    <tr class="row35">
                      <td >32</td>
                      <td class="column1 style3 s">2nd Floor</td>
                      <td class="column2 style2 s">Cash</td>
                      <td >Pradip Samaddar</td>
                      <td >Store Keeper Cum Clerk</td>
                      <td class="column5 style3 s">Cash In Charge</td>
                      <td class="column6 style3 n">9434322238</td>
                    </tr>
                    <tr class="row36">
                      <td >33</td>
                      <td class="column1 style3 s">2nd Floor</td>
                      <td class="column2 style2 s">RTI</td>
                      <td class="column3 style14 s">Samiran Bose</td>
                      <td class="column4 style10 s">LDC</td>
                      <td class="column5 style10 s">Dealting with RTI application</td>
                      <td class="column6 style3 n">9433469803</td>
                    </tr>
                    <tr class="row37">
                      <td >34</td>
                      <td class="column1 style3 s">2nd Floor</td>
                      <td class="column2 style2 s">Joy Bangla Scheme</td>
                      <td >Shiba Prasad Roy</td>
                      <td >LDC</td>
                      <td class="column5 style3 s">Manabik, samabyathi, Rupashree, Joybangala scheme</td>
                      <td class="column6 style3 n">9333178542</td>
                    </tr>
                    <tr class="row38">
                      <td >35</td>
                      <td >2nd Floor</td>
                      <td class="column2 style5 s style13" rowspan="2">Electric</td>
                      <td >Krinjjwal Bose</td>
                      <td >SAE P.W.D(Electrical) </td>
                      <td class="column5 style3 s">Development Work Estimate preparring and Bill</td>
                      <td class="column6 style3 n">8637833214</td>
                    </tr>
                    <tr class="row39">
                      <td >36</td>
                      <td class="column1 style12 s">2nd Floor</td>
                      <td >Raju Roy</td>
                      <td >LDC</td>
                      <td class="column5 style10 s">Road and Electric Prob</td>
                      <td class="column6 style3 n">9775688564</td>
                    </tr>
                    <tr class="row40">
                      <td >37</td>
                      <td class="column1 style3 s">2nd Floor</td>
                      <td class="column2 style2 s">Store</td>
                      <td >Amit Sau</td>
                      <td >Group D</td>
                      <td class="column5 style10 s">Store</td>
                      <td class="column6 style3 n">9434302612</td>
                    </tr>
                    <tr class="row41">
                      <td >38</td>
                      <td class="column1 style3 s">2nd Floor</td>
                      <td class="column2 style2 s">SWM collection</td>
                      <td >Satya Narayan Nayek</td>
                      <td >LDC</td>
                      <td class="column5 style10 s">SWM H to H collection</td>
                      <td class="column6 style3 n">7872004242</td>
                    </tr>
                    <tr class="row42">
                      <td >39</td>
                      <td >Ground Floor</td>
                      <td class="column2 style2 s">Motor Vehicle</td>
                      <td >Biswajit Ghosh ( Bapi)</td>
                      <td >Group D</td>
                      <td class="column5 style10 s">All vehicle ( Ambulanc , Tracotor and other office)</td>
                      <td class="column6 style3 n">9474595435</td>
                    </tr>
                    <tr class="row43">
                      <td >40</td>
                      <td >Ground Floor</td>
                      <td class="column2 style5 s style13" rowspan="3">Ambulance</td>
                      <td >Sankar Mukherjee</td>
                      <td >Ambulance Driver</td>
                      <td class="column5 style10 s">ICU AMBULANCE</td>
                      <td class="column6 style3 n">9333282080</td>
                    </tr>
                    <tr class="row44">
                      <td >41</td>
                      <td >Ground Floor</td>
                      <td >Ashat Ali </td>
                      <td >Ambulance Driver</td>
                      <td class="column5 style10 s">SMALL AMBULANCE</td>
                      <td class="column6 style3 n">9378379012</td>
                    </tr>
                    <tr class="row45">
                      <td >42</td>
                      <td >Ground Floor</td>
                      <td class="column3 style20 s">RAJU SAHA</td>
                      <td class="column4 style17 s">Ambulance Driver</td>
                      <td class="column5 style10 s">NON AC AMBULANCE</td>
                      <td class="column6 style3 n">9333282080</td>
                    </tr>
                    <tr class="row46">
                      <td >43</td>
                      <td >Ground Floor</td>
                      <td class="column2 style5 s style13" rowspan="2">Swargarath</td>
                      <td class="column3 style20 s">BAPI SARKAR</td>
                      <td class="column4 style17 s">SWARGARATH DRIVER</td>
                      <td class="column5 style10 s">AC SWARGARATH</td>
                      <td class="column6 style3 n">8250342676</td>
                    </tr>
                    <tr >
                      <td >44</td>
                      <td >Ground Floor</td>
                      <td >ABHIJIT DAS</td>
                      <td >SWARGARATH DRIVER</td>
                      <td>AC SWARGARATH</td>
                      <td >9733597979</td>
                    </tr>
                    <tr >
                      <td >45</td>
                      <td >Ground Floor</td>
                      <td  rowspan="2">BSK</td>
                      <td >SUPARNA BRAHMA</td>
                      <td >BSK</td>
                      <td  rowspan="2">ALL KIND OF ONLINE SERVICE AS DIRECTED GOVERNMENT </td>
                      <td >7908417727</td>
                    </tr>
                    <tr >
                      <td >46</td>
                      <td>Ground Floor</td>
                      <td >SUBHADIP GHOSH</td>
                      <td >BSK</td>
                      <td >9547633423</td>
                    </tr>
                    <tr >
                      <td >47</td>
                      <td >Tahanapara</td>
                      <td  rowspan="2">Crematorium</td>
                      <td >Provash Chakraborty</td>
                      <td >Contractual </td>
                      <td >DEATH CERTIFICATE AND CREMETRIUM</td>
                      <td >7866953184</td>
                    </tr>
                    <tr >
                      <td >48</td>
                      <td >Tahanapara</td>
                      <td >Gour Banerjee</td>
                      <td >Contractual </td>
                      <td >DEATH CERTIFICATE AND CREMETRIUM</td>
                      <td >9046832804</td>
                    </tr>
                    <tr >
                      <td  rowspan="5">49</td>
                      <td  rowspan="5">1st Floor</td>
                      <td  rowspan="5">Municipal Office No</td>
                      <td >OFFICE PHONE NO</td>
                      <td  colspan="3">03473 210 - 030 / 215-830  (Chairman Room)</td>
                    </tr>
                    <tr >
                      <td>OFFICE FAX NO </td>
                      <td  colspan="3">03473 210 - 029  (also Vice Chairman Room)</td>
                    </tr>
                    <tr >
                      <td >Municipal Whatsapp</td>
                      <td  colspan="3">9434382222</td>
                    </tr>
                    <tr >
                      <td >Office email</td>
                      <td  colspan="3"><a href="mailto:ranaghatmunicipality@gmail.com" title="">ranaghatmunicipality@gmail.com</a></td>
                    </tr>
                    <tr >
                      <td >Web Site</td>
                      <td  colspan="3"><a href="http://www.ranaghatmunicipality.org/" title="">www.ranaghatmunicipality.org</a></td>
                    </tr>
                  </tbody>
              </table>
            
              </div>
           </div>
      </div>
    </div>
  </section>

