import { Component, OnInit } from '@angular/core';
import { NgxSpinnerService } from "ngx-spinner";
import { ToastrService } from 'ngx-toastr';
import { Router, ActivatedRoute } from '@angular/router';
import { ApiService } from 'src/app/services/api.service';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { formatDate } from '@angular/common'
import { DatePipe, Location } from '@angular/common';
import { PagerService } from '../../services/pager.service';

@Component({
  selector: 'app-admin-download',
  templateUrl: './admin-download.component.html',
  styleUrls: ['./admin-download.component.css']
})
export class AdminDownloadComponent implements OnInit {
  adminDownloadForm: any;
  downloadList: any = [];
  fileDownload: any;
  preview: boolean = false;
  currentPage: number = 1;
  pageSize: number = 10;
  totalCount: number = 0;
  pager: any = {};
  pagedItems: any = [];
  pageNo: number = 1;
  searchItem: string = '';
  id: any;
  displayStyleAdd = "none";
  displayStyleEdit = "none";
  invalid: boolean = false;
  userid: any;

  constructor(private apiService: ApiService,
    private router: Router,
    private formBuilder: FormBuilder,
    private SpinnerService: NgxSpinnerService,
    private toastrService: ToastrService,
    private datePipe: DatePipe,
    private pagerService: PagerService) {
    this.initForm();
  }

  ngOnInit(): void {
    this.getallDownload(this.currentPage);
    // if(localStorage.getItem('action') == 'updated'){
    //   this.toastrService.success('Recruitment updated successfully');
    //   localStorage.removeItem('action');
    // } 
    // if(localStorage.getItem('action') == 'saved'){
    //   this.toastrService.success('Recruitment added successfully');
    //   localStorage.removeItem('action');
    // } 
  }
  

  get f1() { return this.adminDownloadForm.controls; }

  initForm() {
    this.adminDownloadForm = this.formBuilder.group({
      subject: ['', Validators.required],
      title: ['', Validators.required],
      uploaddate: ['', Validators.required],
      ispublished: [''],
      fileRecruitment: ['', Validators.required],
      uploadtype: ['', Validators.required]
    });
  }

  getallDownload(currentPage: number) {
    let param = {
      // "filter": this.searchItem,
      // "order": "title",
      // "limit": 10,
      // "page": this.pageNo

      "filter": this.searchItem,
      "order": "title",
      "limit": this.pageSize,
      "page": currentPage
    }
    //this.SpinnerService.show(); 
    this.apiService.adminDownloadGetAll(param).subscribe((res: any) => {
      if (res.result == "Success") {
        this.downloadList = res.data.downloads;
        //this.SpinnerService.hide(); 
        this.totalCount = res.data.count;
        console.log('totalcount', this.totalCount)
        this.setPage(currentPage);
      }
      else{
        this.totalCount = res.data.count;
        console.log('totalcount', this.totalCount)
        this.setPage(currentPage);
      }
    },
      (error: any) => {

      });
  }

  setPage(page: number) {
    this.pager = this.pagerService.getPager(this.totalCount, page, this.pageSize);
    this.pagedItems = this.totalCount > 0 ? this.downloadList.slice(this.pager.startIndex, this.pager.endIndex + 1) : [];
  }

  searchTable(event: any) {
    this.searchItem = event.target.value;
    this.getallDownload(this.currentPage);
  }

  onSelectFile(event: any) {
    if (event.target.files.length > 0) {
      if (event.target.files[0].size > 3 * 1024 * 1024) {

        event.target.value = null;
      }
      else {
        this.fileDownload = <File>event.target.files[0];
        this.f1.fileRecruitment.setValue(this.fileDownload);
      }
    }
  }

  Submit() {
    if (this.adminDownloadForm.invalid) {
      this.invalid = true;
      return;
    }
    else {
      let profileFD = new FormData();
      this.userid = localStorage.getItem('userid');
      if (this.fileDownload) { profileFD.append('file', this.f1.fileRecruitment.value) }
      profileFD.append("subject", this.f1.subject.value)
      profileFD.append("title", this.f1.title.value)
      profileFD.append("uploaddate", this.f1.uploaddate.value)
      profileFD.append("isActive", this.f1.ispublished.value)
      profileFD.append("downloadType", this.f1.uploadtype.value)
      profileFD.append("createdBy", this.userid)
      this.apiService.adminDownloadadd(profileFD).subscribe((res: any) => {
        if (res.result == "Success") {
          this.toastrService.success('Document added successfully');
          window.location.reload();
        }
      },
        (error: any) => {
          this.toastrService.error('Document not added successfully');
        });
    }
  }
  openPopupEdit(id: any) {
    this.id = id;
    this.apiService.adminDownloadId(id).subscribe((res: any) => {
      if (res.result == "Success") {
        console.log('edit data :', res.data)
        if (res.data.fileName) {
          this.preview = true;
        }
        this.f1.subject.setValue(res.data.subject);
        this.f1.title.setValue(res.data.title);
        //this.f1.uploaddate.setValue(res.data.uploadDate);
        //this.f1.fileRecruitment.setValue(res.data.fileName);
        this.f1.uploaddate.setValue(this.datePipe.transform(res.data.uploadDate, 'yyyy-MM-dd', 'en'));
        //this.f1.uploaddate.setValue(formatDate(res.data.uploadDate,'dd-MM-yyyy','en'));
        this.f1.uploadtype.setValue(res.data.downloadType);
        this.f1.ispublished.setValue(res.data.isActive);
      }
    },
      (error: any) => {

      });
    this.displayStyleEdit = "block";

  }
  Update() {
    let profileFD = new FormData();
    if (this.fileDownload
    ) { profileFD.append('file', this.fileDownload, this.fileDownload.name); }
    profileFD.append("subject", this.f1.subject.value)
    profileFD.append("title", this.f1.title.value)
    profileFD.append("uploaddate", this.f1.uploaddate.value)
    profileFD.append("isActive", this.f1.ispublished.value)
    profileFD.append("downloadType", this.f1.uploadtype.value)
    profileFD.append("createdBy", this.userid)
    profileFD.append("id", this.id)

    this.apiService.adminDownloadUpdate(profileFD).subscribe((res: any) => {
      if (res.result == "Success") {
        this.toastrService.success('Document updated successfully');
        window.location.reload();
      }
    },
      (error: any) => {
        this.toastrService.error("Document not updated successfully");
      });
  }

  Delete(id: any, fileid: any) {
    let noticeFD = new FormData();    
    noticeFD.append("id", id)
    noticeFD.append("filename", fileid)
    this.id = id;    
    this.apiService.adminDownloadDelete(noticeFD).subscribe((res: any) => {
      if (res.result == "Success") {
        this.toastrService.success('Document deleted successfully');
        window.location.reload();
      }
    },
      (error: any) => {
        this.toastrService.error("Document not deleted successfully");
      });
  }

  openPopupAdd() {
    this.displayStyleAdd = "block";
    this.f1.title.setValue(null);
    this.f1.subject.setValue(null);
    this.f1.uploaddate.setValue(null);
    this.f1.ispublished.setValue(null);
    this.f1.uploadtype.setValue(null);
  }
  closePopupAdd() {
    this.displayStyleAdd = "none";
  }
  closePopupEdit() {
    this.displayStyleEdit = "none";
  }

  ShowDoc() {
    this.apiService.viewDownloadFile(this.id).subscribe((res: any) => {
      if (res) {
        var mediaType = 'application/pdf';
        var blob = new Blob([res], { type: mediaType });
        var fileURL = URL.createObjectURL(blob);
        //console.log("The Url is ", fileURL);
        window.open(fileURL);
      }
    }, errMsg => {
      console.log(errMsg);
      // this.toastr.errorToastr(errMsg, 'ERROR');
    });
  }

}
