import { NgModule } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';
import { HomeComponent } from './component/home/home.component';
import { TenderCurrentComponent } from './component/tender-current/tender-current.component';
import { TenderArchiveComponent } from './component/tender-archive/tender-archive.component';
import { AboutUsComponent} from './component/about-us/about-us.component';
import { MissionVissionComponent } from './component/mission-vission/mission-vission.component';
import { OtherinfoComponent} from './component/otherinfo/otherinfo.component';
import { PhotoGalaryComponent} from './component/photo-galary/photo-galary.component';
import { RtiComponent} from './component/rti/rti.component';
import{ WardCouncillorComponent} from './component/ward-councillor/ward-councillor.component';
// import { WordwiseinfoComponent} from './component/wordwiseinfo/wordwiseinfo.component';
import { WordwiseinfoComponent} from './component/wordwiseinfo/wordwiseinfo.component';
import{ ContactsComponent} from './component/contacts/contacts.component';
import { AuditComponent} from './component/audit/audit.component';
import { AdminLoginComponent } from './component/admin-login/admin-login.component';
import { AdminDashboardComponent } from './component/admin-dashboard/admin-dashboard.component';
import { SitemapComponent} from './shared/sitemap/sitemap.component';
import { SpeechComponent} from './component/speech/speech.component';
import { TownProfileComponent} from './component/town-profile/town-profile.component';
import { NoticeComponent} from './component/notice/notice.component';
import { ViceChairmanComponent} from './component/vice-chairman/vice-chairman.component'
import { GalleryInsideComponent } from './component/gallery-inside/gallery-inside.component';
import { AdminTenderComponent } from './component/admin-tender/admin-tender.component';
import { AdminNoticeComponent} from './component/admin-notice/admin-notice.component';
import { AdminNewseventComponent } from './component/admin-newsevent/admin-newsevent.component';
import { AdminPageComponent} from './component/admin-page/admin-page.component';
import { AdminGalleryComponent} from './component/admin-gallery/admin-gallery.component';
import { AuthGuard } from './auth.guard';

import { AdminRecruitmentComponent} from './component/admin-recruitment/admin-recruitment.component';
import { RecruitmentComponent} from './component/recruitment/recruitment.component';
import { OutbreakOfCovidComponent} from './component/outbreak-of-covid/outbreak-of-covid.component';
import { LockdownOrderComponent} from './component/lockdown-order/lockdown-order.component';
import { CovidControlRoomComponent} from './component/covid-control-room/covid-control-room.component';
import { AdminDownloadComponent} from './component/admin-download/admin-download.component';
import { HeadOfDeptComponent} from './component/head-of-dept/head-of-dept.component';
import { MunicipalityAtAGlanceComponent} from './component/municipality-at-a-glance/municipality-at-a-glance.component';
import { CensusComponent} from './component/census/census.component';
import { ChangePasswordComponent} from './component/change-password/change-password.component';
import { WbrtpsComponent} from './component/wbrtps/wbrtps.component';
import { MunicipalityOperationalStructureComponent} from './component/municipality-operational-structure/municipality-operational-structure.component';
import { WardwiseDetailsComponent} from './component/wardwise-details/wardwise-details.component';
import { AccBalancesheetUploadComponent} from './component/acc-balancesheet-upload/acc-balancesheet-upload.component';
import { SlumPopulationComponent} from './component/slum-population/slum-population.component';
import { WardCommityComponent} from './component/ward-commity/ward-commity.component';
import { BalancesheetComponent} from './component/balancesheet/balancesheet.component';
import { AnnualReportComponent} from './component/annual-report/annual-report.component';
import { DepartmentalwiseContactsComponent} from './component/departmentalwise-contacts/departmentalwise-contacts.component';
import { SelfHelpGrpComponent} from './component/self-help-grp/self-help-grp.component';
import { MunicipalBasicInfoComponent} from './component/municipal-basic-info/municipal-basic-info.component';
import { CtPtComponent} from './component/ct-pt/ct-pt.component';
import { AdminWardComponent } from './component/admin-ward/admin-ward.component';
import{ DownloadComponent} from './component/download/download.component';
import{ AdminInternalauditComponent } from './component/admin-internalaudit/admin-internalaudit.component';
import { ChangeProfileComponent } from './component/change-profile/change-profile.component';
const routes: Routes = [
 // { path: '', component: HomeComponent },
{ path: '', redirectTo: 'home', pathMatch: 'full' },
  { path: 'home', component: HomeComponent },
  { path:'about-us',component:AboutUsComponent},
  { path: 'tender-current', component: TenderCurrentComponent },
  { path: 'tender-archive', component: TenderArchiveComponent },
  { path:'mission-vission',component:MissionVissionComponent },
  { path:'otherinfo',component:OtherinfoComponent },
  { path:'PhotoGalary',component:PhotoGalaryComponent },
  { path:'RTI',component:RtiComponent },
  { path:'ward-councillor',component:WardCouncillorComponent },
  // { path:'wordwiseinfo',component:WordwiseinfoComponent },
  { path:'wordwiseinfo',component:WordwiseinfoComponent},
  { path:'contacts',component:ContactsComponent },
  { path:'audit',component:AuditComponent },
  { path:'adminLogin',component:AdminLoginComponent },
  { path:'adminDashboard',component:AdminDashboardComponent,canActivate: [AuthGuard] },
  { path:'admingallery',component:AdminGalleryComponent,canActivate: [AuthGuard]},
  { path:'sitemap',component:SitemapComponent},
  { path:'speech',component:SpeechComponent},
  { path:'town-profile',component:TownProfileComponent},
  { path:'notice',component:NoticeComponent},
  { path:'vice-chairman',component:ViceChairmanComponent},
  { path:'galleryInside',component:GalleryInsideComponent},
  { path:'adminTender',component:AdminTenderComponent,canActivate: [AuthGuard]},
  { path:'adminNotice',component:AdminNoticeComponent,canActivate: [AuthGuard]},
  { path:'adminNewsevent',component:AdminNewseventComponent,canActivate: [AuthGuard]},
  { path:'adminPage',component:AdminPageComponent,canActivate: [AuthGuard] },
  //{ path:'adminTender',component:AdminTenderComponent,canActivate: [AuthGuard]},
  { path:'adminNotice',component:AdminNoticeComponent,canActivate: [AuthGuard]},
  { path:'adminNewsevent',component:AdminNewseventComponent,canActivate: [AuthGuard]},
  //{ path:'adminPage',component:AdminPageComponent},
  { path:'adminRecruitment',component:AdminRecruitmentComponent,canActivate: [AuthGuard]},
  { path:'recruitment',component:RecruitmentComponent},
  { path:'outbreak-of-covid',component:OutbreakOfCovidComponent},
  { path:'LockdownOrder',component:LockdownOrderComponent},
  { path:'covid-control-room',component:CovidControlRoomComponent},
  { path:'adminDownload', component:AdminDownloadComponent,canActivate: [AuthGuard]}, 
  { path:'headofdept', component:HeadOfDeptComponent},
  { path:'at-a-glance', component:MunicipalityAtAGlanceComponent},
  { path: 'census', component:CensusComponent},
  { path: 'changepassword', component: ChangePasswordComponent},
  { path: 'wbrtps', component: WbrtpsComponent},
  { path: 'operational-structure', component:MunicipalityOperationalStructureComponent},
  { path: 'wardwise-details', component:WardwiseDetailsComponent},
  { path: 'acc-balance', component:AccBalancesheetUploadComponent},
  { path: 'slum-population', component:SlumPopulationComponent},
  { path: 'Ward-Committee', component:WardCommityComponent},
  { path: 'balance-sheet', component:BalancesheetComponent},
  { path: 'annual-report', component:AnnualReportComponent},
  { path: 'deptwish-contact', component:DepartmentalwiseContactsComponent },
  { path: 'self-help-grp', component:SelfHelpGrpComponent},
  { path: 'Municipal-Basic-Info', component:MunicipalBasicInfoComponent},
  { path: 'ct-pt', component:CtPtComponent},
  { path: 'admin-ward', component:AdminWardComponent,canActivate: [AuthGuard]},
  { path: 'download', component:DownloadComponent},
  { path: 'adminInternalAudit',component:AdminInternalauditComponent,canActivate: [AuthGuard]},
  { path: 'change-profile', component:ChangeProfileComponent,canActivate: [AuthGuard] },
];

@NgModule({
  imports: [RouterModule.forRoot(routes,{useHash: true})],
  exports: [RouterModule]
})
export class AppRoutingModule { }
