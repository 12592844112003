import { Component, OnInit } from '@angular/core';
import { NgxSpinnerService } from "ngx-spinner";
import { ApiService } from 'src/app/services/api.service';

@Component({
  selector: 'app-notice',
  templateUrl: './notice.component.html',
  styleUrls: ['./notice.component.css']
})
export class NoticeComponent implements OnInit {
  notice:any;
  constructor(private apiService: ApiService,
    private SpinnerService: NgxSpinnerService,) { }

  ngOnInit(): void {
    this.apiService.NoticeList().subscribe((res:any )=> {
      this.SpinnerService.show();
     this.notice = res.data;
     this.SpinnerService.hide();
  },
  (error:any )=> {
  
  });
  }
  downloadNotice(noticeId:any){
    this.apiService.viewNoticeFile(noticeId).subscribe(res => {
      const fileURL = URL.createObjectURL(res);
      window.open(fileURL, '_blank');
    });
  }
}
