import { Component, OnInit } from '@angular/core';
import { Router,ActivatedRoute } from '@angular/router';
import { ApiService } from 'src/app/services/api.service'

@Component({
  selector: 'app-about-us',
  templateUrl: './about-us.component.html',
  styleUrls: ['./about-us.component.css']
})
export class AboutUsComponent implements OnInit {
 about:any;
  constructor(private apiService: ApiService,private router: Router,private route:ActivatedRoute) { }

  ngOnInit(): void {
    this.apiService.aboutUs().subscribe((res:any )=> {
       this.about = res.data
    },
    (error:any )=> {
  
    });
  }

}
