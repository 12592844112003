import { Component, OnInit } from '@angular/core';
import { Router, ActivatedRoute } from '@angular/router';
import { ApiService } from 'src/app/services/api.service';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { formatDate } from '@angular/common';
import { NgxSpinnerService } from "ngx-spinner";
import { ToastrService } from 'ngx-toastr';
import { DatePipe, Location } from '@angular/common';
import { PagerService } from '../../services/pager.service';

@Component({
  selector: 'app-admin-newsevent',
  templateUrl: './admin-newsevent.component.html',
  styleUrls: ['./admin-newsevent.component.css']
})
export class AdminNewseventComponent implements OnInit {

  adminNewseventForm: any;
  tableData: any = [];
  userid: any;
  publishingdate: any;
  eventdate:any;
  displayStylePlus = "none";
  displayStyleEdit = "none";
  displayStyleAdd = "none";
  currentPage: number = 1;
  pageSize: number = 10;
  totalCount: number = 0;
  pager: any = {};
  pagedItems: any = [];
  pageNo: number = 1;
  searchItem: string = '';
  id:any;

  constructor(private apiService: ApiService, 
    private router: Router, 
    private formBuilder: FormBuilder,
    private SpinnerService: NgxSpinnerService,
    private toastrService: ToastrService,
    private datePipe: DatePipe,
    private pagerService: PagerService) {
    this.initForm();
  }

  ngOnInit(): void {
    this.getallNewsEvent(this.currentPage);

  }

  getallNewsEvent(currentPage: number) {
    let param = {
      // "filter": this.searchItem,
      // "order": "title",
      // "limit": 10,
      // "page": this.pageNo

      "filter": this.searchItem,
      "order": "title",
      "limit": this.pageSize,
      "page": currentPage

    }
    //this.SpinnerService.show(); 
    this.apiService.adminEventnewsGetall(param).subscribe((res: any) => {
      if (res.result == "Success") {
        this.tableData = res.data.newsEvents;
        //this.SpinnerService.hide(); 
        this.totalCount = res.data.count;
        console.log('totalcount', this.totalCount)
        this.setPage(currentPage);
      }
      else{
        this.totalCount = res.data.count;
        console.log('totalcount', this.totalCount)
        this.setPage(currentPage);
      }
    },
      (error: any) => {

      });
  }

  setPage(page: number) {
    this.pager = this.pagerService.getPager(this.totalCount, page, this.pageSize);
    this.pagedItems = this.totalCount > 0 ? this.tableData.slice(this.pager.startIndex, this.pager.endIndex + 1) : [];
  }

  get f1() { return this.adminNewseventForm.controls; }

  initForm() {
    this.adminNewseventForm = this.formBuilder.group({
      title: ['',Validators.required],
      body: ['',Validators.required],
      //blurb: [''],
      publishingdate: ['',Validators.required],
      eventdate: ['',Validators.required],
      ispublished: [false],
      type: ['',Validators.required],
    });
  }



  Submit() {
    let profileFD = new FormData();
    this.userid = localStorage.getItem('userid');
    profileFD.append("title", this.f1.title.value)
    profileFD.append("body", this.f1.body.value)
    profileFD.append("publishingDate", this.f1.publishingdate.value)
    profileFD.append("eventDate", this.f1.eventdate.value)
    profileFD.append("isPublished", this.f1.ispublished.value)
    profileFD.append("type", this.f1.type.value)
    profileFD.append("createdBy", this.userid)
    this.apiService.adminEventnewsAdd(profileFD).subscribe((res: any) => {
      if (res.result == "Success") {
        this.toastrService.success('News & event added successfully');
        window.location.reload();
      }
    },
      (error: any) => {
        this.toastrService.error('News & event not added successfully');
      });
    this.displayStyleAdd = "none";
  }

  openPopupEdit(id: any) {
    this.id = id;
    this.apiService.adminEventnewsId(id).subscribe((res: any) => {
      if (res.result == "Success") {
        this.f1.title.setValue(res.data.title);
        this.f1.body.setValue(res.data.body);
        //this.f1.blurb.setValue(res.data.blurb);
        this.f1.type.setValue(res.data.type);
        this.f1.ispublished.setValue(res.data.isPublished);
        //this.f1.publishingdate.setValue(res.data.publishingDate);
        this.f1.publishingdate.setValue(this.datePipe.transform(new Date(res.data.publishingDate), 'yyyy-MM-dd'));

        //this.f1.eventdate.setValue(res.data.eventDate);        
        this.f1.eventdate.setValue(this.datePipe.transform(new Date(res.data.eventDate), 'yyyy-MM-dd'));
        console.log('pub_date', res.data.publishingDate);
        console.log('evnt_date', res.data.eventDate);
      }
    },
      (error: any) => {

      });
    this.displayStyleEdit = "block";
  }
  closePopupEdit() {
    this.displayStyleEdit = "none";
  }

  openPopupAdd() {
    this.displayStyleAdd = "block";
    this.f1.title.setValue(null);
    this.f1.body.setValue(null);
    //this.f1.blurb.setValue(null);
    this.f1.type.setValue(null);
    this.f1.ispublished.setValue(null);
    this.f1.publishingdate.setValue(null);
    this.f1.eventdate.setValue(null);
  }
  closePopupAdd() {
    this.displayStyleAdd = "none";
  }

  searchTable(event: any) {
    this.searchItem = event.target.value;
    this.getallNewsEvent(this.currentPage);
  }

  Update(){
    let profileFD = new FormData();
    
    profileFD.append("title", this.f1.title.value)
    profileFD.append("body", this.f1.body.value)
    //profileFD.append("blurb", this.f1.blurb.value)
    profileFD.append("publishingDate", this.f1.publishingdate.value)
    profileFD.append("eventDate", this.f1.eventdate.value)
    profileFD.append("isPublished", this.f1.ispublished.value)
    profileFD.append("type", this.f1.type.value)
    profileFD.append("id", this.id)

    this.apiService.adminEventnewsUpdate(profileFD).subscribe((res: any) => {
      if (res.result == "Success") {
        this.toastrService.success('News & event updated successfully');
        window.location.reload();
      }
    },
      (error: any) => {
        this.toastrService.error('News & event updated successfully');
      });
  }

  SelectPublishingDate(event: any) {
    this.publishingdate = event.target.value;
    if (this.publishingdate > this.eventdate) {
      this.f1.publishingdate.value = "";
      this.publishingdate = null;
      alert("Publishing Date should be less than event date");
    }
  }
  SelectEventDate(event: any) {
    this.eventdate = event.target.value;
    if (this.publishingdate > this.eventdate) {
      this.f1.eventdate.value = "";
      this.eventdate = null;
      alert("Event Date should be greater than publising date");
    }
  }
}
