import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-ward-commity',
  templateUrl: './ward-commity.component.html',
  styleUrls: ['./ward-commity.component.css']
})
export class WardCommityComponent implements OnInit {

  constructor() { }

  ngOnInit(): void {
  }

}
