<app-header></app-header>
<div class="main-wapper">
    <div class="wapper-body">
        <div class="row">
            <div class="col-lg-12 col-md-12 col-sm-12 col-xs-12 col-mob-12">
                <div class="top-panel">
                    <form>
                        <div class="form-group">
                            <input type="text" name="text" (keyup)="searchTable($event)" placeholder="Search files">
                            <a href="#" class="src-icon"><img src="assets/assetsAdmin/img/loupe.png"></a>
                        </div>
                    </form>
                    <a href="javascript:void(0);" class="add-btn"><i class="fa fa-plus" (click)="openPopupAdd()"
                            aria-hidden="true" title="Add Page"></i></a>
                </div>
                <div class="bottom-panel">
                    <div class="table-responsive">
                        <table class="table table-striped jambo_table">
                            <thead>
                                <tr style="color: black;">
                                    <th>Sl No</th>
                                    <th>Title</th>
                                    <th>Financial Year</th>
                                    <th>Uploading Date</th>
                                    <th>Action</th>
                                </tr>
                            </thead>
                            <tbody *ngFor="let item of getallInternalAuditList;let i=index">
                                <tr>
                                    <td>{{(pager.startIndex -1) + i + 2}}</td>
                                    <td>{{item.title}}</td>
                                    <td>{{item.financialYear}}</td>
                                    <td>{{item.uploadDate}}</td>
                                    <td class="last">
                                        <div class="action-button">
                                            <!-- <a href="javascript:void(0);" class="plus-btn"><i class="fa fa-plus" (click)="openPopupPlus()"
                            aria-hidden="true" title="View"></i></a> -->
                                            <a href="javascript:void(0);" class="edit-btn"><i class="fa fa-edit"
                                                    (click)="openPopupEdit(item.id)" aria-hidden="true"
                                                    title="Edit"></i></a>
                                            <!-- <a href="javascript:void(0);" class="delete-btn"><i class="fa fa-times" aria-hidden="true"></i></a> -->
                                        </div>
                                    </td>
                                </tr>

                            </tbody>
                        </table>
                    </div>

                    <!-- #region  Pagination-->
                    <div class="row">
                        <div class="col-md-5">
                            <div class="dataTables_info" *ngIf="totalCount>0">Showing {{pager.startIndex +1}} to
                                {{pager.endIndex+1}}
                                of {{totalCount}} entries</div>
                        </div>

                        <div class="col-md-7">
                            <div class="dataTables_paginate paging_simple_numbers">
                                <ul *ngIf="pager.pages && pager.pages.length" class="pagination">
                                    <li class="paginate_button previous disabled" ng-disabled="true"
                                        [ngClass]="{'disabled':pager.currentPage === 1}">
                                        <a aria-controls="datatable-fixed-header"
                                            (click)="getallInternalAudit(1)">First</a>
                                    </li>
                                    <li class="paginate_button previous disabled"
                                        [ngClass]="{disabled:pager.currentPage === 1}">
                                        <a aria-controls="datatable-fixed-header"
                                            (click)="getallInternalAudit(pager.currentPage - 1)">Previous</a>
                                    </li>
                                    <li class="paginate_button previous disabled" *ngFor="let page of pager.pages"
                                        [ngClass]="{active:pager.currentPage === page}">
                                        <a aria-controls="datatable-fixed-header"
                                            (click)="getallInternalAudit(page)">{{page}}</a>
                                    </li>
                                    <li class="paginate_button previous disabled"
                                        [ngClass]="{disabled:pager.currentPage === pager.totalPages}">
                                        <a aria-controls="datatable-fixed-header"
                                            (click)="getallInternalAudit(pager.currentPage + 1)">Next</a>
                                    </li>
                                    <li class="paginate_button previous disabled"
                                        [ngClass]="{disabled:pager.currentPage === pager.totalPages}">
                                        <a aria-controls="datatable-fixed-header"
                                            (click)="getallInternalAudit(pager.totalPages)">Last</a>
                                    </li>
                                </ul>
                            </div>
                        </div>
                    </div>
                    <!-- #endregion -->

                    <!-- <div class="row">
              <div class="col-md-5">
                <div class="dataTables_info" id="datatable-fixed-header_info" role="status" aria-live="polite">Showing 1 to 10 of 57 entries</div>
              </div>
              <div class="col-md-7">
                <div class="dataTables_paginate paging_simple_numbers" id="datatable-fixed-header_paginate">
                  <ul class="pagination">
                    <li class="paginate_button previous disabled" id="datatable-fixed-header_previous"><a href="#" aria-controls="datatable-fixed-header" data-dt-idx="0" tabindex="0">Previous</a></li>
                    <li class="paginate_button active"><a href="#" aria-controls="datatable-fixed-header" data-dt-idx="1" tabindex="0">1</a></li>
                    <li class="paginate_button "><a href="#" aria-controls="datatable-fixed-header" data-dt-idx="2" tabindex="0">2</a></li>
                    <li class="paginate_button "><a href="#" aria-controls="datatable-fixed-header" data-dt-idx="3" tabindex="0">3</a></li>
                    <li class="paginate_button "><a href="#" aria-controls="datatable-fixed-header" data-dt-idx="4" tabindex="0">4</a></li>
                    <li class="paginate_button "><a href="#" aria-controls="datatable-fixed-header" data-dt-idx="5" tabindex="0">5</a></li>
                    <li class="paginate_button "><a href="#" aria-controls="datatable-fixed-header" data-dt-idx="6" tabindex="0">6</a></li>
                    <li class="paginate_button next" id="datatable-fixed-header_next"><a href="#" aria-controls="datatable-fixed-header" data-dt-idx="7" tabindex="0">Next</a></li>
                  </ul>
                </div>
              </div>
            </div> -->

                </div>
            </div>
        </div>
    </div>
</div>

<ngx-spinner bdColor="rgba(51, 51, 51, 0.8)" size="default" type="ball-spin-clockwise">
    <p style="color: white">Please Wait... </p>
</ngx-spinner>

<!---pop-new-poroject-->
<div class="new-pop" class="modal" [ngStyle]="{'display':displayStyleAdd}">
    <div class="new-pop-body"><a href="javascript:void(0);" (click)="closePopupAdd()" class="cls-new-pop"><img
                src="assets/assetsAdmin/img/close.png" /></a>
        <h4>Add Internal Audit</h4>
        <!-- <p>Set frame rate, size resolution</p> -->
        <form id="contact-form" role="form" [formGroup]="adminInternalAuditForm">
            <div class="controls">
                <div class="row">
                    <div class="col-md-4">
                        <div class="form-group">
                            <label for="form_name">Title *</label>
                            <input id="form_name" type="text" name="title" formControlName="title" class="form-control"
                                required="required" data-error="Title is required.">
                            <div *ngIf="f1.title.invalid && (f1.title.dirty || f1.title.touched)" class="alert">
                                <div *ngIf="f1.title.errors.required" style="color: red;">
                                    Please enter title
                                </div>
                            </div>
                        </div>
                    </div>
                    <div class="col-md-4">
                        <div class="form-group">
                            <label for="form_name">Financial Year</label>
                            <input id="form_name" type="text" name="title" formControlName="financialyear"
                                class="form-control">
                        </div>
                    </div>                    
                </div>
                <div class="row">
                    <div class="col-md-4">
                        <div class="form-group">
                            <label>Select File</label>
                            <input type="file" (change)="onSelectFile($event)" formControlName="fileInternalAudit"
                                class="file">
                            <font color="sky blue">(Maximum 3 MB)</font>
                            <div
                                *ngIf="f1.fileInternalAudit.invalid && (f1.fileInternalAudit.dirty || f1.fileInternalAudit.touched)">
                                <div *ngIf="f1.fileInternalAudit.errors.required" style="color: red;">
                                    Please enter fileTender
                                </div>
                            </div>
                        </div>
                    </div>
                    <div class="col-md-4">
                        <div class="form-group">
                            <label for="form_cption">Date of Uploading *</label>
                            <input type="date" name="begin" value="" formControlName="uploadingdate">
                            <div *ngIf="f1.uploadingdate.invalid && (f1.uploadingdate.dirty || f1.uploadingdate.touched)"
                                class="alert">
                                <div *ngIf="f1.uploadingdate.errors.required" style="color: red;">
                                    Please enter uploading date
                                </div>
                            </div>
                        </div>
                    </div>
                    <div class="col-md-4">
                        <label>Is Active</label>&nbsp;
                        <input type="checkbox" id="published" name="isactive" formControlName="isactive"
                            style="width:20px;height:20px;" class="form-control">
                    </div>
                </div>                
                <div class="row">
                    <div class="col-md-12">
                        <div class="btn-grp">
                            <button type="button" class="done-btn" (click)="Submit()">Submit</button>
                            <button type="button" class="cancl-btn" (click)="closePopupAdd()">Cancel</button>
                        </div>
                    </div>
                </div>
            </div>
        </form>
    </div>
</div>
<ngx-spinner bdColor="rgba(51, 51, 51, 0.8)" size="default" type="ball-spin-clockwise">
    <p style="color: white">Please Wait... </p>
</ngx-spinner>
<!---pop-Edit Pge-->
<div class="edit-pop" class="modal" [ngStyle]="{'display':displayStyleEdit}">
    <div class="new-pop-body"><a href="javascript:void(0);" (click)="closePopupEdit()" class="cls-edit-pop"><img
                src="./assets/img/close.png" /></a>
        <h4>Edit Internal Audit</h4>
        <!-- <p>Set frame rate, size resolution</p> -->
        <form id="contact-form" role="form" [formGroup]="adminInternalAuditForm">
            <div class="controls">
                <div class="row">
                    <div class="col-md-4">
                        <div class="form-group">
                            <label for="form_name">Title *</label>
                            <input id="form_name" type="text" name="title" formControlName="title" class="form-control"
                                required="required" data-error="Title is required.">
                            <div *ngIf="f1.title.invalid && (f1.title.dirty || f1.title.touched)" class="alert">
                                <div *ngIf="f1.title.errors.required" style="color: red;">
                                    Please enter title
                                </div>
                            </div>
                        </div>
                    </div>
                    <div class="col-md-4">
                        <div class="form-group">
                            <label for="form_name">Financial Year</label>
                            <input id="form_name" type="text" name="title" formControlName="financialyear" class="form-control">
                        </div>
                    </div>                    
                </div>
                <div class="row">
                    <div class="col-md-4">
                        <div class="form-group">
                            <label>Select File</label>
                            <input type="file" (change)="onSelectFile($event)" formControlName="fileInternalAudit"
                                class="file">
                            <font color="sky blue">(Maximum 3 MB)</font>
                            <div
                                *ngIf="f1.fileInternalAudit.invalid && (f1.fileInternalAudit.dirty || f1.fileInternalAudit.touched)">
                                <div *ngIf="f1.fileInternalAudit.errors.required" style="color: red;">
                                    Please enter fileTender
                                </div>
                            </div>
                        </div>
                    </div>
                    <div class="col-md-4">
                        <div class="form-group">
                            <label for="form_cption">Date of Uploading *</label>
                            <input type="date" name="begin" value="" formControlName="uploadingdate">
                            <div *ngIf="f1.uploadingdate.invalid && (f1.uploadingdate.dirty || f1.uploadingdate.touched)"
                                class="alert">
                                <div *ngIf="f1.uploadingdate.errors.required" style="color: red;">
                                    Please enter uploading date
                                </div>
                            </div>
                        </div>
                    </div>
                    <div class="col-md-4">
                        <label>Is Active</label>&nbsp;
                        <input type="checkbox" id="published" name="isactive" formControlName="isactive"
                            style="width:20px;height:20px;" class="form-control">
                    </div>
                </div>
                <div class="col-md-12">
                    <div class="btn-grp">
                        <button type="button" class="done-btn" (click)="Update()">Update</button>
                        <button type="button" class="cancl-btn" (click)="closePopupEdit()">Cancel</button>
                    </div>
                </div>
            </div>
        </form>
    </div>
</div>