<app-header></app-header>
<div class="inner-background-two">
    <img src="assets/images/inner_banner.jpg">
    <div class="latest-overlay-two">
    <div class="container"><h2>Self-Help-Group</h2></div>
  </div>
  </div>
  <section class="inner-post">
    <div class="container">
      <div class="row">
         <div class="col-lg-9 col-sm-12 col-12">
             <div class="vison-section">
              
               <div >
                <table border="1" cellpadding="0" cellspacing="0" >
        
                    <tbody>
                      <tr class="row0">
                        <td style="font-weight: 700;text-align: center; font-size: large;width: 1221px;">Sl. No</td>
                        <td style="font-weight: 700;text-align: center; font-size: large;width: 2000px;">Name of the Self Help Group/NGO/Community Based Organisation</td>
                        <td style="font-weight: 700;text-align: center; font-size: large;width: 2000px;">Address of the Organization</td>
                        <td style="font-weight: 700;text-align: center; font-size: large;width: 1221px;">Ward No</td>
                        <td style="font-weight: 700;text-align: center; font-size: large;width: 1221px;">Ward Name</td>
                        <td style="font-weight: 700;text-align: center; font-size: large;width: 1221px;">Has the organization declared for ODF(Y/N)</td>
                      </tr>
                      <tr class="row1">
                        <td class="column0 style2 n">1</td>
                        <td class="column1 style3 s">RADHAMADHAB TCG</td>
                        <td class="column2 style4 s">SARAK PARA</td>
                        <td class="column3 style2 n">1</td>
                        <td class="column4 style4 s">SARAK PARA</td>
                        <td class="column5 style2 s">YES</td>
                      </tr>
                      <tr class="row2">
                        <td class="column0 style2 n">2</td>
                        <td class="column1 style3 s">JOY GURU TCG</td>
                        <td class="column2 style4 s">SARAK  PARA</td>
                        <td class="column3 style2 n">1</td>
                        <td class="column4 style4 s">SARAK  PARA</td>
                        <td class="column5 style2 s">YES</td>
                      </tr>
                      <tr class="row3">
                        <td class="column0 style2 n">3</td>
                        <td class="column1 style3 s">SREE CHAITANYA  TCG</td>
                        <td class="column2 style4 s">SARAK PARA</td>
                        <td class="column3 style2 n">1</td>
                        <td class="column4 style4 s">SARAK PARA</td>
                        <td class="column5 style2 s">YES</td>
                      </tr>
                      <tr class="row4">
                        <td class="column0 style2 n">4</td>
                        <td class="column1 style3 s">SARAK PARA 2NO TCG</td>
                        <td class="column2 style4 s">SARAK PARA</td>
                        <td class="column3 style2 n">1</td>
                        <td class="column4 style4 s">SARAK PARA</td>
                        <td class="column5 style2 s">YES</td>
                      </tr>
                      <tr class="row5">
                        <td class="column0 style2 n">5</td>
                        <td class="column1 style3 s">SARAK PARA 3 NO TCG</td>
                        <td class="column2 style4 s">SARAK PARA</td>
                        <td class="column3 style2 n">1</td>
                        <td class="column4 style4 s">SARAK PARA</td>
                        <td class="column5 style2 s">YES</td>
                      </tr>
                      <tr class="row6">
                        <td class="column0 style2 n">6</td>
                        <td class="column1 style3 s">KHEYA GHAT LANE 1NO TCG</td>
                        <td class="column2 style5 s">KHEYA GHAT LANE</td>
                        <td class="column3 style2 n">1</td>
                        <td class="column4 style5 s">KHEYA GHAT LANE</td>
                        <td class="column5 style2 s">YES</td>
                      </tr>
                      <tr class="row7">
                        <td class="column0 style2 n">7</td>
                        <td class="column1 style3 s">PURATAN BAZAR  STREET  1NO TCG</td>
                        <td class="column2 style5 s">PURATN BAZAR STREET</td>
                        <td class="column3 style2 n">1</td>
                        <td class="column4 style5 s">PURATN BAZAR STREET</td>
                        <td class="column5 style2 s">YES</td>
                      </tr>
                      <tr class="row8">
                        <td class="column0 style2 n">8</td>
                        <td class="column1 style3 s">KHEYA GHAT LANE 2NO TCG</td>
                        <td class="column2 style5 s">KHEYA GHAT LANE</td>
                        <td class="column3 style2 n">1</td>
                        <td class="column4 style5 s">KHEYA GHAT LANE</td>
                        <td class="column5 style2 s">YES</td>
                      </tr>
                      <tr class="row9">
                        <td class="column0 style2 n">9</td>
                        <td class="column1 style3 s">PEYARA BAGAN LANE 1 NO TCG</td>
                        <td class="column2 style5 s">PEYARA BAGAN LANE</td>
                        <td class="column3 style2 n">1</td>
                        <td class="column4 style5 s">PEYARA BAGAN LANE</td>
                        <td class="column5 style2 s">YES</td>
                      </tr>
                      <tr class="row10">
                        <td class="column0 style2 n">10</td>
                        <td class="column1 style3 s">PEYARA BAGAN LANE 2 NO CTG</td>
                        <td class="column2 style5 s">PEYARA BAGAN LANE</td>
                        <td class="column3 style2 n">1</td>
                        <td class="column4 style5 s">PEYARA BAGAN LANE</td>
                        <td class="column5 style2 s">YES</td>
                      </tr>
                      <tr class="row11">
                        <td class="column0 style2 n">11</td>
                        <td class="column1 style3 s">SARAK PARA 5 NO TCG</td>
                        <td class="column2 style5 s">SARAK PARA (FAKIR PARA LANE )</td>
                        <td class="column3 style2 n">1</td>
                        <td class="column4 style5 s">SARAK PARA (FAKIR PARA LANE )</td>
                        <td class="column5 style2 s">YES</td>
                      </tr>
                      <tr class="row12">
                        <td class="column0 style2 n">12</td>
                        <td class="column1 style3 s">KHEYA GHAT LANE  3 NO TCG</td>
                        <td class="column2 style5 s">KHEYA GHAT LANE</td>
                        <td class="column3 style2 n">1</td>
                        <td class="column4 style5 s">KHEYA GHAT LANE</td>
                        <td class="column5 style2 s">YES</td>
                      </tr>
                      <tr class="row13">
                        <td class="column0 style2 n">13</td>
                        <td class="column1 style3 s">SARAK PARA 6 NO SHG</td>
                        <td class="column2 style4 s">SARAK PARA</td>
                        <td class="column3 style2 n">1</td>
                        <td class="column4 style4 s">SARAK PARA</td>
                        <td class="column5 style2 s">YES</td>
                      </tr>
                      <tr class="row14">
                        <td class="column0 style2 n">14</td>
                        <td class="column1 style3 s">MAHUT PARA 2 NO SHG</td>
                        <td class="column2 style4 s">MAHUT PARA</td>
                        <td class="column3 style2 n">1</td>
                        <td class="column4 style4 s">MAHUT PARA</td>
                        <td class="column5 style2 s">YES</td>
                      </tr>
                      <tr class="row15">
                        <td class="column0 style2 n">15</td>
                        <td class="column1 style3 s">MAHUT PARA 3 NO SHG</td>
                        <td class="column2 style4 s">MAHUT PARA</td>
                        <td class="column3 style2 n">1</td>
                        <td class="column4 style4 s">MAHUT PARA</td>
                        <td class="column5 style2 s">YES</td>
                      </tr>
                      <tr class="row16">
                        <td class="column0 style2 n">16</td>
                        <td class="column1 style3 s">PEYARA BAGAN LANE 4 NO SHG</td>
                        <td class="column2 style4 s">PEYARA BAGAN</td>
                        <td class="column3 style2 n">1</td>
                        <td class="column4 style4 s">PEYARA BAGAN</td>
                        <td class="column5 style2 s">YES</td>
                      </tr>
                      <tr class="row17">
                        <td class="column0 style2 n">17</td>
                        <td class="column1 style3 s">PEYARA BAGAN LANE 5 NO SHG</td>
                        <td class="column2 style5 s">PEYARA BAGAN LANE </td>
                        <td class="column3 style2 n">1</td>
                        <td class="column4 style5 s">PEYARA BAGAN LANE </td>
                        <td class="column5 style2 s">YES</td>
                      </tr>
                      <tr class="row18">
                        <td class="column0 style2 n">18</td>
                        <td class="column1 style3 s">PUERATAN BAZAR STREET 2 NO SHG</td>
                        <td class="column2 style5 s">PURATAN BAZAR STREET</td>
                        <td class="column3 style2 n">1</td>
                        <td class="column4 style5 s">PURATAN BAZAR STREET</td>
                        <td class="column5 style2 s">YES</td>
                      </tr>
                      <tr class="row19">
                        <td class="column0 style2 n">19</td>
                        <td class="column1 style3 s">MAHUT PARA 4 NO SHG</td>
                        <td class="column2 style4 s">MAHUT PARA </td>
                        <td class="column3 style2 n">1</td>
                        <td class="column4 style4 s">MAHUT PARA </td>
                        <td class="column5 style2 s">YES</td>
                      </tr>
                      <tr class="row20">
                        <td class="column0 style2 n">20</td>
                        <td class="column1 style3 s">MAHUT PARA 5 NO SHG</td>
                        <td class="column2 style4 s">MAHUT PARA </td>
                        <td class="column3 style2 n">1</td>
                        <td class="column4 style4 s">MAHUT PARA </td>
                        <td class="column5 style2 s">YES</td>
                      </tr>
                      <tr class="row21">
                        <td class="column0 style2 n">21</td>
                        <td class="column1 style3 s">MAHUT PARA 6 NO SHG</td>
                        <td class="column2 style4 s">MAHUT PARA </td>
                        <td class="column3 style2 n">1</td>
                        <td class="column4 style4 s">MAHUT PARA </td>
                        <td class="column5 style2 s">YES</td>
                      </tr>
                      <tr class="row22">
                        <td class="column0 style2 n">22</td>
                        <td class="column1 style3 s">KHEYAGHAT LANE 4 NO SHG</td>
                        <td class="column2 style5 s">KHEYAGHAT LANE</td>
                        <td class="column3 style2 n">1</td>
                        <td class="column4 style5 s">KHEYAGHAT LANE</td>
                        <td class="column5 style2 s">YES</td>
                      </tr>
                      <tr class="row23">
                        <td class="column0 style2 n">23</td>
                        <td class="column1 style3 s">SARAK PARA 7 NO SHG</td>
                        <td class="column2 style5 s">SARAK PARA</td>
                        <td class="column3 style2 n">1</td>
                        <td class="column4 style5 s">SARAK PARA</td>
                        <td class="column5 style2 s">YES</td>
                      </tr>
                      <tr class="row24">
                        <td class="column0 style2 n">24</td>
                        <td class="column1 style3 s">KHEYAGHAT LANE 5 NO SHG</td>
                        <td class="column2 style5 s">KHEYAGHAT LANE</td>
                        <td class="column3 style2 n">1</td>
                        <td class="column4 style5 s">KHEYAGHAT LANE</td>
                        <td class="column5 style2 s">YES</td>
                      </tr>
                      <tr class="row25">
                        <td class="column0 style2 n">25</td>
                        <td class="column1 style3 s">GHATAK PARA 1NO  TCG</td>
                        <td class="column2 style4 s">GHATAK PARA</td>
                        <td class="column3 style2 n">2</td>
                        <td class="column4 style4 s">GHATAK PARA</td>
                        <td class="column5 style2 s">YES</td>
                      </tr>
                      <tr class="row26">
                        <td class="column0 style2 n">26</td>
                        <td class="column1 style3 s">GHATAK PARA 2NO SHG</td>
                        <td class="column2 style4 s">GHATAK PARA</td>
                        <td class="column3 style2 n">2</td>
                        <td class="column4 style4 s">GHATAK PARA</td>
                        <td class="column5 style2 s">YES</td>
                      </tr>
                      <tr class="row27">
                        <td class="column0 style2 n">27</td>
                        <td class="column1 style3 s">GHATAK PARA 3NO SHG</td>
                        <td class="column2 style4 s">A.N.P.C ROAD</td>
                        <td class="column3 style2 n">2</td>
                        <td class="column4 style4 s">A.N.P.C ROAD</td>
                        <td class="column5 style2 s">YES</td>
                      </tr>
                      <tr class="row28">
                        <td class="column0 style2 n">28</td>
                        <td class="column1 style3 s">GHATAK PARA 4NO SHG</td>
                        <td class="column2 style4 s">GHATAK PARA</td>
                        <td class="column3 style2 n">2</td>
                        <td class="column4 style4 s">GHATAK PARA</td>
                        <td class="column5 style2 s">YES</td>
                      </tr>
                      <tr class="row29">
                        <td class="column0 style2 n">29</td>
                        <td class="column1 style3 s">GHATAK PARA 5 NO SHG</td>
                        <td class="column2 style4 s">GHATAK PARA</td>
                        <td class="column3 style2 n">2</td>
                        <td class="column4 style4 s">GHATAK PARA</td>
                        <td class="column5 style2 s">YES</td>
                      </tr>
                      <tr class="row30">
                        <td class="column0 style2 n">30</td>
                        <td class="column1 style3 s">GHATAK PARA 6 NO SHG</td>
                        <td class="column2 style4 s">GHATAK PARA</td>
                        <td class="column3 style2 n">2</td>
                        <td class="column4 style4 s">GHATAK PARA</td>
                        <td class="column5 style2 s">YES</td>
                      </tr>
                      <tr class="row31">
                        <td class="column0 style2 n">31</td>
                        <td class="column1 style3 s">SASTITALA 1 NO SHG</td>
                        <td class="column2 style4 s">SASTI TALA</td>
                        <td class="column3 style2 n">2</td>
                        <td class="column4 style4 s">SASTI TALA</td>
                        <td class="column5 style2 s">YES</td>
                      </tr>
                      <tr class="row32">
                        <td class="column0 style2 n">32</td>
                        <td class="column1 style3 s">SASTITALA 2 NO SHG</td>
                        <td class="column2 style4 s">SASTI TALA</td>
                        <td class="column3 style2 n">2</td>
                        <td class="column4 style4 s">SASTI TALA</td>
                        <td class="column5 style2 s">YES</td>
                      </tr>
                      <tr class="row33">
                        <td class="column0 style2 n">33</td>
                        <td class="column1 style3 s">ICHHA  TCG</td>
                        <td class="column2 style4 s">MATHER PARA </td>
                        <td class="column3 style2 n">3</td>
                        <td class="column4 style4 s">MATHER PARA </td>
                        <td class="column5 style2 s">YES</td>
                      </tr>
                      <tr class="row34">
                        <td class="column0 style2 n">34</td>
                        <td class="column1 style3 s">DHANA BARSHA TCG</td>
                        <td class="column2 style4 s">MATHER PARA </td>
                        <td class="column3 style2 n">3</td>
                        <td class="column4 style4 s">MATHER PARA </td>
                        <td class="column5 style2 s">YES</td>
                      </tr>
                      <tr class="row35">
                        <td class="column0 style2 n">35</td>
                        <td class="column1 style3 s">VARSHA TCG</td>
                        <td class="column2 style4 s">MATHER PARA </td>
                        <td class="column3 style2 n">3</td>
                        <td class="column4 style4 s">MATHER PARA </td>
                        <td class="column5 style2 s">YES</td>
                      </tr>
                      <tr class="row36">
                        <td class="column0 style2 n">36</td>
                        <td class="column1 style3 s">SATATA TCG</td>
                        <td class="column2 style4 s">MATHER PARA </td>
                        <td class="column3 style2 n">3</td>
                        <td class="column4 style4 s">MATHER PARA </td>
                        <td class="column5 style2 s">YES</td>
                      </tr>
                      <tr class="row37">
                        <td class="column0 style2 n">37</td>
                        <td class="column1 style3 s">SRISTI TCG</td>
                        <td class="column2 style4 s">MATHER PARA </td>
                        <td class="column3 style2 n">3</td>
                        <td class="column4 style4 s">MATHER PARA </td>
                        <td class="column5 style2 s">YES</td>
                      </tr>
                      <tr class="row38">
                        <td class="column0 style2 n">38</td>
                        <td class="column1 style3 s">MATHER PARA 1 NO TCG</td>
                        <td class="column2 style5 s">MATHER PARA </td>
                        <td class="column3 style2 n">3</td>
                        <td class="column4 style5 s">MATHER PARA </td>
                        <td class="column5 style2 s">YES</td>
                      </tr>
                      <tr class="row39">
                        <td class="column0 style2 n">39</td>
                        <td class="column1 style3 s">MATHER PARA 2 NO TCG</td>
                        <td class="column2 style5 s">MATHER PARA </td>
                        <td class="column3 style2 n">3</td>
                        <td class="column4 style5 s">MATHER PARA </td>
                        <td class="column5 style2 s">YES</td>
                      </tr>
                      <tr class="row40">
                        <td class="column0 style2 n">40</td>
                        <td class="column1 style3 s">MATHER PARA 3 NO TCG</td>
                        <td class="column2 style5 s">MATHER PARA </td>
                        <td class="column3 style2 n">3</td>
                        <td class="column4 style5 s">MATHER PARA </td>
                        <td class="column5 style2 s">YES</td>
                      </tr>
                      <tr class="row41">
                        <td class="column0 style2 n">41</td>
                        <td class="column1 style3 s">MATHER PARA 4 NO TCG</td>
                        <td class="column2 style5 s">MATHER PARA </td>
                        <td class="column3 style2 n">3</td>
                        <td class="column4 style5 s">MATHER PARA </td>
                        <td class="column5 style2 s">YES</td>
                      </tr>
                      <tr class="row42">
                        <td class="column0 style2 n">42</td>
                        <td class="column1 style3 s">KHIRKI BAGAN LANE 1NO  TCG</td>
                        <td class="column2 style5 s">KHIRKI BAGAN  LANE</td>
                        <td class="column3 style2 n">3</td>
                        <td class="column4 style5 s">KHIRKI BAGAN  LANE</td>
                        <td class="column5 style2 s">YES</td>
                      </tr>
                      <tr class="row43">
                        <td class="column0 style2 n">43</td>
                        <td class="column1 style3 s">MATHER PARA 5NO SHG</td>
                        <td class="column2 style4 s">MATHER PARA</td>
                        <td class="column3 style2 n">3</td>
                        <td class="column4 style4 s">MATHER PARA</td>
                        <td class="column5 style2 s">YES</td>
                      </tr>
                      <tr class="row44">
                        <td class="column0 style2 n">44</td>
                        <td class="column1 style3 s">MATHER PARA 6NO SHG</td>
                        <td class="column2 style4 s">KHIRKI BAGAN</td>
                        <td class="column3 style2 n">3</td>
                        <td class="column4 style4 s">KHIRKI BAGAN</td>
                        <td class="column5 style2 s">YES</td>
                      </tr>
                      <tr class="row45">
                        <td class="column0 style2 n">45</td>
                        <td class="column1 style3 s">MATHER PARA 7NO SHG</td>
                        <td class="column2 style4 s">KHIRKI BAGAN</td>
                        <td class="column3 style2 n">3</td>
                        <td class="column4 style4 s">KHIRKI BAGAN</td>
                        <td class="column5 style2 s">YES</td>
                      </tr>
                      <tr class="row46">
                        <td class="column0 style2 n">46</td>
                        <td class="column1 style3 s">MATHER PARA 8 NO SHG</td>
                        <td class="column2 style4 s">MATHER PARA</td>
                        <td class="column3 style2 n">3</td>
                        <td class="column4 style4 s">MATHER PARA</td>
                        <td class="column5 style2 s">YES</td>
                      </tr>
                      <tr class="row47">
                        <td class="column0 style2 n">47</td>
                        <td class="column1 style3 s">MATHER PARA 9 NO SHG</td>
                        <td class="column2 style4 s">MATHER PARA</td>
                        <td class="column3 style2 n">3</td>
                        <td class="column4 style4 s">MATHER PARA</td>
                        <td class="column5 style2 s">YES</td>
                      </tr>
                      <tr class="row48">
                        <td class="column0 style2 n">48</td>
                        <td class="column1 style3 s">MAA SARADA TCG</td>
                        <td class="column2 style4 s">AMLOKI TALA </td>
                        <td class="column3 style2 n">4</td>
                        <td class="column4 style4 s">AMLOKI TALA </td>
                        <td class="column5 style2 s">YES</td>
                      </tr>
                      <tr class="row49">
                        <td class="column0 style2 n">49</td>
                        <td class="column1 style3 s">AMLOKI TALA LANE 1 NO TCG</td>
                        <td class="column2 style4 s">AMLOKI TALA </td>
                        <td class="column3 style2 n">4</td>
                        <td class="column4 style4 s">AMLOKI TALA </td>
                        <td class="column5 style2 s">YES</td>
                      </tr>
                      <tr class="row50">
                        <td class="column0 style2 n">50</td>
                        <td class="column1 style3 s">BOSE PARA 1NO TCG</td>
                        <td class="column2 style4 s">BOSE  PARA</td>
                        <td class="column3 style2 n">4</td>
                        <td class="column4 style4 s">BOSE  PARA</td>
                        <td class="column5 style2 s">YES</td>
                      </tr>
                      <tr class="row51">
                        <td class="column0 style2 n">51</td>
                        <td class="column1 style3 s">P . K GHATAK  TCG</td>
                        <td class="column2 style5 s">BHANGRA  PARA </td>
                        <td class="column3 style2 n">4</td>
                        <td class="column4 style5 s">BHANGRA  PARA </td>
                        <td class="column5 style2 s">YES</td>
                      </tr>
                      <tr class="row52">
                        <td class="column0 style2 n">52</td>
                        <td class="column1 style3 s">P.C. STREET 2NO SHG</td>
                        <td class="column2 style5 s">P.C STREET BYE LANE</td>
                        <td class="column3 style2 n">4</td>
                        <td class="column4 style5 s">P.C STREET BYE LANE</td>
                        <td class="column5 style2 s">YES</td>
                      </tr>
                      <tr class="row53">
                        <td class="column0 style2 n">53</td>
                        <td class="column1 style3 s">AMLOKI TALA LANE 3 NO SHG</td>
                        <td class="column2 style4 s">AMLOKI TALA</td>
                        <td class="column3 style2 n">4</td>
                        <td class="column4 style4 s">AMLOKI TALA</td>
                        <td class="column5 style2 s">YES</td>
                      </tr>
                      <tr class="row54">
                        <td class="column0 style2 n">54</td>
                        <td class="column1 style3 s">AMLOKI TALA LANE 4 NO SHG</td>
                        <td class="column2 style4 s">AMLOKI TALA</td>
                        <td class="column3 style2 n">4</td>
                        <td class="column4 style4 s">AMLOKI TALA</td>
                        <td class="column5 style2 s">YES</td>
                      </tr>
                      <tr class="row55">
                        <td class="column0 style2 n">55</td>
                        <td class="column1 style3 s">P.C STREET 1 NO SHG</td>
                        <td class="column2 style5 s">P.C STREET BYE LANE</td>
                        <td class="column3 style2 n">4</td>
                        <td class="column4 style5 s">P.C STREET BYE LANE</td>
                        <td class="column5 style2 s">YES</td>
                      </tr>
                      <tr class="row56">
                        <td class="column0 style2 n">56</td>
                        <td class="column1 style3 s">D.L ROY TCG</td>
                        <td class="column2 style5 s">SIDDHESWARI TALA LANE</td>
                        <td class="column3 style2 n">6</td>
                        <td class="column4 style5 s">SIDDHESWARI TALA LANE</td>
                        <td class="column5 style2 s">YES</td>
                      </tr>
                      <tr class="row57">
                        <td class="column0 style2 n">57</td>
                        <td class="column1 style3 s">KALITALA TCG</td>
                        <td class="column2 style5 s">SHYAM BABU PONDS LANE</td>
                        <td class="column3 style2 n">5</td>
                        <td class="column4 style5 s">SHYAM BABU PONDS LANE</td>
                        <td class="column5 style2 s">YES</td>
                      </tr>
                      <tr class="row58">
                        <td class="column0 style2 n">58</td>
                        <td class="column1 style3 s">S .B PONDS LANE 1 NO TCG</td>
                        <td class="column2 style5 s">SHYAM BABU PONDS LANE</td>
                        <td class="column3 style2 n">5</td>
                        <td class="column4 style5 s">SHYAM BABU PONDS LANE</td>
                        <td class="column5 style2 s">YES</td>
                      </tr>
                      <tr class="row59">
                        <td class="column0 style2 n">59</td>
                        <td class="column1 style3 s">S .B PONDS LANE 2 NO TCG</td>
                        <td class="column2 style5 s">SHYAM BABU PONDS LANE</td>
                        <td class="column3 style2 n">5</td>
                        <td class="column4 style5 s">SHYAM BABU PONDS LANE</td>
                        <td class="column5 style2 s">YES</td>
                      </tr>
                      <tr class="row60">
                        <td class="column0 style2 n">60</td>
                        <td class="column1 style3 s">S .B PONDS LANE 3 NO TCG</td>
                        <td class="column2 style5 s">SHYAM BABU PONDS LANE</td>
                        <td class="column3 style2 n">5</td>
                        <td class="column4 style5 s">SHYAM BABU PONDS LANE</td>
                        <td class="column5 style2 s">YES</td>
                      </tr>
                      <tr class="row61">
                        <td class="column0 style2 n">61</td>
                        <td class="column1 style3 s">S .B PONDS LANE 4 NO TCG</td>
                        <td class="column2 style5 s">SHYAM BABU PONDS LANE</td>
                        <td class="column3 style2 n">5</td>
                        <td class="column4 style5 s">SHYAM BABU PONDS LANE</td>
                        <td class="column5 style2 s">YES</td>
                      </tr>
                      <tr class="row62">
                        <td class="column0 style2 n">62</td>
                        <td class="column1 style3 s">S .B PONDS LANE 5 NO TCG</td>
                        <td class="column2 style5 s">SHYAM BABU PONDS LANE</td>
                        <td class="column3 style2 n">5</td>
                        <td class="column4 style5 s">SHYAM BABU PONDS LANE</td>
                        <td class="column5 style2 s">YES</td>
                      </tr>
                      <tr class="row63">
                        <td class="column0 style2 n">63</td>
                        <td class="column1 style3 s">S .B PONDS LANE 6 NO TCG</td>
                        <td class="column2 style5 s">SHYAM BABU PONDS LANE</td>
                        <td class="column3 style2 n">5</td>
                        <td class="column4 style5 s">SHYAM BABU PONDS LANE</td>
                        <td class="column5 style2 s">YES</td>
                      </tr>
                      <tr class="row64">
                        <td class="column0 style2 n">64</td>
                        <td class="column1 style3 s">S .B PONDS LANE 7 NO TCG</td>
                        <td class="column2 style5 s">SHYAM BABU PONDS LANE</td>
                        <td class="column3 style2 n">5</td>
                        <td class="column4 style5 s">SHYAM BABU PONDS LANE</td>
                        <td class="column5 style2 s">YES</td>
                      </tr>
                      <tr class="row65">
                        <td class="column0 style2 n">65</td>
                        <td class="column1 style3 s">S.B PONDS LANE 8 NO SHG</td>
                        <td class="column2 style5 s">SHYAM BABU PONDS LANE</td>
                        <td class="column3 style2 n">5</td>
                        <td class="column4 style5 s">SHYAM BABU PONDS LANE</td>
                        <td class="column5 style2 s">YES</td>
                      </tr>
                      <tr class="row66">
                        <td class="column0 style2 n">66</td>
                        <td class="column1 style3 s">S.B PONDS LANE 9 NO SHG</td>
                        <td class="column2 style5 s">SHYAM BABU PONDS LANE</td>
                        <td class="column3 style2 n">5</td>
                        <td class="column4 style5 s">SHYAM BABU PONDS LANE</td>
                        <td class="column5 style2 s">YES</td>
                      </tr>
                      <tr class="row67">
                        <td class="column0 style2 n">67</td>
                        <td class="column1 style3 s">S.B PONDS LANE 10 NO SHG</td>
                        <td class="column2 style5 s">SHYAM BABU PONDS LANE</td>
                        <td class="column3 style2 n">5</td>
                        <td class="column4 style5 s">SHYAM BABU PONDS LANE</td>
                        <td class="column5 style2 s">YES</td>
                      </tr>
                      <tr class="row68">
                        <td class="column0 style2 n">68</td>
                        <td class="column1 style3 s">P K GHATAK LANE 1 NO TCG</td>
                        <td class="column2 style5 s">P.K GHATAK LANE</td>
                        <td class="column3 style2 n">6</td>
                        <td class="column4 style5 s">P.K GHATAK LANE</td>
                        <td class="column5 style2 s">YES</td>
                      </tr>
                      <tr class="row69">
                        <td class="column0 style2 n">69</td>
                        <td class="column1 style3 s">P K GHATAK LANE 2 NO TCG</td>
                        <td class="column2 style5 s">P.K GHATAK LANE</td>
                        <td class="column3 style2 n">6</td>
                        <td class="column4 style5 s">P.K GHATAK LANE</td>
                        <td class="column5 style2 s">YES</td>
                      </tr>
                      <tr class="row70">
                        <td class="column0 style2 n">70</td>
                        <td class="column1 style3 s">P.K GHATAK LANE 3 NO SHG</td>
                        <td class="column2 style5 s">P.K GHATAK LANE</td>
                        <td class="column3 style2 n">6</td>
                        <td class="column4 style5 s">P.K GHATAK LANE</td>
                        <td class="column5 style2 s">YES</td>
                      </tr>
                      <tr class="row71">
                        <td class="column0 style2 n">71</td>
                        <td class="column1 style3 s">P.K GHATAK LANE 4 NO SHG</td>
                        <td class="column2 style5 s">P.K GHATAK LANE</td>
                        <td class="column3 style2 n">6</td>
                        <td class="column4 style5 s">P.K GHATAK LANE</td>
                        <td class="column5 style2 s">YES</td>
                      </tr>
                      <tr class="row72">
                        <td class="column0 style2 n">72</td>
                        <td class="column1 style3 s">P.K GHATAK LANE 5 NO SHG</td>
                        <td class="column2 style5 s">P.K GHATAK LANE</td>
                        <td class="column3 style2 n">6</td>
                        <td class="column4 style5 s">P.K GHATAK LANE</td>
                        <td class="column5 style2 s">YES</td>
                      </tr>
                      <tr class="row73">
                        <td class="column0 style2 n">73</td>
                        <td class="column1 style3 s">BINAY CHATTERJEE TCG</td>
                        <td class="column2 style5 s">K.P CHATTERJEE LANE </td>
                        <td class="column3 style2 n">7</td>
                        <td class="column4 style5 s">K.P CHATTERJEE LANE </td>
                        <td class="column5 style2 s">YES</td>
                      </tr>
                      <tr class="row74">
                        <td class="column0 style2 n">74</td>
                        <td class="column1 style3 s">RADHA BALLAV TCG</td>
                        <td class="column2 style5 s">RADHABALLAV TALA</td>
                        <td class="column3 style2 n">7</td>
                        <td class="column4 style5 s">RADHABALLAV TALA</td>
                        <td class="column5 style2 s">YES</td>
                      </tr>
                      <tr class="row75">
                        <td class="column0 style2 n">75</td>
                        <td class="column1 style3 s">BISTU BASU LANE 1NO TCG</td>
                        <td class="column2 style5 s">BISTU BASU LANE</td>
                        <td class="column3 style2 n">7</td>
                        <td class="column4 style5 s">BISTU BASU LANE</td>
                        <td class="column5 style2 s">YES</td>
                      </tr>
                      <tr class="row76">
                        <td class="column0 style2 n">76</td>
                        <td class="column1 style3 s">R.K BANERJEE LANE 2NO TCG</td>
                        <td class="column2 style5 s">R  K BANERJEE LANE</td>
                        <td class="column3 style2 n">7</td>
                        <td class="column4 style5 s">R  K BANERJEE LANE</td>
                        <td class="column5 style2 s">YES</td>
                      </tr>
                      <tr class="row77">
                        <td class="column0 style2 n">77</td>
                        <td class="column1 style3 s">SCHOOL LANE 1 NO  TCG</td>
                        <td class="column2 style5 s">SCHOOL LANE </td>
                        <td class="column3 style2 n">7</td>
                        <td class="column4 style5 s">SCHOOL LANE </td>
                        <td class="column5 style2 s">YES</td>
                      </tr>
                      <tr class="row78">
                        <td class="column0 style2 n">78</td>
                        <td class="column1 style3 s">P.C STREET 1 NO TCG</td>
                        <td class="column2 style5 s">P.C STREET</td>
                        <td class="column3 style2 n">7</td>
                        <td class="column4 style5 s">P.C STREET</td>
                        <td class="column5 style2 s">YES</td>
                      </tr>
                      <tr class="row79">
                        <td class="column0 style2 n">79</td>
                        <td class="column1 style3 s">STRAND ROAD 1NO TCG</td>
                        <td class="column2 style5 s">STRAND ROAD </td>
                        <td class="column3 style2 n">7</td>
                        <td class="column4 style5 s">STRAND ROAD </td>
                        <td class="column5 style2 s">YES</td>
                      </tr>
                      <tr class="row80">
                        <td class="column0 style2 n">80</td>
                        <td class="column1 style3 s">DAKSHIN PARA 1NO SHG</td>
                        <td class="column2 style5 s">DAKSHIN PARA </td>
                        <td class="column3 style2 n">7</td>
                        <td class="column4 style5 s">DAKSHIN PARA </td>
                        <td class="column5 style2 s">YES</td>
                      </tr>
                      <tr class="row81">
                        <td class="column0 style2 n">81</td>
                        <td class="column1 style3 s">STRAND ROAD 2NO SHG</td>
                        <td class="column2 style5 s">STRAND ROAD </td>
                        <td class="column3 style2 n">7</td>
                        <td class="column4 style5 s">STRAND ROAD </td>
                        <td class="column5 style2 s">YES</td>
                      </tr>
                      <tr class="row82">
                        <td class="column0 style2 n">82</td>
                        <td class="column1 style3 s">BISTU BASU LANE 2 NO SHG</td>
                        <td class="column2 style5 s">BISTU BASU LANE</td>
                        <td class="column3 style2 n">7</td>
                        <td class="column4 style5 s">BISTU BASU LANE</td>
                        <td class="column5 style2 s">YES</td>
                      </tr>
                      <tr class="row83">
                        <td class="column0 style2 n">83</td>
                        <td class="column1 style3 s">S.N GUPTA LANE 1 NO SHG</td>
                        <td class="column2 style5 s">S N GUPTA LANE </td>
                        <td class="column3 style2 n">7</td>
                        <td class="column4 style5 s">S N GUPTA LANE </td>
                        <td class="column5 style2 s">YES</td>
                      </tr>
                      <tr class="row84">
                        <td class="column0 style2 n">84</td>
                        <td class="column1 style3 s">SAATHI TC GROUP</td>
                        <td class="column2 style5 s">BISWAS PARA RANAGHAT NADIA</td>
                        <td class="column3 style2 n">8</td>
                        <td class="column4 style5 s">BISWAS PARA RANAGHAT NADIA</td>
                        <td class="column5 style2 s">YES</td>
                      </tr>
                      <tr class="row85">
                        <td class="column0 style2 n">85</td>
                        <td class="column1 style3 s">AKASH TCG</td>
                        <td class="column2 style5 s">P.C STREET RANAGHAT</td>
                        <td class="column3 style2 n">8</td>
                        <td class="column4 style5 s">P.C STREET RANAGHAT</td>
                        <td class="column5 style2 s">YES</td>
                      </tr>
                      <tr class="row86">
                        <td class="column0 style2 n">86</td>
                        <td class="column1 style3 s">BISTU BASU LANE 1 NO TCG</td>
                        <td class="column2 style5 s">R.K BANERJEE LANE </td>
                        <td class="column3 style2 n">8</td>
                        <td class="column4 style5 s">R.K BANERJEE LANE </td>
                        <td class="column5 style2 s">YES</td>
                      </tr>
                      <tr class="row87">
                        <td class="column0 style2 n">87</td>
                        <td class="column1 style3 s">R.K BANERJEE LANE 1 NO TCG</td>
                        <td class="column2 style5 s">R.K BANERJEE LANE </td>
                        <td class="column3 style2 n">8</td>
                        <td class="column4 style5 s">R.K BANERJEE LANE </td>
                        <td class="column5 style2 s">YES</td>
                      </tr>
                      <tr class="row88">
                        <td class="column0 style2 n">88</td>
                        <td class="column1 style3 s">BISWAS PARA 1 NO SHG</td>
                        <td class="column2 style5 s">BISWAS PARA</td>
                        <td class="column3 style2 n">8</td>
                        <td class="column4 style5 s">BISWAS PARA</td>
                        <td class="column5 style2 s">YES</td>
                      </tr>
                      <tr class="row89">
                        <td class="column0 style2 n">89</td>
                        <td class="column1 style3 s">BISWAS PARA 2 NO SHG</td>
                        <td class="column2 style5 s">BISWAS PARA</td>
                        <td class="column3 style2 n">8</td>
                        <td class="column4 style5 s">BISWAS PARA</td>
                        <td class="column5 style2 s">YES</td>
                      </tr>
                      <tr class="row90">
                        <td class="column0 style2 n">90</td>
                        <td class="column1 style3 s">BISWAS PARA 3 NO SHG</td>
                        <td class="column2 style5 s">BISWAS PARA</td>
                        <td class="column3 style2 n">8</td>
                        <td class="column4 style5 s">BISWAS PARA</td>
                        <td class="column5 style2 s">YES</td>
                      </tr>
                      <tr class="row91">
                        <td class="column0 style2 n">91</td>
                        <td class="column1 style3 s">BISWAS PARA 4 NO SHG</td>
                        <td class="column2 style5 s">BISWAS PARA</td>
                        <td class="column3 style2 n">8</td>
                        <td class="column4 style5 s">BISWAS PARA</td>
                        <td class="column5 style2 s">YES</td>
                      </tr>
                      <tr class="row92">
                        <td class="column0 style2 n">92</td>
                        <td class="column1 style3 s">DISPENSARY LANE 1 NO SHG</td>
                        <td class="column2 style5 s">DISPENSARY LANE</td>
                        <td class="column3 style2 n">9</td>
                        <td class="column4 style5 s">DISPENSARY LANE</td>
                        <td class="column5 style2 s">YES</td>
                      </tr>
                      <tr class="row93">
                        <td class="column0 style2 n">93</td>
                        <td class="column1 style3 s">DISPENSARY LANE 2 NO SHG</td>
                        <td class="column2 style5 s">DISPENSARY LANE</td>
                        <td class="column3 style2 n">9</td>
                        <td class="column4 style5 s">DISPENSARY LANE</td>
                        <td class="column5 style2 s">YES</td>
                      </tr>
                      <tr class="row94">
                        <td class="column0 style2 n">94</td>
                        <td class="column1 style3 s">DISPENSARY LANE 3 NO SHG</td>
                        <td class="column2 style5 s">DISPENSARY LANE</td>
                        <td class="column3 style2 n">9</td>
                        <td class="column4 style5 s">DISPENSARY LANE</td>
                        <td class="column5 style2 s">YES</td>
                      </tr>
                      <tr class="row95">
                        <td class="column0 style2 n">95</td>
                        <td class="column1 style3 s">DISPENSARY LANE 4 NO SHG</td>
                        <td class="column2 style5 s">DISPENSARY LANE</td>
                        <td class="column3 style2 n">9</td>
                        <td class="column4 style5 s">DISPENSARY LANE</td>
                        <td class="column5 style2 s">YES</td>
                      </tr>
                      <tr class="row96">
                        <td class="column0 style2 n">96</td>
                        <td class="column1 style3 s">SARASWATI T&amp;C </td>
                        <td class="column2 style4 s">THANAPARA</td>
                        <td class="column3 style2 n">10</td>
                        <td class="column4 style4 s">THANAPARA</td>
                        <td class="column5 style2 s">YES</td>
                      </tr>
                      <tr class="row97">
                        <td class="column0 style2 n">97</td>
                        <td class="column1 style3 s">TISTA  T&amp;C</td>
                        <td class="column2 style4 s">THANAPARA</td>
                        <td class="column3 style2 n">10</td>
                        <td class="column4 style4 s">THANAPARA</td>
                        <td class="column5 style2 s">YES</td>
                      </tr>
                      <tr class="row98">
                        <td class="column0 style2 n">98</td>
                        <td class="column1 style3 s">JHILAM  T&amp;C</td>
                        <td class="column2 style4 s">THANAPARA</td>
                        <td class="column3 style2 n">10</td>
                        <td class="column4 style4 s">THANAPARA</td>
                        <td class="column5 style2 s">YES</td>
                      </tr>
                      <tr class="row99">
                        <td class="column0 style2 n">99</td>
                        <td class="column1 style3 s">ICCHAMATI  TCG</td>
                        <td class="column2 style4 s">THANAPARA</td>
                        <td class="column3 style2 n">10</td>
                        <td class="column4 style4 s">THANAPARA</td>
                        <td class="column5 style2 s">YES</td>
                      </tr>
                      <tr class="row100">
                        <td class="column0 style2 n">100</td>
                        <td class="column1 style3 s">LUNI T&amp;C GROUP</td>
                        <td class="column2 style4 s">THANAPARA</td>
                        <td class="column3 style2 n">10</td>
                        <td class="column4 style4 s">THANAPARA</td>
                        <td class="column5 style2 s">YES</td>
                      </tr>
                      <tr class="row101">
                        <td class="column0 style2 n">101</td>
                        <td class="column1 style3 s">THANAPARA 2NO TCG</td>
                        <td class="column2 style4 s">THANAPARA</td>
                        <td class="column3 style2 n">10</td>
                        <td class="column4 style4 s">THANAPARA</td>
                        <td class="column5 style2 s">YES</td>
                      </tr>
                      <tr class="row102">
                        <td class="column0 style2 n">102</td>
                        <td class="column1 style3 s">&nbsp;BALASON TCG</td>
                        <td class="column2 style4 s">COURT PARA</td>
                        <td class="column3 style2 n">10</td>
                        <td class="column4 style4 s">COURT PARA</td>
                        <td class="column5 style2 s">YES</td>
                      </tr>
                      <tr class="row103">
                        <td class="column0 style2 n">103</td>
                        <td class="column1 style3 s">THANAPARA 1NO TCG</td>
                        <td class="column2 style4 s">THANAPARA</td>
                        <td class="column3 style2 n">10</td>
                        <td class="column4 style4 s">THANAPARA</td>
                        <td class="column5 style2 s">YES</td>
                      </tr>
                      <tr class="row104">
                        <td class="column0 style2 n">104</td>
                        <td class="column1 style3 s">ANJANA  TCG</td>
                        <td class="column2 style4 s">THANAPARA</td>
                        <td class="column3 style2 n">10</td>
                        <td class="column4 style4 s">THANAPARA</td>
                        <td class="column5 style2 s">YES</td>
                      </tr>
                      <tr class="row105">
                        <td class="column0 style2 n">105</td>
                        <td class="column1 style3 s">THANAPARA 3NO TCG</td>
                        <td class="column2 style4 s">THANAPARA</td>
                        <td class="column3 style2 n">10</td>
                        <td class="column4 style4 s">THANAPARA</td>
                        <td class="column5 style2 s">YES</td>
                      </tr>
                      <tr class="row106">
                        <td class="column0 style2 n">106</td>
                        <td class="column1 style3 s">RAJBAGAN PARA 2 NO TCG</td>
                        <td class="column2 style5 s">RAJBAGAN PARA</td>
                        <td class="column3 style2 n">10</td>
                        <td class="column4 style5 s">RAJBAGAN PARA</td>
                        <td class="column5 style2 s">YES</td>
                      </tr>
                      <tr class="row107">
                        <td class="column0 style2 n">107</td>
                        <td class="column1 style3 s">THANAPARA 4NO TCG</td>
                        <td class="column2 style4 s">THANAPARA</td>
                        <td class="column3 style2 n">10</td>
                        <td class="column4 style4 s">THANAPARA</td>
                        <td class="column5 style2 s">YES</td>
                      </tr>
                      <tr class="row108">
                        <td class="column0 style2 n">108</td>
                        <td class="column1 style3 s">COURT PARA 2NO TCG</td>
                        <td class="column2 style4 s">COURT PARA</td>
                        <td class="column3 style2 n">10</td>
                        <td class="column4 style4 s">COURT PARA</td>
                        <td class="column5 style2 s">YES</td>
                      </tr>
                      <tr class="row109">
                        <td class="column0 style2 n">109</td>
                        <td class="column1 style3 s">THANAPARA 6NO TCG</td>
                        <td class="column2 style4 s">THANAPARA</td>
                        <td class="column3 style2 n">10</td>
                        <td class="column4 style4 s">THANAPARA</td>
                        <td class="column5 style2 s">YES</td>
                      </tr>
                      <tr class="row110">
                        <td class="column0 style2 n">110</td>
                        <td class="column1 style3 s">THANAPARA 9NO TCG</td>
                        <td class="column2 style5 s">2NO SWARGADWAR ROAD</td>
                        <td class="column3 style2 n">10</td>
                        <td class="column4 style5 s">2NO SWARGADWAR ROAD</td>
                        <td class="column5 style2 s">YES</td>
                      </tr>
                      <tr class="row111">
                        <td class="column0 style2 n">111</td>
                        <td class="column1 style3 s">THANAPARA 10 NO TCG</td>
                        <td class="column2 style5 s">2NO SWARGADWAR ROAD</td>
                        <td class="column3 style2 n">10</td>
                        <td class="column4 style5 s">2NO SWARGADWAR ROAD</td>
                        <td class="column5 style2 s">YES</td>
                      </tr>
                      <tr class="row112">
                        <td class="column0 style2 n">112</td>
                        <td class="column1 style3 s">THANAPARA 11NO TCG</td>
                        <td class="column2 style4 s">THANAPARA</td>
                        <td class="column3 style2 n">10</td>
                        <td class="column4 style4 s">THANAPARA</td>
                        <td class="column5 style2 s">YES</td>
                      </tr>
                      <tr class="row113">
                        <td class="column0 style2 n">113</td>
                        <td class="column1 style3 s">SREENATH PUR ROAD 1NO TCG</td>
                        <td class="column2 style5 s">SREENATH PUR ROAD </td>
                        <td class="column3 style2 n">10</td>
                        <td class="column4 style5 s">SREENATH PUR ROAD </td>
                        <td class="column5 style2 s">YES</td>
                      </tr>
                      <tr class="row114">
                        <td class="column0 style2 n">114</td>
                        <td class="column1 style3 s">S.D ROAD 1NO TCG</td>
                        <td class="column2 style5 s">SWARGADWAR ROAD</td>
                        <td class="column3 style2 n">10</td>
                        <td class="column4 style5 s">SWARGADWAR ROAD</td>
                        <td class="column5 style2 s">YES</td>
                      </tr>
                      <tr class="row115">
                        <td class="column0 style2 n">115</td>
                        <td class="column1 style3 s">S.D ROAD 2NO SHG</td>
                        <td class="column2 style4 s">S.D ROAD</td>
                        <td class="column3 style2 n">10</td>
                        <td class="column4 style4 s">S.D ROAD</td>
                        <td class="column5 style2 s">YES</td>
                      </tr>
                      <tr class="row116">
                        <td class="column0 style2 n">116</td>
                        <td class="column1 style3 s">S.D ROAD 3NO SHG</td>
                        <td class="column2 style4 s">S.D ROAD</td>
                        <td class="column3 style2 n">10</td>
                        <td class="column4 style4 s">S.D ROAD</td>
                        <td class="column5 style2 s">YES</td>
                      </tr>
                      <tr class="row117">
                        <td class="column0 style2 n">117</td>
                        <td class="column1 style3 s">S.D ROAD 4NO SHG</td>
                        <td class="column2 style4 s">S.D ROAD</td>
                        <td class="column3 style2 n">10</td>
                        <td class="column4 style4 s">S.D ROAD</td>
                        <td class="column5 style2 s">YES</td>
                      </tr>
                      <tr class="row118">
                        <td class="column0 style2 n">118</td>
                        <td class="column1 style3 s">S.D ROAD 5NO SHG</td>
                        <td class="column2 style4 s">S.D ROAD</td>
                        <td class="column3 style2 n">10</td>
                        <td class="column4 style4 s">S.D ROAD</td>
                        <td class="column5 style2 s">YES</td>
                      </tr>
                      <tr class="row119">
                        <td class="column0 style2 n">119</td>
                        <td class="column1 style3 s">RAJBAGAN PARA 3 NO SHG</td>
                        <td class="column2 style4 s">RAJBAGAN PARA</td>
                        <td class="column3 style2 n">10</td>
                        <td class="column4 style4 s">RAJBAGAN PARA</td>
                        <td class="column5 style2 s">YES</td>
                      </tr>
                      <tr class="row120">
                        <td class="column0 style2 n">120</td>
                        <td class="column1 style3 s">MEGHNA  TCG</td>
                        <td class="column2 style5 s">S.D ROAD , THANAPARA</td>
                        <td class="column3 style2 n">10</td>
                        <td class="column4 style5 s">S.D ROAD , THANAPARA</td>
                        <td class="column5 style2 s">YES</td>
                      </tr>
                      <tr class="row121">
                        <td class="column0 style2 n">121</td>
                        <td class="column1 style3 s">S.D RODA 6 NO SHG</td>
                        <td class="column2 style4 s">S.D ROAD</td>
                        <td class="column3 style2 n">10</td>
                        <td class="column4 style4 s">S.D ROAD</td>
                        <td class="column5 style2 s">YES</td>
                      </tr>
                      <tr class="row122">
                        <td class="column0 style2 n">122</td>
                        <td class="column1 style3 s">S.D RODA 7 NO SHG</td>
                        <td class="column2 style4 s">S.D ROAD</td>
                        <td class="column3 style2 n">10</td>
                        <td class="column4 style4 s">S.D ROAD</td>
                        <td class="column5 style2 s">YES</td>
                      </tr>
                      <tr class="row123">
                        <td class="column0 style2 n">123</td>
                        <td class="column1 style3 s">S.V ROAD 2 NO SHG</td>
                        <td class="column2 style4 s">S.D ROAD</td>
                        <td class="column3 style2 n">10</td>
                        <td class="column4 style4 s">S.D ROAD</td>
                        <td class="column5 style2 s">YES</td>
                      </tr>
                      <tr class="row124">
                        <td class="column0 style2 n">124</td>
                        <td class="column1 style3 s">S.D RODA 8 NO SHG</td>
                        <td class="column2 style4 s">S.D ROAD</td>
                        <td class="column3 style2 n">10</td>
                        <td class="column4 style4 s">S.D ROAD</td>
                        <td class="column5 style2 s">YES</td>
                      </tr>
                      <tr class="row125">
                        <td class="column0 style2 n">125</td>
                        <td class="column1 style3 s">S.D ROAD 9 NO SHG</td>
                        <td class="column2 style4 s">S.D ROAD</td>
                        <td class="column3 style2 n">10</td>
                        <td class="column4 style4 s">S.D ROAD</td>
                        <td class="column5 style2 s">YES</td>
                      </tr>
                      <tr class="row126">
                        <td class="column0 style2 n">126</td>
                        <td class="column1 style3 s">RAJBAGAN PARA 4 NO SHG</td>
                        <td class="column2 style5 s">RAJBAGAN PARA</td>
                        <td class="column3 style2 n">10</td>
                        <td class="column4 style5 s">RAJBAGAN PARA</td>
                        <td class="column5 style2 s">YES</td>
                      </tr>
                      <tr class="row127">
                        <td class="column0 style2 n">127</td>
                        <td class="column1 style3 s">S D ROAD 10 NO SHG</td>
                        <td class="column2 style4 s">S.D.ROAD</td>
                        <td class="column3 style2 n">10</td>
                        <td class="column4 style4 s">S.D.ROAD</td>
                        <td class="column5 style2 s">YES</td>
                      </tr>
                      <tr class="row128">
                        <td class="column0 style2 n">128</td>
                        <td class="column1 style3 s">S D ROAD 11 NO SHG</td>
                        <td class="column2 style4 s">S.D.ROAD</td>
                        <td class="column3 style2 n">10</td>
                        <td class="column4 style4 s">S.D.ROAD</td>
                        <td class="column5 style2 s">YES</td>
                      </tr>
                      <tr class="row129">
                        <td class="column0 style2 n">129</td>
                        <td class="column1 style3 s">COURT PARA 3 NO SHG</td>
                        <td class="column2 style4 s">COURT PARA</td>
                        <td class="column3 style2 n">10</td>
                        <td class="column4 style4 s">COURT PARA</td>
                        <td class="column5 style2 s">YES</td>
                      </tr>
                      <tr class="row130">
                        <td class="column0 style2 n">130</td>
                        <td class="column1 style3 s">S.D ROAD 12 SHG</td>
                        <td class="column2 style4 s">S.D.ROAD</td>
                        <td class="column3 style2 n">10</td>
                        <td class="column4 style4 s">S.D.ROAD</td>
                        <td class="column5 style2 s">YES</td>
                      </tr>
                      <tr class="row131">
                        <td class="column0 style2 n">131</td>
                        <td class="column1 style3 s">SAMRAT TCG</td>
                        <td class="column2 style4 s">SARAT PALLY</td>
                        <td class="column3 style2 n">11</td>
                        <td class="column4 style4 s">SARAT PALLY</td>
                        <td class="column5 style2 s">YES</td>
                      </tr>
                      <tr class="row132">
                        <td class="column0 style2 n">132</td>
                        <td class="column1 style3 s">MAHARAJ TCG</td>
                        <td class="column2 style4 s">SARAT PALLY</td>
                        <td class="column3 style2 n">11</td>
                        <td class="column4 style4 s">SARAT PALLY</td>
                        <td class="column5 style2 s">YES</td>
                      </tr>
                      <tr class="row133">
                        <td class="column0 style2 n">133</td>
                        <td class="column1 style3 s">UDYOG TCG</td>
                        <td class="column2 style4 s">SARAT PALLY</td>
                        <td class="column3 style2 n">11</td>
                        <td class="column4 style4 s">SARAT PALLY</td>
                        <td class="column5 style2 s">YES</td>
                      </tr>
                      <tr class="row134">
                        <td class="column0 style2 n">134</td>
                        <td class="column1 style3 s">RAJDEEP TCG</td>
                        <td class="column2 style4 s">SARAT PALLY</td>
                        <td class="column3 style2 n">11</td>
                        <td class="column4 style4 s">SARAT PALLY</td>
                        <td class="column5 style2 s">YES</td>
                      </tr>
                      <tr class="row135">
                        <td class="column0 style2 n">135</td>
                        <td class="column1 style3 s">INDIRA TCG</td>
                        <td class="column2 style5 s">NITYA NANDA NAGAR</td>
                        <td class="column3 style2 n">11</td>
                        <td class="column4 style5 s">NITYA NANDA NAGAR</td>
                        <td class="column5 style2 s">YES</td>
                      </tr>
                      <tr class="row136">
                        <td class="column0 style2 n">136</td>
                        <td class="column1 style3 s">RAMKRISHNA PALLY 1NO TCG</td>
                        <td class="column2 style5 s">RAMKRISHNA PALLY</td>
                        <td class="column3 style2 n">11</td>
                        <td class="column4 style5 s">RAMKRISHNA PALLY</td>
                        <td class="column5 style2 s">YES</td>
                      </tr>
                      <tr class="row137">
                        <td class="column0 style2 n">137</td>
                        <td class="column1 style3 s">BHABANIGARH 1NO TCG</td>
                        <td class="column2 style5 s">BHABANI GARH</td>
                        <td class="column3 style2 n">11</td>
                        <td class="column4 style5 s">BHABANI GARH</td>
                        <td class="column5 style2 s">YES</td>
                      </tr>
                      <tr class="row138">
                        <td class="column0 style2 n">138</td>
                        <td class="column1 style3 s">RAJOKINI TCG</td>
                        <td class="column2 style5 s">DAKSHIN PARA</td>
                        <td class="column3 style2 n">11</td>
                        <td class="column4 style5 s">DAKSHIN PARA</td>
                        <td class="column5 style2 s">YES</td>
                      </tr>
                      <tr class="row139">
                        <td class="column0 style2 n">139</td>
                        <td class="column1 style3 s">BANSARI  TCG</td>
                        <td class="column2 style5 s">SARAT PALLY </td>
                        <td class="column3 style2 n">11</td>
                        <td class="column4 style5 s">SARAT PALLY </td>
                        <td class="column5 style2 s">YES</td>
                      </tr>
                      <tr class="row140">
                        <td class="column0 style2 n">140</td>
                        <td class="column1 style3 s">SARAT PALLY 1NO TCG</td>
                        <td class="column2 style5 s">SARAT PALLY</td>
                        <td class="column3 style2 n">11</td>
                        <td class="column4 style5 s">SARAT PALLY</td>
                        <td class="column5 style2 s">YES</td>
                      </tr>
                      <tr class="row141">
                        <td class="column0 style2 n">141</td>
                        <td class="column1 style3 s">NEW BHABANIGARH 1 NO TCG</td>
                        <td class="column2 style5 s">NEW BHABANIGARH</td>
                        <td class="column3 style2 n">11</td>
                        <td class="column4 style5 s">NEW BHABANIGARH</td>
                        <td class="column5 style2 s">YES</td>
                      </tr>
                      <tr class="row142">
                        <td class="column0 style2 n">142</td>
                        <td class="column1 style3 s">DAKSHIN  PARA 1NO TCG</td>
                        <td class="column2 style5 s">DAKSHIN PARA</td>
                        <td class="column3 style2 n">11</td>
                        <td class="column4 style5 s">DAKSHIN PARA</td>
                        <td class="column5 style2 s">YES</td>
                      </tr>
                      <tr class="row143">
                        <td class="column0 style2 n">143</td>
                        <td class="column1 style3 s">RAMKRISHNA PALLY 2NO TCG</td>
                        <td class="column2 style5 s">RAMKRISHNA PALLY</td>
                        <td class="column3 style2 n">11</td>
                        <td class="column4 style5 s">RAMKRISHNA PALLY</td>
                        <td class="column5 style2 s">YES</td>
                      </tr>
                      <tr class="row144">
                        <td class="column0 style2 n">144</td>
                        <td class="column1 style3 s">RAMKRISHNA PALLY 3 NO TCG</td>
                        <td class="column2 style5 s">RAMKRISHNA PALLY</td>
                        <td class="column3 style2 n">11</td>
                        <td class="column4 style5 s">RAMKRISHNA PALLY</td>
                        <td class="column5 style2 s">YES</td>
                      </tr>
                      <tr class="row145">
                        <td class="column0 style2 n">145</td>
                        <td class="column1 style3 s">SHARAT PALLY 4 NO SHG</td>
                        <td class="column2 style5 s">SARAT PALLY</td>
                        <td class="column3 style2 n">11</td>
                        <td class="column4 style5 s">SARAT PALLY</td>
                        <td class="column5 style2 s">YES</td>
                      </tr>
                      <tr class="row146">
                        <td class="column0 style2 n">146</td>
                        <td class="column1 style3 s">BHABANI GARH 2 NO SHG</td>
                        <td class="column2 style5 s">NEW BHABANIGARH</td>
                        <td class="column3 style2 n">11</td>
                        <td class="column4 style5 s">NEW BHABANIGARH</td>
                        <td class="column5 style2 s">YES</td>
                      </tr>
                      <tr class="row147">
                        <td class="column0 style2 n">147</td>
                        <td class="column1 style3 s">SARAT PALLY 5 NO SHG</td>
                        <td class="column2 style5 s">SARAT PALLY</td>
                        <td class="column3 style2 n">11</td>
                        <td class="column4 style5 s">SARAT PALLY</td>
                        <td class="column5 style2 s">YES</td>
                      </tr>
                      <tr class="row148">
                        <td class="column0 style2 n">148</td>
                        <td class="column1 style3 s">BHABANI GARH 3 SHG</td>
                        <td class="column2 style5 s">BHABANI GARH</td>
                        <td class="column3 style2 n">11</td>
                        <td class="column4 style5 s">BHABANI GARH</td>
                        <td class="column5 style2 s">YES</td>
                      </tr>
                      <tr class="row149">
                        <td class="column0 style2 n">149</td>
                        <td class="column1 style3 s">BAISHAKHI TCG</td>
                        <td class="column2 style5 s">MADAN MOHAN PALY</td>
                        <td class="column3 style2 n">12</td>
                        <td class="column4 style5 s">MADAN MOHAN PALY</td>
                        <td class="column5 style2 s">YES</td>
                      </tr>
                      <tr class="row150">
                        <td class="column0 style2 n">150</td>
                        <td class="column1 style3 s">AHANA TCG</td>
                        <td class="column2 style5 s">S.V SARAN DAS PARA</td>
                        <td class="column3 style2 n">12</td>
                        <td class="column4 style5 s">S.V SARAN DAS PARA</td>
                        <td class="column5 style2 s">YES</td>
                      </tr>
                      <tr class="row151">
                        <td class="column0 style2 n">151</td>
                        <td class="column1 style3 s">PAKIZA TCG </td>
                        <td class="column2 style4 s">NASRA PARA</td>
                        <td class="column3 style2 n">12</td>
                        <td class="column4 style4 s">NASRA PARA</td>
                        <td class="column5 style2 s">YES</td>
                      </tr>
                      <tr class="row152">
                        <td class="column0 style2 n">152</td>
                        <td class="column1 style3 s">MADAN MOHAN PALLY 1NO TCG</td>
                        <td class="column2 style5 s">MADAN MOHAN PALY</td>
                        <td class="column3 style2 n">12</td>
                        <td class="column4 style5 s">MADAN MOHAN PALY</td>
                        <td class="column5 style2 s">YES</td>
                      </tr>
                      <tr class="row153">
                        <td class="column0 style2 n">153</td>
                        <td class="column1 style3 s">DAS PARA 1NO TCG</td>
                        <td class="column2 style5 s">S.V SARAN DAS PARA</td>
                        <td class="column3 style2 n">12</td>
                        <td class="column4 style5 s">S.V SARAN DAS PARA</td>
                        <td class="column5 style2 s">YES</td>
                      </tr>
                      <tr class="row154">
                        <td class="column0 style2 n">154</td>
                        <td class="column1 style3 s">SILATALA 2NO TCG </td>
                        <td class="column2 style5 s">BHOLANATH RUDRA ROAD</td>
                        <td class="column3 style2 n">12</td>
                        <td class="column4 style5 s">BHOLANATH RUDRA ROAD</td>
                        <td class="column5 style2 s">YES</td>
                      </tr>
                      <tr class="row155">
                        <td class="column0 style2 n">155</td>
                        <td class="column1 style3 s">MADAN MOHAN PALLY 2NO TCG</td>
                        <td class="column2 style5 s">MADAN MOHAN PALY</td>
                        <td class="column3 style2 n">12</td>
                        <td class="column4 style5 s">MADAN MOHAN PALY</td>
                        <td class="column5 style2 s">YES</td>
                      </tr>
                      <tr class="row156">
                        <td class="column0 style2 n">156</td>
                        <td class="column1 style3 s">NASRA PARA 1NO TCG</td>
                        <td class="column2 style5 s">SHARMA PARA LANE( NASRA PARA)</td>
                        <td class="column3 style2 n">12</td>
                        <td class="column4 style5 s">SHARMA PARA LANE( NASRA PARA)</td>
                        <td class="column5 style2 s">YES</td>
                      </tr>
                      <tr class="row157">
                        <td class="column0 style2 n">157</td>
                        <td class="column1 style3 s">MADAN MOHAN COLONY 3NO</td>
                        <td class="column2 style5 s">MADAN MOHAN COLONY</td>
                        <td class="column3 style2 n">12</td>
                        <td class="column4 style5 s">MADAN MOHAN COLONY</td>
                        <td class="column5 style2 s">YES</td>
                      </tr>
                      <tr class="row158">
                        <td class="column0 style2 n">158</td>
                        <td class="column1 style3 s">SITALATALA 3NO</td>
                        <td class="column2 style5 s">SITALA TALA</td>
                        <td class="column3 style2 n">12</td>
                        <td class="column4 style5 s">SITALA TALA</td>
                        <td class="column5 style2 s">YES</td>
                      </tr>
                      <tr class="row159">
                        <td class="column0 style2 n">159</td>
                        <td class="column1 style3 s">SITALA TALA  4 NO</td>
                        <td class="column2 style5 s">SITALA TALA</td>
                        <td class="column3 style2 n">12</td>
                        <td class="column4 style5 s">SITALA TALA</td>
                        <td class="column5 style2 s">YES</td>
                      </tr>
                      <tr class="row160">
                        <td class="column0 style2 n">160</td>
                        <td class="column1 style3 s">SITALA TALA 5 NO</td>
                        <td class="column2 style5 s">SITALA TALA</td>
                        <td class="column3 style2 n">12</td>
                        <td class="column4 style5 s">SITALA TALA</td>
                        <td class="column5 style2 s">YES</td>
                      </tr>
                      <tr class="row161">
                        <td class="column0 style2 n">161</td>
                        <td class="column1 style3 s">MISSION ROAD 1 NO SHG</td>
                        <td class="column2 style5 s">MISSION ROAD</td>
                        <td class="column3 style2 n">12</td>
                        <td class="column4 style5 s">MISSION ROAD</td>
                        <td class="column5 style2 s">YES</td>
                      </tr>
                      <tr class="row162">
                        <td class="column0 style2 n">162</td>
                        <td class="column1 style3 s">MADAN MOHAN COLONY 4 NO</td>
                        <td class="column2 style5 s">MADAN MOHAN COLONY</td>
                        <td class="column3 style2 n">12</td>
                        <td class="column4 style5 s">MADAN MOHAN COLONY</td>
                        <td class="column5 style2 s">YES</td>
                      </tr>
                      <tr class="row163">
                        <td class="column0 style2 n">163</td>
                        <td class="column1 style3 s">MADAN MOHAN COLONY 5 NO</td>
                        <td class="column2 style5 s">MADAN MOHAN COLONY</td>
                        <td class="column3 style2 n">12</td>
                        <td class="column4 style5 s">MADAN MOHAN COLONY</td>
                        <td class="column5 style2 s">YES</td>
                      </tr>
                      <tr class="row164">
                        <td class="column0 style2 n">164</td>
                        <td class="column1 style3 s">MISSION ROAD 2 NO SHG</td>
                        <td class="column2 style5 s">MISSION ROAD</td>
                        <td class="column3 style2 n">12</td>
                        <td class="column4 style5 s">MISSION ROAD</td>
                        <td class="column5 style2 s">YES</td>
                      </tr>
                      <tr class="row165">
                        <td class="column0 style2 n">165</td>
                        <td class="column1 style3 s">SADHUR BAGAN 2 NO SHG</td>
                        <td class="column2 style5 s">SADHUR BAGAN</td>
                        <td class="column3 style2 n">12</td>
                        <td class="column4 style5 s">SADHUR BAGAN</td>
                        <td class="column5 style2 s">YES</td>
                      </tr>
                      <tr class="row166">
                        <td class="column0 style2 n">166</td>
                        <td class="column1 style3 s">HARIZAN PALLY 1 NO SHG</td>
                        <td class="column2 style5 s">HARIZAN PALLY</td>
                        <td class="column3 style2 n">12</td>
                        <td class="column4 style5 s">HARIZAN PALLY</td>
                        <td class="column5 style2 s">YES</td>
                      </tr>
                      <tr class="row167">
                        <td class="column0 style2 n">167</td>
                        <td class="column1 style3 s">SHITALA TALA 6 NO SHG</td>
                        <td class="column2 style5 s">SHITALATALA</td>
                        <td class="column3 style2 n">12</td>
                        <td class="column4 style5 s">SHITALATALA</td>
                        <td class="column5 style2 s">YES</td>
                      </tr>
                      <tr class="row168">
                        <td class="column0 style2 n">168</td>
                        <td class="column1 style3 s">SHITALA TALA 7 NO SHG</td>
                        <td class="column2 style5 s">SHITALATALA</td>
                        <td class="column3 style2 n">12</td>
                        <td class="column4 style5 s">SHITALATALA</td>
                        <td class="column5 style2 s">YES</td>
                      </tr>
                      <tr class="row169">
                        <td class="column0 style2 n">169</td>
                        <td class="column1 style3 s">SADHUR BAGAN 3 NO SHG</td>
                        <td class="column2 style5 s">SADHUR BAGAN</td>
                        <td class="column3 style2 n">12</td>
                        <td class="column4 style5 s">SADHUR BAGAN</td>
                        <td class="column5 style2 s">YES</td>
                      </tr>
                      <tr class="row170">
                        <td class="column0 style2 n">170</td>
                        <td class="column1 style3 s">SADHUR BAGAN 4 NO SHG</td>
                        <td class="column2 style5 s">SADHUR BAGAN</td>
                        <td class="column3 style2 n">12</td>
                        <td class="column4 style5 s">SADHUR BAGAN</td>
                        <td class="column5 style2 s">YES</td>
                      </tr>
                      <tr class="row171">
                        <td class="column0 style2 n">171</td>
                        <td class="column1 style3 s">SADHUR BAGAN 5 NO SHG</td>
                        <td class="column2 style5 s">SADHUR BAGAN</td>
                        <td class="column3 style2 n">12</td>
                        <td class="column4 style5 s">SADHUR BAGAN</td>
                        <td class="column5 style2 s">YES</td>
                      </tr>
                      <tr class="row172">
                        <td class="column0 style2 n">172</td>
                        <td class="column1 style3 s">SADHUR BAGAN 6 NO SHG</td>
                        <td class="column2 style5 s">SADHUR BAGAN</td>
                        <td class="column3 style2 n">12</td>
                        <td class="column4 style5 s">SADHUR BAGAN</td>
                        <td class="column5 style2 s">YES</td>
                      </tr>
                      <tr class="row173">
                        <td class="column0 style2 n">173</td>
                        <td class="column1 style3 s">SADHUR BAGAN 7 NO SHG</td>
                        <td class="column2 style5 s">SADHUR BAGAN</td>
                        <td class="column3 style2 n">12</td>
                        <td class="column4 style5 s">SADHUR BAGAN</td>
                        <td class="column5 style2 s">YES</td>
                      </tr>
                      <tr class="row174">
                        <td class="column0 style2 n">174</td>
                        <td class="column1 style3 s">SHITALATALA 8 NO </td>
                        <td class="column2 style5 s">SHITALATALA</td>
                        <td class="column3 style2 n">12</td>
                        <td class="column4 style5 s">SHITALATALA</td>
                        <td class="column5 style2 s">YES</td>
                      </tr>
                      <tr class="row175">
                        <td class="column0 style2 n">175</td>
                        <td class="column1 style3 s">SHITALATALA 9  NO </td>
                        <td class="column2 style5 s">SHITALATALA</td>
                        <td class="column3 style2 n">12</td>
                        <td class="column4 style5 s">SHITALATALA</td>
                        <td class="column5 style2 s">YES</td>
                      </tr>
                      <tr class="row176">
                        <td class="column0 style2 n">176</td>
                        <td class="column1 style3 s">SADHUR BAGAN 8 NO SHG</td>
                        <td class="column2 style5 s">SADHUR BAGAN</td>
                        <td class="column3 style2 n">12</td>
                        <td class="column4 style5 s">SADHUR BAGAN</td>
                        <td class="column5 style2 s">YES</td>
                      </tr>
                      <tr class="row177">
                        <td class="column0 style2 n">177</td>
                        <td class="column1 style3 s">DAS PARA 2 NO SHG</td>
                        <td class="column2 style5 s">DAS PARA</td>
                        <td class="column3 style2 n">12</td>
                        <td class="column4 style5 s">DAS PARA</td>
                        <td class="column5 style2 s">YES</td>
                      </tr>
                      <tr class="row178">
                        <td class="column0 style2 n">178</td>
                        <td class="column1 style3 s">DAS PARA 3 NO SHG</td>
                        <td class="column2 style5 s">DAS PARA</td>
                        <td class="column3 style2 n">12</td>
                        <td class="column4 style5 s">DAS PARA</td>
                        <td class="column5 style2 s">YES</td>
                      </tr>
                      <tr class="row179">
                        <td class="column0 style2 n">179</td>
                        <td class="column1 style3 s">MAA DURGA  TCG</td>
                        <td class="column2 style5 s">UTTAR NASRA RANAGHAT NADIA</td>
                        <td class="column3 style2 n">13</td>
                        <td class="column4 style5 s">UTTAR NASRA RANAGHAT NADIA</td>
                        <td class="column5 style2 s">YES</td>
                      </tr>
                      <tr class="row180">
                        <td class="column0 style2 n">180</td>
                        <td class="column1 style3 s">MAA TARA TCG</td>
                        <td class="column2 style5 s">UTTAR NASRA RANAGHAT NADIA</td>
                        <td class="column3 style2 n">13</td>
                        <td class="column4 style5 s">UTTAR NASRA RANAGHAT NADIA</td>
                        <td class="column5 style2 s">YES</td>
                      </tr>
                      <tr class="row181">
                        <td class="column0 style2 n">181</td>
                        <td class="column1 style3 s">SURIYA SEN TCG</td>
                        <td class="column2 style5 s">UTTAR NASRA RANAGHAT NADIA</td>
                        <td class="column3 style2 n">13</td>
                        <td class="column4 style5 s">UTTAR NASRA RANAGHAT NADIA</td>
                        <td class="column5 style2 s">YES</td>
                      </tr>
                      <tr class="row182">
                        <td class="column0 style2 n">182</td>
                        <td class="column1 style3 s">MAA JAHURA TCG</td>
                        <td class="column2 style5 s">UTTAR NASRA RANAGHAT NADIA</td>
                        <td class="column3 style2 n">13</td>
                        <td class="column4 style5 s">UTTAR NASRA RANAGHAT NADIA</td>
                        <td class="column5 style2 s">YES</td>
                      </tr>
                      <tr class="row183">
                        <td class="column0 style2 n">183</td>
                        <td class="column1 style3 s">MAA CHANDI TCG</td>
                        <td class="column2 style5 s">UTTAR NASRA RANAGHAT NADIA</td>
                        <td class="column3 style2 n">13</td>
                        <td class="column4 style5 s">UTTAR NASRA RANAGHAT NADIA</td>
                        <td class="column5 style2 s">YES</td>
                      </tr>
                      <tr class="row184">
                        <td class="column0 style2 n">184</td>
                        <td class="column1 style3 s">BHOLANATH TCG</td>
                        <td class="column2 style5 s">UTTAR NASRA RANAGHAT NADIA</td>
                        <td class="column3 style2 n">13</td>
                        <td class="column4 style5 s">UTTAR NASRA RANAGHAT NADIA</td>
                        <td class="column5 style2 s">YES</td>
                      </tr>
                      <tr class="row185">
                        <td class="column0 style2 n">185</td>
                        <td class="column1 style3 s">&nbsp;JAY HIND TCG</td>
                        <td class="column2 style5 s">UTTAR NASRA RANAGHAT NADIA</td>
                        <td class="column3 style2 n">13</td>
                        <td class="column4 style5 s">UTTAR NASRA RANAGHAT NADIA</td>
                        <td class="column5 style2 s">YES</td>
                      </tr>
                      <tr class="row186">
                        <td class="column0 style2 n">186</td>
                        <td class="column1 style3 s">MAITRI TCG</td>
                        <td class="column2 style5 s">UTTAR NASRA RANAGHAT NADIA</td>
                        <td class="column3 style2 n">13</td>
                        <td class="column4 style5 s">UTTAR NASRA RANAGHAT NADIA</td>
                        <td class="column5 style2 s">YES</td>
                      </tr>
                      <tr class="row187">
                        <td class="column0 style2 n">187</td>
                        <td class="column1 style3 s">BISHAL TCG</td>
                        <td class="column2 style5 s">UTTAR NASRA RANAGHAT NADIA</td>
                        <td class="column3 style2 n">13</td>
                        <td class="column4 style5 s">UTTAR NASRA RANAGHAT NADIA</td>
                        <td class="column5 style2 s">YES</td>
                      </tr>
                      <tr class="row188">
                        <td class="column0 style2 n">188</td>
                        <td class="column1 style3 s">BASANTI TCG</td>
                        <td class="column2 style5 s">UTTAR NASRA RANAGHAT NADIA</td>
                        <td class="column3 style2 n">13</td>
                        <td class="column4 style5 s">UTTAR NASRA RANAGHAT NADIA</td>
                        <td class="column5 style2 s">YES</td>
                      </tr>
                      <tr class="row189">
                        <td class="column0 style2 n">189</td>
                        <td class="column1 style3 s">UPASANA TCG</td>
                        <td class="column2 style5 s">UTTAR NASRA RANAGHAT NADIA</td>
                        <td class="column3 style2 n">13</td>
                        <td class="column4 style5 s">UTTAR NASRA RANAGHAT NADIA</td>
                        <td class="column5 style2 s">YES</td>
                      </tr>
                      <tr class="row190">
                        <td class="column0 style2 n">190</td>
                        <td class="column1 style3 s">TRINTH TCG</td>
                        <td class="column2 style5 s">UTTAR NASRA RANAGHAT NADIA</td>
                        <td class="column3 style2 n">13</td>
                        <td class="column4 style5 s">UTTAR NASRA RANAGHAT NADIA</td>
                        <td class="column5 style2 s">YES</td>
                      </tr>
                      <tr class="row191">
                        <td class="column0 style2 n">191</td>
                        <td class="column1 style3 s">UTTARNASRA 1NO TCG</td>
                        <td class="column2 style5 s">UTTAR NASRA RANAGHAT NADIA</td>
                        <td class="column3 style2 n">13</td>
                        <td class="column4 style5 s">UTTAR NASRA RANAGHAT NADIA</td>
                        <td class="column5 style2 s">YES</td>
                      </tr>
                      <tr class="row192">
                        <td class="column0 style2 n">192</td>
                        <td class="column1 style3 s">UTTARNASRA 2NO TCG</td>
                        <td class="column2 style5 s">UTTAR NASRA RANAGHAT NADIA</td>
                        <td class="column3 style2 n">13</td>
                        <td class="column4 style5 s">UTTAR NASRA RANAGHAT NADIA</td>
                        <td class="column5 style2 s">YES</td>
                      </tr>
                      <tr class="row193">
                        <td class="column0 style2 n">193</td>
                        <td class="column1 style3 s">UTTARNASRA 3NO TCG</td>
                        <td class="column2 style5 s">UTTAR NASRA RANAGHAT NADIA</td>
                        <td class="column3 style2 n">13</td>
                        <td class="column4 style5 s">UTTAR NASRA RANAGHAT NADIA</td>
                        <td class="column5 style2 s">YES</td>
                      </tr>
                      <tr class="row194">
                        <td class="column0 style2 n">194</td>
                        <td class="column1 style3 s">UTTARNASRA 6NO TCG</td>
                        <td class="column2 style5 s">UTTAR NASRA RANAGHAT NADIA</td>
                        <td class="column3 style2 n">13</td>
                        <td class="column4 style5 s">UTTAR NASRA RANAGHAT NADIA</td>
                        <td class="column5 style2 s">YES</td>
                      </tr>
                      <tr class="row195">
                        <td class="column0 style2 n">195</td>
                        <td class="column1 style3 s">UTTARNASRA 7 NO TCG</td>
                        <td class="column2 style5 s">UTTAR NASRA RANAGHAT NADIA</td>
                        <td class="column3 style2 n">13</td>
                        <td class="column4 style5 s">UTTAR NASRA RANAGHAT NADIA</td>
                        <td class="column5 style2 s">YES</td>
                      </tr>
                      <tr class="row196">
                        <td class="column0 style2 n">196</td>
                        <td class="column1 style3 s">UTTARNASRA 8 NO TCG</td>
                        <td class="column2 style5 s">UTTAR NASRA RANAGHAT NADIA</td>
                        <td class="column3 style2 n">13</td>
                        <td class="column4 style5 s">UTTAR NASRA RANAGHAT NADIA</td>
                        <td class="column5 style2 s">YES</td>
                      </tr>
                      <tr class="row197">
                        <td class="column0 style2 n">197</td>
                        <td class="column1 style3 s">UTTARNASRA 9 NO TCG</td>
                        <td class="column2 style5 s">UTTAR NASRA RANAGHAT NADIA</td>
                        <td class="column3 style2 n">13</td>
                        <td class="column4 style5 s">UTTAR NASRA RANAGHAT NADIA</td>
                        <td class="column5 style2 s">YES</td>
                      </tr>
                      <tr class="row198">
                        <td class="column0 style2 n">198</td>
                        <td class="column1 style3 s">UTTAR NASRA 11NO SHG</td>
                        <td class="column2 style5 s">UTTAR NASRA</td>
                        <td class="column3 style2 n">13</td>
                        <td class="column4 style5 s">UTTAR NASRA</td>
                        <td class="column5 style2 s">YES</td>
                      </tr>
                      <tr class="row199">
                        <td class="column0 style2 n">199</td>
                        <td class="column1 style3 s">UTTAR NASRA 12NO SHG</td>
                        <td class="column2 style5 s">UTTAR NASRA</td>
                        <td class="column3 style2 n">13</td>
                        <td class="column4 style5 s">UTTAR NASRA</td>
                        <td class="column5 style2 s">YES</td>
                      </tr>
                      <tr class="row200">
                        <td class="column0 style2 n">200</td>
                        <td class="column1 style3 s">UTTAR NASRA 13NO SHG</td>
                        <td class="column2 style5 s">UTTAR NASRA</td>
                        <td class="column3 style2 n">13</td>
                        <td class="column4 style5 s">UTTAR NASRA</td>
                        <td class="column5 style2 s">YES</td>
                      </tr>
                      <tr class="row201">
                        <td class="column0 style2 n">201</td>
                        <td class="column1 style3 s">UTTAR NASRA 14NO SHG</td>
                        <td class="column2 style5 s">UTTAR NASRA</td>
                        <td class="column3 style2 n">13</td>
                        <td class="column4 style5 s">UTTAR NASRA</td>
                        <td class="column5 style2 s">YES</td>
                      </tr>
                      <tr class="row202">
                        <td class="column0 style2 n">202</td>
                        <td class="column1 style3 s">UTTAR NASRA 15NO SHG</td>
                        <td class="column2 style5 s">UTTAR NASRA</td>
                        <td class="column3 style2 n">13</td>
                        <td class="column4 style5 s">UTTAR NASRA</td>
                        <td class="column5 style2 s">YES</td>
                      </tr>
                      <tr class="row203">
                        <td class="column0 style2 n">203</td>
                        <td class="column1 style3 s">UTTAR NASRA 16NO SHG</td>
                        <td class="column2 style5 s">UTTAR NASRA</td>
                        <td class="column3 style2 n">13</td>
                        <td class="column4 style5 s">UTTAR NASRA</td>
                        <td class="column5 style2 s">YES</td>
                      </tr>
                      <tr class="row204">
                        <td class="column0 style2 n">204</td>
                        <td class="column1 style3 s">UTTAR NASRA 17NO SHG</td>
                        <td class="column2 style5 s">UTTAR NASRA</td>
                        <td class="column3 style2 n">13</td>
                        <td class="column4 style5 s">UTTAR NASRA</td>
                        <td class="column5 style2 s">YES</td>
                      </tr>
                      <tr class="row205">
                        <td class="column0 style2 n">205</td>
                        <td class="column1 style3 s">UTTAR NASRA 18NO SHG</td>
                        <td class="column2 style5 s">UTTAR NASRA</td>
                        <td class="column3 style2 n">13</td>
                        <td class="column4 style5 s">UTTAR NASRA</td>
                        <td class="column5 style2 s">YES</td>
                      </tr>
                      <tr class="row206">
                        <td class="column0 style2 n">206</td>
                        <td class="column1 style3 s">UTTAR NASRA 19NO SHG</td>
                        <td class="column2 style5 s">UTTAR NASRA</td>
                        <td class="column3 style2 n">13</td>
                        <td class="column4 style5 s">UTTAR NASRA</td>
                        <td class="column5 style2 s">YES</td>
                      </tr>
                      <tr class="row207">
                        <td class="column0 style2 n">207</td>
                        <td class="column1 style3 s">UTTAR NASRA 20 NO SHG</td>
                        <td class="column2 style5 s">UTTAR NASRA</td>
                        <td class="column3 style2 n">13</td>
                        <td class="column4 style5 s">UTTAR NASRA</td>
                        <td class="column5 style2 s">YES</td>
                      </tr>
                      <tr class="row208">
                        <td class="column0 style2 n">208</td>
                        <td class="column1 style3 s">UTTAR NASRA 21NO SHG</td>
                        <td class="column2 style5 s">UTTAR NASRA</td>
                        <td class="column3 style2 n">13</td>
                        <td class="column4 style5 s">UTTAR NASRA</td>
                        <td class="column5 style2 s">YES</td>
                      </tr>
                      <tr class="row209">
                        <td class="column0 style2 n">209</td>
                        <td class="column1 style3 s">UTTAR NASRA 22NO SHG</td>
                        <td class="column2 style5 s">UTTAR NASRA</td>
                        <td class="column3 style2 n">13</td>
                        <td class="column4 style5 s">UTTAR NASRA</td>
                        <td class="column5 style2 s">YES</td>
                      </tr>
                      <tr class="row210">
                        <td class="column0 style2 n">210</td>
                        <td class="column1 style3 s">UTTAR NASRA 23NO SHG</td>
                        <td class="column2 style5 s">UTTAR NASRA</td>
                        <td class="column3 style2 n">13</td>
                        <td class="column4 style5 s">UTTAR NASRA</td>
                        <td class="column5 style2 s">YES</td>
                      </tr>
                      <tr class="row211">
                        <td class="column0 style2 n">211</td>
                        <td class="column1 style3 s">UTTAR NASRA 10 NO SHG</td>
                        <td class="column2 style5 s">UTTAR NASRA</td>
                        <td class="column3 style2 n">13</td>
                        <td class="column4 style5 s">UTTAR NASRA</td>
                        <td class="column5 style2 s">YES</td>
                      </tr>
                      <tr class="row212">
                        <td class="column0 style2 n">212</td>
                        <td class="column1 style3 s">MAA MANASA TCG</td>
                        <td class="column2 style4 s">A.P ROAD</td>
                        <td class="column3 style2 n">14</td>
                        <td class="column4 style4 s">A.P ROAD</td>
                        <td class="column5 style2 s">YES</td>
                      </tr>
                      <tr class="row213">
                        <td class="column0 style2 n">213</td>
                        <td class="column1 style3 s">NETAJI TCG</td>
                        <td class="column2 style4 s">CHUNURI PARA</td>
                        <td class="column3 style2 n">14</td>
                        <td class="column4 style4 s">CHUNURI PARA</td>
                        <td class="column5 style2 s">YES</td>
                      </tr>
                      <tr class="row214">
                        <td class="column0 style2 n">214</td>
                        <td class="column1 style3 s">SHIBAJI TCG</td>
                        <td class="column2 style4 s">MANASA TALA</td>
                        <td class="column3 style2 n">14</td>
                        <td class="column4 style4 s">MANASA TALA</td>
                        <td class="column5 style2 s">YES</td>
                      </tr>
                      <tr class="row215">
                        <td class="column0 style2 n">215</td>
                        <td class="column1 style3 s">AROBINDA TCG</td>
                        <td class="column2 style4 s">A.P ROAD</td>
                        <td class="column3 style2 n">14</td>
                        <td class="column4 style4 s">A.P ROAD</td>
                        <td class="column5 style2 s">YES</td>
                      </tr>
                      <tr class="row216">
                        <td class="column0 style2 n">216</td>
                        <td class="column1 style3 s">TARAKNATH TCG</td>
                        <td class="column2 style5 s">NASRA COLONY</td>
                        <td class="column3 style2 n">14</td>
                        <td class="column4 style5 s">NASRA COLONY</td>
                        <td class="column5 style2 s">YES</td>
                      </tr>
                      <tr class="row217">
                        <td class="column0 style2 n">217</td>
                        <td class="column1 style3 s">PARAMA TCG</td>
                        <td class="column2 style5 s">SREE NAGAR JANATA ROAD </td>
                        <td class="column3 style2 n">14</td>
                        <td class="column4 style5 s">SREE NAGAR JANATA ROAD </td>
                        <td class="column5 style2 s">YES</td>
                      </tr>
                      <tr class="row218">
                        <td class="column0 style2 n">218</td>
                        <td class="column1 style3 s">SUBHAS TCG</td>
                        <td class="column2 style5 s">SREE NAGAR JANATA ROAD </td>
                        <td class="column3 style2 n">14</td>
                        <td class="column4 style5 s">SREE NAGAR JANATA ROAD </td>
                        <td class="column5 style2 s">YES</td>
                      </tr>
                      <tr class="row219">
                        <td class="column0 style2 n">219</td>
                        <td class="column1 style3 s">S.N.J ROAD 1NO TCG</td>
                        <td class="column2 style5 s">SREE NAGAR JANATA ROAD </td>
                        <td class="column3 style2 n">14</td>
                        <td class="column4 style5 s">SREE NAGAR JANATA ROAD </td>
                        <td class="column5 style2 s">YES</td>
                      </tr>
                      <tr class="row220">
                        <td class="column0 style2 n">220</td>
                        <td class="column1 style3 s">CHUNURI PARA 1NO TCG</td>
                        <td class="column2 style5 s">CHUNURI PARA</td>
                        <td class="column3 style2 n">14</td>
                        <td class="column4 style5 s">CHUNURI PARA</td>
                        <td class="column5 style2 s">YES</td>
                      </tr>
                      <tr class="row221">
                        <td class="column0 style2 n">221</td>
                        <td class="column1 style3 s">A.P ROAD 1NO TCG</td>
                        <td class="column2 style5 s">A.P ROAD</td>
                        <td class="column3 style2 n">14</td>
                        <td class="column4 style5 s">A.P ROAD</td>
                        <td class="column5 style2 s">YES</td>
                      </tr>
                      <tr class="row222">
                        <td class="column0 style2 n">222</td>
                        <td class="column1 style3 s">MANASATALA 1NO TCG</td>
                        <td class="column2 style5 s">MANASA TALA PARA</td>
                        <td class="column3 style2 n">14</td>
                        <td class="column4 style5 s">MANASA TALA PARA</td>
                        <td class="column5 style2 s">YES</td>
                      </tr>
                      <tr class="row223">
                        <td class="column0 style2 n">223</td>
                        <td class="column1 style3 s">PARK STREET 1NO TCG</td>
                        <td class="column2 style5 s">PARK STREET</td>
                        <td class="column3 style2 n">14</td>
                        <td class="column4 style5 s">PARK STREET</td>
                        <td class="column5 style2 s">YES</td>
                      </tr>
                      <tr class="row224">
                        <td class="column0 style2 n">224</td>
                        <td class="column1 style3 s">PARK STREET 2NO TCG</td>
                        <td class="column2 style5 s">PARK STREET CHABI GATE</td>
                        <td class="column3 style2 n">14</td>
                        <td class="column4 style5 s">PARK STREET CHABI GATE</td>
                        <td class="column5 style2 s">YES</td>
                      </tr>
                      <tr class="row225">
                        <td class="column0 style2 n">225</td>
                        <td class="column1 style3 s">A.P ROAD 2NO TCG</td>
                        <td class="column2 style4 s">A.P ROAD</td>
                        <td class="column3 style2 n">14</td>
                        <td class="column4 style4 s">A.P ROAD</td>
                        <td class="column5 style2 s">YES</td>
                      </tr>
                      <tr class="row226">
                        <td class="column0 style2 n">226</td>
                        <td class="column1 style3 s">A.P ROAD 3NO TCG</td>
                        <td class="column2 style4 s">A.P ROAD</td>
                        <td class="column3 style2 n">14</td>
                        <td class="column4 style4 s">A.P ROAD</td>
                        <td class="column5 style2 s">YES</td>
                      </tr>
                      <tr class="row227">
                        <td class="column0 style2 n">227</td>
                        <td class="column1 style3 s">CHUNURI PARA 2NO TCG</td>
                        <td class="column2 style4 s">CHUNURI PARA</td>
                        <td class="column3 style2 n">14</td>
                        <td class="column4 style4 s">CHUNURI PARA</td>
                        <td class="column5 style2 s">YES</td>
                      </tr>
                      <tr class="row228">
                        <td class="column0 style2 n">228</td>
                        <td class="column1 style3 s">CHUNURI PARA 3NO</td>
                        <td class="column2 style4 s">CHUNURI PARA</td>
                        <td class="column3 style2 n">14</td>
                        <td class="column4 style4 s">CHUNURI PARA</td>
                        <td class="column5 style2 s">YES</td>
                      </tr>
                      <tr class="row229">
                        <td class="column0 style2 n">229</td>
                        <td class="column1 style3 s">CHUNURI PARA 4NO</td>
                        <td class="column2 style4 s">CHUNURI PARA</td>
                        <td class="column3 style2 n">14</td>
                        <td class="column4 style4 s">CHUNURI PARA</td>
                        <td class="column5 style2 s">YES</td>
                      </tr>
                      <tr class="row230">
                        <td class="column0 style2 n">230</td>
                        <td class="column1 style3 s">NASRA COLONY 1 NO TCG</td>
                        <td class="column2 style5 s">NASRA COLONY</td>
                        <td class="column3 style2 n">14</td>
                        <td class="column4 style5 s">NASRA COLONY</td>
                        <td class="column5 style2 s">YES</td>
                      </tr>
                      <tr class="row231">
                        <td class="column0 style2 n">231</td>
                        <td class="column1 style3 s">NASRA COLONY 2 NO TCG</td>
                        <td class="column2 style5 s">NASRA COLONY</td>
                        <td class="column3 style2 n">14</td>
                        <td class="column4 style5 s">NASRA COLONY</td>
                        <td class="column5 style2 s">YES</td>
                      </tr>
                      <tr class="row232">
                        <td class="column0 style2 n">232</td>
                        <td class="column1 style3 s">S.N.J ROAD 2NO TCG</td>
                        <td class="column2 style5 s">SREE NAGAR JANATA ROAD </td>
                        <td class="column3 style2 n">14</td>
                        <td class="column4 style5 s">SREE NAGAR JANATA ROAD </td>
                        <td class="column5 style2 s">YES</td>
                      </tr>
                      <tr class="row233">
                        <td class="column0 style2 n">233</td>
                        <td class="column1 style3 s">CHUNURI PARA 5 NO TCG</td>
                        <td class="column2 style4 s">CHUNURI PARA</td>
                        <td class="column3 style2 n">14</td>
                        <td class="column4 style4 s">CHUNURI PARA</td>
                        <td class="column5 style2 s">YES</td>
                      </tr>
                      <tr class="row234">
                        <td class="column0 style2 n">234</td>
                        <td class="column1 style3 s">CHUNURI PARA 6NO TCG</td>
                        <td class="column2 style4 s">CHUNURI PARA</td>
                        <td class="column3 style2 n">14</td>
                        <td class="column4 style4 s">CHUNURI PARA</td>
                        <td class="column5 style2 s">YES</td>
                      </tr>
                      <tr class="row235">
                        <td class="column0 style2 n">235</td>
                        <td class="column1 style3 s">MANASA TALA PARA 2 NO TCG</td>
                        <td class="column2 style5 s">MANASA TALA PARA</td>
                        <td class="column3 style2 n">14</td>
                        <td class="column4 style5 s">MANASA TALA PARA</td>
                        <td class="column5 style2 s">YES</td>
                      </tr>
                      <tr class="row236">
                        <td class="column0 style2 n">236</td>
                        <td class="column1 style3 s">S.N.J ROAD 3NO TCG</td>
                        <td class="column2 style5 s">SREE NAGAR JANATA ROAD </td>
                        <td class="column3 style2 n">14</td>
                        <td class="column4 style5 s">SREE NAGAR JANATA ROAD </td>
                        <td class="column5 style2 s">YES</td>
                      </tr>
                      <tr class="row237">
                        <td class="column0 style2 n">237</td>
                        <td class="column1 style3 s">CHUNURI PARA 7NO TCG</td>
                        <td class="column2 style4 s">CHUNURI PARA</td>
                        <td class="column3 style2 n">14</td>
                        <td class="column4 style4 s">CHUNURI PARA</td>
                        <td class="column5 style2 s">YES</td>
                      </tr>
                      <tr class="row238">
                        <td class="column0 style2 n">238</td>
                        <td class="column1 style3 s">CHUNURI PARA 8NO TCG</td>
                        <td class="column2 style4 s">CHUNURI PARA</td>
                        <td class="column3 style2 n">14</td>
                        <td class="column4 style4 s">CHUNURI PARA</td>
                        <td class="column5 style2 s">YES</td>
                      </tr>
                      <tr class="row239">
                        <td class="column0 style2 n">239</td>
                        <td class="column1 style3 s">MANASATALA 3 NO TCG</td>
                        <td class="column2 style4 s">MANASA TALA</td>
                        <td class="column3 style2 n">14</td>
                        <td class="column4 style4 s">MANASA TALA</td>
                        <td class="column5 style2 s">YES</td>
                      </tr>
                      <tr class="row240">
                        <td class="column0 style2 n">240</td>
                        <td class="column1 style3 s">PARK STREET 3 NO SHG</td>
                        <td class="column2 style5 s">PARK STREET</td>
                        <td class="column3 style2 n">14</td>
                        <td class="column4 style5 s">PARK STREET</td>
                        <td class="column5 style2 s">YES</td>
                      </tr>
                      <tr class="row241">
                        <td class="column0 style2 n">241</td>
                        <td class="column1 style3 s">PARK STREET 4 NO SHG</td>
                        <td class="column2 style5 s">PARK STREET</td>
                        <td class="column3 style2 n">14</td>
                        <td class="column4 style5 s">PARK STREET</td>
                        <td class="column5 style2 s">YES</td>
                      </tr>
                      <tr class="row242">
                        <td class="column0 style2 n">242</td>
                        <td class="column1 style3 s">GOPAL NAGAR ROAD 1 NO SHG</td>
                        <td class="column2 style5 s">GOPAL NAGAR</td>
                        <td class="column3 style2 n">14</td>
                        <td class="column4 style5 s">GOPAL NAGAR</td>
                        <td class="column5 style2 s">YES</td>
                      </tr>
                      <tr class="row243">
                        <td class="column0 style2 n">243</td>
                        <td class="column1 style3 s">CHUNURI PARA 9 NO SHG</td>
                        <td class="column2 style5 s">CHUNURI PARA</td>
                        <td class="column3 style2 n">14</td>
                        <td class="column4 style5 s">CHUNURI PARA</td>
                        <td class="column5 style2 s">YES</td>
                      </tr>
                      <tr class="row244">
                        <td class="column0 style2 n">244</td>
                        <td class="column1 style3 s">MANASHA TALA PARA 4 NO SHG</td>
                        <td class="column2 style5 s">MANATHA TALA</td>
                        <td class="column3 style2 n">14</td>
                        <td class="column4 style5 s">MANATHA TALA</td>
                        <td class="column5 style2 s">YES</td>
                      </tr>
                      <tr class="row245">
                        <td class="column0 style2 n">245</td>
                        <td class="column1 style3 s">PARK STREET 5 NO SHG</td>
                        <td class="column2 style5 s">PARK STREET</td>
                        <td class="column3 style2 n">14</td>
                        <td class="column4 style5 s">PARK STREET</td>
                        <td class="column5 style2 s">YES</td>
                      </tr>
                      <tr class="row246">
                        <td class="column0 style2 n">246</td>
                        <td class="column1 style3 s">S.N.J ROAD 4 NO SHG</td>
                        <td class="column2 style4 s">S,N,J ROAD</td>
                        <td class="column3 style2 n">14</td>
                        <td class="column4 style4 s">S,N,J ROAD</td>
                        <td class="column5 style2 s">YES</td>
                      </tr>
                      <tr class="row247">
                        <td class="column0 style2 n">247</td>
                        <td class="column1 style3 s">NASRA COLONY 3 NO SHG</td>
                        <td class="column2 style4 s">NASRA COLONY</td>
                        <td class="column3 style2 n">14</td>
                        <td class="column4 style4 s">NASRA COLONY</td>
                        <td class="column5 style2 s">YES</td>
                      </tr>
                      <tr class="row248">
                        <td class="column0 style2 n">248</td>
                        <td class="column1 style3 s">AGRAGAMI  TCG</td>
                        <td class="column2 style5 s">SURYA NAGAR</td>
                        <td class="column3 style2 n">15</td>
                        <td class="column4 style5 s">SURYA NAGAR</td>
                        <td class="column5 style2 s">YES</td>
                      </tr>
                      <tr class="row249">
                        <td class="column0 style2 n">249</td>
                        <td class="column1 style3 s">SANHATI TCG</td>
                        <td class="column2 style5 s">SURYA NAGAR</td>
                        <td class="column3 style2 n">15</td>
                        <td class="column4 style5 s">SURYA NAGAR</td>
                        <td class="column5 style2 s">YES</td>
                      </tr>
                      <tr class="row250">
                        <td class="column0 style2 n">250</td>
                        <td class="column1 style3 s">PRAGATI TCG</td>
                        <td class="column2 style5 s">SURYA NAGAR</td>
                        <td class="column3 style2 n">15</td>
                        <td class="column4 style5 s">SURYA NAGAR</td>
                        <td class="column5 style2 s">YES</td>
                      </tr>
                      <tr class="row251">
                        <td class="column0 style2 n">251</td>
                        <td class="column1 style3 s">AGRADUT TCG</td>
                        <td class="column2 style5 s">SURYA NAGAR</td>
                        <td class="column3 style2 n">15</td>
                        <td class="column4 style5 s">SURYA NAGAR</td>
                        <td class="column5 style2 s">YES</td>
                      </tr>
                      <tr class="row252">
                        <td class="column0 style2 n">252</td>
                        <td class="column1 style3 s">SURIYA SIKHA  TCG</td>
                        <td class="column2 style5 s">SURYA NAGAR</td>
                        <td class="column3 style2 n">15</td>
                        <td class="column4 style5 s">SURYA NAGAR</td>
                        <td class="column5 style2 s">YES</td>
                      </tr>
                      <tr class="row253">
                        <td class="column0 style2 n">253</td>
                        <td class="column1 style3 s">SURIYA KIRAN TCG</td>
                        <td class="column2 style5 s">SURYA NAGAR</td>
                        <td class="column3 style2 n">15</td>
                        <td class="column4 style5 s">SURYA NAGAR</td>
                        <td class="column5 style2 s">YES</td>
                      </tr>
                      <tr class="row254">
                        <td class="column0 style2 n">254</td>
                        <td class="column1 style3 s">SRI GOURANGA TCG</td>
                        <td class="column2 style5 s">SURYA NAGAR</td>
                        <td class="column3 style2 n">15</td>
                        <td class="column4 style5 s">SURYA NAGAR</td>
                        <td class="column5 style2 s">YES</td>
                      </tr>
                      <tr class="row255">
                        <td class="column0 style2 n">255</td>
                        <td class="column1 style3 s">SURYYA TAPA TCG</td>
                        <td class="column2 style5 s">SURYA NAGAR</td>
                        <td class="column3 style2 n">15</td>
                        <td class="column4 style5 s">SURYA NAGAR</td>
                        <td class="column5 style2 s">YES</td>
                      </tr>
                      <tr class="row256">
                        <td class="column0 style2 n">256</td>
                        <td class="column1 style3 s">SHRADHANJALI TCG</td>
                        <td class="column2 style5 s">SURYA NAGAR</td>
                        <td class="column3 style2 n">15</td>
                        <td class="column4 style5 s">SURYA NAGAR</td>
                        <td class="column5 style2 s">YES</td>
                      </tr>
                      <tr class="row257">
                        <td class="column0 style2 n">257</td>
                        <td class="column1 style3 s">ANJALI TCG</td>
                        <td class="column2 style5 s">SURYA NAGAR</td>
                        <td class="column3 style2 n">15</td>
                        <td class="column4 style5 s">SURYA NAGAR</td>
                        <td class="column5 style2 s">YES</td>
                      </tr>
                      <tr class="row258">
                        <td class="column0 style2 n">258</td>
                        <td class="column1 style3 s">BRATI TCG</td>
                        <td class="column2 style5 s">SURYA NAGAR</td>
                        <td class="column3 style2 n">15</td>
                        <td class="column4 style5 s">SURYA NAGAR</td>
                        <td class="column5 style2 s">YES</td>
                      </tr>
                      <tr class="row259">
                        <td class="column0 style2 n">259</td>
                        <td class="column1 style3 s">MAA KALYANI TCG</td>
                        <td class="column2 style5 s">SURIYA NAGAR</td>
                        <td class="column3 style2 n">15</td>
                        <td class="column4 style5 s">SURIYA NAGAR</td>
                        <td class="column5 style2 s">YES</td>
                      </tr>
                      <tr class="row260">
                        <td class="column0 style2 n">260</td>
                        <td class="column1 style3 s">MAA MAHAMAYA TCG</td>
                        <td class="column2 style5 s">SURYA NAGAR</td>
                        <td class="column3 style2 n">15</td>
                        <td class="column4 style5 s">SURYA NAGAR</td>
                        <td class="column5 style2 s">YES</td>
                      </tr>
                      <tr class="row261">
                        <td class="column0 style2 n">261</td>
                        <td class="column1 style3 s">MAA ANNAPURNA TCG</td>
                        <td class="column2 style5 s">SURYA NAGAR</td>
                        <td class="column3 style2 n">15</td>
                        <td class="column4 style5 s">SURYA NAGAR</td>
                        <td class="column5 style2 s">YES</td>
                      </tr>
                      <tr class="row262">
                        <td class="column0 style2 n">262</td>
                        <td class="column1 style3 s">SURIYA SAHINI TCG</td>
                        <td class="column2 style5 s">SURYA NAGAR</td>
                        <td class="column3 style2 n">15</td>
                        <td class="column4 style5 s">SURYA NAGAR</td>
                        <td class="column5 style2 s">YES</td>
                      </tr>
                      <tr class="row263">
                        <td class="column0 style2 n">263</td>
                        <td class="column1 style3 s">MEGHA TCG </td>
                        <td class="column2 style5 s">PAIK PARA</td>
                        <td class="column3 style2 n">15</td>
                        <td class="column4 style5 s">PAIK PARA</td>
                        <td class="column5 style2 s">YES</td>
                      </tr>
                      <tr class="row264">
                        <td class="column0 style2 n">264</td>
                        <td class="column1 style3 s">SREE GURU TCG</td>
                        <td class="column2 style5 s">SURYA NAGAR</td>
                        <td class="column3 style2 n">15</td>
                        <td class="column4 style5 s">SURYA NAGAR</td>
                        <td class="column5 style2 s">YES</td>
                      </tr>
                      <tr class="row265">
                        <td class="column0 style2 n">265</td>
                        <td class="column1 style3 s">RAM BALARAM  TCG</td>
                        <td class="column2 style5 s">SURYA NAGAR</td>
                        <td class="column3 style2 n">15</td>
                        <td class="column4 style5 s">SURYA NAGAR</td>
                        <td class="column5 style2 s">YES</td>
                      </tr>
                      <tr class="row266">
                        <td class="column0 style2 n">266</td>
                        <td class="column1 style3 s">JYOTI TCG</td>
                        <td class="column2 style5 s">MUKTI NAGAR</td>
                        <td class="column3 style2 n">15</td>
                        <td class="column4 style5 s">MUKTI NAGAR</td>
                        <td class="column5 style2 s">YES</td>
                      </tr>
                      <tr class="row267">
                        <td class="column0 style2 n">267</td>
                        <td class="column1 style3 s">NABOMUKTI TCG</td>
                        <td class="column2 style5 s">MUKTI NAGAR</td>
                        <td class="column3 style2 n">15</td>
                        <td class="column4 style5 s">MUKTI NAGAR</td>
                        <td class="column5 style2 s">YES</td>
                      </tr>
                      <tr class="row268">
                        <td class="column0 style2 n">268</td>
                        <td class="column1 style3 s">JAY MAA TARA TCG</td>
                        <td class="column2 style5 s">SURYA NAGAR</td>
                        <td class="column3 style2 n">15</td>
                        <td class="column4 style5 s">SURYA NAGAR</td>
                        <td class="column5 style2 s">YES</td>
                      </tr>
                      <tr class="row269">
                        <td class="column0 style2 n">269</td>
                        <td class="column1 style3 s">RADHA RANI TCG</td>
                        <td class="column2 style5 s">SURYA NAGAR</td>
                        <td class="column3 style2 n">15</td>
                        <td class="column4 style5 s">SURYA NAGAR</td>
                        <td class="column5 style2 s">YES</td>
                      </tr>
                      <tr class="row270">
                        <td class="column0 style2 n">270</td>
                        <td class="column1 style3 s">SHIBA  TCG</td>
                        <td class="column2 style5 s">SURYA NAGAR</td>
                        <td class="column3 style2 n">15</td>
                        <td class="column4 style5 s">SURYA NAGAR</td>
                        <td class="column5 style2 s">YES</td>
                      </tr>
                      <tr class="row271">
                        <td class="column0 style2 n">271</td>
                        <td class="column1 style3 s">JAY BHARAT </td>
                        <td class="column2 style5 s">MUKTI NAGAR</td>
                        <td class="column3 style2 n">15</td>
                        <td class="column4 style5 s">MUKTI NAGAR</td>
                        <td class="column5 style2 s">YES</td>
                      </tr>
                      <tr class="row272">
                        <td class="column0 style2 n">272</td>
                        <td class="column1 style3 s">AKANTA  TCG</td>
                        <td class="column2 style5 s">SURYA NAGAR</td>
                        <td class="column3 style2 n">15</td>
                        <td class="column4 style5 s">SURYA NAGAR</td>
                        <td class="column5 style2 s">YES</td>
                      </tr>
                      <tr class="row273">
                        <td class="column0 style2 n">273</td>
                        <td class="column1 style3 s">DEEPSIKHA TCG</td>
                        <td class="column2 style5 s">SURYA NAGAR</td>
                        <td class="column3 style2 n">15</td>
                        <td class="column4 style5 s">SURYA NAGAR</td>
                        <td class="column5 style2 s">YES</td>
                      </tr>
                      <tr class="row274">
                        <td class="column0 style2 n">274</td>
                        <td class="column1 style3 s">PAIK PARA 2NO SHG</td>
                        <td class="column2 style5 s">PAIK PARA</td>
                        <td class="column3 style2 n">15</td>
                        <td class="column4 style5 s">PAIK PARA</td>
                        <td class="column5 style2 s">YES</td>
                      </tr>
                      <tr class="row275">
                        <td class="column0 style2 n">275</td>
                        <td class="column1 style3 s">SURIYA NAGAR 2NO SHG</td>
                        <td class="column2 style5 s">SURYA NAGAR</td>
                        <td class="column3 style2 n">15</td>
                        <td class="column4 style5 s">SURYA NAGAR</td>
                        <td class="column5 style2 s">YES</td>
                      </tr>
                      <tr class="row276">
                        <td class="column0 style2 n">276</td>
                        <td class="column1 style3 s">&nbsp;SURIYA NAGAR 3 NO SHG</td>
                        <td class="column2 style5 s">SURYA NAGAR</td>
                        <td class="column3 style2 n">15</td>
                        <td class="column4 style5 s">SURYA NAGAR</td>
                        <td class="column5 style2 s">YES</td>
                      </tr>
                      <tr class="row277">
                        <td class="column0 style2 n">277</td>
                        <td class="column1 style3 s">SURIYA NAGAR 1 NO SHG</td>
                        <td class="column2 style5 s">SURYA NAGAR</td>
                        <td class="column3 style2 n">15</td>
                        <td class="column4 style5 s">SURYA NAGAR</td>
                        <td class="column5 style2 s">YES</td>
                      </tr>
                      <tr class="row278">
                        <td class="column0 style2 n">278</td>
                        <td class="column1 style3 s">SURYA NAGAR 14NO SHG</td>
                        <td class="column2 style5 s">SURIYA NAGAR</td>
                        <td class="column3 style2 n">15</td>
                        <td class="column4 style5 s">SURIYA NAGAR</td>
                        <td class="column5 style2 s">YES</td>
                      </tr>
                      <tr class="row279">
                        <td class="column0 style2 n">279</td>
                        <td class="column1 style3 s">SURYA NAGAR 15 NO SHG</td>
                        <td class="column2 style5 s">SURIYA NAGAR</td>
                        <td class="column3 style2 n">15</td>
                        <td class="column4 style5 s">SURIYA NAGAR</td>
                        <td class="column5 style2 s">YES</td>
                      </tr>
                      <tr class="row280">
                        <td class="column0 style2 n">280</td>
                        <td class="column1 style3 s">PAIK PARA 5 NO SHG</td>
                        <td class="column2 style5 s">PAIK PARA</td>
                        <td class="column3 style2 n">15</td>
                        <td class="column4 style5 s">PAIK PARA</td>
                        <td class="column5 style2 s">YES</td>
                      </tr>
                      <tr class="row281">
                        <td class="column0 style2 n">281</td>
                        <td class="column1 style3 s">SURYA NAGAR 11NO SHG</td>
                        <td class="column2 style5 s">SURIYA NAGAR</td>
                        <td class="column3 style2 n">15</td>
                        <td class="column4 style5 s">SURIYA NAGAR</td>
                        <td class="column5 style2 s">YES</td>
                      </tr>
                      <tr class="row282">
                        <td class="column0 style2 n">282</td>
                        <td class="column1 style3 s">SURYA NAGAR 12NO SHG</td>
                        <td class="column2 style5 s">SURIYA NAGAR</td>
                        <td class="column3 style2 n">15</td>
                        <td class="column4 style5 s">SURIYA NAGAR</td>
                        <td class="column5 style2 s">YES</td>
                      </tr>
                      <tr class="row283">
                        <td class="column0 style2 n">283</td>
                        <td class="column1 style3 s">SURIYA NAGAR 13 NO SHG</td>
                        <td class="column2 style5 s">SURIYA NAGAR</td>
                        <td class="column3 style2 n">15</td>
                        <td class="column4 style5 s">SURIYA NAGAR</td>
                        <td class="column5 style2 s">YES</td>
                      </tr>
                      <tr class="row284">
                        <td class="column0 style2 n">284</td>
                        <td class="column1 style3 s">SURIYA NAGAR 16 NO SHG</td>
                        <td class="column2 style5 s">SURIYA NAGAR</td>
                        <td class="column3 style2 n">15</td>
                        <td class="column4 style5 s">SURIYA NAGAR</td>
                        <td class="column5 style2 s">YES</td>
                      </tr>
                      <tr class="row285">
                        <td class="column0 style2 n">285</td>
                        <td class="column1 style3 s">SURIYA NAGAR 17 NO SHG</td>
                        <td class="column2 style5 s">SURIYA NAGAR</td>
                        <td class="column3 style2 n">15</td>
                        <td class="column4 style5 s">SURIYA NAGAR</td>
                        <td class="column5 style2 s">YES</td>
                      </tr>
                      <tr class="row286">
                        <td class="column0 style2 n">286</td>
                        <td class="column1 style3 s">PAIK PARA 6 NO SHG</td>
                        <td class="column2 style5 s">PAIK PARA</td>
                        <td class="column3 style2 n">15</td>
                        <td class="column4 style5 s">PAIK PARA</td>
                        <td class="column5 style2 s">YES</td>
                      </tr>
                      <tr class="row287">
                        <td class="column0 style2 n">287</td>
                        <td class="column1 style3 s">SURIYA NAGAR 18 NO SHG</td>
                        <td class="column2 style5 s">SURYA NAGAR</td>
                        <td class="column3 style2 n">15</td>
                        <td class="column4 style5 s">SURYA NAGAR</td>
                        <td class="column5 style2 s">YES</td>
                      </tr>
                      <tr class="row288">
                        <td class="column0 style2 n">288</td>
                        <td class="column1 style3 s">PAIK PARA 7 NO SHG</td>
                        <td class="column2 style5 s">PAIK PARA</td>
                        <td class="column3 style2 n">15</td>
                        <td class="column4 style5 s">PAIK PARA</td>
                        <td class="column5 style2 s">YES</td>
                      </tr>
                      <tr class="row289">
                        <td class="column0 style2 n">289</td>
                        <td class="column1 style3 s">PAIK PARA 3 NO SHG</td>
                        <td class="column2 style5 s">PAIK PARA</td>
                        <td class="column3 style2 n">15</td>
                        <td class="column4 style5 s">PAIK PARA</td>
                        <td class="column5 style2 s">YES</td>
                      </tr>
                      <tr class="row290">
                        <td class="column0 style2 n">290</td>
                        <td class="column1 style3 s">SURYA NAGAR 4 NO SHG</td>
                        <td class="column2 style5 s">SURYA NAGAR</td>
                        <td class="column3 style2 n">15</td>
                        <td class="column4 style5 s">SURYA NAGAR</td>
                        <td class="column5 style2 s">YES</td>
                      </tr>
                      <tr class="row291">
                        <td class="column0 style2 n">291</td>
                        <td class="column1 style3 s">SURYA NAGAR 6 NO SHG</td>
                        <td class="column2 style5 s">SURYA NAGAR</td>
                        <td class="column3 style2 n">15</td>
                        <td class="column4 style5 s">SURYA NAGAR</td>
                        <td class="column5 style2 s">YES</td>
                      </tr>
                      <tr class="row292">
                        <td class="column0 style2 n">292</td>
                        <td class="column1 style3 s">SURYA NAGAR 7 NO SHG</td>
                        <td class="column2 style5 s">SURYA NAGAR</td>
                        <td class="column3 style2 n">15</td>
                        <td class="column4 style5 s">SURYA NAGAR</td>
                        <td class="column5 style2 s">YES</td>
                      </tr>
                      <tr class="row293">
                        <td class="column0 style2 n">293</td>
                        <td class="column1 style3 s">SURYA NAGAR 8 NO SHG</td>
                        <td class="column2 style5 s">SURYA NAGAR</td>
                        <td class="column3 style2 n">15</td>
                        <td class="column4 style5 s">SURYA NAGAR</td>
                        <td class="column5 style2 s">YES</td>
                      </tr>
                      <tr class="row294">
                        <td class="column0 style2 n">294</td>
                        <td class="column1 style3 s">MUKTI NAGAR 1 NO SHG</td>
                        <td class="column2 style5 s">MUKTI NAGAR</td>
                        <td class="column3 style2 n">15</td>
                        <td class="column4 style5 s">MUKTI NAGAR</td>
                        <td class="column5 style2 s">YES</td>
                      </tr>
                      <tr class="row295">
                        <td class="column0 style2 n">295</td>
                        <td class="column1 style3 s">MUKTI NAGAR 2 NO</td>
                        <td class="column2 style5 s">MUKTI NAGAR</td>
                        <td class="column3 style2 n">15</td>
                        <td class="column4 style5 s">MUKTI NAGAR</td>
                        <td class="column5 style2 s">YES</td>
                      </tr>
                      <tr class="row296">
                        <td class="column0 style2 n">296</td>
                        <td class="column1 style3 s">SURYA NAGAR 9 NO SHG</td>
                        <td class="column2 style5 s">SURYA NAGAR</td>
                        <td class="column3 style2 n">15</td>
                        <td class="column4 style5 s">SURYA NAGAR</td>
                        <td class="column5 style2 s">YES</td>
                      </tr>
                      <tr class="row297">
                        <td class="column0 style2 n">297</td>
                        <td class="column1 style3 s">SURYA NAGAR 10 NO SHG</td>
                        <td class="column2 style5 s">SURYA NAGAR</td>
                        <td class="column3 style2 n">15</td>
                        <td class="column4 style5 s">SURYA NAGAR</td>
                        <td class="column5 style2 s">YES</td>
                      </tr>
                      <tr class="row298">
                        <td class="column0 style2 n">298</td>
                        <td class="column1 style3 s">PAIK PARA 4 NO SHG</td>
                        <td class="column2 style5 s">PAIK PARA</td>
                        <td class="column3 style2 n">15</td>
                        <td class="column4 style5 s">PAIK PARA</td>
                        <td class="column5 style2 s">YES</td>
                      </tr>
                      <tr class="row299">
                        <td class="column0 style2 n">299</td>
                        <td class="column1 style3 s">SURYA NAGAR 19 NO SHG</td>
                        <td class="column2 style5 s">SURIYA NAGAR</td>
                        <td class="column3 style2 n">15</td>
                        <td class="column4 style5 s">SURIYA NAGAR</td>
                        <td class="column5 style2 s">YES</td>
                      </tr>
                      <tr class="row300">
                        <td class="column0 style2 n">300</td>
                        <td class="column1 style3 s">SURYA NAGAR 20 NO SHG</td>
                        <td class="column2 style5 s">SURIYA NAGAR</td>
                        <td class="column3 style2 n">15</td>
                        <td class="column4 style5 s">SURIYA NAGAR</td>
                        <td class="column5 style2 s">YES</td>
                      </tr>
                      <tr class="row301">
                        <td class="column0 style2 n">301</td>
                        <td class="column1 style3 s">MATANGINI  TCG</td>
                        <td class="column2 style5 s">MAHAPRABHU PARA</td>
                        <td class="column3 style2 n">15</td>
                        <td class="column4 style5 s">MAHAPRABHU PARA</td>
                        <td class="column5 style2 s">YES</td>
                      </tr>
                      <tr class="row302">
                        <td class="column0 style2 n">302</td>
                        <td class="column1 style3 s">MAHAPRABHU PARA 1NO TCG</td>
                        <td class="column2 style5 s">MAHAPRABHU PARA</td>
                        <td class="column3 style2 n">16</td>
                        <td class="column4 style5 s">MAHAPRABHU PARA</td>
                        <td class="column5 style2 s">YES</td>
                      </tr>
                      <tr class="row303">
                        <td class="column0 style2 n">303</td>
                        <td class="column1 style3 s">MAHAPRABHU PARA STREET 14 NO SHG</td>
                        <td class="column2 style5 s">MAHAPRABHU PARA</td>
                        <td class="column3 style2 n">16</td>
                        <td class="column4 style5 s">MAHAPRABHU PARA</td>
                        <td class="column5 style2 s">YES</td>
                      </tr>
                      <tr class="row304">
                        <td class="column0 style2 n">304</td>
                        <td class="column1 style3 s">MAHAPRABHU PARA STREET 15 NO SHG</td>
                        <td class="column2 style5 s">MAHAPRABHU PARA</td>
                        <td class="column3 style2 n">16</td>
                        <td class="column4 style5 s">MAHAPRABHU PARA</td>
                        <td class="column5 style2 s">YES</td>
                      </tr>
                      <tr class="row305">
                        <td class="column0 style2 n">305</td>
                        <td class="column1 style3 s">MAHAPRABHU PARA STREET 16 NO SHG</td>
                        <td class="column2 style5 s">MAHAPRABHU PARA</td>
                        <td class="column3 style2 n">16</td>
                        <td class="column4 style5 s">MAHAPRABHU PARA</td>
                        <td class="column5 style2 s">YES</td>
                      </tr>
                      <tr class="row306">
                        <td class="column0 style2 n">306</td>
                        <td class="column1 style3 s">MAHAPRABHU PARA STREET 17 NO SHG</td>
                        <td class="column2 style5 s">MAHAPRABHU PARA</td>
                        <td class="column3 style2 n">16</td>
                        <td class="column4 style5 s">MAHAPRABHU PARA</td>
                        <td class="column5 style2 s">YES</td>
                      </tr>
                      <tr class="row307">
                        <td class="column0 style2 n">307</td>
                        <td class="column1 style3 s">MAHAPRABHU PARA STREET 18 NO SHG</td>
                        <td class="column2 style5 s">MAHAPRABHU PARA</td>
                        <td class="column3 style2 n">16</td>
                        <td class="column4 style5 s">MAHAPRABHU PARA</td>
                        <td class="column5 style2 s">YES</td>
                      </tr>
                      <tr class="row308">
                        <td class="column0 style2 n">308</td>
                        <td class="column1 style3 s">MAHAPRABHU PARA STREET 2 NO</td>
                        <td class="column2 style5 s">MAHAPRABHU PARA</td>
                        <td class="column3 style2 n">16</td>
                        <td class="column4 style5 s">MAHAPRABHU PARA</td>
                        <td class="column5 style2 s">YES</td>
                      </tr>
                      <tr class="row309">
                        <td class="column0 style2 n">309</td>
                        <td class="column1 style3 s">MAHAPRABHU PARA STREET 3 NO</td>
                        <td class="column2 style5 s">MAHAPRABHU PARA</td>
                        <td class="column3 style2 n">16</td>
                        <td class="column4 style5 s">MAHAPRABHU PARA</td>
                        <td class="column5 style2 s">YES</td>
                      </tr>
                      <tr class="row310">
                        <td class="column0 style2 n">310</td>
                        <td class="column1 style3 s">MAHAPRABHU PARA STREET 4 NO</td>
                        <td class="column2 style5 s">MAHAPRABHU PARA</td>
                        <td class="column3 style2 n">16</td>
                        <td class="column4 style5 s">MAHAPRABHU PARA</td>
                        <td class="column5 style2 s">YES</td>
                      </tr>
                      <tr class="row311">
                        <td class="column0 style2 n">311</td>
                        <td class="column1 style3 s">MAHAPRABHU PARA STREET 5 NO</td>
                        <td class="column2 style5 s">MAHAPRABHU PARA</td>
                        <td class="column3 style2 n">16</td>
                        <td class="column4 style5 s">MAHAPRABHU PARA</td>
                        <td class="column5 style2 s">YES</td>
                      </tr>
                      <tr class="row312">
                        <td class="column0 style2 n">312</td>
                        <td class="column1 style3 s">MAHAPRABHU PARA STREET 6 NO</td>
                        <td class="column2 style5 s">MAHAPRABHU PARA</td>
                        <td class="column3 style2 n">16</td>
                        <td class="column4 style5 s">MAHAPRABHU PARA</td>
                        <td class="column5 style2 s">YES</td>
                      </tr>
                      <tr class="row313">
                        <td class="column0 style2 n">313</td>
                        <td class="column1 style3 s">MAHAPRABHU PARA STREET 7 NO</td>
                        <td class="column2 style5 s">MAHAPRABHU PARA</td>
                        <td class="column3 style2 n">16</td>
                        <td class="column4 style5 s">MAHAPRABHU PARA</td>
                        <td class="column5 style2 s">YES</td>
                      </tr>
                      <tr class="row314">
                        <td class="column0 style2 n">314</td>
                        <td class="column1 style3 s">MAHAPRABHU PARA STREET 8 NO</td>
                        <td class="column2 style5 s">MAHAPRABHU PARA</td>
                        <td class="column3 style2 n">16</td>
                        <td class="column4 style5 s">MAHAPRABHU PARA</td>
                        <td class="column5 style2 s">YES</td>
                      </tr>
                      <tr class="row315">
                        <td class="column0 style2 n">315</td>
                        <td class="column1 style3 s">MAHAPRABHU PARA STREET 9 NO</td>
                        <td class="column2 style5 s">MAHAPRABHU PARA</td>
                        <td class="column3 style2 n">16</td>
                        <td class="column4 style5 s">MAHAPRABHU PARA</td>
                        <td class="column5 style2 s">YES</td>
                      </tr>
                      <tr class="row316">
                        <td class="column0 style2 n">316</td>
                        <td class="column1 style3 s">MAHAPRABHU PARA STREET 10 NO</td>
                        <td class="column2 style5 s">MAHAPRABHU PARA</td>
                        <td class="column3 style2 n">16</td>
                        <td class="column4 style5 s">MAHAPRABHU PARA</td>
                        <td class="column5 style2 s">YES</td>
                      </tr>
                      <tr class="row317">
                        <td class="column0 style2 n">317</td>
                        <td class="column1 style3 s">KAMARPARA LANE 1 NO SHG</td>
                        <td class="column2 style5 s">MAHAPRABHU PARA</td>
                        <td class="column3 style2 n">16</td>
                        <td class="column4 style5 s">MAHAPRABHU PARA</td>
                        <td class="column5 style2 s">YES</td>
                      </tr>
                      <tr class="row318">
                        <td class="column0 style2 n">318</td>
                        <td class="column1 style3 s">KAMARPARA LANE 2 NO</td>
                        <td class="column2 style5 s">MAHAPRABHU PARA</td>
                        <td class="column3 style2 n">16</td>
                        <td class="column4 style5 s">MAHAPRABHU PARA</td>
                        <td class="column5 style2 s">YES</td>
                      </tr>
                      <tr class="row319">
                        <td class="column0 style2 n">319</td>
                        <td class="column1 style3 s">KAMARPARA LANE 3 NO</td>
                        <td class="column2 style5 s">MAHAPRABHU PARA</td>
                        <td class="column3 style2 n">16</td>
                        <td class="column4 style5 s">MAHAPRABHU PARA</td>
                        <td class="column5 style2 s">YES</td>
                      </tr>
                      <tr class="row320">
                        <td class="column0 style2 n">320</td>
                        <td class="column1 style3 s">RATH DANGA ROAD 1 NO</td>
                        <td class="column2 style5 s">MAHAPRABHU PARA</td>
                        <td class="column3 style2 n">16</td>
                        <td class="column4 style5 s">MAHAPRABHU PARA</td>
                        <td class="column5 style2 s">YES</td>
                      </tr>
                      <tr class="row321">
                        <td class="column0 style2 n">321</td>
                        <td class="column1 style3 s">RATH DANGA ROAD 2 NO</td>
                        <td class="column2 style5 s">MAHAPRABHU PARA</td>
                        <td class="column3 style2 n">16</td>
                        <td class="column4 style5 s">MAHAPRABHU PARA</td>
                        <td class="column5 style2 s">YES</td>
                      </tr>
                      <tr class="row322">
                        <td class="column0 style2 n">322</td>
                        <td class="column1 style3 s">RATH DANGA ROAD 3 NO</td>
                        <td class="column2 style5 s">MAHAPRABHU PARA</td>
                        <td class="column3 style2 n">16</td>
                        <td class="column4 style5 s">MAHAPRABHU PARA</td>
                        <td class="column5 style2 s">YES</td>
                      </tr>
                      <tr class="row323">
                        <td class="column0 style2 n">323</td>
                        <td class="column1 style3 s">RATH DANGA ROAD 4 NO</td>
                        <td class="column2 style5 s">MAHAPRABHU PARA</td>
                        <td class="column3 style2 n">16</td>
                        <td class="column4 style5 s">MAHAPRABHU PARA</td>
                        <td class="column5 style2 s">YES</td>
                      </tr>
                      <tr class="row324">
                        <td class="column0 style2 n">324</td>
                        <td class="column1 style3 s">MAHAPRABHU PARA STREET 12 NO</td>
                        <td class="column2 style5 s">MAHAPRABHU PARA</td>
                        <td class="column3 style2 n">16</td>
                        <td class="column4 style5 s">MAHAPRABHU PARA</td>
                        <td class="column5 style2 s">YES</td>
                      </tr>
                      <tr class="row325">
                        <td class="column0 style2 n">325</td>
                        <td class="column1 style3 s">MAHAPRABHU PARA STREET 11 NO</td>
                        <td class="column2 style5 s">MAHAPRABHU PARA</td>
                        <td class="column3 style2 n">16</td>
                        <td class="column4 style5 s">MAHAPRABHU PARA</td>
                        <td class="column5 style2 s">YES</td>
                      </tr>
                      <tr class="row326">
                        <td class="column0 style2 n">326</td>
                        <td class="column1 style3 s">MAHAPRABHU PARA ST.19 NO SHG</td>
                        <td class="column2 style5 s">MAHAPRABHU PARA</td>
                        <td class="column3 style2 n">16</td>
                        <td class="column4 style5 s">MAHAPRABHU PARA</td>
                        <td class="column5 style2 s">YES</td>
                      </tr>
                      <tr class="row327">
                        <td class="column0 style2 n">327</td>
                        <td class="column1 style3 s">MAHAPRABHU PARA ST.20 NO SHG</td>
                        <td class="column2 style5 s">MAHAPRABHU PARA</td>
                        <td class="column3 style2 n">16</td>
                        <td class="column4 style5 s">MAHAPRABHU PARA</td>
                        <td class="column5 style2 s">YES</td>
                      </tr>
                      <tr class="row328">
                        <td class="column0 style2 n">328</td>
                        <td class="column1 style3 s">MURARI NAGAR 1 NO SHG</td>
                        <td class="column2 style5 s">MURARI NAGAR </td>
                        <td class="column3 style2 n">16</td>
                        <td class="column4 style5 s">MURARI NAGAR </td>
                        <td class="column5 style2 s">YES</td>
                      </tr>
                      <tr class="row329">
                        <td class="column0 style2 n">329</td>
                        <td class="column1 style3 s">SHYAMA PRASAD PALLY 1NO TCG</td>
                        <td class="column2 style5 s">SAHYAM PRASAD PALLY</td>
                        <td class="column3 style2 n">17</td>
                        <td class="column4 style5 s">SAHYAM PRASAD PALLY</td>
                        <td class="column5 style2 s">YES</td>
                      </tr>
                      <tr class="row330">
                        <td class="column0 style2 n">330</td>
                        <td class="column1 style3 s">NASRA PARA LANE 1NO TCG</td>
                        <td class="column2 style4 s">NASRA PARA</td>
                        <td class="column3 style2 n">17</td>
                        <td class="column4 style4 s">NASRA PARA</td>
                        <td class="column5 style2 s">YES</td>
                      </tr>
                      <tr class="row331">
                        <td class="column0 style2 n">331</td>
                        <td class="column1 style3 s">NASRA PARA LANE 2NO TCG</td>
                        <td class="column2 style4 s">NASRA PARA</td>
                        <td class="column3 style2 n">17</td>
                        <td class="column4 style4 s">NASRA PARA</td>
                        <td class="column5 style2 s">YES</td>
                      </tr>
                      <tr class="row332">
                        <td class="column0 style2 n">332</td>
                        <td class="column1 style3 s">NASRA PARA LANE 3NO TCG</td>
                        <td class="column2 style4 s">NASRA PARA</td>
                        <td class="column3 style2 n">17</td>
                        <td class="column4 style4 s">NASRA PARA</td>
                        <td class="column5 style2 s">YES</td>
                      </tr>
                      <tr class="row333">
                        <td class="column0 style2 n">333</td>
                        <td class="column1 style3 s">MADANMOHAN COLONY 1NO TCG</td>
                        <td class="column2 style5 s">MADAN MOHAN COLONY</td>
                        <td class="column3 style2 n">17</td>
                        <td class="column4 style5 s">MADAN MOHAN COLONY</td>
                        <td class="column5 style2 s">YES</td>
                      </tr>
                      <tr class="row334">
                        <td class="column0 style2 n">334</td>
                        <td class="column1 style3 s">NASRA PARA LANE 4NO TCG</td>
                        <td class="column2 style4 s">NASRA PARA</td>
                        <td class="column3 style2 n">17</td>
                        <td class="column4 style4 s">NASRA PARA</td>
                        <td class="column5 style2 s">YES</td>
                      </tr>
                      <tr class="row335">
                        <td class="column0 style2 n">335</td>
                        <td class="column1 style3 s">OLD BERHAMPUR ROAD 1NO TCG </td>
                        <td class="column2 style5 s">DAKSHIN PARA</td>
                        <td class="column3 style2 n">17</td>
                        <td class="column4 style5 s">DAKSHIN PARA</td>
                        <td class="column5 style2 s">YES</td>
                      </tr>
                      <tr class="row336">
                        <td class="column0 style2 n">336</td>
                        <td class="column1 style3 s">NASRA PARA LANE 6NO TCG</td>
                        <td class="column2 style5 s">NASRA PARA SHARMA PARA</td>
                        <td class="column3 style2 n">17</td>
                        <td class="column4 style5 s">NASRA PARA SHARMA PARA</td>
                        <td class="column5 style2 s">YES</td>
                      </tr>
                      <tr class="row337">
                        <td class="column0 style2 n">337</td>
                        <td class="column1 style3 s">NASRA PARA  LANE 7NO TCG</td>
                        <td class="column2 style5 s">NASRA PARA</td>
                        <td class="column3 style2 n">17</td>
                        <td class="column4 style5 s">NASRA PARA</td>
                        <td class="column5 style2 s">YES</td>
                      </tr>
                      <tr class="row338">
                        <td class="column0 style2 n">338</td>
                        <td class="column1 style3 s">NASRA PARA LANE 8NO TCG</td>
                        <td class="column2 style5 s">NASRA PARA KRIPA MOYEE TALA</td>
                        <td class="column3 style2 n">17</td>
                        <td class="column4 style5 s">NASRA PARA KRIPA MOYEE TALA</td>
                        <td class="column5 style2 s">YES</td>
                      </tr>
                      <tr class="row339">
                        <td class="column0 style2 n">339</td>
                        <td class="column1 style3 s">DAKSHIN PARA 1NO TCG</td>
                        <td class="column2 style5 s">DAKSHIN PARA</td>
                        <td class="column3 style2 n">17</td>
                        <td class="column4 style5 s">DAKSHIN PARA</td>
                        <td class="column5 style2 s">YES</td>
                      </tr>
                      <tr class="row340">
                        <td class="column0 style2 n">340</td>
                        <td class="column1 style3 s">DAKSHIN PARA 2NO TCG</td>
                        <td class="column2 style5 s">DAKSHIN PARA</td>
                        <td class="column3 style2 n">17</td>
                        <td class="column4 style5 s">DAKSHIN PARA</td>
                        <td class="column5 style2 s">YES</td>
                      </tr>
                      <tr class="row341">
                        <td class="column0 style2 n">341</td>
                        <td class="column1 style3 s">DAKSHIN PARA 3NO TCG</td>
                        <td class="column2 style5 s">DAKSHIN PARA</td>
                        <td class="column3 style2 n">17</td>
                        <td class="column4 style5 s">DAKSHIN PARA</td>
                        <td class="column5 style2 s">YES</td>
                      </tr>
                      <tr class="row342">
                        <td class="column0 style2 n">342</td>
                        <td class="column1 style3 s">NASRA PARA LANE 9NO TCG</td>
                        <td class="column2 style5 s">NASRA PARA MISSION ROAD</td>
                        <td class="column3 style2 n">17</td>
                        <td class="column4 style5 s">NASRA PARA MISSION ROAD</td>
                        <td class="column5 style2 s">YES</td>
                      </tr>
                      <tr class="row343">
                        <td class="column0 style2 n">343</td>
                        <td class="column1 style3 s">SHYAM PRASAD PALLY 2NO TCG</td>
                        <td class="column2 style5 s">SHYAMPRASAD PALLY</td>
                        <td class="column3 style2 n">17</td>
                        <td class="column4 style5 s">SHYAMPRASAD PALLY</td>
                        <td class="column5 style2 s">YES</td>
                      </tr>
                      <tr class="row344">
                        <td class="column0 style2 n">344</td>
                        <td class="column1 style3 s">BAROGHAR COLONY 1NO TCG</td>
                        <td class="column2 style5 s">BAROGHAR COLONY NASRA PARA</td>
                        <td class="column3 style2 n">17</td>
                        <td class="column4 style5 s">BAROGHAR COLONY NASRA PARA</td>
                        <td class="column5 style2 s">YES</td>
                      </tr>
                      <tr class="row345">
                        <td class="column0 style2 n">345</td>
                        <td class="column1 style3 s">BAROGHAR COLONY 2NO TCG</td>
                        <td class="column2 style5 s">BAROGHAR COLONY NASRA PARA</td>
                        <td class="column3 style2 n">17</td>
                        <td class="column4 style5 s">BAROGHAR COLONY NASRA PARA</td>
                        <td class="column5 style2 s">YES</td>
                      </tr>
                      <tr class="row346">
                        <td class="column0 style2 n">346</td>
                        <td class="column1 style3 s">NASRA PARA LANE 10 NO  TCG</td>
                        <td class="column2 style4 s">NASRA PARA</td>
                        <td class="column3 style2 n">17</td>
                        <td class="column4 style4 s">NASRA PARA</td>
                        <td class="column5 style2 s">YES</td>
                      </tr>
                      <tr class="row347">
                        <td class="column0 style2 n">347</td>
                        <td class="column1 style3 s">SHYAMA PROSAD 3NO PALLY  SHG</td>
                        <td class="column2 style5 s">SHYAMA PROSAD PALLY</td>
                        <td class="column3 style2 n">17</td>
                        <td class="column4 style5 s">SHYAMA PROSAD PALLY</td>
                        <td class="column5 style2 s">YES</td>
                      </tr>
                      <tr class="row348">
                        <td class="column0 style2 n">348</td>
                        <td class="column1 style3 s">DAKSHIN PARA 4 NO SHG</td>
                        <td class="column2 style5 s">DAKSHIN PARA </td>
                        <td class="column3 style2 n">17</td>
                        <td class="column4 style5 s">DAKSHIN PARA </td>
                        <td class="column5 style2 s">YES</td>
                      </tr>
                      <tr class="row349">
                        <td class="column0 style2 n">349</td>
                        <td class="column1 style3 s">NASRA PARA LANE 11 NO SHG</td>
                        <td class="column2 style5 s">NASRA PARA LANE</td>
                        <td class="column3 style2 n">17</td>
                        <td class="column4 style5 s">NASRA PARA LANE</td>
                        <td class="column5 style2 s">YES</td>
                      </tr>
                      <tr class="row350">
                        <td class="column0 style2 n">350</td>
                        <td class="column1 style3 s">NASRA PARA LANE 12 NO SHG</td>
                        <td class="column2 style5 s">NASRA PARA LANE</td>
                        <td class="column3 style2 n">17</td>
                        <td class="column4 style5 s">NASRA PARA LANE</td>
                        <td class="column5 style2 s">YES</td>
                      </tr>
                      <tr class="row351">
                        <td class="column0 style2 n">351</td>
                        <td class="column1 style3 s">SHYAMA PROSAD 4NO PALLY SHG</td>
                        <td class="column2 style5 s">SHYAMA PROSAD PALLY</td>
                        <td class="column3 style2 n">17</td>
                        <td class="column4 style5 s">SHYAMA PROSAD PALLY</td>
                        <td class="column5 style2 s">YES</td>
                      </tr>
                      <tr class="row352">
                        <td class="column0 style2 n">352</td>
                        <td class="column1 style3 s">SHYAMA PROSAD PALLY 5 NO SHG</td>
                        <td class="column2 style5 s">SHYAMA PROSAD PALLY</td>
                        <td class="column3 style2 n">17</td>
                        <td class="column4 style5 s">SHYAMA PROSAD PALLY</td>
                        <td class="column5 style2 s">YES</td>
                      </tr>
                      <tr class="row353">
                        <td class="column0 style2 n">353</td>
                        <td class="column1 style3 s">MODAN MOHAN COLONY 2 NO SHG</td>
                        <td class="column2 style5 s">MODAN MOHAN COLONY</td>
                        <td class="column3 style2 n">17</td>
                        <td class="column4 style5 s">MODAN MOHAN COLONY</td>
                        <td class="column5 style2 s">YES</td>
                      </tr>
                      <tr class="row354">
                        <td class="column0 style2 n">354</td>
                        <td class="column1 style3 s">NABAPALLY 1 NO SHG</td>
                        <td class="column2 style5 s">NABAPALLY</td>
                        <td class="column3 style2 n">17</td>
                        <td class="column4 style5 s">NABAPALLY</td>
                        <td class="column5 style2 s">YES</td>
                      </tr>
                      <tr class="row355">
                        <td class="column0 style2 n">355</td>
                        <td class="column1 style3 s">SHAYAMA PRASAD PALLY 6 NO SHG</td>
                        <td class="column2 style5 s">SHAYAMA PRASAD PALLY</td>
                        <td class="column3 style2 n">17</td>
                        <td class="column4 style5 s">SHAYAMA PRASAD PALLY</td>
                        <td class="column5 style2 s">YES</td>
                      </tr>
                      <tr class="row356">
                        <td class="column0 style2 n">356</td>
                        <td class="column1 style3 s">SHAYAMA PRASAD PALLY 7 NO SHG</td>
                        <td class="column2 style5 s">SHAYAMA PRASAD PALLY</td>
                        <td class="column3 style2 n">17</td>
                        <td class="column4 style5 s">SHAYAMA PRASAD PALLY</td>
                        <td class="column5 style2 s">YES</td>
                      </tr>
                      <tr class="row357">
                        <td class="column0 style2 n">357</td>
                        <td class="column1 style3 s">NABA PALLY 2 NO SHG</td>
                        <td class="column2 style5 s">NABA PALLY</td>
                        <td class="column3 style2 n">18</td>
                        <td class="column4 style5 s">NABA PALLY</td>
                        <td class="column5 style2 s">YES</td>
                      </tr>
                      <tr class="row358">
                        <td class="column0 style2 n">358</td>
                        <td class="column1 style3 s">KAMALA  TCG</td>
                        <td class="column2 style5 s">NASRA COLONY PURBA PPARA</td>
                        <td class="column3 style2 n">18</td>
                        <td class="column4 style5 s">NASRA COLONY PURBA PPARA</td>
                        <td class="column5 style2 s">YES</td>
                      </tr>
                      <tr class="row359">
                        <td class="column0 style2 n">359</td>
                        <td class="column1 style3 s">BANI  TCG</td>
                        <td class="column2 style5 s">NASRA COLONY PURBA PPARA</td>
                        <td class="column3 style2 n">18</td>
                        <td class="column4 style5 s">NASRA COLONY PURBA PPARA</td>
                        <td class="column5 style2 s">YES</td>
                      </tr>
                      <tr class="row360">
                        <td class="column0 style2 n">360</td>
                        <td class="column1 style3 s">MUKTI TCG</td>
                        <td class="column2 style4 s">MUKTINAGAR</td>
                        <td class="column3 style2 n">18</td>
                        <td class="column4 style4 s">MUKTINAGAR</td>
                        <td class="column5 style2 s">YES</td>
                      </tr>
                      <tr class="row361">
                        <td class="column0 style2 n">361</td>
                        <td class="column1 style3 s">MAHAKALI TCG</td>
                        <td class="column2 style5 s">NASRA COLONY PURBA PPARA</td>
                        <td class="column3 style2 n">18</td>
                        <td class="column4 style5 s">NASRA COLONY PURBA PPARA</td>
                        <td class="column5 style2 s">YES</td>
                      </tr>
                      <tr class="row362">
                        <td class="column0 style2 n">362</td>
                        <td class="column1 style3 s">MAHA LAKSHMI TCG</td>
                        <td class="column2 style5 s">NASRA COLONY MADHYA PPARA</td>
                        <td class="column3 style2 n">18</td>
                        <td class="column4 style5 s">NASRA COLONY MADHYA PPARA</td>
                        <td class="column5 style2 s">YES</td>
                      </tr>
                      <tr class="row363">
                        <td class="column0 style2 n">363</td>
                        <td class="column1 style3 s">UTTAM TCG</td>
                        <td class="column2 style4 s">MUKTINAGAR</td>
                        <td class="column3 style2 n">18</td>
                        <td class="column4 style4 s">MUKTINAGAR</td>
                        <td class="column5 style2 s">YES</td>
                      </tr>
                      <tr class="row364">
                        <td class="column0 style2 n">364</td>
                        <td class="column1 style3 s">RABINDRA  TCG</td>
                        <td class="column2 style5 s">NASRA COLONY MADHYA PPARA</td>
                        <td class="column3 style2 n">18</td>
                        <td class="column4 style5 s">NASRA COLONY MADHYA PPARA</td>
                        <td class="column5 style2 s">YES</td>
                      </tr>
                      <tr class="row365">
                        <td class="column0 style2 n">365</td>
                        <td class="column1 style3 s">NAZRUL TCG</td>
                        <td class="column2 style5 s">NASRA COLONY PURBA PPARA</td>
                        <td class="column3 style2 n">18</td>
                        <td class="column4 style5 s">NASRA COLONY PURBA PPARA</td>
                        <td class="column5 style2 s">YES</td>
                      </tr>
                      <tr class="row366">
                        <td class="column0 style2 n">366</td>
                        <td class="column1 style3 s">CHITTRANJAN  TCG</td>
                        <td class="column2 style5 s">NASRA COLONY MADHYA PPARA</td>
                        <td class="column3 style2 n">18</td>
                        <td class="column4 style5 s">NASRA COLONY MADHYA PPARA</td>
                        <td class="column5 style2 s">YES</td>
                      </tr>
                      <tr class="row367">
                        <td class="column0 style2 n">367</td>
                        <td class="column1 style3 s">DESHPRIYO TCG</td>
                        <td class="column2 style4 s">MUKTINAGAR</td>
                        <td class="column3 style2 n">18</td>
                        <td class="column4 style4 s">MUKTINAGAR</td>
                        <td class="column5 style2 s">YES</td>
                      </tr>
                      <tr class="row368">
                        <td class="column0 style2 n">368</td>
                        <td class="column1 style3 s">NASRA COLONY 1NO TCG</td>
                        <td class="column2 style5 s">NASRA COLONY MADHYA PPARA</td>
                        <td class="column3 style2 n">18</td>
                        <td class="column4 style5 s">NASRA COLONY MADHYA PPARA</td>
                        <td class="column5 style2 s">YES</td>
                      </tr>
                      <tr class="row369">
                        <td class="column0 style2 n">369</td>
                        <td class="column1 style3 s">NASRA COLONY 2NO TCG</td>
                        <td class="column2 style5 s">NASRA COLONY MADHYA PPARA</td>
                        <td class="column3 style2 n">18</td>
                        <td class="column4 style5 s">NASRA COLONY MADHYA PPARA</td>
                        <td class="column5 style2 s">YES</td>
                      </tr>
                      <tr class="row370">
                        <td class="column0 style2 n">370</td>
                        <td class="column1 style3 s">NASRA COLONY 3NO TCG</td>
                        <td class="column2 style5 s">NASRA COLONY MADHYA PPARA</td>
                        <td class="column3 style2 n">18</td>
                        <td class="column4 style5 s">NASRA COLONY MADHYA PPARA</td>
                        <td class="column5 style2 s">YES</td>
                      </tr>
                      <tr class="row371">
                        <td class="column0 style2 n">371</td>
                        <td class="column1 style3 s">NASRA COLONY 4NO TCG</td>
                        <td class="column2 style5 s">NASRA COLONY DAKSHIN PARA</td>
                        <td class="column3 style2 n">18</td>
                        <td class="column4 style5 s">NASRA COLONY DAKSHIN PARA</td>
                        <td class="column5 style2 s">YES</td>
                      </tr>
                      <tr class="row372">
                        <td class="column0 style2 n">372</td>
                        <td class="column1 style3 s">NASRA COLONY 5NO TCG</td>
                        <td class="column2 style5 s">NASRA COLONY DAKSHIN PARA</td>
                        <td class="column3 style2 n">18</td>
                        <td class="column4 style5 s">NASRA COLONY DAKSHIN PARA</td>
                        <td class="column5 style2 s">YES</td>
                      </tr>
                      <tr class="row373">
                        <td class="column0 style2 n">373</td>
                        <td class="column1 style6 s">SUCHITRA TCG</td>
                        <td class="column2 style5 s">NASRA COLONY</td>
                        <td class="column3 style2 n">18</td>
                        <td class="column4 style5 s">NASRA COLONY</td>
                        <td class="column5 style2 s">YES</td>
                      </tr>
                      <tr class="row374">
                        <td class="column0 style2 n">374</td>
                        <td class="column1 style6 s">UDYAN TCG</td>
                        <td class="column2 style5 s">NASRA COLONY</td>
                        <td class="column3 style2 n">18</td>
                        <td class="column4 style5 s">NASRA COLONY</td>
                        <td class="column5 style2 s">YES</td>
                      </tr>
                      <tr class="row375">
                        <td class="column0 style2 n">375</td>
                        <td class="column1 style3 s">NASRA COLONY 8NO TCG</td>
                        <td class="column2 style5 s">NASRA COLONY</td>
                        <td class="column3 style2 n">18</td>
                        <td class="column4 style5 s">NASRA COLONY</td>
                        <td class="column5 style2 s">YES</td>
                      </tr>
                      <tr class="row376">
                        <td class="column0 style2 n">376</td>
                        <td class="column1 style3 s">NASRA COLONY 9NO</td>
                        <td class="column2 style5 s">NASRA COLONY</td>
                        <td class="column3 style2 n">18</td>
                        <td class="column4 style5 s">NASRA COLONY</td>
                        <td class="column5 style2 s">YES</td>
                      </tr>
                      <tr class="row377">
                        <td class="column0 style2 n">377</td>
                        <td class="column1 style3 s">NASRA COLONY 10 NO</td>
                        <td class="column2 style5 s">NASRA COLONY</td>
                        <td class="column3 style2 n">18</td>
                        <td class="column4 style5 s">NASRA COLONY</td>
                        <td class="column5 style2 s">YES</td>
                      </tr>
                      <tr class="row378">
                        <td class="column0 style2 n">378</td>
                        <td class="column1 style3 s">NASRA COLONY 11NO</td>
                        <td class="column2 style5 s">NASRA COLONY</td>
                        <td class="column3 style2 n">18</td>
                        <td class="column4 style5 s">NASRA COLONY</td>
                        <td class="column5 style2 s">YES</td>
                      </tr>
                      <tr class="row379">
                        <td class="column0 style2 n">379</td>
                        <td class="column1 style3 s">NASRA COLONY 12NO</td>
                        <td class="column2 style5 s">NASRA COLONY</td>
                        <td class="column3 style2 n">18</td>
                        <td class="column4 style5 s">NASRA COLONY</td>
                        <td class="column5 style2 s">YES</td>
                      </tr>
                      <tr class="row380">
                        <td class="column0 style2 n">380</td>
                        <td class="column1 style3 s">NASRA COLONY 13NO TCG</td>
                        <td class="column2 style5 s">NASRA COLONY</td>
                        <td class="column3 style2 n">18</td>
                        <td class="column4 style5 s">NASRA COLONY</td>
                        <td class="column5 style2 s">YES</td>
                      </tr>
                      <tr class="row381">
                        <td class="column0 style2 n">381</td>
                        <td class="column1 style3 s">NASRA COLONY 17 NO SHG</td>
                        <td class="column2 style5 s">NASRA COLONY</td>
                        <td class="column3 style2 n">18</td>
                        <td class="column4 style5 s">NASRA COLONY</td>
                        <td class="column5 style2 s">YES</td>
                      </tr>
                      <tr class="row382">
                        <td class="column0 style2 n">382</td>
                        <td class="column1 style3 s">NASRA COLONY 18 NO SHG</td>
                        <td class="column2 style5 s">NASRA COLONY</td>
                        <td class="column3 style2 n">18</td>
                        <td class="column4 style5 s">NASRA COLONY</td>
                        <td class="column5 style2 s">YES</td>
                      </tr>
                      <tr class="row383">
                        <td class="column0 style2 n">383</td>
                        <td class="column1 style3 s">NASRA COLONY 19 NO SHG</td>
                        <td class="column2 style5 s">NASRA COLONY</td>
                        <td class="column3 style2 n">18</td>
                        <td class="column4 style5 s">NASRA COLONY</td>
                        <td class="column5 style2 s">YES</td>
                      </tr>
                      <tr class="row384">
                        <td class="column0 style2 n">384</td>
                        <td class="column1 style3 s">NASRA COLONY 14 NO SHG</td>
                        <td class="column2 style5 s">NASRA COLONY</td>
                        <td class="column3 style2 n">18</td>
                        <td class="column4 style5 s">NASRA COLONY</td>
                        <td class="column5 style2 s">YES</td>
                      </tr>
                      <tr class="row385">
                        <td class="column0 style2 n">385</td>
                        <td class="column1 style3 s">NASRA COLONY 15 NO SHG</td>
                        <td class="column2 style5 s">NASRA COLONY</td>
                        <td class="column3 style2 n">18</td>
                        <td class="column4 style5 s">NASRA COLONY</td>
                        <td class="column5 style2 s">YES</td>
                      </tr>
                      <tr class="row386">
                        <td class="column0 style2 n">386</td>
                        <td class="column1 style3 s">NASRA COLONY 16 NO</td>
                        <td class="column2 style5 s">NASRA COLONY</td>
                        <td class="column3 style2 n">18</td>
                        <td class="column4 style5 s">NASRA COLONY</td>
                        <td class="column5 style2 s">YES</td>
                      </tr>
                      <tr class="row387">
                        <td class="column0 style2 n">387</td>
                        <td class="column1 style3 s">NASRA COLONY 20 NO SHG</td>
                        <td class="column2 style5 s">NASRA COLONY</td>
                        <td class="column3 style2 n">18</td>
                        <td class="column4 style5 s">NASRA COLONY</td>
                        <td class="column5 style2 s">YES</td>
                      </tr>
                      <tr class="row388">
                        <td class="column0 style2 n">388</td>
                        <td class="column1 style3 s">NASRA COLONY 21 NO SHG</td>
                        <td class="column2 style5 s">NASRA COLONY</td>
                        <td class="column3 style2 n">18</td>
                        <td class="column4 style5 s">NASRA COLONY</td>
                        <td class="column5 style2 s">YES</td>
                      </tr>
                      <tr class="row389">
                        <td class="column0 style2 n">389</td>
                        <td class="column1 style3 s">SAGARIKA TCG</td>
                        <td class="column2 style5 s">TALPUKUR PARA DAS PARA</td>
                        <td class="column3 style2 n">19</td>
                        <td class="column4 style5 s">TALPUKUR PARA DAS PARA</td>
                        <td class="column5 style2 s">YES</td>
                      </tr>
                      <tr class="row390">
                        <td class="column0 style2 n">390</td>
                        <td class="column1 style3 s">RATH TALA 2NO TCG </td>
                        <td class="column2 style5 s">RATHTALA RANAGHAT NADIA</td>
                        <td class="column3 style2 n">19</td>
                        <td class="column4 style5 s">RATHTALA RANAGHAT NADIA</td>
                        <td class="column5 style2 s">YES</td>
                      </tr>
                      <tr class="row391">
                        <td class="column0 style2 n">391</td>
                        <td class="column1 style3 s">RATH TALA 3NO TCG</td>
                        <td class="column2 style5 s">RATHTALA RANAGHAT NADIA</td>
                        <td class="column3 style2 n">19</td>
                        <td class="column4 style5 s">RATHTALA RANAGHAT NADIA</td>
                        <td class="column5 style2 s">YES</td>
                      </tr>
                      <tr class="row392">
                        <td class="column0 style2 n">392</td>
                        <td class="column1 style3 s">RATH TALA 4NO TCG</td>
                        <td class="column2 style5 s">RATHTALA RANAGHAT NADIA</td>
                        <td class="column3 style2 n">19</td>
                        <td class="column4 style5 s">RATHTALA RANAGHAT NADIA</td>
                        <td class="column5 style2 s">YES</td>
                      </tr>
                      <tr class="row393">
                        <td class="column0 style2 n">393</td>
                        <td class="column1 style3 s">RATH TALA 6NO TCG</td>
                        <td class="column2 style5 s">RATHTALA RANAGHAT NADIA</td>
                        <td class="column3 style2 n">19</td>
                        <td class="column4 style5 s">RATHTALA RANAGHAT NADIA</td>
                        <td class="column5 style2 s">YES</td>
                      </tr>
                      <tr class="row394">
                        <td class="column0 style2 n">394</td>
                        <td class="column1 style3 s">RATH TALA 8NO TCG</td>
                        <td class="column2 style5 s">RATHTALA RANAGHAT NADIA</td>
                        <td class="column3 style2 n">19</td>
                        <td class="column4 style5 s">RATHTALA RANAGHAT NADIA</td>
                        <td class="column5 style2 s">YES</td>
                      </tr>
                      <tr class="row395">
                        <td class="column0 style2 n">395</td>
                        <td class="column1 style3 s">MILL PARA 1 NO  TCG</td>
                        <td class="column2 style5 s">MILL PARA</td>
                        <td class="column3 style2 n">19</td>
                        <td class="column4 style5 s">MILL PARA</td>
                        <td class="column5 style2 s">YES</td>
                      </tr>
                      <tr class="row396">
                        <td class="column0 style2 n">396</td>
                        <td class="column1 style3 s">MUCHIPARA LANE 2NO SHG</td>
                        <td class="column2 style5 s">MUCHIPARA LANE</td>
                        <td class="column3 style2 n">19</td>
                        <td class="column4 style5 s">MUCHIPARA LANE</td>
                        <td class="column5 style2 s">YES</td>
                      </tr>
                      <tr class="row397">
                        <td class="column0 style2 n">397</td>
                        <td class="column1 style3 s">MUCHIPARA LANE 3NO SHG</td>
                        <td class="column2 style5 s">MUCHIPARA LANE</td>
                        <td class="column3 style2 n">19</td>
                        <td class="column4 style5 s">MUCHIPARA LANE</td>
                        <td class="column5 style2 s">YES</td>
                      </tr>
                      <tr class="row398">
                        <td class="column0 style2 n">398</td>
                        <td class="column1 style3 s">MUCHIPARA LANE 4NO SHG</td>
                        <td class="column2 style5 s">MUCHIPARA LANE</td>
                        <td class="column3 style2 n">19</td>
                        <td class="column4 style5 s">MUCHIPARA LANE</td>
                        <td class="column5 style2 s">YES</td>
                      </tr>
                      <tr class="row399">
                        <td class="column0 style2 n">399</td>
                        <td class="column1 style3 s">RATH  TALA 9 NO SHG</td>
                        <td class="column2 style5 s">RATH TALA BUS STAND</td>
                        <td class="column3 style2 n">19</td>
                        <td class="column4 style5 s">RATH TALA BUS STAND</td>
                        <td class="column5 style2 s">YES</td>
                      </tr>
                      <tr class="row400">
                        <td class="column0 style2 n">400</td>
                        <td class="column1 style3 s">RATHTALA 11 NO SHG</td>
                        <td class="column2 style5 s">RATH TALA BUS STAND</td>
                        <td class="column3 style2 n">19</td>
                        <td class="column4 style5 s">RATH TALA BUS STAND</td>
                        <td class="column5 style2 s">YES</td>
                      </tr>
                      <tr class="row401">
                        <td class="column0 style2 n">401</td>
                        <td class="column1 style3 s">RATHTALA 12 NO SHG</td>
                        <td class="column2 style5 s">RATH TALA BAZAR</td>
                        <td class="column3 style2 n">19</td>
                        <td class="column4 style5 s">RATH TALA BAZAR</td>
                        <td class="column5 style2 s">YES</td>
                      </tr>
                      <tr class="row402">
                        <td class="column0 style2 n">402</td>
                        <td class="column1 style3 s">MUCHIPARA LANE 1 NO</td>
                        <td class="column2 style5 s">MUCHIPARA LANE</td>
                        <td class="column3 style2 n">19</td>
                        <td class="column4 style5 s">MUCHIPARA LANE</td>
                        <td class="column5 style2 s">YES</td>
                      </tr>
                      <tr class="row403">
                        <td class="column0 style2 n">403</td>
                        <td class="column1 style3 s">RATHTALA 1 NO TCG </td>
                        <td class="column2 style5 s">TALPUKUR PARA DAS PARA</td>
                        <td class="column3 style2 n">19</td>
                        <td class="column4 style5 s">TALPUKUR PARA DAS PARA</td>
                        <td class="column5 style2 s">YES</td>
                      </tr>
                      <tr class="row404">
                        <td class="column0 style2 n">404</td>
                        <td class="column1 style3 s">AGROGATI TCG </td>
                        <td class="column2 style5 s">TAL PUKUR PARA  RANAGHAT NADIA</td>
                        <td class="column3 style2 n">20</td>
                        <td class="column4 style5 s">TAL PUKUR PARA  RANAGHAT NADIA</td>
                        <td class="column5 style2 s">YES</td>
                      </tr>
                      <tr class="row405">
                        <td class="column0 style2 n">405</td>
                        <td class="column1 style3 s">BASUNDHARA TCG</td>
                        <td class="column2 style5 s">TAL PUKUR PARA  RANAGHAT NADIA</td>
                        <td class="column3 style2 n">20</td>
                        <td class="column4 style5 s">TAL PUKUR PARA  RANAGHAT NADIA</td>
                        <td class="column5 style2 s">YES</td>
                      </tr>
                      <tr class="row406">
                        <td class="column0 style2 n">406</td>
                        <td class="column1 style3 s">MANGALDIP TCG</td>
                        <td class="column2 style5 s">TAL PUKUR PARA  RANAGHAT NADIA</td>
                        <td class="column3 style2 n">20</td>
                        <td class="column4 style5 s">TAL PUKUR PARA  RANAGHAT NADIA</td>
                        <td class="column5 style2 s">YES</td>
                      </tr>
                      <tr class="row407">
                        <td class="column0 style2 n">407</td>
                        <td class="column1 style3 s">TALPUKUR PARA 1NO TCG</td>
                        <td class="column2 style5 s">TAL PUKUR PARA  RANAGHAT NADIA</td>
                        <td class="column3 style2 n">20</td>
                        <td class="column4 style5 s">TAL PUKUR PARA  RANAGHAT NADIA</td>
                        <td class="column5 style2 s">YES</td>
                      </tr>
                      <tr class="row408">
                        <td class="column0 style2 n">408</td>
                        <td class="column1 style3 s">MAHUT PARA 1NO TCG</td>
                        <td class="column2 style4 s">MAHUT PARA</td>
                        <td class="column3 style2 n">20</td>
                        <td class="column4 style4 s">MAHUT PARA</td>
                        <td class="column5 style2 s">YES</td>
                      </tr>
                      <tr class="row409">
                        <td class="column0 style2 n">409</td>
                        <td class="column1 style3 s">NARKEL BAGAN 1NO TCG</td>
                        <td class="column2 style5 s">NARKEL BAGAN</td>
                        <td class="column3 style2 n">20</td>
                        <td class="column4 style5 s">NARKEL BAGAN</td>
                        <td class="column5 style2 s">YES</td>
                      </tr>
                      <tr class="row410">
                        <td class="column0 style2 n">410</td>
                        <td class="column1 style3 s">NARKEL BAGAN 2NO TCG</td>
                        <td class="column2 style5 s">NARKEL BAGAN</td>
                        <td class="column3 style2 n">20</td>
                        <td class="column4 style5 s">NARKEL BAGAN</td>
                        <td class="column5 style2 s">YES</td>
                      </tr>
                      <tr class="row411">
                        <td class="column0 style2 n">411</td>
                        <td class="column1 style3 s">TALPUKUR PARA 4NO TCG</td>
                        <td class="column2 style5 s">TALPUKUR PARA</td>
                        <td class="column3 style2 n">20</td>
                        <td class="column4 style5 s">TALPUKUR PARA</td>
                        <td class="column5 style2 s">YES</td>
                      </tr>
                      <tr class="row412">
                        <td class="column0 style2 n">412</td>
                        <td class="column1 style3 s">TALPUKUR PARA 5NO TCG</td>
                        <td class="column2 style5 s">TALPUKUR PARA</td>
                        <td class="column3 style2 n">20</td>
                        <td class="column4 style5 s">TALPUKUR PARA</td>
                        <td class="column5 style2 s">YES</td>
                      </tr>
                      <tr class="row413">
                        <td class="column0 style2 n">413</td>
                        <td class="column1 style3 s">TALPUKUR PARA 6NO TCG</td>
                        <td class="column2 style5 s">TALPUKUR PARA</td>
                        <td class="column3 style2 n">20</td>
                        <td class="column4 style5 s">TALPUKUR PARA</td>
                        <td class="column5 style2 s">YES</td>
                      </tr>
                      <tr class="row414">
                        <td class="column0 style2 n">414</td>
                        <td class="column1 style3 s">TALPUKUR PARA 7NO TCG</td>
                        <td class="column2 style5 s">TALPUKUR PARA</td>
                        <td class="column3 style2 n">20</td>
                        <td class="column4 style5 s">TALPUKUR PARA</td>
                        <td class="column5 style2 s">YES</td>
                      </tr>
                      <tr class="row415">
                        <td class="column0 style2 n">415</td>
                        <td class="column1 style3 s">TALPUKUR PARA 3 NO TCG </td>
                        <td class="column2 style5 s">TALPUKUR PARA</td>
                        <td class="column3 style2 n">20</td>
                        <td class="column4 style5 s">TALPUKUR PARA</td>
                        <td class="column5 style2 s">YES</td>
                      </tr>
                      <tr class="row416">
                        <td class="column0 style2 n">416</td>
                        <td class="column1 style3 s">TALPUKUR PARA 8 NO SHG</td>
                        <td class="column2 style5 s">TALPUKUR PARA</td>
                        <td class="column3 style2 n">20</td>
                        <td class="column4 style5 s">TALPUKUR PARA</td>
                        <td class="column5 style2 s">YES</td>
                      </tr>
                      <tr class="row417">
                        <td class="column0 style2 n">417</td>
                        <td class="column1 style3 s">NARKEL BAGAN LANE 4 NO SHG</td>
                        <td class="column2 style5 s">NARKEL BAGAN LANE</td>
                        <td class="column3 style2 n">20</td>
                        <td class="column4 style5 s">NARKEL BAGAN LANE</td>
                        <td class="column5 style2 s">YES</td>
                      </tr>
                      <tr class="row418">
                        <td class="column0 style2 n">418</td>
                        <td class="column1 style3 s">NARKEL BAGAN 3 NO SHG </td>
                        <td class="column2 style5 s">NARKEL BAGAN LANE</td>
                        <td class="column3 style2 n">20</td>
                        <td class="column4 style5 s">NARKEL BAGAN LANE</td>
                        <td class="column5 style2 s">YES</td>
                      </tr>
                      <tr class="row419">
                        <td class="column0 style2 n">419</td>
                        <td class="column1 style3 s">TALPUKUR PARA 9 NO SHG</td>
                        <td class="column2 style5 s">TALPUKUR PARA</td>
                        <td class="column3 style2 n">20</td>
                        <td class="column4 style5 s">TALPUKUR PARA</td>
                        <td class="column5 style2 s">YES</td>
                      </tr>
                      <tr class="row420">
                        <td class="column0 style2 n">420</td>
                        <td class="column1 style3 s">MAHUT PARA 7 NO SHG</td>
                        <td class="column2 style5 s">MAHUT PARA</td>
                        <td class="column3 style2 n">20</td>
                        <td class="column4 style5 s">MAHUT PARA</td>
                        <td class="column5 style2 s">YES</td>
                      </tr>
                      <tr class="row421">
                        <td class="column0 style2 n">421</td>
                        <td class="column1 style3 s">TALPUKUR PARA 10 NO SHG</td>
                        <td class="column2 style5 s">TALPUKUR PARA </td>
                        <td class="column3 style2 n">20</td>
                        <td class="column4 style5 s">TALPUKUR PARA </td>
                        <td class="column5 style2 s">YES</td>
                      </tr>
                      <tr class="row422">
                        <td class="column0 style2 n">422</td>
                        <td class="column1 style3 s">TALPUKUR PARA 11 NO SHG</td>
                        <td class="column2 style5 s">TALPUKUR PARA </td>
                        <td class="column3 style2 n">20</td>
                        <td class="column4 style5 s">TALPUKUR PARA </td>
                        <td class="column5 style2 s">YES</td>
                      </tr>
                      <tr class="row423">
                        <td class="column0 style2 n">423</td>
                        <td class="column1 style3 s">TALPUKUR PARA 12 NO SHG</td>
                        <td class="column2 style5 s">TALPUKUR PARA </td>
                        <td class="column3 style2 n">20</td>
                        <td class="column4 style5 s">TALPUKUR PARA </td>
                        <td class="column5 style2 s">YES</td>
                      </tr>
                      <tr class="row424">
                        <td class="column0 style2 n">424</td>
                        <td class="column1 style3 s">TALPUKUR PARA 13 NO SHG</td>
                        <td class="column2 style5 s">TALPUKUR PARA </td>
                        <td class="column3 style2 n">20</td>
                        <td class="column4 style5 s">TALPUKUR PARA </td>
                        <td class="column5 style2 s">YES</td>
                      </tr>
                      <tr class="row425">
                        <td class="column0 style2 n">425</td>
                        <td class="column1 style3 s">TALPUKUR PARA 14 NO SHG</td>
                        <td class="column2 style5 s">TALPUKUR PARA </td>
                        <td class="column3 style2 n">20</td>
                        <td class="column4 style5 s">TALPUKUR PARA </td>
                        <td class="column5 style2 s">YES</td>
                      </tr>
                      <tr class="row426">
                        <td class="column0 style2 n">426</td>
                        <td class="column1 style3 s">SARAK PARA 5 NO TCG</td>
                        <td class="column2 style5 s">SARAK PARA</td>
                        <td class="column3 style2 n">1</td>
                        <td class="column4 style5 s">SARAK PARA</td>
                        <td class="column5 style2 s">YES</td>
                      </tr>
                      <tr class="row427">
                        <td class="column0 style2 n">427</td>
                        <td class="column1 style3 s">SARAK PARA 6 NO SHG</td>
                        <td class="column2 style4 s">SAAK PARA</td>
                        <td class="column3 style2 n">1</td>
                        <td class="column4 style4 s">SAAK PARA</td>
                        <td class="column5 style2 s">YES</td>
                      </tr>
                      <tr class="row428">
                        <td class="column0 style2 n">428</td>
                        <td class="column1 style3 s">KHEYAGHAT LANE 5 NO SHG</td>
                        <td class="column2 style5 s">KHEYAGHAT LANE</td>
                        <td class="column3 style2 n">1</td>
                        <td class="column4 style5 s">KHEYAGHAT LANE</td>
                        <td class="column5 style2 s">YES</td>
                      </tr>
                      <tr class="row429">
                        <td class="column0 style2 n">429</td>
                        <td class="column1 style3 s">AMLOKI TALA LANE 1 NO TCG</td>
                        <td class="column2 style4 s">AMLOKI TALA </td>
                        <td class="column3 style2 n">4</td>
                        <td class="column4 style4 s">AMLOKI TALA </td>
                        <td class="column5 style2 s">YES</td>
                      </tr>
                      <tr class="row430">
                        <td class="column0 style2 n">430</td>
                        <td class="column1 style3 s">AMLOKI TALA LANE 4 NO SHG</td>
                        <td class="column2 style4 s">AMLOKI TALA</td>
                        <td class="column3 style2 n">4</td>
                        <td class="column4 style4 s">AMLOKI TALA</td>
                        <td class="column5 style2 s">YES</td>
                      </tr>
                      <tr class="row431">
                        <td class="column0 style2 n">431</td>
                        <td class="column1 style3 s">DAKSHIN PARA 1NO SHG</td>
                        <td class="column2 style5 s">DAKSHIN PARA </td>
                        <td class="column3 style2 n">7</td>
                        <td class="column4 style5 s">DAKSHIN PARA </td>
                        <td class="column5 style2 s">YES</td>
                      </tr>
                      <tr class="row432">
                        <td class="column0 style2 n">432</td>
                        <td class="column1 style3 s">BISWAS PARA 1 NO SHG</td>
                        <td class="column2 style5 s">BISWAS PARA</td>
                        <td class="column3 style2 n">8</td>
                        <td class="column4 style5 s">BISWAS PARA</td>
                        <td class="column5 style2 s">YES</td>
                      </tr>
                      <tr class="row433">
                        <td class="column0 style2 n">433</td>
                        <td class="column1 style3 s">BISWAS PARA 4 NO SHG</td>
                        <td class="column2 style5 s">BISWAS PARA</td>
                        <td class="column3 style2 n">8</td>
                        <td class="column4 style5 s">BISWAS PARA</td>
                        <td class="column5 style2 s">YES</td>
                      </tr>
                      <tr class="row434">
                        <td class="column0 style2 n">434</td>
                        <td class="column1 style3 s">SARASWATI TCG </td>
                        <td class="column2 style4 s">THANAPARA</td>
                        <td class="column3 style2 n">10</td>
                        <td class="column4 style4 s">THANAPARA</td>
                        <td class="column5 style2 s">YES</td>
                      </tr>
                      <tr class="row435">
                        <td class="column0 style2 n">435</td>
                        <td class="column1 style3 s">TISTA  TCG</td>
                        <td class="column2 style4 s">THANAPARA</td>
                        <td class="column3 style2 n">10</td>
                        <td class="column4 style4 s">THANAPARA</td>
                        <td class="column5 style2 s">YES</td>
                      </tr>
                      <tr class="row436">
                        <td class="column0 style2 n">436</td>
                        <td class="column1 style3 s">JHILAM  TCG</td>
                        <td class="column2 style4 s">THANAPARA</td>
                        <td class="column3 style2 n">10</td>
                        <td class="column4 style4 s">THANAPARA</td>
                        <td class="column5 style2 s">YES</td>
                      </tr>
                      <tr class="row437">
                        <td class="column0 style2 n">437</td>
                        <td class="column1 style3 s">LUNI TCG </td>
                        <td class="column2 style4 s">THANAPARA</td>
                        <td class="column3 style2 n">10</td>
                        <td class="column4 style4 s">THANAPARA</td>
                        <td class="column5 style2 s">YES</td>
                      </tr>
                      <tr class="row438">
                        <td class="column0 style2 n">438</td>
                        <td class="column1 style3 s">&nbsp;BALASON TCG</td>
                        <td class="column2 style4 s">COURT PARA</td>
                        <td class="column3 style2 n">10</td>
                        <td class="column4 style4 s">COURT PARA</td>
                        <td class="column5 style2 s">YES</td>
                      </tr>
                      <tr class="row439">
                        <td class="column0 style2 n">439</td>
                        <td class="column1 style3 s">RAJBAGAN PARA 2 NO TCG</td>
                        <td class="column2 style5 s">RAJBAGAN PARA</td>
                        <td class="column3 style2 n">10</td>
                        <td class="column4 style5 s">RAJBAGAN PARA</td>
                        <td class="column5 style2 s">YES</td>
                      </tr>
                      <tr class="row440">
                        <td class="column0 style2 n">440</td>
                        <td class="column1 style3 s">RAJBAGAN PARA 3 NO SHG</td>
                        <td class="column2 style4 s">RAJBAGAN PARA</td>
                        <td class="column3 style2 n">10</td>
                        <td class="column4 style4 s">RAJBAGAN PARA</td>
                        <td class="column5 style2 s">YES</td>
                      </tr>
                      <tr class="row441">
                        <td class="column0 style2 n">441</td>
                        <td class="column1 style3 s">MEGHNA  TCG</td>
                        <td class="column2 style5 s">S.D ROAD , THANAPARA</td>
                        <td class="column3 style2 n">10</td>
                        <td class="column4 style5 s">S.D ROAD , THANAPARA</td>
                        <td class="column5 style2 s">YES</td>
                      </tr>
                      <tr class="row442">
                        <td class="column0 style2 n">442</td>
                        <td class="column1 style3 s">S.D RODA 6 NO SHG</td>
                        <td class="column2 style4 s">S.D ROAD</td>
                        <td class="column3 style2 n">10</td>
                        <td class="column4 style4 s">S.D ROAD</td>
                        <td class="column5 style2 s">YES</td>
                      </tr>
                      <tr class="row443">
                        <td class="column0 style2 n">443</td>
                        <td class="column1 style3 s">S.V ROAD 2 NO SHG</td>
                        <td class="column2 style4 s">S.D ROAD</td>
                        <td class="column3 style2 n">10</td>
                        <td class="column4 style4 s">S.D ROAD</td>
                        <td class="column5 style2 s">YES</td>
                      </tr>
                      <tr class="row444">
                        <td class="column0 style2 n">444</td>
                        <td class="column1 style3 s">BHABANI GARH 3 SHG</td>
                        <td class="column2 style5 s">BHABANI GARH</td>
                        <td class="column3 style2 n">11</td>
                        <td class="column4 style5 s">BHABANI GARH</td>
                        <td class="column5 style2 s">YES</td>
                      </tr>
                      <tr class="row445">
                        <td class="column0 style2 n">445</td>
                        <td class="column1 style3 s">NASRA PARA 1NO TCG</td>
                        <td class="column2 style5 s">NASRA PARA</td>
                        <td class="column3 style2 n">17</td>
                        <td class="column4 style5 s">NASRA PARA</td>
                        <td class="column5 style2 s">YES</td>
                      </tr>
                      <tr class="row446">
                        <td class="column0 style2 n">446</td>
                        <td class="column1 style3 s">SHITALA TALA 6 NO SHG</td>
                        <td class="column2 style5 s">SHITALATALA</td>
                        <td class="column3 style2 n">12</td>
                        <td class="column4 style5 s">SHITALATALA</td>
                        <td class="column5 style2 s">YES</td>
                      </tr>
                      <tr class="row447">
                        <td class="column0 style2 n">447</td>
                        <td class="column1 style3 s">CHUNURI PARA 1NO TCG</td>
                        <td class="column2 style5 s">CHUNURI PARA</td>
                        <td class="column3 style2 n">5</td>
                        <td class="column4 style5 s">CHUNURI PARA</td>
                        <td class="column5 style2 s">YES</td>
                      </tr>
                      <tr class="row448">
                        <td class="column0 style2 n">448</td>
                        <td class="column1 style3 s">SURIYA SIKHA  TCG</td>
                        <td class="column2 style5 s">SURYA NAGAR</td>
                        <td class="column3 style2 n">15</td>
                        <td class="column4 style5 s">SURYA NAGAR</td>
                        <td class="column5 style2 s">YES</td>
                      </tr>
                      <tr class="row449">
                        <td class="column0 style2 n">449</td>
                        <td class="column1 style3 s">JAY MAA TARA TCG</td>
                        <td class="column2 style5 s">SURYA NAGAR</td>
                        <td class="column3 style2 n">15</td>
                        <td class="column4 style5 s">SURYA NAGAR</td>
                        <td class="column5 style2 s">YES</td>
                      </tr>
                      <tr class="row450">
                        <td class="column0 style2 n">450</td>
                        <td class="column1 style3 s">RADHA RANI TCG</td>
                        <td class="column2 style5 s">SURYA NAGAR</td>
                        <td class="column3 style2 n">15</td>
                        <td class="column4 style5 s">SURYA NAGAR</td>
                        <td class="column5 style2 s">YES</td>
                      </tr>
                      <tr class="row451">
                        <td class="column0 style2 n">451</td>
                        <td class="column1 style3 s">SURIYA NAGAR 2NO SHG</td>
                        <td class="column2 style5 s">SURYA NAGAR</td>
                        <td class="column3 style2 n">15</td>
                        <td class="column4 style5 s">SURYA NAGAR</td>
                        <td class="column5 style2 s">YES</td>
                      </tr>
                      <tr class="row452">
                        <td class="column0 style2 n">452</td>
                        <td class="column1 style3 s">SURIYA NAGAR 16 NO SHG</td>
                        <td class="column2 style5 s">SURIYA NAGAR</td>
                        <td class="column3 style2 n">15</td>
                        <td class="column4 style5 s">SURIYA NAGAR</td>
                        <td class="column5 style2 s">YES</td>
                      </tr>
                      <tr class="row453">
                        <td class="column0 style2 n">453</td>
                        <td class="column1 style3 s">SURIYA NAGAR 17 NO SHG</td>
                        <td class="column2 style5 s">SURIYA NAGAR</td>
                        <td class="column3 style2 n">15</td>
                        <td class="column4 style5 s">SURIYA NAGAR</td>
                        <td class="column5 style2 s">YES</td>
                      </tr>
                      <tr class="row454">
                        <td class="column0 style2 n">454</td>
                        <td class="column1 style3 s">SURYA NAGAR 6 NO SHG</td>
                        <td class="column2 style5 s">SURYA NAGAR</td>
                        <td class="column3 style2 n">15</td>
                        <td class="column4 style5 s">SURYA NAGAR</td>
                        <td class="column5 style2 s">YES</td>
                      </tr>
                      <tr class="row455">
                        <td class="column0 style2 n">455</td>
                        <td class="column1 style3 s">NASRA PARA LANE 12 NO SHG</td>
                        <td class="column2 style5 s">NASRA PARA LANE</td>
                        <td class="column3 style2 n">17</td>
                        <td class="column4 style5 s">NASRA PARA LANE</td>
                        <td class="column5 style2 s">YES</td>
                      </tr>
                      <tr class="row456">
                        <td class="column0 style2 n">456</td>
                        <td class="column1 style3 s">SHAYAMA PRASAD PALLY 6 NO SHG</td>
                        <td class="column2 style5 s">SHAYAMA PRASAD PALLY</td>
                        <td class="column3 style2 n">17</td>
                        <td class="column4 style5 s">SHAYAMA PRASAD PALLY</td>
                        <td class="column5 style2 s">YES</td>
                      </tr>
                      <tr class="row457">
                        <td class="column0 style2 n">457</td>
                        <td class="column1 style3 s">RATHTALA 11 NO SHG</td>
                        <td class="column2 style5 s">RATH TALA BUS STAND</td>
                        <td class="column3 style2 n">19</td>
                        <td class="column4 style5 s">RATH TALA </td>
                        <td class="column5 style2 s">YES</td>
                      </tr>
                    </tbody>
                </table>
             
              </div>
               
              
               
             
             </div>  
         </div>
      </div>
    </div>
  </section>

      
 
