 <!-- <div *ngIf="showMenu == 'admin'">
  <app-header></app-header>
  <router-outlet></router-outlet>
</div>

<div *ngIf="showMenu == 'noAdmin'">
  <app-header></app-header>
  <router-outlet></router-outlet>
  <app-footer></app-footer>
</div> -->



<!-- <app-header></app-header> -->
<router-outlet></router-outlet>
<app-footer *ngIf="showMenu == 'noAdmin'"></app-footer>