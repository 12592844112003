import { Component, OnInit } from '@angular/core';
import { Router, ActivatedRoute } from '@angular/router';
import { ApiService } from 'src/app/services/api.service';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { formatDate } from '@angular/common';
import { NgxSpinnerService } from "ngx-spinner";
import { ToastrService } from 'ngx-toastr';
import { PagerService } from '../../services/pager.service';

@Component({
  selector: 'app-admin-gallery',
  templateUrl: './admin-gallery.component.html',
  styleUrls: ['./admin-gallery.component.css']
})
export class AdminGalleryComponent implements OnInit {
  myFiles: string[] = [];
  imageObject: Array<object> = [];
  adminGalleryForm: any;
  filegallery: any;
  gallaeryList: any;
  photoList: any;
  photocount: number = 0;
  currentFile: any;
  imagepreview: any;
  fileToUpload: any;
  imageUrl: any;
  urls: any;
  displayStyleAdd = "none";
  displayStyleEdit = "none";
  displayStylePlus = "none";
  currentPage: number = 1;
  pageSize: number = 10;
  totalCount: number = 0;
  pager: any = {};
  pagedItems: any = [];
  pageNo: number = 1;
  searchItem: string = '';
  userid: any;
  id: any;
  invalid: boolean = false;
  constructor(private apiService: ApiService,
    private router: Router,
    private formBuilder: FormBuilder,
    private SpinnerService: NgxSpinnerService,
    private toastrService: ToastrService,
    private pagerService: PagerService) {
    this.initForm();
  }

  ngOnInit(): void {
    this.getAllGallery(this.currentPage);
  }
  
  searchTable(event: any) {
    this.searchItem = event.target.value;
    this.getAllGallery(this.currentPage);
  }
  get f1() { return this.adminGalleryForm.controls; }

  initForm() {
    this.adminGalleryForm = this.formBuilder.group({
      subject: ['', Validators.required],
      filegallery: ['', Validators.required]
    });
  }

  getAllGallery(currentPage: number) {
    let param = {
      // "filter": this.searchItem,
      // "order": "subject",
      // "limit": 10,
      // "page": this.pageNo

      "filter": this.searchItem,
      "order": "subject",
      "limit": this.pageSize,
      "page": currentPage

    }
    //this.SpinnerService.show(); 
    this.apiService.adminGalleryGetall(param).subscribe((res: any) => {
      if (res.result == "Success") {
        this.gallaeryList = res.data.galleries;
        console.log('data: ', res.data.galleries)
        //this.SpinnerService.hide(); 
        this.totalCount = res.data.count;
        console.log('totalcount', this.totalCount)
        this.setPage(currentPage);

      }
      else{
        this.totalCount = res.data.count;
        console.log('totalcount', this.totalCount)
        this.setPage(currentPage);
      }
    },
      (error: any) => {

      });
  }

  setPage(page: number) {
    this.pager = this.pagerService.getPager(this.totalCount, page, this.pageSize);
    this.pagedItems = this.totalCount > 0 ? this.gallaeryList.slice(this.pager.startIndex, this.pager.endIndex + 1) : [];
  }

  Submit() {
    if (this.adminGalleryForm.invalid) {
      this.invalid = true;
      return;
    }
    else {
      let profileFD = new FormData();
      this.userid = localStorage.getItem('userid');
      for (var i = 0; i < this.myFiles.length; i++) {
        profileFD.append('file', this.myFiles[i]);
      }
      //if(this.filegallery){profileFD.append('file', this.f1.filegallery.value)}
      profileFD.append("subject", this.f1.subject.value)
      profileFD.append("createdBy", this.userid)
      profileFD.append("createdOn", Date())
      this.apiService.adminGalleryadd(profileFD).subscribe((res: any) => {
        if (res.result == "Success") {
          this.toastrService.success('Gallery added successfully');
          window.location.reload();
        }
      },
        (error: any) => {
          this.toastrService.error('Gallery not added successfully');
        });
    }
  }

  onSelectFile(event: any) {
    if (event.target.files) {
      var filesAmount = event.target.files.length;
      for (let i = 0; i < filesAmount; i++) {
        var reader = new FileReader();
        reader.readAsDataURL(event.target.files[i]);
        reader.onload = (event) => {
          this.urls.push(event.target?.result)
        }
      }
    }
  }

  handleFileInput(file: FileList) {
    this.fileToUpload = file.item(0);

    //Show image preview
    let reader = new FileReader();
    reader.onload = (event: any) => {
      this.imageUrl = event.target.result;
    }
    reader.readAsDataURL(this.fileToUpload);
  }

  getFileDetails(e: any) {
    for (var i = 0; i < e.target.files.length; i++) {
      this.myFiles.push(e.target.files[i]);
      this.fileToUpload = e.target.files[i];
      let reader = new FileReader();
      reader.readAsDataURL(this.fileToUpload);
      reader.onload = (event: any) => {
        console.log(event.target.result);
        this.imageUrl = event.target.result;
      }
      //reader.readAsDataURL(this.fileToUpload);



      //this.myFiles.push(e.target.files[i]);

      // this.imagepreview = '';
      // this.currentFile = this.myFiles;

      // const reader = new FileReader();
      // reader.readAsDataURL(e.target.files[i]);
      // reader.onload = (e: any) => {
      //   console.log(e.target.result);
      //   this.imagepreview = e.target.result;
      //   this.myFiles.push(e.target.files[i]);
      // };
      reader.readAsDataURL(this.currentFile);
    }
    console.log(this.myFiles)
  }

  uploadFiles() {
    const frmData = new FormData();

    for (var i = 0; i < this.myFiles.length; i++) {
      frmData.append("fileUpload", this.myFiles[i]);
    }
  }

  openPopupEdit(id: any) {
    this.id = id;
    this.apiService.adminGalleryId(id).subscribe((res: any) => {
      if (res.result == "Success") {
        console.log('edit :', res.data)
        this.f1.subject.setValue(res.data.subject);
        this.photocount = res.data.photocount;
        this.photoList = res.data.photos;
        console.log('photo list : ', this.photoList)
        //this.f1.publishingdate.setValue(formatDate(res.data.publishingdate,'dd-MM-yyyy','en'));
        for (let item of this.photoList) {
          let image = 'data:image/png;base64,${item.byteData}';
          let param = [
            {
              image: image,
              thumbImage: image,
              title: 'Slider Image 1',
              alt: 'Image Alt 1'
            }
          ];
          this.imageObject.push(param);
        }
      }
    },
      (error: any) => {

      });
    this.displayStyleEdit = "block";

  }
  Update() {
    let profileFD = new FormData();
    
    
    profileFD.append("subject", this.f1.subject.value)
    profileFD.append("id", this.id)

    if(this.myFiles.length !=0){
      for (var i = 0; i < this.myFiles.length; i++) {
        profileFD.append('file', this.myFiles[i]);
      }
    }    
    this.apiService.adminGalleryUpdate(profileFD).subscribe((res: any) => {
      if (res.result == "Success") {
        this.toastrService.success('Gallery updated successfully');
        window.location.reload();
      }
    },
      (error: any) => {
        this.toastrService.error("Gallery not updated successfully");
      });
  }

  DeleteGallery(Id: any){
    this.apiService.adminGalleryDelete(Id).subscribe((res: any) => {
      if (res.result == "Success") {
        this.toastrService.success('Gallery deleted successfully');
        window.location.reload();
      }
    },
      (error: any) => {
        this.toastrService.error("Gallery not deleted successfully");
      });
  }

  checkPhotolistAfterRemove()
  {
    var count = this.photocount;
    let profileFD = new FormData();
    for (var i = 0; i < this.photocount; i++) {
      profileFD.append('file', this.myFiles[i]);
      console.log('file has :', this.myFiles[i])
    }
    
    count = count - 1;
  }  

  RemoveGallery(Id: any){
    this.apiService.adminRemoveGallery(Id).subscribe((res: any) => {
      if (res.result == "Success") {
        this.toastrService.success('Gallery deleted successfully');
        window.location.reload();
      }
    },
      (error: any) => {
        this.toastrService.error("Gallery not deleted successfully");
      });
  }

  closePopupAdd() {
    this.displayStyleAdd = "none";
  }
  openPopupAdd() {
    this.displayStyleAdd = "block";
    this.f1.subject.setValue(null);
  }
  closePopupEdit() {
    this.displayStyleEdit = "none";
  }

}
