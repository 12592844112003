<app-header></app-header>
<div class="inner-background-two">
  <img src="assets/images/inner_banner.jpg">
  <div class="latest-overlay-two">
  <div class="container"><h2>About Us</h2></div>
</div>
</div>
<section class="inner-post">
  <div class="container ">
    <div class="row">
       <div class="col-lg-3 col-sm-12 col-12 ">
        <div class="left-pannel">
          <p>
            Ranaghat Municipality was established in 1864. 
            It started functioning on 21.9.1984 with its office housed in a building donated by the then Zaminder 
            family viz. Pal Chowdhury family. In the beginning area of the municipality was 3.32 sq.
            miles and this area was demarcated into 6 wards covering a total population of 6000. It was the 
            7th Municipality of undivided Bengal. No. of councilors of the first Board was 18 out of which 12 were
            elected by citizens, 4 were nominated by the state Govt. and the remaining 2 were ex-officio 
            councilors. In course of time Nasra Colony, a refugee settlement, was added to the Municipality and 
            its area became 7.72 sq.k.m. With the increase of area and population the 
            municipality had to be demarcated into 16 wards in 1990 and to 19 wards since 1995 by bifurcation 
            of ward nos. 11, 12,14,16,17.
             Present population of the municipality is 68,754 as per census 2001.
          </p>
        </div>
         </div>
         <div class="col-lg-4 col-sm-9 col-9">
          <div class="right-pannel">
            <img src="../../../assets/images/Ranaghat Municiaplity _ ADVT Final _September 2022_page-0001.jpg" width="600" height="700">
       </div>
          </div>
    
    </div>
  </div>
</section>

