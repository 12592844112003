<footer class="footer">
  <div class="container">
    <div class="row justify-content-center">
      <div class="col-xl-12">
        <div class="footer-top-header">
          <div class="row">



            <div class="col-sm-12 col-md-12">
              <ul class="footer_social_icons">
                <li>
                  <a href="https://www.facebook.com/rmatservice?mibextid=ZbWKwL" target="_blank">
                    <i class="fab fa-facebook-f" style="background: #5465ad;color:#ffffff"></i>
                  </a>
                </li>
                <li>
                  <a href="javscript:void(0);" target="_blank">
                    <i class="fab fa-twitter" style="background: #24afde;color:#ffffff"></i>
                  </a>
                </li>
                <li>
                  <a href="javscript:void(0);" target="_blank">
                    <i class="fab fa-instagram icolor" style="background: linear-gradient(90deg, rgba(251,141,87,1) 1%, rgba(228,41,77,1) 30%, rgba(125,72,152,1) 73%);color:#ffffff"></i>
                  </a>
                </li>
                <li>
                  <a href="javscript:void(0);" target="_blank">
                    <i class="fab fa-linkedin-in" style="background: #0c75b2;color:#ffffff"></i>
                  </a>
                </li>
              </ul>
              <ul class="footer-ul-two">
                <li>
                  <a href="javascript:void(0);">About</a>
                </li>
                <li>
                  <a href="javascript:void(0);">Careers </a>
                </li>
                <li>
                  <a href="javascript:void(0);">Feedback</a>
                </li>
              </ul>

            </div>
          </div>
        </div>

      </div>
    </div>
  </div>
  <div class="footer-bottom-header">
    <div class="container">
      <div class="row">
        <div class="col-lg-6 col-md-6">
          <ul class="footer-ul">
            <li>
              <a href="javascript:void(0);">Privacy </a>
            </li>
            <li>
              <a href="javascript:void(0);">Terms of use</a>
            </li>
            <!-- <li>
              <a href="javascript:void(0);">Legislation</a>
            </li> -->
            <li>
              <a href="javascript:void(0);" routerLink="/sitemap">Sitemap</a>
            </li>
            <li>
              <a href="javascript:void(0);" routerLink="/contacts">Contact Us</a>
            </li>
            <!-- <li>
              <a href="javascript:void(0);" routerLink="/adminLogin">Admin Login</a>
            </li> -->
          </ul>
        </div>
        <div class="col-lg-6 col-md-5">
          
          <p class="footer-bottom-text">
            Copyright &copy; 2022. All rights reserved. Designed and <br>Powered by <a href="http://www.xiphostec.com/" target="_blank">xiphos</a>
          </p>

        
        </div>
        <div style="width: 100%; text-align:center">
          <small><a href='https://www.free-website-hit-counter.com' title="Website Hit Counter">You are visitor number:</a></small><br />
          <a href='https://www.free-website-hit-counter.com'><img src='https://www.free-website-hit-counter.com/c.php?d=8&id=143097&s=18' border='0' alt='Website Hit Counter'></a></div>
      </div>
    </div>
  </div>
</footer>