import { Component, OnInit, ViewEncapsulation } from '@angular/core';
import { Router, ActivatedRoute } from '@angular/router';
import { FormControl, FormGroup, Validators } from '@angular/forms';
import { AuthService } from './../../services/auth.service';
import { UserModel } from 'src/app/services/user-model';
import { NotificationService } from './../../services/notification.service'

declare function refreshLogin(): void;
interface IUser {
  username: string;
  password: string;
}
@Component({
  selector: 'app-admin-login',
  templateUrl: './admin-login.component.html'
})
export class AdminLoginComponent implements OnInit {
  loginParameter!: FormGroup;
  user: IUser;
  name: any;
  pwd: any;
  loginpage: any;
  isLoginForm: boolean = false;
  constructor(
    private authService: AuthService, private router: Router,
    private route: ActivatedRoute,
    private notifyService: NotificationService) {
    //this.router.routeReuseStrategy.shouldReuseRoute = () => false;
    //localStorage.removeItem('loginform'); 
    //this.router.navigated =false;
    this.user = {} as IUser;
    this.route.paramMap.subscribe(params => {
      this.ngOnInit();
  });
  }

  ngOnInit() {   
    //this.router.routeReuseStrategy.shouldReuseRoute = () => false;
    this.reloadCurrentRoute();
    this.isLoginForm = true;
     
    
    // const temp1 = document.createElement('link');
    // temp1.innerHTML = '<link id="default-css1" href="assets/assetsAdmin/css/main.css" rel="stylesheet" media="all">';
    // const head1 = document.head;
    // while (temp1.firstChild) {
    //   head1.appendChild(temp1.firstChild);
    // }

    const temp1 = document.createElement('link');
        temp1.innerHTML = '<link id="default-css1" href="assets/assetsAdmin/css/main.css" rel="stylesheet" media="all">';
        const head1 = document.head;
        while (temp1.firstChild) {
          head1.appendChild(temp1.firstChild);
        }        
    this.router.navigate(['/adminLogin']);

    this.authService.reloadLoginPage();
    this.loginpage = localStorage.getItem('loginform');
    
    this.loginParameter = new FormGroup({
      username: new FormControl(this.user.username, [
        Validators.required,
      ]),

      password: new FormControl(this.user.password, [
        Validators.required,
      ]),
    });

    let userModel: UserModel = this.authService.user;
    if (typeof userModel.userId != 'undefined')
      this.router.navigate(['adminDashboard']);

    //this.authService.loadJsFile("assets/assetsAdmin/js/main.js");

    
  }


  get username() {
    return this.loginParameter.get('username')!;
  }

  get password() {
    return this.loginParameter.get('password')!;
  }

  login() {
    debugger

    let param = {
      username: this.name,
      password: this.pwd
    }
    this.authService.login(param);
  }

  reloadCurrentRoute() {
    const cuurentUrl = this.router.url;
    // this.router.navigateByUrl('/adminLogin', {skipLocationChange: true}).then(()=> {
    // this.router.navigate(['cuurentUrl']);
    this.router.navigateByUrl(cuurentUrl).then(() => {
      this.router.navigated = false;
      this.router.navigate(['/adminLogin']);
    });
  }

  ngAfterViewInit() {
    //location.reload();
    this.router.navigate(['/adminLogin']);
    //refreshLogin();
  }

  reloadpage(){
    location.reload();
  }

}
