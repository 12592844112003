import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { Observable } from 'rxjs';
import { AuthService } from './../../services/auth.service';
import { ApiService } from 'src/app/services/api.service';
declare var $: any;
const STORAGE_KEY = "appStorage";
const TOKEN_KEY = "accessToken";

@Component({
  selector: 'app-header',
  templateUrl: './header.component.html',
  styleUrls: ['./header.component.css']
})
export class HeaderComponent implements OnInit {


  isLoggedIn$: Observable<boolean> | undefined;
  roleMenus$: any = [];
  menuList: any = [];
  newmenuList: any = [];
  menuListData: any = [];
  roleid: any;
  loginname: any;
  newroleid: any;
  showMenu: any;
  showLogin: any;
  isdashboard: any;
  pageHeader: any = "Dashboard";
  getMenuItemList: any = [];
  appstorage: any;

  loginpage: any;

  constructor(private authService: AuthService,
    private router: Router,
    private apiService: ApiService) {
    this.isLoggedIn$ = this.authService.isLoggedIn;
    this.showMenu = sessionStorage.getItem('showMenu');
    this.showLogin = sessionStorage.getItem('showLogin');
    this.roleid = sessionStorage.getItem('RoleID');
    this.newmenuList = JSON.parse(<any>sessionStorage.getItem('MENUITEMLIST'));
    this.loginpage = localStorage.getItem('loginform');
    
    
  }
  ngOnInit(): void {
    this.isLoggedIn$ = this.authService.isLoggedIn;
    console.log('islogedin :',this.isLoggedIn$ );
    this.showMenu = sessionStorage.getItem('showMenu');
    this.showLogin = sessionStorage.getItem('showLogin');
    console.log('menulogin :',this.showLogin );
    this.loginname = sessionStorage.getItem('LoginName');
    console.log('login Name', this.loginname);
    this.getMenus();
    
    //this.authService.loadJsFile("assets/assetsAdmin/js/main.js");
  }


  goto(item: any) {

    this.pageHeader = item;
    switch (item) {

      case 'Ward': this.router.navigate(['admin-ward']);
        break;
      case 'Tender': this.router.navigate(['adminTender']);
        break;
      case 'Circular/ Notices/ Orders': this.router.navigate(['adminNotice']);
        break;
      case 'NewsEvent': this.router.navigate(['adminNewsevent']);
        break;
      case 'Page': this.router.navigate(['adminPage']);
        break;
      case 'Recruitment': this.router.navigate(['adminRecruitment']);
        break;
      case 'Downloads': this.router.navigate(['adminDownload']);
        break;
      case 'Gallery': this.router.navigate(['admingallery']);
        break;
      case 'InternalAudit': this.router.navigate(['adminInternalAudit']);
      break;
      case 'change-profile': this.router.navigate(['change-profile']);


    }
  }
  logOut() {
    this.authService.logout();
  }

  getMenus() {
    this.newroleid = localStorage.getItem('userrole');
    console.log('role: ', this.roleid);
    this.apiService.adminMenu(this.roleid).subscribe((res: any) => {
      if (res.result == "Success") {
        this.menuList = res.data;
      }
    });
  }
}
