import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-self-help-grp',
  templateUrl: './self-help-grp.component.html',
  styleUrls: ['./self-help-grp.component.css']
})
export class SelfHelpGrpComponent implements OnInit {

  constructor() { }

  ngOnInit(): void {
  }

}
