<app-header></app-header>
<div class="inner-background-two">
    <img src="assets/images/inner_banner.jpg">
    <div class="latest-overlay-two">
    <div class="container"><h2>Ward Committee</h2></div>
  </div>
  </div>
  <section class="inner-post">
    <div class="container">
      <div class="row">
         <div class="col-lg-9 col-sm-12 col-12">
            
      
        <!-- <div *ngIf="vicechairman">
          <span [innerHtml]="vicechairman.mainContent"></span>
        </div> -->
         
           </div>
      
      </div>
    </div>
  </section>
