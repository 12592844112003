<link href="/src/assets/assetsAdmin/css/main.css" rel="stylesheet">
<div class="login-page">
    <div class="log-box">
        <form class="log-frm">
            <div style="text-align:center; margin-bottom:50px">
                <img src="../../../assets/assetsAdmin/img/logo1.png">
            </div>            
             <h4>Admin</h4>           

            <div class="form-group">
                <label>Username</label>
                <input type="text" [(ngModel)]="name" name="name" placeholder>
            </div>

            <div class="form-group">
                <label>Password</label>
                <input type="Password" [(ngModel)]="pwd" name="pwd" placeholder>
                <a href="javascript:void(0);" class="pas-view"></a>
            </div>

            <div class="form-group text-center pb-0">
                <button type="button" class="login-btn" (click)="login()">Login</button>
                <!-- <a href="javascript:void(0);">Forgot Password ?</a> -->
            </div>
        </form>
    </div>
</div>
<!-- <script src="assets/assetsAdmin/js/jquery-3.2.1.min.js"></script> -->



<!-- <script src="assets/assetsAdmin/js/jquery-3.2.1.min.js"></script>
<script src="assets/assetsAdmin/js-old/"></script>
  <script src="assets/assetsAdmin/js/main.js"></script>
  <script src='https://cdn.ckeditor.com/4.13.0/standard/ckeditor.js'></script> -->
<footer class="log-footer">
    <p>Copyright &copy; 2022 company name. All right reserved.</p>
</footer>