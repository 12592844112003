<app-header></app-header>
<div class="inner-background-two">
    <img src="assets/images/inner_banner.jpg">
    <div class="latest-overlay-two">
    <div class="container"><h2>WBRTPS</h2></div>
  </div>
  </div>
  <section class="inner-post">
    <div class="container">
      <div class="row">
         <div class="col-lg-9 col-sm-12 col-12">
          <div>
            <h1 style="color:rgb(4, 61, 167)">West Bengal Right to Public Service Act & Rules</h1>
            <div class="table-responsive">
              <table class="table table-bordered table-striped">
                  <tbody >
                  <tr>
                      <td style="width:20px; font-size:medium; font-weight: 500; text-align:left">
                        West Bengal Right to Public Service Act, 2013</td>
                      <td style="width:20px; font-size:medium; font-weight: 500;">
                      <a href="javascript:void(0);" target="_blank" href="../../../assets/images/pdfs_wbrtps_act_2013.pdf"><img src="../../../assets/images/pdf_icon.png">Download </a> </td>
                  </tr>
                  <tr>
                      <td style="width:20px; font-size:medium; font-weight: 500; text-align:left">West Bengal Right to Public Service Rules, 2013</td>
                      <td style="width:20px; font-size:medium; font-weight: 500;">
                      <a href="javascript:void(0);" target="_blank" href="../../../assets/images/pdfs_wbrtps_rules_2013.pdf"><img src="../../../assets/images/pdf_icon.png">Download </a> </td>
                  </tr>	
                   <tr>
                      <td style="width:20px; font-size:medium; font-weight: 500; text-align:left">Amendment of West Bengal Right to Public Service Rules, 2013</td>
                      <td style="width:20px; font-size:medium; font-weight: 500;">
                      <a href="javascript:void(0);" target="_blank" href="../../../assets/images/pdfs_wbrtps_rule_amendment.pdf"><img src="../../../assets/images/pdf_icon.png">Download </a> </td>
                  </tr>		
                  <tr>
                    <td style="width:20px; font-size:medium; font-weight: 500; text-align:left">Gazette Notification dated 26th May, 2017</td>
                    <td style="width:20px; font-size:medium; font-weight: 500;">
                    <a href="javascript:void(0);" target="_blank" href="../../../assets/images/pdfs_1716_ud_o_m_estt_e-19_2014.pdf"><img src="../../../assets/images/pdf_icon.png">Download </a> </td>
                </tr>	
                <tr>
                  <td style="width:20px; font-size:medium; font-weight: 500; text-align:left">Gazette Notification dated 17th October, 2014</td>
                  <td style="width:20px; font-size:medium; font-weight: 500;">
                  <a href="javascript:void(0);" target="_blank" href="../../../assets/images/pdfs_3406-ud_o_m_estt_e-19_2014.pdf"><img src="../../../assets/images/pdf_icon.png">Download </a> </td>
              </tr>	
              <tr>
                <td style="width:20px; font-size:medium; font-weight: 500; text-align:left">Gazette Notification dated 06th November, 2013</td>
                <td style="width:20px; font-size:medium; font-weight: 500;">
                <a href="javascript:void(0);" target="_blank" href="../../../assets/images/pdfs_3970-ud_o_m_estt_e-19_2014.pdf"><img src="../../../assets/images/pdf_icon.png">Download </a> </td>
            </tr>	
                  </tbody>
                </table>
            </div>
            <br>
            <h5 style="color:rgb(4, 61, 167)"><u>List of Services</u></h5>
            <div class="table-responsive">
              <table class="table table-bordered table-striped">
                  <tbody >
                  <tr>
                      <td style="width:20px; font-size:medium; font-weight: 500;  text-align:left">
                        Partial modification of Notification No. 2453-UD/O/M/ESTT/E-19/2014(Pt-I) of the WBRTPS Act, 2013 </td>
                      <td style="width:20px; font-size:medium; font-weight: 500;">
                      <a href="javascript:void(0);" target="_blank" href="../../../assets/images/2748_ud.pdf"><img src="../../../assets/images/pdf_icon.png">Download </a> </td>
                  </tr>
                  <tr>
                     
                      <td style="width:20px; font-size:medium; font-weight: 500; text-align:left">Stipulated time limit for rendering the services as per WBRTPS Act, 2013 [Gazette Notification No. 2453-UD/O/M/ESTT./E-19/2014(Pt-I)] Bengali Version </td>
                      <td style="width:20px; font-size:medium; font-weight: 500;">
                      <a href="javascript:void(0);" target="_blank" href="../../../assets/images/2453_ud_bengali.pdf"><img src="../../../assets/images/pdf_icon.png">Download </a> </td>
                  </tr>	
                   <tr>
                     
                      <td style="width:20px; font-size:medium; font-weight: 500; text-align:left">Stipulated time limit for rendering the services as per WBRTPS Act, 2013 [Gazette Notification No. 2453-UD/O/M/ESTT./E-19/2014(Pt-I)]</td>
                      <td style="width:20px; font-size:medium; font-weight: 500;">
                      <a href="javascript:void(0);" target="_blank" href="../../../assets/images/2453-ud_o_m_estt_e-19_2014.pdf"><img src="../../../assets/images/pdf_icon.png">Download </a> </td>
                  </tr>		
                  <tr>
                    <td style="width:20px; font-size:medium; font-weight: 500; text-align:left">Stipulated time limit for rendering the services as per WBRTPS Act, 2013 [Gazette Notification No. 1716-UD/O/M/ESTT./E-19/2014(Pt-I)]</td>
                    <td style="width:20px; font-size:medium; font-weight: 500;">
                    <a href="javascript:void(0);" target="_blank" href="../../../assets/images/pdfs_1716_ud_o_m_estt_e-19_2014.pdf"><img src="../../../assets/images/pdf_icon.png">Download </a> </td>
                </tr>	
                <tr>
                  <td style="width:20px; font-size:medium; font-weight: 500; text-align:left">Gazette Notification dated 17th October, 2014</td>
                  <td style="width:20px; font-size:medium; font-weight: 500;">
                  <a href="javascript:void(0);" target="_blank" href="../../../assets/images/1716-ud_o_m_estt_e-19_20141.pdf"><img src="../../../assets/images/pdf_icon.png">Download </a> </td>
              </tr>	
                  </tbody>
                </table>
            </div>
            <br>
            <h5 style="color:rgb(4, 61, 167)"><u>Forms</u></h5>
            <div class="table-responsive">
              <table class="table table-bordered table-striped" >
                  <tbody >
                  <tr>
                      <td style="width:20px; font-size:medium; font-weight: 500;  text-align:left">
                        Form I ( ACKNOWLEDGEMENT ) </td>
                      <td style="width:20px; font-size:medium; font-weight: 500;">
                      <a href="javascript:void(0);" target="_blank" href="../../../assets/images/pdfs_form_1.pdf"><img src="../../../assets/images/pdf_icon.png">Download </a> </td>
                  </tr>
                  <tr>
                      <td style="width:20px; font-size:medium; font-weight: 500; text-align:left">Form II ( FORM OF APPEAL TO THE APPELLATE OFFICER ) </td>
                      <td style="width:20px; font-size:medium; font-weight: 500;">
                      <a href="javascript:void(0);" target="_blank" href="../../../assets/images/pdfs_form_2.pdf"><img src="../../../assets/images/pdf_icon.png">Download </a> </td>
                  </tr>	
                   <tr>
                      <td style="width:20px; font-size:medium; font-weight: 500; text-align:left">Form III ( FORM OF THE APPEAL TO THE REVIEWING OFFICER )</td>
                      <td style="width:20px; font-size:medium; font-weight: 500;">
                      <a href="javascript:void(0);" target="_blank" href="../../../assets/images/pdfs_form_3.pdf"><img src="../../../assets/images/pdf_icon.png">Download </a> </td>
                  </tr>		
                  </tbody>
                </table>
            </div>
            <br>
            <h5 style="color:rgb(4, 61, 167)"><u>RANAGHAT MUNICIPALITY
              West Bengal Right to Public Services Act, 2013</u></h5>
            <div class="table-responsive">
              <table class="table table-bordered table-striped" >
                  <tbody >
                  <tr>
                      <td style="width:20px; font-size:medium; font-weight: 500;  text-align:left">
                        West Bengal Right to Public Services Act, 2013</td>
                      <td style="width:20px; font-size:medium; font-weight: 500;">
                      <a href="javascript:void(0);" target="_blank" href="../../../assets/images/RANAGHAT MUNICIPALITY W.B. Right to Public Serive Act 2013.pdf"><img src="../../../assets/images/pdf_icon.png">Download </a> </td>
                  </tr>
                  </tbody>
                </table>
            </div>
          </div>

          <!-- <div class="wardcouncilor" *ngIf="getwbrps">
            <span  [innerHtml]="getwbrps.mainContent"></span>
           </div> -->
           
               
            <div style="text-align:right">
               
              </div> 
         
          
           </div>
         <div class="col-lg-3 col-sm-12 col-12">
         <div class="right"></div>
        
      
  
         </div>
      </div>
    </div>
  </section>


