import { Component, OnInit } from '@angular/core';
import { Router,ActivatedRoute } from '@angular/router';
import { ApiService } from 'src/app/services/api.service';
// import { OwlOptions } from 'ngx-owl-carousel-o';


@Component({
  selector: 'app-gallery-inside',
  templateUrl: './gallery-inside.component.html',
  styleUrls: ['./gallery-inside.component.css']
})
export class GalleryInsideComponent implements OnInit {

  galleryid:any;
  insideGallery:any;
  innergalley:any;
  imageObject: Array<object>=[];
  slideConfig={};


  // customOptions: OwlOptions = {
  //   loop: true,
  //   mouseDrag: false,
  //   touchDrag: false,
  //   pullDrag: false,
  //   dots: false,
  //   navSpeed: 700,
  //   navText: ['', ''],
  //   responsive: {
  //     0: {
  //       items: 1
  //     },
  //     400: {
  //       items: 2
  //     },
  //     740: {
  //       items: 3
  //     },
  //     940: {
  //       items: 4
  //     }
  //   },
  //   nav: true
  // }

  constructor(private apiService: ApiService,private router: Router,private route:ActivatedRoute) { 
    this.route.params.subscribe(params => {
      this.galleryid = params.galleryid

    });
  }

  ngOnInit(): void {
      this.apiService.enterGallery(this.galleryid).subscribe((res:any )=> {
     
      this.insideGallery = res.data.photos;
    
      for(let item of this.insideGallery){
        let image = `data:image/png;base64,${item.byteData}`;
        let param = [
          {
            image: image,
            thumbImage: image,
            title: 'Slider Image 1',
            alt: 'Image Alt 1'
          }
        ];  
        this.imageObject.push(param);
      }
      
   },
   (error:any )=> {
    
   });
  }


}

