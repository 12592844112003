import { Component, OnInit } from '@angular/core';
import { NgxSpinnerService } from "ngx-spinner";
import { ApiService } from 'src/app/services/api.service';

@Component({
  selector: 'app-slum-population',
  templateUrl: './slum-population.component.html',
  styleUrls: ['./slum-population.component.css']
})
export class SlumPopulationComponent implements OnInit {
 slumPopulation: any;
  constructor(private apiService: ApiService,
    private SpinnerService: NgxSpinnerService,) { }

  ngOnInit(): void {
    this.apiService.slumPopulationData().subscribe((res:any )=> {
      this.SpinnerService.show();
      this.slumPopulation= res.data;
      this.SpinnerService.hide();
  },
  (error:any )=> {
   
  });
  }

}
