
    
<app-header></app-header>
<div class="inner-background-two">
  <img src="assets/images/inner_banner.jpg">
  <div class="latest-overlay-two">
    <div class="container">
      <h2>Gallery</h2>
    </div>
  </div>
</div>
<section class="inner-post">
  <div class="container">
    <div class="row">
      <div class="col-lg-9 col-sm-12 col-12">

        <div class="row" *ngIf="gallery">
          <div class="col-sm-4">
            <!-- <div *ngFor="let item of gallery"> -->
            <div class="thumbBox">
              <div class="imageWrap"><a (click)="enterGallery(gallery[0].galleryId)"><img
                    src="data:image/png;base64,{{gallery[0].byteData}}" /></a></div>
              <div class="textArea">
                <h5>{{gallery[0].subject}}</h5>
              </div>
            </div>
            <!-- </div> -->
          </div>
          <div class="col-sm-4">
            <div class="thumbBox">
              <div class="imageWrap"><a (click)="enterGallery(gallery[1].galleryId)"><img
                    src="data:image/png;base64,{{gallery[1].byteData}}" alt="#"></a></div>
              <div class="textArea">
                <h5>{{gallery[1].subject}}</h5>
              </div>
            </div>
          </div>
          <div class="col-sm-4">
            <div class="thumbBox">
              <div class="imageWrap"><a (click)="enterGallery(gallery[2].galleryId)"><img
                    src="data:image/png;base64,{{gallery[2].byteData}}" alt="#"></a></div>
              <div class="textArea">
                <h5>{{gallery[2].subject}}</h5>
              </div>
            </div>
          </div>
        </div>
        <div class="row" *ngIf="gallery">
          <div class="col-sm-4">
            <div class="thumbBox">
              <div class="imageWrap"><a (click)="enterGallery(gallery[3].galleryId)"><img
                    src="data:image/png;base64,{{gallery[3].byteData}}" alt="#"></a></div>
              <div class="textArea">
                <h5>{{gallery[3].subject}}</h5>
              </div>
            </div>
          </div>
          <div class="col-sm-4">
            <div class="thumbBox">
              <div class="imageWrap"><a (click)="enterGallery(gallery[4].galleryId)"><img
                    src="data:image/png;base64,{{gallery[4].byteData}}" alt="#"></a></div>
              <div class="textArea">
                <h5>{{gallery[4].subject}}</h5>
              </div>
            </div>
          </div>
          <div class="col-sm-4">
            <div class="thumbBox">
              <div class="imageWrap"><a (click)="enterGallery(gallery[5].galleryId)"><img
                    src="data:image/png;base64,{{gallery[5].byteData}}" alt="#"></a></div>
              <div class="textArea">
                <h5>{{gallery[5].subject}}</h5>
              </div>
            </div>
          </div>
        </div>

      </div>
    
    </div>
  </div>
</section>

