import { Component, OnInit } from '@angular/core';
import { NgxSpinnerService } from "ngx-spinner";
import { ApiService } from 'src/app/services/api.service';

@Component({
  selector: 'app-vice-chairman',
  templateUrl: './vice-chairman.component.html',
  styleUrls: ['./vice-chairman.component.css']
})
export class ViceChairmanComponent implements OnInit {
  vicechairman:any;
  constructor(private apiService: ApiService,
    private SpinnerService: NgxSpinnerService,
    ) { }

  ngOnInit(): void {
    this.apiService.ViceChairmanSpeech().subscribe((res:any )=> {
      this.SpinnerService.show();
       this.vicechairman = res.data;
       this.SpinnerService.hide(); 
   },
   (error:any )=> {
 
   });
   window.scroll({ 
    top: 0, 
    left: 0, 
    behavior: 'smooth' 
});
  } 

}
