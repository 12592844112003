import { Component, OnInit } from '@angular/core';
import { NgxSpinnerService } from "ngx-spinner";
import { ApiService } from 'src/app/services/api.service';

@Component({
  selector: 'app-recruitment',
  templateUrl: './recruitment.component.html',
  styleUrls: ['./recruitment.component.css']
})
export class RecruitmentComponent implements OnInit {
  recruitment:any;
  constructor(private apiService: ApiService,
    private SpinnerService: NgxSpinnerService,) { }

  ngOnInit(): void {
    this.apiService.recruitmentGetAll().subscribe((res:any )=> {
      this.SpinnerService.show();
      this.recruitment= res.data;
      this.SpinnerService.hide();
  },
  (error:any )=> {
   
  });
  }
  downloadRecruitment(recruitmentId:any){
    this.apiService.viewRecruitmentFile(recruitmentId).subscribe(res => {
      const fileURL = URL.createObjectURL(res);
      window.open(fileURL, '_blank');
    });

 }


}
