<app-header></app-header>
<div class="main-wapper">
  <div class="wapper-body">
    <div class="row">
      <div class="col-lg-12 col-md-12 col-sm-12 col-xs-12 col-mob-12">
        <div class="top-panel">
          <form>
            <div class="form-group">
              <input type="text" name="text" (keyup)="searchTable($event)" placeholder="Search files">
              <a href="#" class="src-icon"><img src="assets/assetsAdmin/img/loupe.png"></a>
            </div>
          </form>
          <a href="javascript:void(0);" class="add-btn"><i class="fa fa-plus" (click)="openPopupAdd()"
              aria-hidden="true" title="Add Page"></i></a>
        </div>
        <div class="bottom-panel">
          <div class="table-responsive">
            <table class="table table-striped jambo_table">
              <thead>
                <tr style="color: black; ">
                  <th style="width: 7%;">Sl No</th>
                  <th style="width: 10%;">Ward No</th>
                  <th style="width: 20%;">Ward Councillor Name</th>
                  <th style="width: 25%;">Address</th>
                  <th style="width: 12%;">Mobile No</th>
                  <th style="width: 15%;">Email</th>
                  <th style="width: 10%;">Action</th>
                </tr>
              </thead>
              <tbody *ngFor="let item of getallWardList;let i=index">
                <tr>
                  <td style="width: 7%;">{{(pager.startIndex -1) + i + 2}}</td>
                  <td style="width: 10%;">{{item.wardNo}}</td>
                  <td style="width: 20%;">{{item.wardCouncillorName}}</td>
                  <td style="width: 25%;">{{item.wardAddress }}</td>
                  <td style="width: 12%;">{{item.councillorMobileNo}}</td>
                  <td style="width: 15%;">{{item.emailId}}</td>
                  <td style="width: 10%;" class="last">
                    <div class="action-button">
                      <!-- <a href="javascript:void(0);" class="plus-btn"><i class="fa fa-plus" (click)="openPopupPlus()"
                          aria-hidden="true" title="View"></i></a> -->
                      <a href="javascript:void(0);" class="edit-btn" (click)="openPopupEdit(item.id)"><i
                          class="fa fa-edit" (click)="openPopupEdit(item.id)" aria-hidden="true" title="Edit"></i></a>
                      <!-- <a href="javascript:void(0);" class="delete-btn"><i class="fa fa-times" aria-hidden="true"></i></a> -->
                    </div>
                  </td>
                </tr>

              </tbody>
            </table>
          </div>
          <!-- #region  Pagination-->
          <div class="row">
            <div class="col-md-5">
              <div class="dataTables_info" *ngIf="totalCount>0">Showing {{pager.startIndex +1}} to {{pager.endIndex+1}}
                of {{totalCount}} entries</div>
            </div>
            <div class="col-md-7">
              <div class="dataTables_paginate paging_simple_numbers">
                <ul *ngIf="pager.pages && pager.pages.length" class="pagination">
                  <li class="paginate_button previous disabled" ng-disabled="true"
                    [ngClass]="{'disabled':pager.currentPage === 1}">
                    <a aria-controls="datatable-fixed-header" (click)="getallWardCouncillor(1)">First</a>
                  </li>
                  <li class="paginate_button previous disabled" [ngClass]="{disabled:pager.currentPage === 1}">
                    <a aria-controls="datatable-fixed-header"
                      (click)="getallWardCouncillor(pager.currentPage - 1)">Previous</a>
                  </li>
                  <li class="paginate_button previous disabled" *ngFor="let page of pager.pages"
                    [ngClass]="{active:pager.currentPage === page}">
                    <a aria-controls="datatable-fixed-header" (click)="getallWardCouncillor(page)">{{page}}</a>
                  </li>
                  <li class="paginate_button previous disabled"
                    [ngClass]="{disabled:pager.currentPage === pager.totalPages}">
                    <a aria-controls="datatable-fixed-header"
                      (click)="getallWardCouncillor(pager.currentPage + 1)">Next</a>
                  </li>
                  <li class="paginate_button previous disabled"
                    [ngClass]="{disabled:pager.currentPage === pager.totalPages}">
                    <a aria-controls="datatable-fixed-header" (click)="getallWardCouncillor(pager.totalPages)">Last</a>
                  </li>
                </ul>
              </div>
            </div>
          </div>
          <!-- #endregion -->
        </div>
      </div>
    </div>
  </div>
</div>

<!---pop-new-poroject-->
<div class="new-pop" class="modal" [ngStyle]="{'display':displayStyleAdd}">
  <div class="new-pop-body"><a href="javascript:void(0);" (click)="closePopupAdd()" class="cls-new-pop"><img
        src="assets/assetsAdmin/img/close.png" /></a>
    <h4>Add Ward Councillor</h4>
    <form id="contact-form" [formGroup]="adminWardForm">
      <div class="controls">
        <div class="row">
          <div class="col-md-4">
            <div class="form-group">
              <label for="form_name">Ward No *</label>
              <input id="form_name" type="text" class="form-control" formControlName="wardno">
              <div *ngIf="f1.wardno.invalid && (f1.wardno.dirty || f1.wardno.touched)">
                <div *ngIf="f1.wardno.errors.required" style="color: red;">
                  Please Enter Ward No
                </div>
              </div>
            </div>
          </div>
          <div class="col-md-4">
            <div class="form-group">
              <label for="form_route">Ward Councillor Name *</label>
              <input id="form_route" type="text" name="councillorname" class="form-control"
                formControlName="councillorname">
              <div *ngIf="f1.councillorname.invalid && (f1.councillorname.dirty || f1.councillorname.touched)">
                <div *ngIf="f1.councillorname.errors.required" style="color: red;">
                  Please enter ward councillor name
                </div>
              </div>
            </div>
          </div>
          <div class="col-md-4">
            <div class="form-group">
              <label for="form_name">Councillor Image</label>
              <input type="file" (change)="onSelectCouncillorImage($event)" formControlName="councillorimage"
                class="file">
              <font color="sky blue">(Maximum 3 MB)</font>

            </div>
          </div>
        </div>
        <div class="row">
          <div class="col-md-4">
            <div class="form-group">
              <label for="form_name">Ward Map</label>
              <input type="file" (change)="onSelectWardMap($event)" formControlName="wardmapimage" class="file">
              <font color="sky blue">(Maximum 3 MB)</font>
            </div>
          </div>
          <div class="col-md-4">
            <div class="form-group">
              <label for="form_route">Councillor Mobile No. *</label>
              <input id="form_route" type="text" name="councillormobileno" class="form-control"
                formControlName="councillormobileno">
              <div
                *ngIf="f1.councillormobileno.invalid && (f1.councillormobileno.dirty || f1.councillormobileno.touched)">
                <div *ngIf="f1.councillormobileno.errors.required" style="color: red;">
                  Please enter councillor Mobile
                </div>
              </div>
            </div>
          </div>
          <div class="col-md-4">
            <div class="form-group">
              <label for="form_route">Councillor WhatsApp No. *</label>
              <input id="form_route" type="text" name="councillorwhatsappno" class="form-control"
                formControlName="councillorwhatsappno">
              <div
                *ngIf="f1.councillorwhatsappno.invalid && (f1.councillorwhatsappno.dirty || f1.councillorwhatsappno.touched)">
                <div *ngIf="f1.councillorwhatsappno.errors.required" style="color: red;">
                  Please enter ward councillor WhatsApp
                </div>
              </div>
            </div>
          </div>
        </div>
        <div class="row">
          <div class="col-md-4">
            <div class="form-group">
              <label>Councillor Designation *</label>
              <select id="type" name="councillordesignation" formControlName="councillordesignation"
                class="form-control">
                <option value="Vice Chairman">Vice Chairman</option>
                <option value="Councillor">Councillor</option>
              </select>
              <div
                *ngIf="f1.councillordesignation.invalid && (f1.councillordesignation.dirty || f1.councillordesignation.touched)"
                class="alert">
                <div *ngIf="f1.councillordesignation.errors.required" style="color: red;">
                  Please select councillor designation
                </div>
              </div>
            </div>
          </div>
          <div class="col-md-4">
            <div class="form-group">
              <label>Councillor Party Name</label>
              <select id="type" name="partyname" formControlName="partyname" class="form-control">
                <option value="AITC">AITC</option>
                <option value="BJP">BJP</option>
              </select>
              <div *ngIf="f1.partyname.invalid && (f1.partyname.dirty || f1.partyname.touched)" class="alert">
                <div *ngIf="f1.partyname.errors.required" style="color: red;">
                  Please select party name
                </div>
              </div>
            </div>
          </div>
          <div class="col-md-4">
            <div class="form-group">
              <label for="form_route">Councillor Email *</label>
              <input id="form_route" type="text" name="councilloremail" class="form-control"
                formControlName="councilloremail">
              <div *ngIf="f1.councilloremail.invalid && (f1.councilloremail.dirty || f1.councilloremail.touched)">
                <div *ngIf="f1.councilloremail.errors.required" style="color: red;">
                  Please enter councillor email
                </div>
              </div>
            </div>
          </div>
        </div>
        <div class="row">
          <div class="col-md-8">
            <div class="form-group">
              <label for="form_cption">Ward Address</label>
              <input id="form_cption" type="text" name="publishingdate" formControlName="wardaddress"
                class="form-control">
              <div *ngIf="f1.wardaddress.invalid && (f1.wardaddress.dirty || f1.wardaddress.touched)">
                <div *ngIf="f1.wardaddress.errors.required" style="color: red;">
                  Please enter ward address
                </div>
              </div>
            </div>
          </div>
          <div class="col-md-4">
            <div class="form-group">
              <label for="form_cption">Ward Area</label>
              <input id="form_cption" type="text" name="publishingdate" formControlName="wardarea" class="form-control">
            </div>
          </div>
        </div>
        <div class="row">
          <div class="col-md-2">
            <div class="form-group">
              <label for="form_cption">Land Use</label>
              <select id="type" name="landuse" formControlName="landuse" class="form-control">
                <option value="Residential">Residential</option>
                <option value="Mixed">Mixed</option>
              </select>
            </div>
          </div>
          <div class="col-md-2">
            <div class="form-group">
              <label for="form_cption">Hose Hold No.</label>
              <input id="form_cption" type="text" name="householdno" formControlName="householdno" class="form-control">
            </div>
          </div>
          <div class="col-md-2">
            <div class="form-group">
              <label for="form_cption">BPL Hose Hold No.</label>
              <input id="form_cption" type="text" name="BPLhouseholdno" formControlName="BPLhouseholdno"
                class="form-control">
            </div>
          </div>
          <div class="col-md-2">
            <div class="form-group">
              <label for="form_cption">Total Population</label>
              <input id="form_cption" type="text" name="totalpopulation" formControlName="totalpopulation"
                class="form-control">
            </div>
          </div>
          <div class="col-md-2">
            <div class="form-group">
              <label for="form_cption">Total Male</label>
              <input id="form_cption" type="text" name="totalmale" formControlName="totalmale" class="form-control">
            </div>
          </div>
          <div class="col-md-2">
            <div class="form-group">
              <label for="form_cption">Total Female</label>
              <input id="form_cption" type="text" name="totalfemale" formControlName="totalfemale" class="form-control">
            </div>
          </div>
        </div>
        <div class="row">
          <div class="col-md-12">
            <div class="form-group">
              <label for="form_cption">Road Content</label>
              <textarea name="roadcontent" formControlName="roadcontent" class="form-control" rows="4">
              </textarea>
              <!-- <input id="form_cption" type="area" name="roadcontent" formControlName="roadcontent" class="form-control">               -->
            </div>
          </div>
        </div>
        <div class="row">
          <div class="col-md-2">
            <div class="form-group">
              <label for="form_cption">Total Road Length</label>
              <input id="form_cption" type="text" name="totalroadlength" formControlName="totalroadlength"
                class="form-control">
            </div>
          </div>
          <div class="col-md-2">
            <div class="form-group">
              <label for="form_cption">Population1981</label>
              <input id="form_cption" type="text" name="population1981" formControlName="population1981"
                class="form-control">
            </div>
          </div>
          <div class="col-md-2">
            <div class="form-group">
              <label for="form_cption">Population1991</label>
              <input id="form_cption" type="text" name="population1991" formControlName="population1991"
                class="form-control">
            </div>
          </div>
          <div class="col-md-2">
            <div class="form-group">
              <label for="form_cption">Population2001</label>
              <input id="form_cption" type="text" name="population2001" formControlName="population2001"
                class="form-control">
            </div>
          </div>
          <div class="col-md-2">
            <div class="form-group">
              <label for="form_cption">Population2011</label>
              <input id="form_cption" type="text" name="population2011" formControlName="population2011"
                class="form-control">
            </div>
          </div>
          <div class="col-md-2">
            <div class="form-group">
              <label for="form_cption">Population2022</label>
              <input id="form_cption" type="text" name="population2022" formControlName="population2022"
                class="form-control">
            </div>
          </div>
        </div>
        <div class="row">

          <div class="col-md-2">
            <div class="form-group">
              <label for="form_cption">Floating Population</label>
              <input id="form_cption" type="text" name="floatingpopulation" formControlName="floatingpopulation"
                class="form-control">
            </div>
          </div>
          <div class="col-md-2">
            <div class="form-group">
              <label for="form_cption">No of Hospital</label>
              <input id="form_cption" type="text" name="noofhospiatal" formControlName="noofhospiatal"
                class="form-control">
            </div>
          </div>
          <div class="col-md-2">
            <div class="form-group">
              <label for="form_cption">No Primary School</label>
              <input id="form_cption" type="text" name="noofprimaryschool" formControlName="noofprimaryschool"
                class="form-control">
            </div>
          </div>
          <div class="col-md-6">
            <div class="form-group">
              <label for="form_cption">Primary School Name <span style="font-size: 13px; color: green;">(write multiple
                  school name with comma(,))</span></label>
              <input id="form_cption" type="text" name="primaryschoolname" formControlName="primaryschoolname"
                class="form-control">
            </div>
          </div>
        </div>
        <div class="row">
          <div class="col-md-3">
            <div class="form-group">
              <label for="form_cption">No UPrimary School</label>
              <input id="form_cption" type="text" name="noofuprimaryschool" formControlName="noofuprimaryschool"
                class="form-control">
            </div>
          </div>
          <div class="col-md-6">
            <div class="form-group">
              <label for="form_cption">Upper Primary School Name <span style="font-size: 13px; color: green;">(write
                  multiple school name with comma(,))</span></label>
              <input id="form_cption" type="text" name="uprimaryschoolname" formControlName="uprimaryschoolname"
                class="form-control">
            </div>
          </div>
          <div class="col-md-3">
            <div class="form-group">
              <label for="form_cption">Total No of School</label>
              <input id="form_cption" type="text" name="totalnoschool" formControlName="totalnoschool"
                class="form-control">
            </div>
          </div>
          <div class="col-md-3">
            <div class="form-group">
              <label for="form_cption">No of College</label>
              <input id="form_cption" type="text" name="noofcollege" formControlName="noofcollege" class="form-control">
            </div>
          </div>
          <div class="col-md-3" hidden="true">
            <div class="form-group">
              <label for="form_cption">School Status</label>
              <input id="form_cption" type="text" name="schoolstatus" formControlName="schoolstatus"
                class="form-control">
            </div>
          </div>
          <div class="col-md-3">
            <div class="form-group">
              <label for="form_cption">Description Code</label>
              <input id="form_cption" type="text" name="descriptioncode" formControlName="descriptioncode"
                class="form-control">
            </div>
          </div>
          <div class="col-md-3">
            <div class="form-group">
              <label for="form_cption">Enrollment No</label>
              <input id="form_cption" type="text" name="enrollmentno" formControlName="enrollmentno"
                class="form-control">
            </div>
          </div>
        </div>
        <div class="row">
          <div class="col-md-12">
            <div class="btn-grp">
              <button type="button" class="done-btn " (click)="Submit()">Submit</button>
              <button type="button" class="cancl-btn" (click)="closePopupAdd()">Cancel</button>
            </div>
          </div>
        </div>
      </div>
    </form>
  </div>
</div>
<!---pop-Edit Pge-->
<div class="edit-pop" class="modal" [ngStyle]="{'display':displayStyleEdit}">
  <div class="new-pop-body"><a href="javascript:void(0);" (click)="closePopupEdit()" class="cls-edit-pop"><img
        src="./assets/img/close.png" /></a>
    <h4>Edit Ward Councillor</h4>
    <!-- <p>Set frame rate, size resolution</p> -->
    <form id="contact-form" role="form" [formGroup]="adminWardForm">
      <div class="controls">
        <div class="row">
          <div class="col-md-4">
            <div class="form-group">
              <label for="form_name">Ward No *</label>
              <input id="form_name" type="text" class="form-control" formControlName="wardno">
              <div *ngIf="f1.wardno.invalid && (f1.wardno.dirty || f1.wardno.touched)">
                <div *ngIf="f1.wardno.errors.required" style="color: red;">
                  Please Enter Ward No
                </div>
              </div>
            </div>
          </div>
          <div class="col-md-4">
            <div class="form-group">
              <label for="form_route">Ward Councillor Name *</label>
              <input id="form_route" type="text" name="councillorname" class="form-control"
                formControlName="councillorname">
              <div *ngIf="f1.councillorname.invalid && (f1.councillorname.dirty || f1.councillorname.touched)">
                <div *ngIf="f1.councillorname.errors.required" style="color: red;">
                  Please enter ward councillor name
                </div>
              </div>
            </div>
          </div>
          <div class="col-md-4">
            <div class="form-group">
              <label for="form_name">Councillor Image *</label>
              <input type="file" (change)="onSelectCouncillorImage($event)" formControlName="councillorimage"
                class="file">
              <font color="sky blue">(Maximum 3 MB)</font><br>
              <img src="data:image/jpg;base64,{{bytedataimageforcouncillor}}" width="150" height="130">
            </div>
            
          </div>
        </div>
        <div class="row">
          <div class="col-md-4">
            <div class="form-group">
              <label for="form_name">Ward Map *</label>
              <input type="file" (change)="onSelectWardMap($event)" formControlName="wardmapimage" class="file">
              <font color="sky blue">(Maximum 3 MB)</font><br>
              <img src="data:image/jpg;base64,{{bytedataimageforwardmap}}" width="150" height="130" />
            </div>
            
          </div>
          <div class="col-md-4">
            <div class="form-group">
              <label for="form_route">Councillor Mobile No. *</label>
              <input id="form_route" type="text" name="councillormobileno" class="form-control"
                formControlName="councillormobileno">
              <div
                *ngIf="f1.councillormobileno.invalid && (f1.councillormobileno.dirty || f1.councillormobileno.touched)">
                <div *ngIf="f1.councillormobileno.errors.required" style="color: red;">
                  Please enter councillor Mobile
                </div>
              </div>
            </div>
          </div>
          <div class="col-md-4">
            <div class="form-group">
              <label for="form_route">Councillor WhatsApp No. *</label>
              <input id="form_route" type="text" name="councillorwhatsappno" class="form-control"
                formControlName="councillorwhatsappno">
              <div
                *ngIf="f1.councillorwhatsappno.invalid && (f1.councillorwhatsappno.dirty || f1.councillorwhatsappno.touched)">
                <div *ngIf="f1.councillorwhatsappno.errors.required" style="color: red;">
                  Please enter ward councillor WhatsApp
                </div>
              </div>
            </div>
          </div>
        </div>
        <div class="row">
          <div class="col-md-4">
            <div class="form-group">
              <label>Councillor Designation *</label>
              <select id="type" name="councillordesignation" formControlName="councillordesignation"
                class="form-control">
                <option value="Vice Chairman">Vice Chairman</option>
                <option value="Councillor">Councillor</option>
              </select>
              <div
                *ngIf="f1.councillordesignation.invalid && (f1.councillordesignation.dirty || f1.councillordesignation.touched)"
                class="alert">
                <div *ngIf="f1.councillordesignation.errors.required" style="color: red;">
                  Please select councillor designation
                </div>
              </div>
            </div>
          </div>
          <div class="col-md-4">
            <div class="form-group">
              <label>Councillor Party Name</label>
              <select id="type" name="partyname" formControlName="partyname" class="form-control">
                <option value="AITC">AITC</option>
                <option value="BJP">BJP</option>
              </select>
              <div *ngIf="f1.partyname.invalid && (f1.partyname.dirty || f1.partyname.touched)" class="alert">
                <div *ngIf="f1.partyname.errors.required" style="color: red;">
                  Please select party name
                </div>
              </div>
            </div>
          </div>
          <div class="col-md-4">
            <div class="form-group">
              <label for="form_route">Councillor Email *</label>
              <input id="form_route" type="text" name="councilloremail" class="form-control"
                formControlName="councilloremail">
              <div *ngIf="f1.councilloremail.invalid && (f1.councilloremail.dirty || f1.councilloremail.touched)">
                <div *ngIf="f1.councilloremail.errors.required" style="color: red;">
                  Please enter councillor email
                </div>
              </div>
            </div>
          </div>
        </div>
        <div class="row">
          <div class="col-md-8">
            <div class="form-group">
              <label for="form_cption">Ward Address</label>
              <input id="form_cption" type="text" name="publishingdate" formControlName="wardaddress"
                class="form-control">
              <div *ngIf="f1.wardaddress.invalid && (f1.wardaddress.dirty || f1.wardaddress.touched)">
                <div *ngIf="f1.wardaddress.errors.required" style="color: red;">
                  Please enter ward address
                </div>
              </div>
            </div>
          </div>
          <div class="col-md-4">
            <div class="form-group">
              <label for="form_cption">Ward Area</label>
              <input id="form_cption" type="text" name="publishingdate" formControlName="wardarea" class="form-control">
            </div>
          </div>
        </div>
        <div class="row">
          <div class="col-md-2">
            <div class="form-group">
              <label for="form_cption">Land Use</label>
              <select id="type" name="landuse" formControlName="landuse" class="form-control">
                <option value="Residential">Residential</option>
                <option value="Mixed">Mixed</option>
              </select>
            </div>
          </div>
          <div class="col-md-2">
            <div class="form-group">
              <label for="form_cption">Hose Hold No.</label>
              <input id="form_cption" type="text" name="householdno" formControlName="householdno" class="form-control">
            </div>
          </div>
          <div class="col-md-2">
            <div class="form-group">
              <label for="form_cption">BPL Hose Hold No.</label>
              <input id="form_cption" type="text" name="BPLhouseholdno" formControlName="BPLhouseholdno"
                class="form-control">
            </div>
          </div>
          <div class="col-md-2">
            <div class="form-group">
              <label for="form_cption">Total Population</label>
              <input id="form_cption" type="text" name="totalpopulation" formControlName="totalpopulation"
                class="form-control">
            </div>
          </div>
          <div class="col-md-2">
            <div class="form-group">
              <label for="form_cption">Total Male</label>
              <input id="form_cption" type="text" name="totalmale" formControlName="totalmale" class="form-control">
            </div>
          </div>
          <div class="col-md-2">
            <div class="form-group">
              <label for="form_cption">Total Female</label>
              <input id="form_cption" type="text" name="totalfemale" formControlName="totalfemale" class="form-control">
            </div>
          </div>
        </div>
        <div class="row">
          <div class="col-md-12">
            <div class="form-group">
              <label for="form_cption">Road Content</label>
              <textarea name="roadcontent" formControlName="roadcontent" class="form-control" rows="4">
              </textarea>
              <!-- <input id="form_cption" type="area" name="roadcontent" formControlName="roadcontent" class="form-control">               -->
            </div>
          </div>
        </div>
        <div class="row">
          <div class="col-md-2">
            <div class="form-group">
              <label for="form_cption">Total Road Length</label>
              <input id="form_cption" type="text" name="totalroadlength" formControlName="totalroadlength"
                class="form-control">
            </div>
          </div>
          <div class="col-md-2">
            <div class="form-group">
              <label for="form_cption">Population1981</label>
              <input id="form_cption" type="text" name="population1981" formControlName="population1981"
                class="form-control">
            </div>
          </div>
          <div class="col-md-2">
            <div class="form-group">
              <label for="form_cption">Population1991</label>
              <input id="form_cption" type="text" name="population1991" formControlName="population1991"
                class="form-control">
            </div>
          </div>
          <div class="col-md-2">
            <div class="form-group">
              <label for="form_cption">Population2001</label>
              <input id="form_cption" type="text" name="population2001" formControlName="population2001"
                class="form-control">
            </div>
          </div>
          <div class="col-md-2">
            <div class="form-group">
              <label for="form_cption">Population2011</label>
              <input id="form_cption" type="text" name="population2011" formControlName="population2011"
                class="form-control">
            </div>
          </div>
          <div class="col-md-2">
            <div class="form-group">
              <label for="form_cption">Population2022</label>
              <input id="form_cption" type="text" name="population2022" formControlName="population2022"
                class="form-control">
            </div>
          </div>
        </div>
        <div class="row">

          <div class="col-md-2">
            <div class="form-group">
              <label for="form_cption">Floating Population</label>
              <input id="form_cption" type="text" name="floatingpopulation" formControlName="floatingpopulation"
                class="form-control">
            </div>
          </div>
          <div class="col-md-2">
            <div class="form-group">
              <label for="form_cption">No of Hospital</label>
              <input id="form_cption" type="text" name="noofhospiatal" formControlName="noofhospiatal"
                class="form-control">
            </div>
          </div>
          <div class="col-md-2">
            <div class="form-group">
              <label for="form_cption">No Primary School</label>
              <input id="form_cption" type="text" name="noofprimaryschool" formControlName="noofprimaryschool"
                class="form-control">
            </div>
          </div>
          <div class="col-md-6">
            <div class="form-group">
              <label for="form_cption">Primary School Name <span style="font-size: 13px; color: green;">(write multiple
                  school name with comma(,))</span></label>
              <input id="form_cption" type="text" name="primaryschoolname" formControlName="primaryschoolname"
                class="form-control">
            </div>
          </div>
        </div>
        <div class="row">
          <div class="col-md-3">
            <div class="form-group">
              <label for="form_cption">No UPrimary School</label>
              <input id="form_cption" type="text" name="noofuprimaryschool" formControlName="noofuprimaryschool"
                class="form-control">
            </div>
          </div>
          <div class="col-md-6">
            <div class="form-group">
              <label for="form_cption">Upper Primary School Name <span style="font-size: 13px; color: green;">(write
                  multiple school name with comma(,))</span></label>
              <input id="form_cption" type="text" name="uprimaryschoolname" formControlName="uprimaryschoolname"
                class="form-control">
            </div>
          </div>
          <div class="col-md-3">
            <div class="form-group">
              <label for="form_cption">Total No of School</label>
              <input id="form_cption" type="text" name="totalnoschool" formControlName="totalnoschool"
                class="form-control">
            </div>
          </div>
          <div class="col-md-3">
            <div class="form-group">
              <label for="form_cption">No of College</label>
              <input id="form_cption" type="text" name="noofcollege" formControlName="noofcollege" class="form-control">
            </div>
          </div>
          <div class="col-md-3" hidden="true">
            <div class="form-group">
              <label for="form_cption">School Status</label>
              <input id="form_cption" type="text" name="schoolname" formControlName="schoolstatus" class="form-control">
            </div>
          </div>
          <div class="col-md-3">
            <div class="form-group">
              <label for="form_cption">Description Code</label>
              <input id="form_cption" type="text" name="descriptioncode" formControlName="descriptioncode"
                class="form-control">
            </div>
          </div>
          <div class="col-md-3">
            <div class="form-group">
              <label for="form_cption">Enrollment No</label>
              <input id="form_cption" type="text" name="enrollmentno" formControlName="enrollmentno"
                class="form-control">
            </div>
          </div>
        </div>
        <div class="row">
          <div class="col-md-12">
            <div class="btn-grp">
              <button type="button" class="done-btn" (click)="Update()">Update</button>
              <button type="button" class="cancl-btn" (click)="closePopupEdit()">Cancel</button>
            </div>
          </div>
        </div>
      </div>
    </form>
  </div>
</div>